import { makeStyles, shorthands } from "@griffel/react";
import * as styleConstants from "./input-constants-fabric.styles";

export const useCheckboxStyles = makeStyles({
  checkbox: {
    marginTop: "12px",
    position: "relative",
    display: "block",
    "& input[type='checkbox']": {
      position: "absolute",
      marginLeft: "-28px",
    },
    ":global([dir='rtl']) & input[type='checkbox']": {
      marginRight: "-28px",
    },
    "& label": {
      minHeight: "20px",
      paddingLeft: "28px",
      cursor: "pointer",
      display: "inline-block",
      maxWidth: "100%",
    },
    ":global([dir='rtl']) & label": {
      paddingRight: "28px",
    },
  },
});

export const useTextInputStyles = makeStyles({
  textbox: {
    width: "100%",
    position: "relative",
    display: "block",
    "& input": {
      height: `${styleConstants.HEIGHT}`,
      width: "100%",
      outlineStyle: "none",
      backgroundColor: "transparent",
      ...shorthands.borderStyle(`${styleConstants.BORDER_STYLE}`),
      ...shorthands.borderRadius(`${styleConstants.BORDER_RADIUS}`),
      ...shorthands.borderWidth(
        `${styleConstants.BORDER_WIDTH}`,
        `${styleConstants.BORDER_WIDTH}`,
        `${styleConstants.BORDER_BOTTOM_WIDTH}`,
        `${styleConstants.BORDER_WIDTH}`,
      ),
      ...shorthands.borderColor(`${styleConstants.BORDER_COLOR}`),
      ...shorthands.padding(
        `${styleConstants.PADDING_VERTICAL}`,
        `${styleConstants.PADDING_RIGHT}`,
        `${styleConstants.PADDING_VERTICAL}`,
        `${styleConstants.PADDING_LEFT}`,
      ),
      ":hover": {
        ...shorthands.borderColor(`${styleConstants.BORDER_COLOR_HOVER}`),
      },
      ":focus": {
        ...shorthands.borderColor(`${styleConstants.BORDER_COLOR_FOCUS}`),
      },
      "::-ms-reveal": {
        height: "100%",
        ...shorthands.padding("4px", "8px"),
        marginLeft: "4px",
        marginRight: "-8px",
        ":hover": { color: styleConstants.BORDER_COLOR_FOCUS },
        ":active": { color: "#FFF", backgroundColor: styleConstants.BORDER_COLOR_FOCUS },
      },
    },
    "& input.has-error": {
      ...shorthands.borderColor(`${styleConstants.BORDER_COLOR_FOCUS_HAS_ERROR}`),
      "::-ms-reveal": {
        ":hover": { color: styleConstants.REVEAL_ICON_ERROR_COLOR },
        ":active": {
          color: "#FFFFFF",
          backgroundColor: styleConstants.REVEAL_ICON_ERROR_COLOR,
        },
      },
    },
  },
  emailDomainContainer: {
    display: "flex",
    flexDirection: "row",
  },
  emailDomainInputWrapper: {
    width: "100%",
  },
  emailInputDomain: {
    display: "flex",
    alignItems: "end",
    ...shorthands.borderBottom("1px", "solid", styleConstants.BORDER_COLOR),
    ...shorthands.padding("6px", "0", "6px", "12px"),
    marginBottom: "16px",
    fontSize: "14px",
    color: styleConstants.EMAIL_DOMAIN_COLOR,
  },
  emailInputDomainError: {
    ...shorthands.borderBottom("1px", "solid", styleConstants.BORDER_COLOR_FOCUS_HAS_ERROR),
  },
});

export const useRadioButtonStyles = makeStyles({
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: "6px",
    marginBottom: "12px",
    cursor: "pointer",
    "& > *": {
      cursor: "pointer",
    },
  },
  hiddenContainer: {
    display: "none",
  },
  button: {
    width: "20px",
    height: "20px",
    color: "blue",
  },
  label: {
    marginLeft: "8px",
    marginRight: "8px",
  },
  disabledLabel: {
    color: styleConstants.DISABLED_LABEL,
  },
});
