import React from "react";
import { type TileComponent, Tile } from "./tile";

export interface ITileListProps {
  /** list of tile components */
  tileList: TileComponent[];
  /** aria label for tile list */
  ariaLabelledBy?: string;
  /** ariaDescribedBy for first focused tile */
  ariaDescribedBy?: string;
}

/**
 * TileList component
 * @param props ITileListProps properties for this component
 * @returns A list of tiles
 */
export const TileList: React.FC<ITileListProps> = function TileList(props) {
  const { tileList, ariaLabelledBy, ariaDescribedBy } = props;

  const tileListItems = tileList.map((tileProp, index) => (
    <Tile
      key={tileProp.mainText}
      mainText={tileProp.mainText}
      helpText={tileProp.helpText}
      subText={tileProp.subText}
      imageUrl={tileProp.imageUrl}
      imageRole={tileProp.imageRole}
      onTileClick={tileProp.onTileClick}
      hasInitialFocus={index === 0}
      ariaDescribedBy={index === 0 ? ariaDescribedBy : undefined}
      ariaLabel={tileProp.ariaLabel}
      helpDialogIcon={tileProp.helpDialogIcon}
      helpDialogHeader={tileProp.helpDialogHeader}
      helpDialogDescription={tileProp.helpDialogDescription}
      helpDialogIconTestId={tileProp.helpDialogIconTestId}
      mainTextReplaceValue={tileProp.mainTextReplaceValue}
      optionsMenuButton={tileProp.optionsMenuButton}
    />
  ));

  return (
    <div role="list" id="tileList" aria-labelledby={ariaLabelledBy}>
      {tileListItems}
    </div>
  );
};
