import { type ITelemetryEvent } from "@msidentity/telemetry";
import { type Flavors } from "../constants";
import { type FlowId, type ViewId } from "../constants/routing-constants";
import GlobalConfig from "../global-config";
import type { GlobalState } from "../global-context";

export enum TelemetryEventType {
  Exception = "IDUX_ClientTelemetry_Exceptions",
  Plt = "IDUX_ClientTelemetry_PltEvents",
  ServiceDiag = "IDUX_ClientTelemetry_ServiceDiag",
  UserAction = "IDUX_ClientTelemetry_UserActions",
  Api = "IDUX_ClientTelemetry_ApiEvents",
}

export interface IStandardDimensions {
  view: ViewId & string;
  flow: FlowId & string;
  flavor: Flavors & string;
  correlationId: string;
  hostPageId: string;
}

interface IUserActionsEvent extends ITelemetryEvent {
  _table: TelemetryEventType.UserAction;
  actionName: string;
  actionValue: string;
  dimensions: IStandardDimensions;
}

interface IPltEvent extends ITelemetryEvent {
  _table: TelemetryEventType.Plt;
  dimensions: IStandardDimensions;
}

interface IExceptionEvent extends ITelemetryEvent {
  _table: TelemetryEventType.Exception;
  message: string;
  errorName: string;
  stack: string;
  source: string;
  currentState: Record<string, string>;
  dimensions: IStandardDimensions;
}

// TODO: metricName should be strongly typed
interface IServiceDiagEvent extends ITelemetryEvent {
  _table: TelemetryEventType.ServiceDiag;
  metricName: string;
  metricValue: string;
  dimensions: IStandardDimensions;
}

interface IApiEvent extends ITelemetryEvent {
  _table: TelemetryEventType.Api;
  apiName: string;
  networkDuration: number;
  totalProcessingTime: number;
  responseCode: number;
}

export type TelemetryEvents =
  | IUserActionsEvent
  | IPltEvent
  | IExceptionEvent
  | IServiceDiagEvent
  | IApiEvent;

/**
 * Extracts standard dimensions from the global state. These standard dimensions are used as primary filters in
 * Geneva and should therefore be added to every event.
 * @param state The global state from which to extract the standard dimensions
 * @returns The dimensions which should be added to every event
 */
export function getStandardDimensions(
  state: {
    activeView: ViewId;
    activeFlow: FlowId;
    activeFlavor: Flavors;
  } & Partial<GlobalState>,
): IStandardDimensions {
  // Do not add any PII dimensions here until scrubbing is enabled
  return {
    view: state.activeView,
    flow: state.activeFlow,
    flavor: state.activeFlavor,
    correlationId: GlobalConfig.instance.correlationId,
    hostPageId: GlobalConfig.instance.hostPageId,
    // here is where we can annotate other standard dimensions like build version, server affinity, DC etc.
  };
}
