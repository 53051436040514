import React, { type FormEvent } from "react";
import { InputContainer } from "../../../../components/inputs/input-container";
import {
  TextButtonContainer,
  TextButtonFabric,
} from "../../../../components/text-button/fabric/text-button-fabric";
import { TitleFabric } from "../../../../components/title/fabric/title-fabric";
import { FlowId, ViewId } from "../../../../constants/routing-constants";
import globalConfig from "../../../../global-config";
import { useActivateView } from "../../../../hooks/use-activate-view";
import { useDocumentTitle } from "../../../../hooks/use-document-title";
import useFabricStyles from "../../../../styles/fabric/fabric.styles";
import LoginConfig from "../../login-config";
import { useRemoteConnectCanaryValidationHook } from "../hooks/remote-connect-canary-validation-hooks";
import { remoteConnectCanaryValidationStringsFabric } from "./remote-connect-canary-validation-strings-fabric";

export interface IRemoteConnectCanaryValidationViewProps {
  nextView: string;
}

/**
 * RemoteConnectCanaryValidationView component
 * @param props - Props for the component; it has the 'nextView' to be used coming from Initial view picker which determines the view that would have been shown if the user had not been redirected to this view.
 * @returns A rendered instance of this component
 */
export const RemoteConnectCanaryValidationViewFabric: React.FC<IRemoteConnectCanaryValidationViewProps> =
  function RemoteConnectCanaryValidationViewFabric(props) {
    const { allowCancel, cancelUrl } = globalConfig.instance;
    const { remoteConnectAppName, remoteConnectUserCode } = LoginConfig.instance;
    const { documentTitle, title, description, cancelLabel, nextLabel } =
      remoteConnectCanaryValidationStringsFabric;
    const finalTitle = title.replace("{0}", remoteConnectAppName);
    const { nextView } = props;

    useActivateView(ViewId.RemoteConnectCanaryValidation, FlowId.Login, {
      showIdentityBanner: true,
    });
    useDocumentTitle(documentTitle);

    const fabricStyles = useFabricStyles();
    const { redirect, navigator, onSubmitHandler } = useRemoteConnectCanaryValidationHook();
    const onSubmit = (e: FormEvent<HTMLFormElement>) =>
      onSubmitHandler(redirect, navigator, nextView, e);

    return (
      <form
        name="remoteConnectCanaryValidationForm"
        data-testid="remoteConnectCanaryValidationForm"
        noValidate
        spellCheck="false"
        method="post"
        autoComplete="false"
        onSubmit={onSubmit}
      >
        <TitleFabric
          titleId="remoteConnectCanaryValidationTitle"
          title={finalTitle}
          descriptionId="remoteConnectCanaryValidationDescription"
          description={description}
        />

        <div data-testid="userCode" className={fabricStyles.displaySign}>
          {remoteConnectUserCode}
        </div>

        <InputContainer>
          <div className={fabricStyles.row}>
            <TextButtonContainer>
              {allowCancel && cancelUrl && (
                <TextButtonFabric
                  buttonId="cancelButton"
                  label={cancelLabel}
                  isPrimary={false}
                  isButtonType
                  ariaLabel={cancelLabel}
                  // onClick={postRedirectFormToCancel} placeholder TODO: hook up to allowCancel in next PR (2495065)
                />
              )}
              <TextButtonFabric
                buttonId="nextButton"
                label={nextLabel}
                isPrimary
                isButtonType={false}
                ariaLabel={nextLabel}
              />
            </TextButtonContainer>
          </div>
        </InputContainer>
      </form>
    );
  };
