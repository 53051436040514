import React from "react";
import { MemoryRouter } from "react-router-dom";
import { AuthenticationProvider } from "../../authentication-context";
import Shell from "../../components/shell/fabric/shell-fabric";
import { Flavors, ViewId } from "../../constants";
import { LoginRoutesFabric as LoginRoutes } from "../../flows/login/fabric/login-flow-fabric";
import { shouldAddUserNameToInitialEntries } from "../../flows/login/login-util";
import { GlobalProvider } from "../../global-context";
import { getRouteFromViewId } from "../../utilities/routing-helper";
import { initLoginFromServerData } from "./init-login-from-server-data";

const serverData = window.ServerData;

const { initialGlobalState, initialLoginState, initialAuthenticationState } =
  initLoginFromServerData(serverData, Flavors.Fabric);

/**
 * The app encapsulating the Login flow. This is the outermost component
 * and will be rendered onto the page using ReactDOM.render.
 * @returns An instance of the Login app
 */
export const LoginAppFabric: React.FC = function LoginAppFabric() {
  // This will add username view to the stack giving a back arrow and navigation to go 'back' to username
  // in case of an error on another view
  const initialEntries = shouldAddUserNameToInitialEntries()
    ? [getRouteFromViewId(ViewId.Username), "/"]
    : ["/"];

  return (
    <MemoryRouter initialEntries={initialEntries}>
      <GlobalProvider initialState={initialGlobalState}>
        <AuthenticationProvider initialState={initialAuthenticationState}>
          <Shell>
            <LoginRoutes loginState={initialLoginState} />
          </Shell>
        </AuthenticationProvider>
      </GlobalProvider>
    </MemoryRouter>
  );
};
