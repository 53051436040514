import { type ResponseBody, type ResponseError } from "../../../constants/constants";

export interface OtcResponse extends ResponseBody {
  SasParams?: {
    Success?: boolean;
  };
  FlowToken?: string;
}

export interface OneTimeCodeJsonResponse extends OtcResponse {}

export interface OneTimeCodeJsonError extends ResponseError {
  responseBody?: OneTimeCodeJsonResponse;
}

export interface OneTimeCodeFormResponse extends OtcResponse {
  FlowToken?: string;
  State?: OtcStatus;
  DisplaySignForUI?: string;
}

export type OtcSuccessParams = {
  flowToken?: string;
  response: OneTimeCodeJsonResponse | OneTimeCodeFormResponse;
  displaySign?: string;
};

export type OtcFailureParams = ResponseError & {
  flowToken?: string;
  otcStatus?: number;
};

export type BaseOtcHelperParams = {
  flowToken: string;
};

export enum OtcRequestParams {
  username = "login",
  purpose = "purpose",
  flowToken = "flowtoken",
  canaryFlowToken = "canaryFlowToken",
  channel = "channel",
  uiMode = "UIMode",
  phoneCountry = "MobileCountry",
  phoneCountryCode = "MobileCC",
  unauthSessionId = "uaid",
  proofConfirmation = "ProofConfirmation",
}

export enum OtcPurposes {
  password = "eOTT_OneTimePassword",
  remoteNGC = "eOTT_RemoteNGC",
  noPassword = "eOTT_NoPasswordAccountLoginCode",
  otcLogin = "eOTT_OtcLogin",
  xboxRemoteConnect = "RemoteSignInWithUserCode",
}

export enum OtcChannel {
  authenticator = "Authenticator",
  mobileSms = "SMS",
  emailAddress = "Email",
  voiceCall = "Voice",
  pushNotifications = "PushNotifications",
}

export enum OtcType {
  emailAddress = "AltEmail",
  emailAddressEncrypted = "AltEmailE",
  mobile = "MobileNum",
  mobileEncrypted = "MobileNumE",
  sessionApprover = "SAPId",
}

export enum OtcProperties {
  state = "State",
  sessionLookupKey = "SessionLookupKey",
  displaySignForUI = "DisplaySignForUI",
  flowToken = "FlowToken",
}

export enum OtcStatus {
  none = 0,
  error = 200,
  success = 201,
  hipError = 202,
  ftError = 203,
  inputError = 204,
  destinationError = 205,
  timeout = 300,
}
