import { type UserCredential, ProofType } from "../types/credential-types";

/**
 * Given a user credential, returns true if the proof of the input credential is of email type and false otherwise
 * @param credential the user credential
 * @returns true if the proof of the input credential is of email type and false otherwise
 */
export const isCredentialEmailProofType = (credential: UserCredential): boolean => {
  const proofType = credential.proof?.type;
  return proofType === ProofType.AltEmail || proofType === ProofType.Email;
};
