import { appendOrReplaceQueryStringParams, trim } from "../../../../utilities/strings-helper";
import { LoginStringsVariant } from "../../login-constants";
import { type IPasswordViewStrings } from "./password-view-interface";

/**
 * gets view description based on the variant id
 * @param stringVariantId identifier for the type of login is in progress
 * @param signInDescription existing sign in description
 * @param passwordViewStrings strings for the password view
 * @returns final view description
 */
export const getBrandingDescription = (
  stringVariantId: LoginStringsVariant,
  signInDescription: string,
  passwordViewStrings: IPasswordViewStrings,
) => {
  let message: string | JSX.Element = "";

  const {
    loginEnterPasswordDescription,
    loginSubTitleCSISUV2,
    loginSubTitleCSISUV2Alternative,
    loginSubTitleSISU,
    loginMinecraftRemoteConnectUserNamePageDesc,
  } = passwordViewStrings;

  switch (stringVariantId) {
    case LoginStringsVariant.SkypeMoveAlias:
      message = loginEnterPasswordDescription;
      break;

    case LoginStringsVariant.CombinedSigninSignup:
    case LoginStringsVariant.CombinedSigninSignupDefaultTitle:
      message = loginSubTitleSISU;
      break;

    case LoginStringsVariant.RemoteConnectLogin:
      message = loginMinecraftRemoteConnectUserNamePageDesc;
      break;

    case LoginStringsVariant.CombinedSigninSignupV2:
      message = loginSubTitleCSISUV2;
      break;

    case LoginStringsVariant.CombinedSigninSignupV2WelcomeTitle:
      message = loginSubTitleCSISUV2Alternative;
      break;

    default:
      break;
  }

  if (
    signInDescription &&
    ![LoginStringsVariant.SkypeMoveAlias, LoginStringsVariant.RemoteConnectLogin].includes(
      stringVariantId,
    )
  ) {
    message = signInDescription;
  }

  return message;
};

/**
 * This method is used to add the "mn" (member name) parameter for the reset password link
 * It only adds the parameter if the value is
 * @param rootUrl The URL to update
 * @param queryParam The query parameter to update
 * @param queryParamValue The query param value to add to the URL
 * @returns the updated url
 */
export const updateMemberNamePrefill = (
  rootUrl: string,
  queryParam: string,
  queryParamValue: string,
) => {
  if (queryParamValue) {
    return appendOrReplaceQueryStringParams(rootUrl, {
      [queryParam]: encodeURIComponent(trim(queryParamValue)),
    });
  }

  return rootUrl;
};
