import { type IPasswordViewStrings } from "../password-view-interface";

export const passwordViewStringsFabric: IPasswordViewStrings = {
  loginPasswordPageTitle: getLocalString("Login_PasswordPage_Title"),
  primaryButtonText: getLocalString("SignIn_Title"),
  noPasswordError: getLocalString("NoPassword_Error"),
  forgotPasswordLinkText: getLocalString("ForgotPasswordLink_Text"),
  passwordHint: getLocalString("Password_Hint"),
  loginEnterPasswordDescription: getLocalString("Login_EnterPassword_Description"),
  loginSubTitleSISU: getLocalString("Login_SubTitle_SISU"),
  loginMinecraftRemoteConnectUserNamePageDesc: getLocalString(
    "Login_Minecraft_RemoteConnect_UserNamePage_Desc",
  ),
  loginSubTitleCSISUV2: getLocalString("Login_SubTitle_CSISUV2"),
  loginSubTitleCSISUV2Alternative: getLocalString("Login_SubTitle_CSISUV2_Alternative"),
  loginSwitchToCredPickerLinkEvictedAcct: getLocalString(
    "Login_SwitchToCredPicker_Link_EvictedAcct",
  ),
};
