export const PADDING_VERTICAL = "6px";
export const PADDING_RIGHT = "10px";
export const PADDING_LEFT = "0px";
export const BORDER_BOTTOM_WIDTH = "1px";
export const BORDER_STYLE = "solid";
export const BORDER_WIDTH = "0px";
export const BORDER_COLOR = "#666";
export const HEIGHT = "36px";
export const BORDER_RADIUS = 0;
export const BORDER_COLOR_HOVER = "#323232";
export const BORDER_COLOR_FOCUS = "#0067b8";
export const BORDER_COLOR_FOCUS_HAS_ERROR = "#d13438";
export const EMAIL_DOMAIN_COLOR = "#555555";
export const DISABLED_LABEL = "#cccccc";
export const REVEAL_ICON_ERROR_COLOR = "#e81123";
