import { useContext, useState } from "react";
import { type ICredentialSwitchLinkComponentProperties } from "../../../../components/credential-switch-links/credential-switch-links";
import { type CredentialType, ViewId } from "../../../../constants";
import { LoginContext } from "../../login-context";

export interface ISecondaryContentProperties {
  renderSecondaryContent: boolean;
  isRequestPending: boolean;
  credentialSwitchLinksProps: ICredentialSwitchLinkComponentProperties;
}

/**
 * @returns Properties used to render the secondary content such as credential switch links
 * @param currentCredentialType the current credential type being used to sign the user in
 */
export const useSecondaryContentProperties = (
  currentCredentialType: CredentialType,
): ISecondaryContentProperties => {
  // Login context data
  const {
    viewState: {
      credentials: { availableCredentials, useEvictedCredentials },
    },
  } = useContext(LoginContext);

  const [renderSecondaryContent] = useState<boolean>(!useEvictedCredentials);
  const [isRequestPending, setIsRequestPending] = useState<boolean>(false);
  const credentialSwitchLinksProps = {
    sourceViewId: ViewId.IdpRedirectSpeedbump,
    availableCredentials,
    currentCredential: { credentialType: currentCredentialType },
    setRequestPendingFlag: setIsRequestPending,
    shouldUpdateOtcCredential: true,
  };

  return {
    renderSecondaryContent,
    isRequestPending,
    credentialSwitchLinksProps,
  };
};
