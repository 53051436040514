import React, { useContext } from "react";
import { GlobalContext } from "../global-context";
import { BackgroundImage, useBackgroundStyles } from "./background-image";

/**
 * Background component
 * @returns PLACEHOLDER - NEEDS TO BE UPDATED
 */
const Background: React.FC = function Background() {
  const {
    globalState: {
      styles: { backgroundColor, alwaysShowBackground, layoutTemplate },
    },
  } = useContext(GlobalContext);
  const backgroundStyles = useBackgroundStyles(alwaysShowBackground, layoutTemplate);

  return (
    <div
      id="background-container"
      data-testid="background-container"
      className={backgroundStyles.background}
      role="presentation"
      style={{ backgroundColor }}
    >
      <BackgroundImage />
    </div>
  );
};

export default Background;
