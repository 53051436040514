import {
  type ITelemetryProvider,
  ConsoleTelemetryProvider,
  OneDSTelemetryProvider,
} from "@msidentity/telemetry";
import type { TelemetryEvents } from "./telemetry-helpers/telemetry-helper";
import { ConfigWrapper } from "./utilities/config-wrapper";
import { getInstrumentationKey } from "./utilities/get-instrumentation-key";
import type { ServerData } from "./utilities/server-data";
import {
  type UntrustedExternalInputText,
  setUntrustedExternalInputText,
} from "./utilities/untrusted-external-input-text";
import { type UserOptions } from "./global-reducer";

export const defaultConfig = {
  aadPostUrl: "",
  allowCancel: false,
  apiCanary: "",
  appName: {} as UntrustedExternalInputText,
  canaryTokenName: "canary",
  canaryTokenValue: "",
  cancelUrl: "",
  clientId: "",
  checkProofForAliases: false,
  context: "",
  correlationId: "",
  debugModeUrl: "",
  documentDirection: "ltr",
  environment: "",
  errorText: "",
  fidoLoginUrl: "",
  flowTokenName: "",
  footerA11yConformeUrl: "",
  footerImpressumUrl: "",
  forwardedClientId: "",
  getOtcJsonUrl: "",
  hasDefaultDarkBackground: false,
  hideDebugDetails: false,
  hideTopViewAgreementBackButton: false,
  hostPageId: "",
  initialActionId: 0,
  initialPageId: 0,
  isCiamUserFlow: false,
  isChinaDc: false,
  isCloudBuild: false,
  isHosted: false,
  isNoPasswordOneTimeCodeDisabled: false,
  isPhoneNumberSignupDisallowed: false,
  isRtlMarket: false,
  isXboxOneCore: true,
  localeId: 0,
  loginUrl: "",
  logoutUrl: "",
  manageCredsUrl: "",
  maxPollingErrors: 5,
  msaPostUrl: "",
  noPaBubbleVersion: "",
  overTheShoulder: false,
  pollingIntervalMs: 1000,
  pollingTimeoutMs: 60000,
  postUrl: "",
  postUsername: {} as UntrustedExternalInputText,
  privacyLinkExternal: false,
  remoteConnectEnabled: false,
  resetPasswordUrl: "",
  resumeUrl: "",
  returnUrl: "",
  scenarioId: 0,
  sessionId: "",
  sessionLookupKey: "",
  sessionStateUrl: "",
  showButtons: false,
  showCookieBanner: false,
  showCopyDebugDetailsLink: false,
  showCredentialPickerAsButton: false,
  showLogoutOnFooter: false,
  signInName: {} as UntrustedExternalInputText,
  signInUsername: "",
  siteId: "",
  skipUrl: "",
  switchUserUrl: "",
  telemetry: new ConsoleTelemetryProvider() as ITelemetryProvider<TelemetryEvents>,
  termsOfUseLinkExternal: false,
  transferTokenUrl: "",
  uiFlavor: 0,
  unauthenticatedSessionId: "",
  puid: "",
  xboxOneCoreSupportsSignUp: false,
};
const globalConfig = new ConfigWrapper(defaultConfig);

/**
 * The config wrapper that should be used to access global config properties
 */
export default globalConfig;

// For use when initializing username and displayname with sSigninName
export const signInNameUserOptions = (signInName: UntrustedExternalInputText) =>
  ({
    username: signInName,
    displayUsername: signInName,
    isUnsafeText: false,
  } as UserOptions);

// For use when initializing username and displayname with sPOST_Username
export const postUsernameUserOptions = (postUsername: UntrustedExternalInputText) =>
  ({
    username: postUsername,
    displayUsername: postUsername,
    isUnsafeText: false,
  } as UserOptions);

/* ********* ServerData helpers ********** */

/**
 * Initialize the global config object from ServerData. This function should be called once per App, outside
 * of the component render cycle.
 * Constant properties or properties that are not used by a React component can be stored in config
 * to improve performance and prevent unnecessary re-rendering. Any properties that are used inside a
 * React component's lifecycle (hook or render function) AND can change during the component's lifecycle
 * MUST be stored inside the state context instead. You will see unpredictable behavior at runtime if you
 * mutate config properties from inside a React component.
 * @param serverData The IDP-specific server data object that should be used to initialize the global config
 */
export function initGlobalConfig(serverData: ServerData) {
  const mappedServerData: Partial<typeof defaultConfig> = {};

  if (serverData?.sessionId) {
    mappedServerData.sessionId = serverData.sessionId;
  }

  if (serverData?.correlationId) {
    mappedServerData.correlationId = serverData.correlationId;
  }

  if (serverData?.sPOST_Username) {
    mappedServerData.postUsername = setUntrustedExternalInputText(serverData.sPOST_Username);
  }

  if (serverData?.isChinaDc) {
    mappedServerData.isChinaDc = serverData.isChinaDc;
  }

  if (
    serverData.clientEvents?.telemetryEnabled &&
    serverData.clientEvents?.appInsightsConfig?.instrumentationKey
  ) {
    const telemetryProvider = new OneDSTelemetryProvider<TelemetryEvents>();
    const instrumentationKey = getInstrumentationKey(
      serverData.clientEvents?.appInsightsConfig?.instrumentationKey,
    );
    const endpointUrl = serverData.clientEvents?.endpointUrl;

    telemetryProvider.initialize({
      instrumentationKey,
      ...(endpointUrl && { endpointUrl }),
    });
    mappedServerData.telemetry = telemetryProvider;
  }

  if (serverData?.urlImpressum) {
    mappedServerData.footerImpressumUrl = serverData.urlImpressum;
  }

  if (serverData?.a11yConformeLink) {
    mappedServerData.footerA11yConformeUrl = serverData.a11yConformeLink;
  }

  if (serverData?.urlFooterTOU) {
    mappedServerData.termsOfUseLinkExternal = true;
  }

  if (serverData?.urlFooterPrivacy) {
    mappedServerData.privacyLinkExternal = true;
  }

  if (serverData?.fIsRTLMarket) {
    mappedServerData.isRtlMarket = serverData.fIsRTLMarket;
  }

  if (serverData?.fShowCopyDebugDetailsLink) {
    mappedServerData.showCopyDebugDetailsLink = serverData.fShowCopyDebugDetailsLink;
  }

  if (serverData?.urlSetDebugMode) {
    mappedServerData.debugModeUrl = serverData.urlSetDebugMode;
  }

  if (serverData?.fIsHosted) {
    mappedServerData.isHosted = serverData.fIsHosted;
  }

  if (serverData?.hpgid) {
    mappedServerData.initialPageId = serverData.hpgid;
  }

  if (serverData?.hpgact) {
    mappedServerData.initialActionId = serverData.hpgact;
  }

  if (serverData?.apiCanary) {
    mappedServerData.apiCanary = serverData.apiCanary;
  }

  if (serverData?.fHideTopViewAgreementBackButton) {
    mappedServerData.hideTopViewAgreementBackButton = serverData.fHideTopViewAgreementBackButton;
  }

  if (serverData?.fHasBackgroundColor) {
    mappedServerData.hasDefaultDarkBackground = serverData.fHasBackgroundColor;
  }

  if (serverData?.sAppName) {
    mappedServerData.appName = setUntrustedExternalInputText(serverData.sAppName);
  }

  if (serverData?.sUnauthSessionID) {
    mappedServerData.unauthenticatedSessionId = serverData.sUnauthSessionID;

    // sUnauthSessionID is used as correlation id when no correlation id is available, ie from AccountUX
    if (!mappedServerData.correlationId) {
      mappedServerData.correlationId = serverData.sUnauthSessionID;
    }
  }

  if (serverData?.iUiFlavor) {
    mappedServerData.uiFlavor = serverData.iUiFlavor;
  }

  if (serverData?.iScenarioId) {
    mappedServerData.scenarioId = serverData.iScenarioId;
  }

  if (serverData?.urlLogout) {
    mappedServerData.logoutUrl = serverData.urlLogout;
  }

  if (serverData?.urlLogin) {
    mappedServerData.loginUrl = serverData.urlLogin;
  }

  if (serverData?.fHideDebugDetails) {
    mappedServerData.hideDebugDetails = serverData.fHideDebugDetails;
  }

  if (serverData?.sCtx) {
    mappedServerData.context = serverData.sCtx;
  }

  if (serverData?.sFTName) {
    mappedServerData.flowTokenName = serverData.sFTName;
  }

  if (serverData?.sCanaryTokenName) {
    mappedServerData.canaryTokenName = serverData.sCanaryTokenName;
  }

  if (serverData?.canary) {
    mappedServerData.canaryTokenValue = serverData.canary;
  } else if (serverData?.sCanary) {
    mappedServerData.canaryTokenValue = serverData.sCanary;
  }

  if (serverData?.urlPost) {
    mappedServerData.postUrl = serverData.urlPost;
  }

  if (serverData?.fShowCookieBanner) {
    mappedServerData.showCookieBanner = serverData.fShowCookieBanner;
  }

  if (serverData?.fAllowRemoteConnect) {
    mappedServerData.remoteConnectEnabled = !!serverData.fAllowRemoteConnect;
  }

  if (serverData?.urlRU) {
    mappedServerData.returnUrl = serverData.urlRU;
  }

  if (serverData?.urlTransferToken) {
    mappedServerData.transferTokenUrl = serverData.urlTransferToken;
  }

  if (serverData?.hpgid) {
    mappedServerData.hostPageId = serverData.hpgid.toString();
  } else if (serverData?.sPageId) {
    mappedServerData.hostPageId = serverData.sPageId;
  }

  if (serverData?.urlCancel) {
    mappedServerData.cancelUrl = serverData.urlCancel;
  }

  if (serverData?.sErrTxt) {
    mappedServerData.errorText = serverData.sErrTxt;
  }

  if (serverData?.urlManageCreds) {
    mappedServerData.manageCredsUrl = serverData.urlManageCreds;
  }

  if (serverData?.urlResetPassword) {
    mappedServerData.resetPasswordUrl = serverData.urlResetPassword;
  }

  if (serverData?.urlSwitch) {
    mappedServerData.switchUserUrl = serverData.urlSwitch;
  }

  if (serverData?.sSigninName) {
    mappedServerData.signInName = setUntrustedExternalInputText(serverData.sSigninName);
  }

  // TODO: update to UntrustedExternalInputText
  if (serverData?.sSignInUsername) {
    mappedServerData.signInUsername = serverData.sSignInUsername;
  }

  if (serverData?.fIsCiamUserFlowUx) {
    mappedServerData.isCiamUserFlow = true;
  }

  // Polling config data
  if (serverData.urlSessionState) {
    mappedServerData.sessionStateUrl = serverData.urlSessionState;
  }

  if (serverData.sSessionLookupKey) {
    mappedServerData.sessionLookupKey = serverData.sSessionLookupKey;
  }

  if (serverData?.iPollingInterval) {
    mappedServerData.pollingIntervalMs = serverData.iPollingInterval * 1000;
  } else if (serverData?.iOtcPollingInterval) {
    mappedServerData.pollingIntervalMs = serverData.iOtcPollingInterval * 1000;
  }

  if (serverData.iPollingTimeout) {
    mappedServerData.pollingTimeoutMs = serverData.iPollingTimeout * 1000;
  } else if (serverData?.iOtcPollingTimeout) {
    mappedServerData.pollingTimeoutMs = serverData.iOtcPollingTimeout * 1000;
  }

  if (serverData.iMaxPollErrors) {
    mappedServerData.maxPollingErrors = serverData.iMaxPollErrors;
  }

  if (serverData.urlPostAad) {
    mappedServerData.aadPostUrl = serverData.urlPostAad;
  }

  if (serverData.urlPostMsa) {
    mappedServerData.msaPostUrl = serverData.urlPostMsa;
  }

  if (serverData.urlResume) {
    mappedServerData.resumeUrl = serverData.urlResume;
  }

  if (serverData.urlFidoLogin) {
    mappedServerData.fidoLoginUrl = serverData.urlFidoLogin;
  }

  if (serverData?.sSiteId) {
    mappedServerData.siteId = serverData.sSiteId;
  }

  if (serverData?.sClientId) {
    mappedServerData.clientId = serverData.sClientId;
  }

  if (serverData?.fShowButtons) {
    mappedServerData.showButtons = serverData.fShowButtons;
  }

  if (serverData?.fAllowCancel) {
    mappedServerData.allowCancel = serverData.fAllowCancel;
  }

  if (serverData?.sDocumentDirection) {
    mappedServerData.documentDirection = serverData.sDocumentDirection;
  }

  if (serverData?.sPUID) {
    mappedServerData.puid = serverData.sPUID;
  }

  if (serverData?.fShowLogoutOnFooter !== undefined) {
    mappedServerData.showLogoutOnFooter = serverData.fShowLogoutOnFooter;
  }

  if (serverData?.fIsCloudBuild !== undefined) {
    mappedServerData.isCloudBuild = serverData.fIsCloudBuild;
  }

  if (serverData?.urlGetOneTimeCode) {
    mappedServerData.getOtcJsonUrl = serverData.urlGetOneTimeCode;
  }

  if (serverData?.iRequestLCID) {
    mappedServerData.localeId = serverData.iRequestLCID;
  }

  if (serverData?.sForwardedClientId) {
    mappedServerData.forwardedClientId = serverData.sForwardedClientId;
  }

  if (serverData?.sNoPaBubbleVersion) {
    mappedServerData.noPaBubbleVersion = serverData.sNoPaBubbleVersion;
  }

  if (serverData?.fShowSignInOptionsAsButton !== undefined) {
    mappedServerData.showCredentialPickerAsButton = serverData.fShowSignInOptionsAsButton;
  }

  if (serverData?.environment) {
    mappedServerData.environment = serverData.environment;
  }

  if (serverData?.fIsXboxOneCore) {
    mappedServerData.isXboxOneCore = serverData.fIsXboxOneCore;
  }

  if (serverData?.fXboxOneCoreSupportsSignUp) {
    mappedServerData.xboxOneCoreSupportsSignUp = serverData.fXboxOneCoreSupportsSignUp;
  }

  if (serverData?.fIsOverTheShoulder) {
    mappedServerData.overTheShoulder = serverData.fIsOverTheShoulder;
  }

  if (serverData?.fCheckProofForAliases !== undefined) {
    mappedServerData.checkProofForAliases = serverData.fCheckProofForAliases;
  }

  if (serverData?.fIsNoPaOtcDisabled !== undefined) {
    mappedServerData.isNoPasswordOneTimeCodeDisabled = serverData.fIsNoPaOtcDisabled;
  }

  if (serverData?.fIsPhoneNumberSignupDisallowed !== undefined) {
    mappedServerData.isPhoneNumberSignupDisallowed = !!serverData.fIsPhoneNumberSignupDisallowed;
  }

  globalConfig.initialize(mappedServerData);
}
