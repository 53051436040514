import { type FormEvent, useContext, useState } from "react";
import { AuthenticationContext } from "../../../../authentication-context";
import {
  CredentialType,
  loginOption as LoginOption,
  postType as PostType,
} from "../../../../constants/constants";
import { FlowId, ViewId } from "../../../../constants/routing-constants";
import GlobalConfig from "../../../../global-config";
import { GlobalContext } from "../../../../global-context";
import { GlobalActionType } from "../../../../global-reducer";
import { useNavigateDirection } from "../../../../hooks/use-navigate-direction";
import { ServiceDiagEventNames } from "../../../../telemetry-helpers/service-diag-event-names";
import { useTelemetry } from "../../../../telemetry-helpers/use-telemetry";
import { type OneTimeCodeCredential } from "../../../../types/credential-types";
import { isNoPassword } from "../../../../utilities/api-helpers/one-time-code/get-one-time-code-helper";
import { getFidoSupport } from "../../../../utilities/browser-helper";
import { getRouteFromViewId } from "../../../../utilities/routing-helper";
import {
  cleanseUsername,
  copyQueryStringParameters,
  trimQsParams,
} from "../../../../utilities/strings-helper";
import LoginConfig from "../../login-config";
import { LoginState } from "../../login-constants";
import { LoginContext } from "../../login-context";
import { type ICommonLoginStrings } from "../../login-interface";
import { LoginActionType } from "../../login-reducer";
import { type LoginPostProps } from "../../login-types";
import { getCommonTitle, getShowPhoneDisambigLink } from "../../login-util";
import {
  type IOneTimeCodeViewProperties,
  type IOneTimeCodeViewStrings,
} from "../one-time-code-view-interface";
import { processOtcCredential, unsafePageDescription } from "../one-time-code-view-util";

/**
 * @returns Properties for the evicted cred picker link
 * (on click handler, flag to show the link or not)
 */
export const useEvictedCredPickerLinkProps = () => {
  const {
    viewState: {
      credentials: { evictedCredentials },
    },
    dispatchStateChange: dispatchLoginStateChange,
  } = useContext(LoginContext);
  const navigate = useNavigateDirection();

  const switchToEvictedCredPickerOnClick = () => {
    dispatchLoginStateChange({
      type: LoginActionType.UpdateCredentials,
      payload: { useEvictedCredentials: true },
    });
    navigate(ViewId.OneTimeCode, getRouteFromViewId(ViewId.CredentialPicker));
  };

  return {
    switchToEvictedCredPickerOnClick,
    showEvictedCredPickerLink: (evictedCredentials || []).length > 0,
  };
};

/**
 * @returns Properties for the phone disambiguation link
 * (on click handler, flag to show the link or not)
 */
export const usePhoneDisambiguationLinkProps = () => {
  const {
    globalState: { user },
  } = useContext(GlobalContext);
  const displayUsername = user.displayUsername.unsafeUnescapedString;
  const navigate = useNavigateDirection();

  const showPhoneDisambiguationLink = getShowPhoneDisambigLink(displayUsername);

  return {
    switchToPhoneDisambiguationOnClick: () =>
      navigate(ViewId.OneTimeCode, getRouteFromViewId(ViewId.PhoneDisambiguation)),
    showPhoneDisambiguationLink,
  };
};

/**
 * @param otcCredential The current one time code credential
 * @returns Properties for login form post submission
 */
export const useGetLoginPostProps = (otcCredential: OneTimeCodeCredential): LoginPostProps => {
  // Config properties
  const {
    context,
    canaryTokenValue: canary,
    flowTokenName,
    showCookieBanner,
  } = GlobalConfig.instance;

  const { defaultLoginOptions, foundMsas, isFidoSupportedHint, postedForceSignIn, randomBlob } =
    LoginConfig.instance;

  // Context properties
  const {
    globalState: { user },
  } = useContext(GlobalContext);

  const {
    viewState: {
      credentials: { availableCredentials },
      remoteNgcParams,
    },
  } = useContext(LoginContext);

  const {
    authState: { flowTokenValue },
  } = useContext(AuthenticationContext);

  const cleansedUsername = cleanseUsername(user.username.unsafeUnescapedString);
  const displayUsername = user.displayUsername.unsafeUnescapedString;
  const isFidoSupported = getFidoSupport(isFidoSupportedHint);
  const isKmsiChecked = defaultLoginOptions === LoginOption.rememberPwd;
  const loginOption = isKmsiChecked ? LoginOption.rememberPwd : LoginOption.nothingChecked;
  const postType = isNoPassword(otcCredential, availableCredentials)
    ? PostType.otcNoPassword
    : PostType.otc;

  return {
    canary,
    cleansedUsername,
    context,
    displayUsername,
    flowTokenName,
    flowTokenValue,
    foundMsas,
    isFidoSupported,
    isKmsiChecked,
    loginOption,
    paginatedState: LoginState.OneTimeCode,
    postType,
    postedForceSignIn,
    randomBlob,
    rngcDefaultType: remoteNgcParams.defaultType,
    rngcEntropy: remoteNgcParams.entropy,
    rngcSessionIdentifier: remoteNgcParams.sessionIdentifier,
    showCookieBanner,
  };
};

/**
 * @returns One time code view properties
 * @param strings Flavored strings that are used by this hook
 * @param strings.oneTimeCodeViewStrings Strings that are specific to the OneTimeCode view
 * @param strings.commonLoginStrings Strings that are common to all login views
 */
export const useOneTimeCodeViewProperties = (strings: {
  oneTimeCodeViewStrings: IOneTimeCodeViewStrings;
  commonLoginStrings: ICommonLoginStrings;
}): IOneTimeCodeViewProperties => {
  const { commonLoginStrings, oneTimeCodeViewStrings } = strings;

  // Config properties
  const { postUrl, telemetry } = GlobalConfig.instance;
  const { loginMode, rawQueryString } = LoginConfig.instance;

  // Context properties
  const {
    globalState: {
      activeFlavor,
      user,
      styles: { friendlyAppName },
    },
  } = useContext(GlobalContext);

  const {
    viewState: {
      credentials: {
        availableCredentials,
        otcCredential,
        proofConfirmation,
        useEvictedCredentials,
      },
    },
  } = useContext(LoginContext);

  // Method to log telemetry in case we throw an error
  const { logServiceDiagEvent } = useTelemetry(telemetry, {
    activeView: ViewId.OneTimeCode,
    activeFlow: FlowId.Login,
    activeFlavor,
  });

  // Safe check if otcCredential is defined. It should always be defined on this view.
  if (otcCredential === undefined) {
    logServiceDiagEvent({ metricName: ServiceDiagEventNames.OtcCredentialUndefined });

    throw Error("LoginContext.credentials.otcCredential must be defined on the OneTimeCode view.");
  }

  // Process the current otc credential to get otc max length and updated proof confirmation
  const { otcMaxLength, updatedProofConfirmation } = processOtcCredential(
    otcCredential,
    proofConfirmation,
  );

  // Strings
  const { enterCodeAriaLabel, placeholder, primaryButtonLabel, title } = oneTimeCodeViewStrings;
  const documentTitle = getCommonTitle(loginMode, friendlyAppName, commonLoginStrings);
  const unsafeDisplayName = user.displayUsername.unsafeUnescapedString;
  const description = unsafePageDescription(
    otcCredential,
    updatedProofConfirmation,
    unsafeDisplayName,
    oneTimeCodeViewStrings,
  );
  // AAD-TODO: Update ariaDescribedBy with showCredViewBrandingDesc after adding the branding description
  const enterCodeAriaDescribedBy = "oneTimeCodeTitle oneTimeCodeDescription";

  // Post url for form submission
  const updatedPostUrl = rawQueryString
    ? copyQueryStringParameters(rawQueryString, postUrl)
    : postUrl;

  // One time code props
  const proofData =
    otcCredential.credentialType === CredentialType.OneTimeCode
      ? otcCredential.proof?.data
      : cleanseUsername(user.username.unsafeUnescapedString);
  // AAD-TODO: set based on PublicIdentifierCode
  const otcInputName = isNoPassword(otcCredential, availableCredentials) ? "npotc" : "otc";

  // Cred switch link component props
  const [isRequestPending, setIsRequestPending] = useState(false);
  const credentialSwitchLinksProps = {
    sourceViewId: ViewId.OneTimeCode,
    availableCredentials,
    currentCredential: otcCredential,
    setRequestPendingFlag: setIsRequestPending,
    shouldUpdateOtcCredential: true,
  };

  return {
    credentialSwitchLinksProps,
    description,
    documentTitle,
    enterCodeAriaDescribedBy,
    enterCodeAriaLabel,
    // This flag will be used for Win10InclusiveOOBE flavor view
    isRequestPending,
    otcCredential,
    otcInputName,
    otcMaxLength,
    placeholder,
    primaryButtonLabel,
    proofConfirmation: updatedProofConfirmation,
    proofData,
    postUrl: updatedPostUrl,
    title,
    useEvictedCredentials,
  };
};

/**
 * @param postUrl The url to send the Post request to
 * @param validationError Current validation error
 * @param externalError Current external error
 * @param setFocus Sets focus on the otc input box
 * @param setShowError Shows error above the input box
 * @returns The submit handler for one time code form submission
 */
export const useOtcSubmitHandler = (
  postUrl: string,
  validationError: string,
  externalError: string | JSX.Element,
  setFocus: (focus: boolean) => void,
  setShowError: (showError: boolean) => void,
) => {
  const { dispatchStateChange: dispatchGlobal } = useContext(GlobalContext);

  return (event: FormEvent<HTMLFormElement>) => {
    if (!externalError && !validationError) {
      dispatchGlobal({
        type: GlobalActionType.BeginNavigate,
        source: ViewId.OneTimeCode,
        destination: trimQsParams(postUrl),
      });
    } else {
      // Don't submit the form, stay on page
      event.preventDefault();

      // Initiate validation - show error
      setShowError(true);
      setFocus(true);
    }
  };
};
