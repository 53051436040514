import { useCallback, useContext, useRef, useState } from "react";
import { AuthenticationContext } from "../../../../authentication-context";
import { AuthenticationActionType } from "../../../../authentication-reducer";
import {
  CredentialType,
  loginOption as LoginOption,
  postType as PostType,
  RemoteNgcType,
} from "../../../../constants/constants";
import { ViewId } from "../../../../constants/routing-constants";
import GlobalConfig from "../../../../global-config";
import { type IUser, GlobalContext } from "../../../../global-context";
import { GlobalActionType } from "../../../../global-reducer";
import {
  useBackButtonControl,
  useNavigateDirection,
} from "../../../../hooks/use-navigate-direction";
import { useRedirect } from "../../../../hooks/use-redirect";
import { ProofType } from "../../../../types/credential-types";
import { getOneTimeCode } from "../../../../utilities/api-helpers/one-time-code/get-one-time-code-helper";
import {
  type OtcFailureParams,
  type OtcSuccessParams,
  OtcChannel,
  OtcPurposes,
} from "../../../../utilities/api-helpers/one-time-code/one-time-code-types";
import { getFidoSupport } from "../../../../utilities/browser-helper";
import { getImageSource } from "../../../../utilities/images-helper";
import { SessionLookupKeyType } from "../../../../utilities/polling/session-polling-util";
import { useSessionPolling } from "../../../../utilities/polling/use-session-polling";
import {
  appendOrReplaceQueryStringParams,
  cleanseUsername,
  copyQueryStringParameters,
  replaceTokens,
  trim,
} from "../../../../utilities/strings-helper";
import LoginConfig from "../../login-config";
import { LoginState } from "../../login-constants";
import { LoginContext } from "../../login-context";
import { type ICommonLoginStrings } from "../../login-interface";
import { LoginActionType } from "../../login-reducer";
import { type LoginPostProps } from "../../login-types";
import { getCommonTitle } from "../../login-util";
import { getDescription, getDisplaySignCredSwitchAriaDescribedBy } from "../remote-ngc-view-util";

export interface IRemoteNgcViewProperties {
  primaryButtonAriaDescribedBy: string;
  authenticatorInfoUrl: string;
  description: string;
  displaySign: string;
  documentTitle: string;
  formRef: React.RefObject<HTMLFormElement>;
  error: string;
  setError: React.Dispatch<React.SetStateAction<string>>;
  isRequestPending: boolean;
  loginPostProps: LoginPostProps;
  lostAuthenticatorLinkText: string;
  postUrl: string;
  primaryButtonLabel: string;
  remoteNgcType: RemoteNgcType;
  resendNotificationText: string;
  setIsRequestPending: React.Dispatch<React.SetStateAction<boolean>>;
  setupAndStartPolling: () => void;
  showAnimatedGifWhilePolling: boolean;
  showBackArrowButton: boolean;
  showButtons: boolean;
  showLostAuthenticatorLink: boolean;
  unauthenticatedSessionId: string;
  title: string;
  displaySignRef: React.RefObject<HTMLSpanElement>;
  onLostAuthenticatorClicked: () => void;
}

/**
 * This hook provides the properties used in the credential switch links components.
 * @param title The title displayed in the view. Used to construct the ariaDescribedBy string.
 * @param error The error displayed in the view. Used to construct the ariaDescribedBy string.
 * @param displaySign The code displayed in the view. Used to construct the ariaDescribedBy string.
 * @param setIsRequestPending The setIsRequestPending callback.
 * @returns the credential switch links properties
 */
export const useCredentialSwitchProperties = (
  title: string,
  error: string,
  displaySign: string,
  setIsRequestPending: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const {
    viewState: { credentials },
  } = useContext(LoginContext);
  const { availableCredentials } = credentials;
  const currentCredential = { credentialType: CredentialType.RemoteNGC };

  // When the displaySign is not provided (familiar device), or when there's an error, we set the initial focus and
  // ariaDescribedBy on the credentialSwitchLink component. Otherwise, initial focus and ariaDescribedBy is set on the displaySign.
  const hasFocus = !displaySign || !!error;
  const linkAriaDescribedBy = hasFocus ? getDisplaySignCredSwitchAriaDescribedBy(title, error) : "";

  const credSwitchProps = {
    sourceViewId: ViewId.RemoteNgc,
    availableCredentials,
    currentCredential,
    setRequestPendingFlag: setIsRequestPending,
    showForgotUsername: false,
    hasFocus,
    linkAriaDescribedBy,
    shouldUpdateOtcCredential: true,
  };

  return credSwitchProps;
};

/**
 * This hook provides the properties for the evicted cred picker link.
 * @returns The evicted cred click handler, link text, and flag to show/hide the link.
 */
export const useEvictedCredPickerProperties = () => {
  const {
    viewState: { credentials },
    dispatchStateChange: dispatchLoginStateChange,
  } = useContext(LoginContext);
  const { evictedCredentials } = credentials;
  const navigate = useNavigateDirection();

  const onSwitchToEvictedCredPickerClicked = () => {
    dispatchLoginStateChange({
      type: LoginActionType.UpdateCredentials,
      payload: { useEvictedCredentials: true },
    });
    navigate(ViewId.RemoteNgc, ViewId.CredentialPicker);
  };

  return {
    onSwitchToEvictedCredPickerClicked,
    showSwitchToEvictedCredPicker: evictedCredentials.length > 0,
    switchToEvictedCredPickerText: getLocalString("Login_SwitchToCredPicker_Link_EvictedAcct"),
  };
};

/**
 * This hook is used when the lost authenticator link is clicked.
 * @param user The IUser object
 * @returns The click handler for the lost authenticator link.
 */
export const useOnLostAuthenticatorLinkClicked = (user: IUser) => {
  const onRedirect = useRedirect();
  const { lostAuthenticatorUrl, resetPasswordPrefillParam } = LoginConfig.instance;
  const { dispatchStateChange: dispatchGlobalStateChange } = useContext(GlobalContext);

  return () => {
    const lostAuthenticatorLinkUrl = appendOrReplaceQueryStringParams(
      lostAuthenticatorUrl,
      {
        [resetPasswordPrefillParam]: encodeURIComponent(
          trim(user.displayUsername.unsafeUnescapedString),
        ),
      },
      true,
    );

    dispatchGlobalStateChange({
      type: GlobalActionType.SetShowProgressIndicator,
      payload: true,
    });
    onRedirect(lostAuthenticatorLinkUrl);
  };
};

/**
 * This hook is used to setup session polling with the required callbacks.
 * @param remoteNgcType The remote ngc type
 * @param sessionIdentifier The session identifier
 * @param defaultPollingTitle The title to display when polling
 * @param user The IUser object
 * @param formRef The reference object for the form
 * @param setTitle The method to set the title
 * @param setError The method to set the error
 * @param setIsRequestPendingAndHideBackButton The method to update the isRequestPending state and back arrow button
 * @returns A method that will start the session polling.
 */
export const useStartPolling = (
  remoteNgcType: RemoteNgcType,
  sessionIdentifier: string,
  defaultPollingTitle: string,
  user: IUser,
  formRef: React.RefObject<HTMLFormElement>,
  setTitle: React.Dispatch<React.SetStateAction<string>>,
  setError: React.Dispatch<React.SetStateAction<string>>,
  setIsRequestPendingAndHideBackButton: (isPending: boolean) => void,
) => {
  // Global config
  const { maxPollingErrors, pollingIntervalMs, pollingTimeoutMs, sessionStateUrl } =
    GlobalConfig.instance;

  const onSessionTimeout = () => {
    if (remoteNgcType === RemoteNgcType.PushNotification) {
      setTitle(getLocalString("Request_Timeout"));
      const updatedError = replaceTokens(
        getLocalString("Login_RemoteNGC_SessionTimeout_Description"),
        user.displayUsername.unsafeUnescapedString,
      );
      setError(updatedError);
    }
    // TO-DO: AAD/ESTS - Add logic to update title and error strings when the remote ngc type is ListSessions (ESTS scenario)
  };

  const onSessionDenied = () => {
    setTitle(getLocalString("SessionApproval_Denied_Title"));
    const updatedError = replaceTokens(
      getLocalString("Login_RemoteNGC_SessionDenied_Description"),
      user.displayUsername.unsafeUnescapedString,
    );
    setError(updatedError);
  };

  const onSessionApproved = () => {
    setTitle("");
    setIsRequestPendingAndHideBackButton(true);
    formRef.current?.submit();
  };

  const { startPolling: startSessionPolling } = useSessionPolling({
    pollingUrl: sessionStateUrl,
    onSessionApproved,
    onSessionDenied,
    onSessionTimeout,
    sessionLookupKey: sessionIdentifier,
    sessionLookupKeyType: SessionLookupKeyType.RemoteNgc,
    maxPollingErrors,
    pollingIntervalMs,
    pollingTimeoutMs,
  });

  const startPolling = useCallback(() => {
    setTitle(defaultPollingTitle);
    startSessionPolling();
  }, [setTitle, startSessionPolling, defaultPollingTitle]);

  return startPolling;
};

/**
 * This hook is used to setup the OneTimeCode api request with the required callbacks.
 * @param flowTokenValue The flow token value
 * @param sessionIdentifier The session identifier
 * @param user The IUser object
 * @param startPolling The method to start the session polling
 * @param setTitle The method to set the title
 * @param setError The method to set the error
 * @param setIsRequestPendingAndHideBackButton The method to update the isRequestPending state and back arrow button
 * @returns A method that handles the Get OneTimeCode request.
 */
export const useGetOtcRequest = (
  flowTokenValue: string,
  sessionIdentifier: string,
  user: IUser,
  startPolling: () => void,
  setTitle: React.Dispatch<React.SetStateAction<string>>,
  setError: React.Dispatch<React.SetStateAction<string>>,
  setIsRequestPendingAndHideBackButton: (isPending: boolean) => void,
) => {
  // Login state
  const { dispatchStateChange: dispatchLoginStateChange } = useContext(LoginContext);
  // Authentication state
  const { dispatchStateChange: dispatchAuthenticationStateChange } =
    useContext(AuthenticationContext);
  // Global config
  const { getOtcJsonUrl } = GlobalConfig.instance;

  const updateFlowToken = useCallback(
    (flowToken?: string) => {
      if (flowToken) {
        dispatchAuthenticationStateChange({
          type: AuthenticationActionType.SetFlowTokenValue,
          payload: flowToken,
        });
      }
    },
    [dispatchAuthenticationStateChange],
  );

  const otcOnSuccess = useCallback(
    (res: OtcSuccessParams) => {
      updateFlowToken(res.flowToken);
      dispatchLoginStateChange({
        type: LoginActionType.SetRemoteNgcParams,
        payload: { entropy: res.displaySign ?? "" },
      });
      setIsRequestPendingAndHideBackButton(false);
      startPolling();
      // TO-DO: AAD/ESTS - add request handler for JSON post request success
    },
    [dispatchLoginStateChange, startPolling, setIsRequestPendingAndHideBackButton, updateFlowToken],
  );

  const otcOnFailure = useCallback(
    (res: OtcFailureParams) => {
      updateFlowToken(res.flowToken);
      setIsRequestPendingAndHideBackButton(false);
      setTitle(getLocalString("SessionApproval_Failed_Title"));
      setError(getLocalString("PushNotification_Error"));
      // TO-DO: AAD/ESTS - add logic for handling JSON post request failure
    },
    [setTitle, setError, setIsRequestPendingAndHideBackButton, updateFlowToken],
  );

  const triggerOtcRequest = useCallback(() => {
    dispatchLoginStateChange({
      type: LoginActionType.SetRemoteNgcParams,
      payload: { entropy: "" },
    });
    setIsRequestPendingAndHideBackButton(true);
    // For MSA scenario, we use the sessionIdentifier as the flow token value.
    const flowTokenParam = getOtcJsonUrl ? flowTokenValue : sessionIdentifier;
    getOneTimeCode({
      onSuccess: otcOnSuccess,
      onFailure: otcOnFailure,
      canaryFlowToken: flowTokenValue,
      channel: OtcChannel.authenticator,
      flowToken: flowTokenParam,
      proofType: ProofType.TOTPAuthenticatorV2,
      purpose: OtcPurposes.remoteNGC,
      username: user.username,
    });
  }, [
    dispatchLoginStateChange,
    flowTokenValue,
    getOtcJsonUrl,
    otcOnFailure,
    otcOnSuccess,
    sessionIdentifier,
    user.username,
    setIsRequestPendingAndHideBackButton,
  ]);

  return triggerOtcRequest;
};

/**
 * This hook determines whether to start polling or make the OneTimeCode api request and start polling. It is used on initial view load and when the user clicks the Next button when there is an error.
 * @param setTitle The method to set the title
 * @param setError The method to set the error
 * @param triggerOtcRequest The method to inovke the Get OneTimeCode request
 * @param startPolling The method to start the session polling
 * @returns A method that handles the polling and OTC api request for the remote ngc view.
 */
export const useSetupAndStartPolling = (
  setTitle: React.Dispatch<React.SetStateAction<string>>,
  setError: React.Dispatch<React.SetStateAction<string>>,
  triggerOtcRequest: () => void,
  startPolling: () => void,
) => {
  // Login state data
  const {
    viewState: {
      remoteNgcParams: { requestSent },
    },
  } = useContext(LoginContext);

  // During initialization, check if an OTC request needs to be made before polling.
  // On subsequent attempts (e.g., Next button is clicked), we skip this check and make the request before polling.
  const [isInitialRequest, setIsInitialRequest] = useState(true);
  const setupAndStartPolling = useCallback(() => {
    setTitle("");
    setError("");
    if (isInitialRequest) {
      if (!requestSent) {
        triggerOtcRequest();
      } else {
        startPolling();
      }

      setIsInitialRequest(false);
    } else {
      triggerOtcRequest();
    }
  }, [
    requestSent,
    startPolling,
    setTitle,
    setError,
    setIsInitialRequest,
    isInitialRequest,
    triggerOtcRequest,
  ]);

  return setupAndStartPolling;
};

/**
 * This hook is used to update the request pending and hide/show the back arrow button.
 * @param setIsRequestPending The setIsRequestPending callback
 * @param showBackArrowButton Whether or not the back arrow button is shown on the view, regardless of the request pending state. Used to determine if the
 * back arrow button state should be updated.
 * @returns A method for setting the request pending state and hiding/showing the back arrow button.
 */
export const useIsRequestPendingAndHideBackButton = (
  setIsRequestPending: React.Dispatch<React.SetStateAction<boolean>>,
  showBackArrowButton: boolean,
) => {
  const { dispatchStateChange: dispatchGlobalStateChange } = useContext(GlobalContext);
  return useCallback(
    (isPending: boolean) => {
      setIsRequestPending(isPending);
      if (showBackArrowButton) {
        dispatchGlobalStateChange({
          type: GlobalActionType.HideBackArrowButton,
          payload: isPending,
        });
      }
    },
    [setIsRequestPending, showBackArrowButton, dispatchGlobalStateChange],
  );
};

/**
 * @returns Remote NGC view properties
 * @param strings The strings to use for the view
 * @param strings.commonLoginStrings The common login strings
 */
export const useRemoteNgcViewProperties = (strings: {
  commonLoginStrings: ICommonLoginStrings;
}): IRemoteNgcViewProperties => {
  const { commonLoginStrings } = strings;

  // Global state data
  const {
    globalState: {
      styles: { friendlyAppName },
      user,
    },
  } = useContext(GlobalContext);

  // Global config
  const {
    context,
    canaryTokenValue: canary,
    flowTokenName,
    showCookieBanner,
    postUrl,
    unauthenticatedSessionId,
    showButtons,
  } = GlobalConfig.instance;

  // Authentication state data
  const {
    authState: { flowTokenValue },
  } = useContext(AuthenticationContext);

  // Login config
  const {
    loginMode,
    lostAuthenticatorUrl,
    rawQueryString,
    randomBlob,
    foundMsas,
    postedForceSignIn,
    isFidoSupportedHint,
    defaultLoginOptions,
  } = LoginConfig.instance;

  // Login state data
  const {
    viewState: { remoteNgcParams },
  } = useContext(LoginContext);

  // Remote NGC params from login state
  const {
    showAnimatedGifWhilePolling,
    defaultType,
    entropy: displaySign,
    sessionIdentifier,
  } = remoteNgcParams;

  const showBackArrowButton = useBackButtonControl() && showButtons;
  const documentTitle = getCommonTitle(loginMode, friendlyAppName, commonLoginStrings);
  const defaultTitle = showAnimatedGifWhilePolling
    ? getLocalString("Login_RemoteNGC_SessionPolling_Title")
    : getLocalString("Login_RemoteNGC_SessionPolling_Title_Approve_SignIn");

  const [title, setTitle] = useState(defaultTitle);
  const [error, setError] = useState("");
  const [isRequestPending, setIsRequestPending] = useState(false);

  const remoteNgcType = defaultType || RemoteNgcType.PushNotification;
  const showLostAuthenticatorLink = !!lostAuthenticatorUrl;
  const authenticatorInfoUrl = getImageSource("authenticatorinfo", "gif");
  const formRef = useRef<HTMLFormElement>(null);
  const displaySignRef = useRef<HTMLSpanElement>(null);

  // Strings
  const description = getDescription(remoteNgcType, displaySign, showAnimatedGifWhilePolling);
  const resendNotificationText = getLocalString("Login_RemoteNGC_ResendText");
  const lostAuthenticatorLinkText = getLocalString("RNGC_Lost_Authenticator");
  const primaryButtonLabel = getLocalString("General_Buttons_Next");
  // TO-DO: AAD/ESTS - Update primaryButtonAriaDescribedBy with showCredViewBrandingDesc after adding the branding description
  let primaryButtonAriaDescribedBy = title ? "remoteNgcTitle" : "";
  primaryButtonAriaDescribedBy += error ? " errorDescription" : "";

  // Login post props for form submission
  const cleansedUsername = cleanseUsername(user.username?.unsafeUnescapedString);
  const displayUsername = user.displayUsername.unsafeUnescapedString;
  const isFidoSupported = getFidoSupport(isFidoSupportedHint);
  const isKmsiChecked = defaultLoginOptions === LoginOption.rememberPwd;
  const loginOption = isKmsiChecked ? LoginOption.rememberPwd : LoginOption.nothingChecked;
  const postType = PostType.remoteNGC;
  const updatedPostUrl = rawQueryString
    ? copyQueryStringParameters(rawQueryString, postUrl)
    : postUrl;

  const onLostAuthenticatorClicked = useOnLostAuthenticatorLinkClicked(user);
  const setIsRequestPendingAndHideBackButton = useIsRequestPendingAndHideBackButton(
    setIsRequestPending,
    showBackArrowButton,
  );

  const startPolling = useStartPolling(
    remoteNgcType,
    sessionIdentifier,
    defaultTitle,
    user,
    formRef,
    setTitle,
    setError,
    setIsRequestPendingAndHideBackButton,
  );

  const triggerOtcRequest = useGetOtcRequest(
    flowTokenValue,
    sessionIdentifier,
    user,
    startPolling,
    setTitle,
    setError,
    setIsRequestPendingAndHideBackButton,
  );

  const setupAndStartPolling = useSetupAndStartPolling(
    setTitle,
    setError,
    triggerOtcRequest,
    startPolling,
  );

  return {
    documentTitle,
    showBackArrowButton,
    authenticatorInfoUrl,
    title,
    showAnimatedGifWhilePolling,
    description,
    displaySign,
    remoteNgcType,
    error,
    setError,
    resendNotificationText,
    lostAuthenticatorLinkText,
    showLostAuthenticatorLink,
    primaryButtonLabel,
    isRequestPending,
    primaryButtonAriaDescribedBy,
    onLostAuthenticatorClicked,
    postUrl: updatedPostUrl,
    unauthenticatedSessionId,
    setupAndStartPolling,
    showButtons,
    formRef,
    displaySignRef,
    setIsRequestPending,
    loginPostProps: {
      canary,
      cleansedUsername,
      context,
      flowTokenName,
      flowTokenValue,
      foundMsas,
      isFidoSupported,
      isKmsiChecked,
      loginOption,
      paginatedState: LoginState.RemoteNGC,
      postType,
      postedForceSignIn,
      randomBlob,
      showCookieBanner,
      displayUsername,
      rngcDefaultType: remoteNgcType,
      rngcEntropy: displaySign,
      rngcSessionIdentifier: sessionIdentifier,
    },
  };
};
