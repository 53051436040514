import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { mergeClasses } from "@griffel/react";
import { GlobalContext } from "../../global-context";
import useFabricStyles from "../../styles/fabric/fabric.styles";
import * as styleConstants from "../../styles/fabric/input-constants-fabric.styles";
import { useTextInputStyles } from "../../styles/fabric/input-fabric.styles";
import ErrorContainer from "../error-container";

const TEXT_INPUT_FOCUS_TIMEOUT = 200;

/* Supported autocomplete options. Add new options as needed.
 * See https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
 */
export type AutoCompleteOptions =
  | "current-password"
  | "email"
  | "new-password"
  | "off"
  | "username";

export interface ITextInputProps {
  id: string;
  name: string;
  placeholder: string;
  type: string;
  value?: string;
  maxLength?: number;
  hasFocus?: boolean;
  hasInitialFocus?: boolean;
  showErrorInline?: boolean;
  externalError?: string | JSX.Element;
  ariaLabel?: string;
  ariaLabelledBy?: string;
  ariaDescribedBy?: string;
  customCssWrapper?: string;
  customCss?: string;
  autocomplete?: AutoCompleteOptions;
  changeHandler?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  focusHandler?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  blurHandler?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  validationErrorHandler?: (e: string) => void;
  disabled?: boolean;
  inputValidationFunc?: (value: string) => string;
  useExternalErrorContainer?: boolean;
}

/**
 * TextInput component
 * @param props The properties for this component
 * @param props.id Input field ID
 * @param props.name Input field name
 * @param props.placeholder Input field placeholder - hint for the input
 * @param props.type Input field type
 * @param props.value Input field value
 * @param props.maxLength Maximum allowed characters for the given field
 * @param props.hasFocus Whether the input is currently focused
 * @param props.hasInitialFocus Whether the input field is focused by default
 * @param props.showErrorInline Whether the field is showing an error or not
 * @param props.externalError Error string to be passed by the surrounding component to be displayed;
 *        if showErrorInline is false but externalError is passed, its assumed its the initial ServerData provided error and is shown on render of component
 * @param props.ariaLabelledBy Aria labelled-by value for the input field
 * @param props.ariaLabel Aria label value for the input field (when an aria labelled-by value is not applicable).
 * @param props.ariaDescribedBy Aria described-by value for the input field
 * @param props.customCssWrapper Additional css classes that can be passed to the input wrapper div
 * @param props.customCss Additional css classes that can be passed to the input field
 * @param props.changeHandler Input change callback - see form-stub.tsx for examples
 * @param props.focusHandler Input focus callback
 * @param props.blurHandler Input blur callback
 * @param props.validationErrorHandler callback for when there is validation error to the input
 * @param props.disabled Whether the input field is disabled
 * @param props.inputValidationFunc The validation to use for inline validation
 * @param props.useExternalErrorContainer Whether the error message will instead be displayed in an external error container
 * @returns The display name
 */
export const TextInput: React.FC<ITextInputProps> = function TextInput(props) {
  const {
    id,
    name,
    placeholder,
    type,
    value = "",
    maxLength = 120,
    showErrorInline = false,
    hasFocus = false,
    hasInitialFocus = false,
    ariaLabel,
    ariaLabelledBy,
    ariaDescribedBy,
    disabled = false,
    externalError = "",
    customCssWrapper,
    customCss,
    autocomplete = undefined,
    changeHandler = () => {},
    focusHandler = () => {},
    blurHandler = () => {},
    validationErrorHandler = () => {},
    inputValidationFunc = () => "",
    useExternalErrorContainer = false,
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  const fabricStyles = useFabricStyles();
  const inputStyles = useTextInputStyles();
  const [hover, setHover] = useState(false);
  const [error, setError] = useState("");

  // Grab the app-branded focus color for the primary button. This will also be the focus color of
  // the underline for the text input box
  const {
    globalState: {
      styles: {
        accentColors: { primaryButtonDefaultColor },
      },
    },
  } = useContext(GlobalContext);

  // Determine which colors the text input component's border should be in default, focus and error states
  const borderColorDefault = styleConstants.BORDER_COLOR;
  const borderColorHover = styleConstants.BORDER_COLOR_HOVER;
  const borderColorFocus = primaryButtonDefaultColor || styleConstants.BORDER_COLOR_FOCUS;
  const borderColorError = styleConstants.BORDER_COLOR_FOCUS_HAS_ERROR;

  // These handler functions act as listeners for the `hover` state variable
  const mouseOverHandler = () => setHover(true);
  const mouseOutHandler = () => setHover(false);

  const [currentBorderColor, setBorderColor] = useState(
    hasInitialFocus ? borderColorFocus : borderColorDefault,
  );

  // Get the error text to be displayed based on the following conditions:
  // If showErrorInline is false but externalError is present; this indicates
  // a ServerData provided error. Show it immediately
  // OR if showErrorInline and error or externalError are present
  const getErrorText = useCallback((): string | JSX.Element | null => {
    if (!useExternalErrorContainer && !showErrorInline && externalError) {
      return externalError;
    }

    if (showErrorInline && (error || externalError)) {
      return error || externalError;
    }

    return null;
  }, [useExternalErrorContainer, showErrorInline, externalError, error]);

  useEffect(() => {
    // Delay focus until view transition animations are completed.
    if (hasInitialFocus) {
      setTimeout(() => {
        inputRef?.current?.focus();
      }, TEXT_INPUT_FOCUS_TIMEOUT);
    }
  }, [hasInitialFocus]);

  useEffect(() => {
    if (hasFocus) {
      inputRef?.current?.focus();
    }
  }, [hasFocus]);

  useEffect(() => {
    const errorMessage = inputValidationFunc(value);
    setError(errorMessage);
    validationErrorHandler(errorMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, showErrorInline]);

  const errorText = getErrorText();

  useEffect(() => {
    // Apply border color according to component state. Note that the error color takes precedence
    // over all the other colors
    let borderColor = borderColorDefault;
    if (errorText) {
      borderColor = borderColorError;
    } else if (hasFocus) {
      borderColor = borderColorFocus;
    } else if (hover) {
      borderColor = borderColorHover;
    }

    setBorderColor(borderColor);
  }, [
    borderColorError,
    hasFocus,
    borderColorFocus,
    hover,
    borderColorHover,
    borderColorDefault,
    errorText,
  ]);

  const styleAttribute = { borderColor: currentBorderColor };

  return (
    <div
      className={mergeClasses(fabricStyles.row, customCssWrapper)}
      data-testid="inputComponentWrapper"
    >
      <div className={mergeClasses(fabricStyles.formGroup, inputStyles.textbox, customCss)}>
        {!useExternalErrorContainer && errorText && (
          <ErrorContainer id={`${id}Error`}>{getErrorText()}</ErrorContainer>
        )}
        <input
          ref={inputRef}
          id={id}
          name={name}
          placeholder={placeholder}
          type={type}
          value={value}
          maxLength={maxLength}
          disabled={disabled}
          aria-label={ariaLabel}
          aria-labelledby={ariaLabelledBy}
          aria-describedby={ariaDescribedBy}
          className={mergeClasses(errorText ? "has-error" : "", customCss)}
          autoComplete={autocomplete}
          onChange={changeHandler}
          onBlur={blurHandler}
          onFocus={focusHandler}
          onMouseOver={mouseOverHandler}
          onMouseOut={mouseOutHandler}
          style={styleAttribute}
        />
      </div>
    </div>
  );
};
