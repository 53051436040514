import { PhoneNumberRegex } from "../components/inputs/input-regex-constants";
import { type ICountryInfo } from "./country-helper";
import { isEmailAddress, isPhoneNumber } from "./strings-helper";

/**
 *
 * @param nationalNumber National numebr
 * @param country Country data
 * @returns String representation of the supplied phone number
 */
export const getFullyQualifiedPhoneNumber = (
  nationalNumber: string,
  country?: ICountryInfo | null,
): string => {
  const trimmedNumber = nationalNumber.trim();

  let countryPrefix = "";
  if (trimmedNumber.charAt(0) === "+") {
    countryPrefix = "+";
  } else if (country) {
    countryPrefix = `+${country.code}`;
  }

  return `${countryPrefix}${trimmedNumber.replace(/\D+/g, "")}`;
};

/**
 * Provides a function for inline validation of a phone number.
 * @param error The error string to display
 * @returns Method for validating phone number input
 */
export const getPhoneInputValidation =
  (error: string) =>
  (value: string): string => {
    if (
      !value ||
      isEmailAddress(value) ||
      !isPhoneNumber(value) ||
      !value.match(PhoneNumberRegex)
    ) {
      return error;
    }

    return "";
  };
