import React, { type ReactNode, useEffect, useRef } from "react";
import { mergeClasses } from "@griffel/react";
import { useTileStyles } from "../styles/fabric/tile-fabric.styles";
import { replaceTokens } from "../utilities/strings-helper";
import { ButtonFabric } from "./button/fabric/button-fabric";
import {
  type IOptionsMenuButtonProps,
  OptionsMenuButton,
} from "./options-menu-button/options-menu-button";
import { type AccessibleImages, AccessibleImage } from "./accessible-image";
import { HelpButton } from "./help-button";

export interface ITileProps {
  /** Main text of tile */
  mainText: string;
  /** Optional details text */
  helpText?: string;
  /** Optional sub text */
  subText?: string;
  /** Image of tile */
  imageUrl: AccessibleImages;
  /** aria role of the image */
  imageRole?: string;
  /** onClick Handler */
  onTileClick: (event: React.SyntheticEvent<HTMLElement>) => void;
  /** if tile has initial focus */
  hasInitialFocus?: boolean;
  /** aria label for each tile */
  ariaLabel: string;
  /** optional ariaDescribedBy if needed */
  ariaDescribedBy?: string;
  /** optional className for other components */
  isMoreOptions?: boolean;
  /** optional help icon of tile  */
  helpDialogIcon?: AccessibleImages;
  /** optional header for popup */
  helpDialogHeader?: ReactNode;
  /** optional description for popup */
  helpDialogDescription?: ReactNode;
  /** optional test id for the dialog icon */
  helpDialogIconTestId?: string;
  /** optional menu button for more options */
  optionsMenuButton?: IOptionsMenuButtonProps;
  /** optional value for replacement value in the main text */
  mainTextReplaceValue?: string;
  /** optional value for replacement value in the aria label */
  ariaLabelReplaceValue?: string;
}

export type TileComponent = ITileProps;

/**
 * Tile component
 * @param props The properties for this component
 * @returns tile
 */
export const Tile: React.FC<ITileProps> = function Tile(props) {
  const {
    mainText,
    helpText,
    subText,
    imageUrl,
    imageRole,
    onTileClick = () => {},
    ariaLabel,
    ariaDescribedBy,
    isMoreOptions = false,
    hasInitialFocus = false,
    helpDialogIcon,
    helpDialogHeader,
    helpDialogDescription,
    helpDialogIconTestId,
    mainTextReplaceValue,
    ariaLabelReplaceValue,
    optionsMenuButton,
  } = props;
  const tileStyles = useTileStyles();

  const elementReference = useRef<HTMLButtonElement>(null);
  const tileImageClass = isMoreOptions
    ? mergeClasses(tileStyles.middle, tileStyles.moreOptionsTileImage)
    : mergeClasses(tileStyles.middle, tileStyles.tileImage);
  const tileTextClass = mergeClasses(
    tileStyles.middle,
    tileStyles.tileTextAlign,
    tileStyles.content,
  );

  useEffect(() => {
    if (hasInitialFocus) {
      elementReference?.current?.focus();
    }
  }, [hasInitialFocus]);

  return (
    <div
      className={isMoreOptions ? tileStyles.moreOptionsTile : tileStyles.tileContainer}
      role="listitem"
      aria-label={
        ariaLabelReplaceValue ? replaceTokens(ariaLabel, ariaLabelReplaceValue) : ariaLabel
      }
      aria-describedby={ariaDescribedBy}
    >
      <div className={isMoreOptions ? tileStyles.moreOptionsRowTile : tileStyles.rowTile}>
        <ButtonFabric
          className={
            isMoreOptions ? tileStyles.moreOptionsTileButtonSettings : tileStyles.tileButtonSettings
          }
          onClick={onTileClick}
          ref={elementReference}
        >
          <div className={tileImageClass} data-testid="mainImage">
            <AccessibleImage
              accessibleImages={imageUrl}
              role={imageRole}
              style={isMoreOptions ? tileStyles.moreOptionsTileImage : tileStyles.tileImage}
            />
          </div>
          <div className={tileTextClass}>
            <div data-testid="mainText">
              {mainTextReplaceValue ? replaceTokens(mainText, mainTextReplaceValue) : mainText}
            </div>
            {helpText && (
              <div className={tileStyles.small} data-testid="helpText">
                {helpText}
              </div>
            )}
            {subText && (
              <div className={tileStyles.small} data-testid="subText">
                {subText}
              </div>
            )}
          </div>
        </ButtonFabric>
        {helpDialogIcon && (
          <HelpButton
            helpDialogIcon={helpDialogIcon}
            helpDialogHeader={helpDialogHeader}
            helpDialogDescription={helpDialogDescription}
            helpDialogIconClassName={tileStyles.helpButton}
            helpDialogIconTestId={helpDialogIconTestId}
          />
        )}
        {optionsMenuButton && (
          <OptionsMenuButton
            icon={optionsMenuButton.icon}
            menuButtonIconClassName={tileStyles.menuButton}
            menuItemList={optionsMenuButton.menuItemList}
            menuTestId={optionsMenuButton.menuTestId}
          />
        )}
      </div>
    </div>
  );
};
