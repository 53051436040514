import React from "react";
import { mergeClasses } from "@griffel/react";
import useFabricStyles from "../../../styles/fabric/fabric.styles";
import { useTitleStyles } from "../../../styles/fabric/title-fabric.styles";
import type { ITitleProps } from "../title-interface";

export type ITitleFabricProps = ITitleProps & {
  /** The subtitle beneath the title */
  subtitle?: string;
  /** Whether to display the subtitle */
  useSubtitle?: boolean;
  /** The ID of the title description */
  descriptionId?: string;
  /** The text describing the title */
  description?: string;
};

/**
 * Title component for Fabric
 * @param props The properties for this component
 * @returns An instance of this component
 */
export const TitleFabric: React.FC<ITitleFabricProps> = function TitleFabric(props) {
  const {
    title,
    titleId,
    role = "heading",
    ariaLevel = 1,
    subtitle,
    useSubtitle,
    descriptionId,
    description,
  } = props;

  const titleStyles = useTitleStyles();
  const fabricStyles = useFabricStyles();
  const showSubtitle = useSubtitle !== false && subtitle;

  return (
    <div>
      <div className={titleStyles.title}>
        <div role={role} aria-level={ariaLevel} id={titleId}>
          {title}
        </div>

        {showSubtitle && (
          <div data-test-id="subtitle" className={titleStyles.subtitle}>
            {subtitle}
          </div>
        )}
      </div>

      {description && (
        <div className={mergeClasses(fabricStyles.row, fabricStyles.textBody)}>
          <div
            className={mergeClasses(
              fabricStyles.textBlockBody,
              fabricStyles.overFlowHidden,
              fabricStyles.noMarginTop,
            )}
            id={descriptionId}
          >
            {description}
          </div>
        </div>
      )}
    </div>
  );
};
