import { useContext } from "react";
import { AuthenticationContext } from "../../../../../authentication-context";
import { ViewId } from "../../../../../constants";
import { GlobalContext } from "../../../../../global-context";
import { GlobalActionType } from "../../../../../global-reducer";
import { useNavigateDirection } from "../../../../../hooks/use-navigate-direction";
import { type IFormSubmissionProps } from "../../../../../hooks/use-text-input-form";
import {
  GctResultAction,
  sendAsync,
} from "../../../../../utilities/api-helpers/get-credential-type-helper";
import { getFidoSupport } from "../../../../../utilities/browser-helper";
import { getRouteFromViewId } from "../../../../../utilities/routing-helper";
import { cleanseUsername } from "../../../../../utilities/strings-helper";
import LoginConfig from "../../../login-config";
import { defaultLoginState, LoginContext } from "../../../login-context";
import { LoginActionType } from "../../../login-reducer";

/**
 * @returns handler for calling GCT
 * @param getErrorMessage Function that returns an error message (either a string or element) that should be displayed
 *   if the GCT call fails.
 * @example
 * const getErrorMessage = (unsafeUsername: string, gctError?: string) =>
 *  (`Error: ${gctError || "unknown"}. The username ${unsafeUsername} is invalid.`);
 * const gctHandler = useGCT(getErrorMessage);
 */
export const useGct = (
  getErrorMessage: (unsafeUsername: string, gctError: string) => string | JSX.Element,
) => {
  const navigator = useNavigateDirection();

  const { dispatchStateChange } = useContext(GlobalContext);

  const {
    authState: { flowTokenValue: flowToken },
  } = useContext(AuthenticationContext);

  const {
    viewState: { otherIdpRedirectUrl },
    dispatchStateChange: dispatchLoginStateChange,
  } = useContext(LoginContext);
  const {
    isFidoSupportedHint,
    gctFederationFlags,
    isFederationDisabled,
    isExternalFederationDisallowed,
    isRemoteNGCSupported,
    isOtcLoginDisabled: otclogindisallowed,
    getCredentialTypeUrl,
    postProofType,
    showSignup,
    signupUrl,
    fedQs,
  } = LoginConfig.instance;

  const isFidoSupported = getFidoSupport(isFidoSupportedHint);

  return async (formSubmissionProps: IFormSubmissionProps) => {
    const { value: unsafeUsername, errorHandler } = formSubmissionProps;

    const gctResult = await sendAsync(
      {
        country: "", // for now, country is not needed in MSA
        flowToken,
        gctFederationFlags: gctFederationFlags || 0,
        getCredentialTypeUrl,
        isExternalFederationDisallowed,
        isFederationDisabled,
        isFidoSupported,
        isRemoteNGCSupported,
        otclogindisallowed,
        otherIdpRedirectUrl,
        unsafeUsername,
        fedQs,
      },
      {
        postProofType,
        showSignup,
        signupUrl,
      },
    );

    switch (gctResult.action) {
      case GctResultAction.ShowError:
        if (gctResult.error) {
          const errorMessage = getErrorMessage(unsafeUsername, gctResult.error);
          errorHandler(errorMessage);
        }

        break;
      case GctResultAction.SwitchView:
        if (gctResult.sharedData) {
          dispatchStateChange({
            type: GlobalActionType.SetUser,
            payload: {
              username: cleanseUsername(unsafeUsername),
              displayUsername: gctResult.sharedData.displayName || unsafeUsername,
            },
          });
          dispatchLoginStateChange({
            type: LoginActionType.SetLocation,
            payload: gctResult.sharedData.location!,
          });
          dispatchLoginStateChange({
            type: LoginActionType.SetRemoteNgcParams,
            payload: gctResult.sharedData.remoteNgcParams || defaultLoginState.remoteNgcParams,
          });
          // Update login context with the availableCredentials from the GCT response
          dispatchLoginStateChange({
            type: LoginActionType.UpdateCredentials,
            payload: {
              availableCredentials: gctResult.sharedData.availableCredentials,
              otcCredential: gctResult.sharedData.otcCredential,
              useEvictedCredentials: gctResult.sharedData.useEvictedCredentials,
            },
          });
          dispatchLoginStateChange({
            type: LoginActionType.SetIdpRedirectData,
            payload: {
              url: gctResult.viewParams?.idpRedirectUrl,
              postParams: gctResult.viewParams?.idpRedirectPostParams,
              provider: gctResult.viewParams?.idpRedirectProvider,
            },
          });
        }

        navigator(ViewId.Username, getRouteFromViewId(gctResult.viewId!));

        break;
      default:
      // do nothing for now
    }
  };
};
