import React from "react";
import { mergeClasses } from "@griffel/react";
import { useProofImageStyles } from "../styles/fabric/proof-image-fabric.styles";
import { ProofType } from "../types/credential-types";
import { isSvgSupported } from "../utilities/browser-helper";
import {
  accessPassCredIcon,
  authenticatorIcon,
  authenticatorV2fluentIcon,
  authenticatorV2Icon,
  callIcon,
  certificateCredIcon,
  emailIcon,
  fidoCredIcon,
  smsIcon,
} from "../utilities/image-helpers/accessible-images";
import { type AccessibleImages, AccessibleImage } from "./accessible-image";

export interface IProofImageProps {
  proofType: ProofType;
  small?: boolean;
  animate?: boolean;
  dataTestId?: string;
}

/**
 * Gets the accessible image for a given proof type
 * @param proofType The proof type
 * @returns The accessible image
 */
export const getProofImage = (proofType: ProofType): AccessibleImages => {
  let proofImage = {
    light: "",
    dark: "",
  };

  switch (proofType) {
    case ProofType.Email:
      proofImage = emailIcon;
      break;
    case ProofType.SMS:
    case ProofType.TwoWaySMS:
      proofImage = smsIcon;
      break;
    case ProofType.Voice:
    case ProofType.TwoWayVoice:
      proofImage = callIcon;
      break;
    case ProofType.TOTPAuthenticatorV2:
    case ProofType.CompanionApp:
      // check which extension is supported first - authenticatorV2fluentIcon only has a SVG version.
      // We use the the authenticatorV2Icon as the PNG version
      proofImage = isSvgSupported ? authenticatorV2fluentIcon : authenticatorV2Icon;
      break;
    case ProofType.TOTPAuthenticator:
      proofImage = authenticatorIcon;
      break;
    case ProofType.FidoKey:
      proofImage = fidoCredIcon;
      break;
    case ProofType.AccessPass:
      proofImage = accessPassCredIcon;
      break;
    case ProofType.Certificate:
      proofImage = certificateCredIcon;
      break;
    default:
      return proofImage;
  }

  return proofImage;
};

/**
 * Shared component that sets the image path and shared CSS styles for a given proof type.
 * NOTE: When proof type is companion app, the displayCompanionApp flag should be used to toggle whether or not this component is shown.
 * @param props IProofImageProps
 * @param props.small Whether or not the image should be small
 * @param props.animate Whether or not the image should be animated
 * @param props.dataTestId The datatest id for the accessible image
 * @returns A wrapper for the AccessibleImage component
 */
export const ProofImage: React.FC<IProofImageProps> = function ProofImage(props: IProofImageProps) {
  const { proofType, small = false, animate = false, dataTestId } = props;
  const styles = useProofImageStyles();

  const proofImage = getProofImage(proofType);
  const hasImage = !!(proofImage?.light && proofImage?.dark);
  const imageStyles = mergeClasses(
    styles.icon,
    small ? styles.small : "",
    animate ? styles.animate : "",
  );

  return hasImage ? (
    <AccessibleImage
      accessibleImages={proofImage}
      style={imageStyles}
      role="presentation"
      dataTestId={dataTestId}
    />
  ) : null;
};

export default ProofImage;
