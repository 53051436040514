import React, { useContext, useState } from "react";
import { mergeClasses } from "@griffel/react";
import { ExternalClassName } from "../../../constants/constants";
import { GlobalContext } from "../../../global-context";
import useLayoutAnimateStyles from "../../../styles/fabric/layout-animate-fabric.styles";
import { isCssAnimationSupported } from "../../../utilities/browser-helper";
import Background from "../../background";
import DirectionWrapper from "../../direction-wrapper";
import Footer from "../../footer/fabric/footer-fabric";
import Header from "../../header";
import LayoutContent, { useLayoutContentStyles } from "../../layout-content";

type LightboxLayoutProps = {
  styles: {
    disableLightbox: string;
    inner: string;
    innerAlwaysShowBackground: string;
    innerWithPopup: string;
    lightboxCover: string;
    middle: string;
    outer: string;
    paginationView: string;
    paginationViewWithIdentityBanner: string;
    wide: string;
    transparent: string;
  };
};

/**
 * LightboxLayout component
 * @param props The properties for this component
 * @param props.styles The styles to apply to various parts of this component
 * @param props.children The child elements to render inside this component
 * @returns PLACEHOLDER - NEEDS TO BE UPDATED
 */
const LightboxLayoutFabric: React.FC<LightboxLayoutProps> = function LightboxLayoutFabric({
  styles,
  children,
}) {
  const {
    globalState,
    globalState: {
      showMoreOptions,
      isWideView,
      styles: { useTransparentLightbox, alwaysShowBackground },
    },
  } = useContext(GlobalContext);
  const [showDebugInfo, setShowDebugInfo] = useState(false);
  const [focusOnDebugCopyLink, setFocusOnDebugCopyLink] = useState(false);
  const [focusOnEllipsis, setFocusOnEllipsis] = useState(false);

  const animateStyles = useLayoutAnimateStyles();
  const animationSupported = isCssAnimationSupported();

  const middleClassNames = mergeClasses(styles.middle, ExternalClassName.middle);
  let innerClassName = mergeClasses(
    styles.inner,
    isWideView ? styles.wide : "",
    useTransparentLightbox ? styles.transparent : "",
    alwaysShowBackground ? styles.innerAlwaysShowBackground : "",
    showMoreOptions ? styles.innerWithPopup : "",
  );

  // Add animation styles for lightbox/inner container when animation is supported
  if (animationSupported) {
    innerClassName = mergeClasses(innerClassName, animateStyles.fadeIn);
  }

  return (
    <DirectionWrapper testID="lightbox-layout">
      <Background />
      <div id="outer" data-testid="outer" className={styles.outer} role="presentation">
        <Header />
        <div id="middle" className={middleClassNames} role="main">
          <LayoutContent
            focusOnDebugCopyLink={focusOnDebugCopyLink}
            setFocusOnEllipsis={setFocusOnEllipsis}
            showDebugInfo={showDebugInfo}
            setShowDebugInfo={setShowDebugInfo}
            styles={useLayoutContentStyles(globalState, innerClassName)}
          >
            {children}
          </LayoutContent>
        </div>
        <Footer
          isDebugInfoShown={showDebugInfo}
          focusOnEllipsis={focusOnEllipsis}
          onDebugItemClick={(e) => {
            e.preventDefault();
            setFocusOnDebugCopyLink(true);
            setShowDebugInfo(!showDebugInfo);
          }}
        />
      </div>
    </DirectionWrapper>
  );
};

export default LightboxLayoutFabric;
