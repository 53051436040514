import {
  type ErrorWithResponse,
  type ResponseBody,
  type ResponseError,
} from "../constants/constants";
import GlobalConfig from "../global-config";
import type { TelemetryState } from "../global-context";
import { ExceptionHelper } from "../telemetry-helpers/exception-helper";
import { TelemetryEventType } from "../telemetry-helpers/telemetry-helper";

export const REQUEST_ERROR_NAME = "RequestError";
export const PARSE_FAILED = "json parse failed";
export const REQUEST_FAILED = "request failed";
export const NETWORK_ERROR = "network error";
export const REQUEST_TIMEOUT = "request timeout";
export const CALL_GENERIC_ERROR_CODE = 8000;
export const CALL_TIMEOUT_ERROR_CODE = 8001;
export const CALL_ABORTED_ERROR_CODE = 8002;

export type ErrorDetails = {
  code: number;
  message: string;
  isFatal: boolean;
  debugMessage: string;
};

export type RequestStats = {
  apiName: string;
  processingStartTime: Date;
  networkCallStartTime: Date;
  networkCallEndTime: Date;
};

export interface RequestOptions extends RequestInit {
  processingStartTime?: Date;
  apiName?: string;
  telemetryState?: TelemetryState;
  useApiCanary?: boolean;
  timeout?: number;
}

/** @private */
function updateApiCanary(apiCanary: string) {
  GlobalConfig.instance.apiCanary = apiCanary;
}

/** @private */
function logApiTelemetryEvent(
  response: Response,
  requestStats: RequestStats,
  telemetryState?: TelemetryState,
) {
  const { telemetry } = GlobalConfig.instance;
  const { apiName, networkCallStartTime, networkCallEndTime, processingStartTime } = requestStats;

  telemetry.addEvent({
    _table: TelemetryEventType.Api,
    apiName,
    networkDuration: networkCallEndTime.getTime() - networkCallStartTime.getTime(),
    responseCode: response.status,
    totalProcessingTime: new Date().getTime() - processingStartTime.getTime(),
    dimensions: telemetryState?.dimensions || {},
  });
}

/** @private */
export const getErrorDetails = async function getErrorDetails(
  response: Response,
): Promise<ErrorDetails> {
  let isFatal = false;
  let code = CALL_GENERIC_ERROR_CODE;
  let message = "Request Failed -- No Response from Server";
  // We have to clone this because we've already called ".json()" and the response throws an error if you access it twice
  const responseText = await response.clone().text();

  switch (response.statusText) {
    case "timeout":
      code = CALL_TIMEOUT_ERROR_CODE;
      message = "Timeout Error";
      isFatal = true;
      break;
    case "abort":
      code = CALL_ABORTED_ERROR_CODE;
      message = "Aborted";
      break;
    case "error":
      if (response.status >= 400) {
        isFatal = true;
      }

      break;
    case "parsererror":
      message = "Unable to parse response";
      isFatal = true;
      break;
    default:
      break;
  }

  return {
    code,
    message,
    debugMessage: `(response.status ${response.status}) response.text ${responseText}`,
    isFatal,
  };
};

/** @private */
export const parseError = async function parseError(
  response: Response,
  responseBody: ResponseBody,
) {
  const parsedResponse = response.status === 500 ? responseBody : {};

  // If the object has no error in it, the request failed or the server returned an unexpected error response.
  parsedResponse.error ||= await getErrorDetails(response);

  return parsedResponse;
};

/** @private */
export const logTelemetry = async function logTelemetry(
  response: Response,
  requestStats: RequestStats,
  telemetryState?: TelemetryState,
): Promise<Response> {
  const requestDetails: RequestStats = { ...requestStats };
  requestDetails.networkCallEndTime = new Date();

  if (!response.ok) {
    // If the status is not within 200-299
    const error: ErrorWithResponse = new Error(REQUEST_FAILED);
    ExceptionHelper.logException(error, telemetryState, REQUEST_ERROR_NAME);
    error.response = response;
    throw error;
  }

  logApiTelemetryEvent(response, requestDetails, telemetryState);

  return response;
};

export type CommonHeaders = {
  hpgid: string;
  hpgact: string;
  Accept?: string;
  canary?: string;
  correlationId?: string;
  sessionId?: string;
} & HeadersInit;

/**
 * This method is used to generate the common headers used in requests, such as correlationId and sessionId.
 * @param providedHeaders - Optional headers to include. If they share the key of common headers, they will be overwritten.
 * @param useApiCanary - Whether to provide the apiCanary as a `canary` header. (Note: `forQueryString` must be false)
 * @param forQueryString - Whether the headers are for a query request. If false, the `Accept` header is set to `application/json`.
 * @returns The common headers to add to fetch requests
 */
export function getCommonHeaders(
  providedHeaders?: HeadersInit,
  useApiCanary = true,
  forQueryString = false,
) {
  const { initialActionId, initialPageId, apiCanary, correlationId, sessionId } =
    GlobalConfig.instance;
  const commonHeaders = {
    hpgid: initialPageId.toString(),
    hpgact: initialActionId.toString(),
  } as CommonHeaders;

  if (!forQueryString) {
    commonHeaders.Accept = "application/json";

    if (useApiCanary && apiCanary) {
      commonHeaders.canary = apiCanary;
    }
  }

  if (correlationId) {
    commonHeaders.correlationId = correlationId;
  }

  if (sessionId) {
    commonHeaders.sessionId = sessionId;
  }

  return { ...providedHeaders, ...commonHeaders } as CommonHeaders;
}

/**
 * This method used to make requests via the `fetch` API.
 * The method provides generic error handling, records telemetry, and more.
 *
 * **WARNING**: This method _does not_ automatically provide common headers, such as correlationId and sessionId.
 *
 * @param url The URL that should be fetched.
 * @param options An object containing any custom settings that you want to apply to the request.
 *  For more details see https://developer.mozilla.org/en-US/docs/Web/API/fetch.
 * @returns A promise that either resolves to the Response or rejects with an error.
 */
export const request = async function request(url: string, options: RequestOptions) {
  const requestStats: RequestStats = {
    apiName: options.apiName || url,
    processingStartTime: options.processingStartTime || new Date(),
    networkCallStartTime: new Date(),
    networkCallEndTime: new Date(),
  };
  const { timeout } = options;
  const requestOptions = { ...options };
  let timeoutTriggered = false;
  let timeoutID: ReturnType<typeof setTimeout>;
  if (timeout) {
    const controller = new AbortController();
    requestOptions.signal = controller.signal;
    timeoutID = setTimeout(() => {
      timeoutTriggered = true;
      controller.abort();
    }, timeout);
  }

  return fetch(url, requestOptions)
    .then((response) => {
      if (timeoutID) {
        clearTimeout(timeoutID);
      }

      return logTelemetry(response, requestStats, options?.telemetryState);
    })
    .catch((error: ResponseError) => {
      // If we caught the error earlier, just throw it again
      if (error.message === REQUEST_FAILED) {
        throw error;
      } else {
        // Will reject with a TypeError when a network error is encountered or CORS is misconfigured on the server-side,
        // although this usually means permission issues or similar — a 404 does not constitute a network error, for example.
        const networkError = new Error(timeoutTriggered ? REQUEST_TIMEOUT : NETWORK_ERROR);
        ExceptionHelper.logException(networkError, options?.telemetryState, REQUEST_ERROR_NAME);
        throw networkError;
      }
    });
};

const COMMON_OPTIONS = {
  credentials: "same-origin" as "same-origin", // This is default
} as RequestOptions;

const POST_OPTIONS = {
  ...COMMON_OPTIONS,
  method: "POST",
} as RequestOptions;

const GET_OPTIONS = {
  ...COMMON_OPTIONS,
  method: "GET",
} as RequestOptions;

/**
 * This method is used to POST a request via the `fetch` API.
 * The method provides generic error handling, records telemetry, and more.
 * Common headers, such as correlationId and sessionId are added automatically.
 *
 * @param url The URL that should be fetched.
 * @param options An object containing any custom settings that you want to apply to the request.
 *  For more details see https://developer.mozilla.org/en-US/docs/Web/API/fetch.
 * @returns A promise that either resolves to the parsed response body or rejects with an error.
 */
export const post = async function post(url: string, options: RequestOptions) {
  return request(url, {
    ...POST_OPTIONS,
    ...options,
    headers: getCommonHeaders(options?.headers, options?.useApiCanary),
  });
};

/**
 * This method is used to POST a JSON request via the `fetch` API.
 * The method provides a `Content-type` header for the JSON type, provides generic error handling, records telemetry, and more.
 * Common headers, such as correlationId and sessionId are added automatically.
 *
 * @param url The URL that should be fetched.
 * @param options An object containing any custom settings that you want to apply to the request.
 *  For more details see https://developer.mozilla.org/en-US/docs/Web/API/fetch.
 * @returns A promise that either resolves to the parsed response body or rejects with an error.
 */
export const postJSON = async function postJSON<T>(url: string, options: RequestOptions) {
  const requestOptions: RequestOptions = { ...options };
  requestOptions.headers = {
    ...requestOptions.headers,
    "Content-type": "application/json; charset=utf-8",
  };

  let response: Response | null = null;
  let responseBody: Partial<T> = {};
  let failed = false;
  let failedOnParse = false;
  let haveParsedResponse = false;
  let error: ResponseError | null = null;

  // Try to POST and note any failure
  try {
    response = await post(url, requestOptions);
  } catch (e) {
    failed = true;

    if (e instanceof Error) {
      error = e;
      // If we threw an error from a bad status code, check if we have a reference to the response in the error
      // If so, grab the value and delete it from the error
      if (error.response) {
        response = error.response;
      }
    }
  }

  // Try to parse the JSON response and note any failure
  if (response) {
    try {
      responseBody = await response.json();
      haveParsedResponse = true;
    } catch {
      failedOnParse = true;
      failed = true;
    }
  }

  // If we failed at any point, try to add the responseBody to the thrown error
  if (failed) {
    const errorMessage = failedOnParse ? PARSE_FAILED : REQUEST_FAILED;
    // Only create a new error if we didn't get one from before
    error ||= new Error(errorMessage);

    if (response) {
      if (haveParsedResponse) {
        error.responseBody = responseBody;
      }
    }

    if (failedOnParse) {
      ExceptionHelper.logException(error, options?.telemetryState, REQUEST_ERROR_NAME);
    }

    throw error;
  }

  return responseBody;
};

/**
 * This method is used to POST a JSON request via the `fetch` API.
 * The method provides a `Content-type` header for the JSON type, provides generic error handling, records telemetry, and more.
 * Common headers, such as correlationId and sessionId are added automatically.
 * In addition to the `postJSON` logic, this also update the apiCanary from the response when defined
 * as well as considers the request a failure if the response has an error property.
 *
 * @param url The URL that should be fetched.
 * @param options An object containing any custom settings that you want to apply to the request.
 *  For more details see https://developer.mozilla.org/en-US/docs/Web/API/fetch.
 * @returns A promise that either resolves to the parsed response body or rejects with an error.
 */
export const postApiRequest = async function postApiRequest<T extends ResponseBody = ResponseBody>(
  url: string,
  options: RequestOptions,
) {
  let error: ResponseError | null = null;
  let failed = false;
  let responseBody: Partial<T & { apiCanary?: string }> = {};

  try {
    responseBody = await postJSON<T>(url, options);
    if (responseBody.error) {
      failed = true;
    }
  } catch (e) {
    failed = true;
    if (e instanceof Error) {
      error = e;
    }
  }

  if (failed) {
    error ||= new Error(REQUEST_FAILED);

    if (responseBody) {
      error.responseBody ||= responseBody;
    }

    // If we have a response/responseBody and no error.responseBody.error, define one
    if (error.response && error.responseBody && !error.responseBody?.error) {
      error.responseBody = await parseError(error.response, error.responseBody);
    }

    throw error;
  } else if (responseBody.apiCanary) {
    updateApiCanary(responseBody.apiCanary);
    delete responseBody.apiCanary;
  }

  return responseBody;
};

/**
 * This method is used to GET a request via the `fetch` API.
 * The method provides generic error handling, records telemetry, and more.
 * Common headers, such as correlationId and sessionId are added automatically.
 *
 * @param url The URL that should be fetched.
 * @param options An object containing any custom settings that you want to apply to the request.
 *  For more details see https://developer.mozilla.org/en-US/docs/Web/API/fetch.
 * @returns A promise that either resolves to the Response or rejects with an error.
 */
export const get = async function get(url: string, options: RequestOptions) {
  return request(url, {
    ...GET_OPTIONS,
    ...options,
    headers: getCommonHeaders(options?.headers, options?.useApiCanary),
  });
};

/**
 * This method is used to make a GET request via the `fetch` API for retrieving a text file from the CDN.
 * The method provides generic error handling, records telemetry, and more. It does not include common headers.
 * @param url The URL that should be fetched.
 * @param options An object containing any custom settings that you want to apply to the request.
 *  For more details see https://developer.mozilla.org/en-US/docs/Web/API/fetch.
 * @returns A promise that either resolves to the parsed response body text or rejects with an error.
 */
export const getText = async function getText(url: string, options: RequestOptions) {
  const response = await request(url, {
    ...GET_OPTIONS,
    ...options,
  });

  return response.text();
};
