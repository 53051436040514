import { mergeClasses } from "@griffel/react";
import { type ITileProps, type TileComponent } from "../../../../components/tile";
import { CredentialType } from "../../../../constants/constants";
import { useRedirect } from "../../../../hooks/use-redirect";
import useFabricStyles from "../../../../styles/fabric/fabric.styles";
import { type UserCredential } from "../../../../types/credential-types";
import {
  documentationIcon,
  fidoCredIcon,
  forgotCredIcon,
  githubCredIcon,
  linkedInCredIcon,
} from "../../../../utilities/image-helpers/accessible-images";
import { useFidoPostRedirect, useFidoProperties } from "../../hooks/login-hooks";
import LoginConfig from "../../login-config";
import { type ICommonLoginStrings, type ISignInOptionsStrings } from "../../login-interface";
import { createHelpDialogDescription, createHelpDialogHeader } from "../../login-util";

export interface ISignInOptions {
  fidoProps: ITileProps;
  linkedInProps: ITileProps;
  gitHubProps: ITileProps;
  forgotUsernameProps: ITileProps;
}

export const useSignInOptionProperties = ({
  commonLoginStrings,
  signInOptionsStrings,
}: {
  commonLoginStrings: ICommonLoginStrings;
  signInOptionsStrings: ISignInOptionsStrings;
}): ISignInOptions => {
  const {
    linkedInMainText,
    gitHubMainText,
    githubHelpDialogDesc,
    githubHelpDialogDesc2,
    forgotUsernameMainText,
  } = signInOptionsStrings;

  const onSignInOptionClick = useRedirect();
  const redirectCallback = useFidoPostRedirect();

  const fabricStyles = useFabricStyles();
  const { linkedInFedUrl, githubFedUrl, forgotUsernameUrl } = LoginConfig.instance;

  const { fidoLinkText, fidoHelpText, fidoDialogHeader, fidoDialogDescription } =
    useFidoProperties(commonLoginStrings);

  return {
    fidoProps: {
      mainText: fidoLinkText,
      helpText: fidoHelpText,
      imageUrl: fidoCredIcon,
      onTileClick: redirectCallback,
      ariaLabel: fidoLinkText,
      helpDialogIcon: documentationIcon,
      helpDialogHeader: fidoDialogHeader,
      helpDialogDescription: fidoDialogDescription,
      helpDialogIconTestId: "fido-help",
    },
    linkedInProps: {
      mainText: linkedInMainText,
      imageUrl: linkedInCredIcon,
      onTileClick: () => {
        onSignInOptionClick(linkedInFedUrl, true);
      },
      ariaLabel: linkedInMainText,
    },
    gitHubProps: {
      mainText: gitHubMainText,
      imageUrl: githubCredIcon,
      onTileClick: () => {
        onSignInOptionClick(githubFedUrl, true);
      },
      ariaLabel: gitHubMainText,
      helpDialogIcon: documentationIcon,
      helpDialogHeader: createHelpDialogHeader(githubCredIcon, gitHubMainText),
      helpDialogDescription: createHelpDialogDescription(
        githubHelpDialogDesc,
        githubHelpDialogDesc2,
        mergeClasses(fabricStyles.row, fabricStyles.textBody),
      ),
      helpDialogIconTestId: "github-help",
    },
    forgotUsernameProps: {
      mainText: forgotUsernameMainText,
      imageUrl: forgotCredIcon,
      onTileClick: () => {
        onSignInOptionClick(forgotUsernameUrl, true);
      },
      ariaLabel: forgotUsernameMainText,
    },
  };
};

export const useSignInOptionsBuilder = (
  credentials: UserCredential[],
  {
    commonLoginStrings,
    signInOptionsStrings,
  }: { commonLoginStrings: ICommonLoginStrings; signInOptionsStrings: ISignInOptionsStrings },
) => {
  const { showForgotUsernameLink, forgotUsernameUrl } = LoginConfig.instance;

  const tileList: TileComponent[] = [];

  const { fidoProps, linkedInProps, gitHubProps, forgotUsernameProps } = useSignInOptionProperties({
    commonLoginStrings,
    signInOptionsStrings,
  });

  credentials.forEach((item) => {
    if (item.credentialType === CredentialType.Fido) {
      tileList.push(fidoProps);
    } else if (item.credentialType === CredentialType.LinkedIn && item.redirectUrl) {
      tileList.push(linkedInProps);
    } else if (item.credentialType === CredentialType.GitHub && item.redirectUrl) {
      tileList.push(gitHubProps);
    }
  });

  if (showForgotUsernameLink && forgotUsernameUrl) {
    tileList.push(forgotUsernameProps);
  }

  return tileList;
};
