import { CredentialType } from "../../../constants/constants";
import { type OneTimeCodeCredential, type UserCredential } from "../../../types/credential-types";
import { type ILoginCredentials } from "../login-context";

export interface RemoteNgcCredential extends UserCredential {
  credentialType: CredentialType.RemoteNGC;
}

export interface PublicIdentifierCodeCredential extends UserCredential {
  credentialType: CredentialType.PublicIdentifierCode;
}

export type ConfirmSendCredential =
  | OneTimeCodeCredential
  | RemoteNgcCredential
  | PublicIdentifierCodeCredential;

export type ConfirmSendCredentialTypes =
  | CredentialType.OneTimeCode
  | CredentialType.RemoteNGC
  | CredentialType.PublicIdentifierCode;

export const ExpectedConfirmSendCredentialTypes = [
  CredentialType.OneTimeCode,
  CredentialType.RemoteNGC,
  CredentialType.PublicIdentifierCode,
];

export interface OtcUserCredentials extends ILoginCredentials {
  otcCredential: OneTimeCodeCredential;
  preferredCredential: CredentialType.OneTimeCode;
}

export interface NonOtcUserCredentials extends ILoginCredentials {
  preferredCredential: Exclude<ConfirmSendCredentialTypes, CredentialType.OneTimeCode>;
}

export type ConfirmSendUserCredentials = OtcUserCredentials | NonOtcUserCredentials;

export interface ConfirmSendStrings {
  switchToEvictCredText: string;
  changeUserLinkText: string;
  headerText: string;
}
