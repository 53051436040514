import { type IUsernameViewStrings } from "../username-view-interface";

export const usernameViewStringsFabric: IUsernameViewStrings = {
  header: getLocalString("SignIn_Title"),
  placeholder: getLocalString("SignIn_UserName_Hint"),
  signInOptionsText: getLocalString("Login_CredPicker_Title_NoUser"),
  errorInvalidMemberNameEmailPhoneSkype: getLocalString("SignIn_Error_EmailMissing"),
  badPhoneNumberErrorMessage: getLocalString("Error_1208"),
  signupLinkAriaLabel: getLocalString("Login_Signup_CreateOneLink_AriaLabel"),
  loginSignupLink: getLocalString("Login_Signup_Link"),
};
