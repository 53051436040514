/**
 * This method is used to load the custom CSS code which can load custom CSS files, process the files, and apply them.
 * @param cssFileUrl The URL of the custom CSS file to be loaded
 * @returns A Promise that resolves when CSS has been loaded and applied.
 */
export const loadCustomCss = async function loadCustomCss(cssFileUrl: string) {
  const { loadCss } = await import(
    /* webpackChunkName: "custom-css-loader" */ "./custom-css-loader"
  );
  await loadCss(cssFileUrl);
};

/**
 * This is used to remove any custom CSS style tag that may have existed on another page and is no longer needed.
 * It queries the unique ID and removes the tag if it exists.
 */
export const removeCustomCss = function removeCustomCss() {
  document.getElementById("customCssStyle")?.remove();
};
