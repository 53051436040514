export enum ServiceDiagEventNames {
  None = "",
  AccountTransferDenied = "AccountTransfer_Denied",
  AccountTransferErrorMissingOtc = "AccountTransferError_MissingOtc",
  AccountTransferErrorUnknownError = "AccountTransferError_UnknownError",
  AccountTransferMobileDenied = "AccountTransfer_MobileDenied",
  AccountTransferSuccess = "AccountTransfer_Success",
  AccountTransferTimeout = "AccountTransfer_Timeout",
  OtcCredentialUndefined = "OtcCredential_Undefined",
  QrLogoNotFound = "QrLogo_NotFound",
  Redirect = "Redirect",
}
