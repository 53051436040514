import { type FormEvent, useContext } from "react";
import { ViewId } from "../../../../../constants";
import { GlobalContext } from "../../../../../global-context";
import { GlobalActionType } from "../../../../../global-reducer";
import { trimQsParams } from "../../../../../utilities/strings-helper";

export const usePasswordHandler = (
  postUrl: string,
  validationError: string,
  externalError: string | JSX.Element,
  onFocus: () => void,
  showError: (showError: boolean) => void,
) => {
  const { dispatchStateChange: dispatchGlobal } = useContext(GlobalContext);

  return (event: FormEvent<HTMLFormElement>) => {
    onFocus();
    showError(true);

    if (!validationError && !externalError) {
      dispatchGlobal({
        type: GlobalActionType.BeginNavigate,
        source: ViewId.Password,
        destination: trimQsParams(postUrl),
      });

      return;
    }

    event.preventDefault();
  };
};
