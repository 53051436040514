import { makeStyles, shorthands } from "@griffel/react";
import { MOBILE_LAYOUT_SIZE } from "./constants-fabric.styles";

export const useVerticalSplitFabricStyles = makeStyles({
  verticalContainer: {
    display: "table",
    height: "100%",
    width: "100%",
  },
  backgroundContainer: {
    display: "table-cell",
    position: "relative",
    height: "100%",

    [MOBILE_LAYOUT_SIZE]: { display: "none" },
  },
  lightboxContainer: {
    width: "500px",
    display: "table-cell",
    verticalAlign: "top",
  },
  mainSection: {
    height: "100%",
    display: "table-row",
  },
  mainContainer: {
    display: "table-cell",
  },
  lightbox: {
    ...shorthands.margin("20px", "auto"),
    minWidth: 0,
    boxShadow: "none",

    [MOBILE_LAYOUT_SIZE]: {
      minWidth: "initial",
      ...shorthands.margin("auto"),
    },
  },
});
