import React, { useContext, useEffect } from "react";
import { mergeClasses } from "@griffel/react";
import { CredentialSwitchLinksComponent } from "../../../../components/credential-switch-links/credential-switch-links";
import { LinkButton } from "../../../../components/link-button";
import ProgressIndicatorFabric, {
  ProgressIndicatorContainer,
} from "../../../../components/loading-progress/fabric/progress-indicator-fabric";
import { ProofImage } from "../../../../components/proof-image";
import {
  TextButtonContainer,
  TextButtonFabric,
} from "../../../../components/text-button/fabric/text-button-fabric";
import { TitleFabric } from "../../../../components/title/fabric/title-fabric";
import { RemoteNgcType } from "../../../../constants/constants";
import { FlowId, ViewId } from "../../../../constants/routing-constants";
import { useActivateView } from "../../../../hooks/use-activate-view";
import { useDocumentTitle } from "../../../../hooks/use-document-title";
import { useEffectOnce } from "../../../../hooks/use-effect-once";
import useFabricStyles from "../../../../styles/fabric/fabric.styles";
import { FOCUS_TIMEOUT } from "../../../../styles/fabric/layout-animate-fabric.styles";
import { useRemoteNgcStyles } from "../../../../styles/fabric/remote-ngc-view-fabric.styles";
import { ProofType } from "../../../../types/credential-types";
import { SecondaryContentContainerFabric } from "../../components/fabric/secondary-content-container-fabric";
import { commonLoginStringsFabric } from "../../fabric/common-login-strings-fabric";
import { LoginContext } from "../../login-context";
import { LoginFlowPostHiddenInputs } from "../../login-flow-post-hidden-inputs";
import { LoginActionType } from "../../login-reducer";
import { getServerErrorText } from "../../login-util";
import {
  useCredentialSwitchProperties,
  useEvictedCredPickerProperties,
  useRemoteNgcViewProperties,
} from "../hooks/remote-ngc-view-hooks";
import { getDisplaySignCredSwitchAriaDescribedBy } from "../remote-ngc-view-util";

/**
 * RemoteNgcView component
 * @returns A rendered instance of this component
 */
export const RemoteNgcViewFabric: React.FC = function RemoteNgcViewFabric() {
  const {
    documentTitle,
    showBackArrowButton,
    authenticatorInfoUrl,
    title,
    showAnimatedGifWhilePolling,
    description,
    displaySign,
    remoteNgcType,
    error,
    setError,
    resendNotificationText,
    lostAuthenticatorLinkText,
    showLostAuthenticatorLink,
    primaryButtonLabel,
    isRequestPending,
    postUrl,
    unauthenticatedSessionId,
    primaryButtonAriaDescribedBy,
    onLostAuthenticatorClicked,
    loginPostProps,
    formRef,
    setupAndStartPolling,
    showButtons,
    setIsRequestPending,
    displaySignRef,
  } = useRemoteNgcViewProperties({ commonLoginStrings: commonLoginStringsFabric });

  const {
    canary,
    cleansedUsername,
    context,
    flowTokenName,
    flowTokenValue,
    foundMsas,
    isFidoSupported,
    isKmsiChecked,
    loginOption,
    paginatedState,
    postType,
    postedForceSignIn,
    randomBlob,
    showCookieBanner,
    displayUsername,
    rngcDefaultType,
    rngcEntropy,
    rngcSessionIdentifier,
  } = loginPostProps;

  const {
    onSwitchToEvictedCredPickerClicked,
    showSwitchToEvictedCredPicker,
    switchToEvictedCredPickerText,
  } = useEvictedCredPickerProperties();

  const credSwitchLinkProps = useCredentialSwitchProperties(
    title,
    error,
    displaySign,
    setIsRequestPending,
  );

  // Set initial focus on the displaySign when it is shown (i.e, when it's an unfamiliar device). Otherwise, when the displaySign is
  // not shown (i.e., when it's a familiar device or when there's an error), we set focus on the credential switch link.
  useEffect(() => {
    if (displaySign) {
      setTimeout(() => {
        displaySignRef?.current?.focus();
      }, FOCUS_TIMEOUT);
    }
  }, [displaySign, displaySignRef]);

  const {
    viewState: {
      credentials: { useEvictedCredentials },
      serverErrorShown,
    },
    dispatchStateChange: dispatchLoginStateChange,
  } = useContext(LoginContext);

  useActivateView(ViewId.RemoteNgc, FlowId.Login, {
    showBackButtonOnActiveView: showBackArrowButton,
  });

  useDocumentTitle(documentTitle);

  const styles = useFabricStyles();
  const remoteNgcStyles = useRemoteNgcStyles();

  // If there is an error from the server, show it and don't start polling
  const serverError = getServerErrorText(commonLoginStringsFabric);

  useEffectOnce(() => {
    if (serverError && !serverErrorShown) {
      dispatchLoginStateChange({
        type: LoginActionType.SetServerErrorShown,
        payload: true,
      });

      setError(serverError);
    } else {
      setupAndStartPolling();
    }
  });

  const displaySignContent = (
    <div className={remoteNgcStyles.displaySign}>
      <span
        data-testid="displaySign"
        aria-describedby={getDisplaySignCredSwitchAriaDescribedBy(title, error)}
        ref={displaySignRef}
        tabIndex={-1}
        id="displaySign"
      >
        {displaySign}
      </span>
    </div>
  );

  const contentWithGif = (
    <div className={styles.section}>
      {displaySign && (
        <div className={mergeClasses(styles.section, remoteNgcStyles.pullLeft)}>
          <div className={styles.textBody}>{displaySignContent}</div>
        </div>
      )}
      <div
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
        id="pollingDescription"
        className={mergeClasses(
          styles.textBlockBody,
          styles.overFlowHidden,
          remoteNgcStyles.descriptionPadding,
        )}
      >
        {description}
      </div>
      <div className={styles.section}>
        <div className={mergeClasses(styles.row, styles.textBody)}>
          <img
            role="presentation"
            data-testid="authenticatorInfo"
            src={authenticatorInfoUrl}
            alt=""
            className={mergeClasses(styles.centerBlock, remoteNgcStyles.authenticatorImage)}
          />
        </div>
      </div>
    </div>
  );

  const contentWithoutGif = (
    <>
      <div className={styles.section}>
        <div className={mergeClasses(styles.row, styles.textBody)}>
          <ProofImage
            small
            animate
            proofType={ProofType.TOTPAuthenticatorV2}
            dataTestId="proofImg"
          />
          <div
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
            id="pollingDescription"
            className={mergeClasses(styles.textBlockBody, styles.overFlowHidden)}
          >
            {description}
          </div>
        </div>
      </div>
      {displaySign && (
        <div className={styles.section}>
          <div
            className={mergeClasses(styles.row, styles.textBody, remoteNgcStyles.displaySignRow)}
          >
            {displaySignContent}
          </div>
        </div>
      )}
    </>
  );

  const errorContent = (
    <div className={styles.section}>
      <div className={styles.formGroup}>
        <div
          id="errorDescription"
          className={mergeClasses(
            styles.row,
            styles.textBody,
            styles.textBlockBody,
            styles.alertError,
          )}
        >
          {error}
        </div>
        {remoteNgcType === RemoteNgcType.PushNotification && (
          <div className={mergeClasses(styles.row, styles.textBody, styles.textBlockBody)}>
            {resendNotificationText}
          </div>
        )}
      </div>
    </div>
  );

  const content = showAnimatedGifWhilePolling ? contentWithGif : contentWithoutGif;

  const secondaryContent = !isRequestPending && (
    <SecondaryContentContainerFabric>
      {!useEvictedCredentials && (
        <div className={styles.row}>
          <div className={styles.formGroup}>
            <CredentialSwitchLinksComponent {...credSwitchLinkProps} />
          </div>
        </div>
      )}
      {!useEvictedCredentials && showSwitchToEvictedCredPicker && (
        <div className={styles.row}>
          <div className={styles.formGroup}>
            <LinkButton
              text={switchToEvictedCredPickerText}
              onClick={onSwitchToEvictedCredPickerClicked}
            />
          </div>
        </div>
      )}
      {showLostAuthenticatorLink && (
        <div className={styles.row}>
          <div className={styles.formGroup}>
            <LinkButton text={lostAuthenticatorLinkText} onClick={onLostAuthenticatorClicked} />
          </div>
        </div>
      )}
    </SecondaryContentContainerFabric>
  );

  const renderMainContent = () => {
    if (!isRequestPending) {
      return error ? errorContent : content;
    }

    return (
      <ProgressIndicatorContainer>
        <ProgressIndicatorFabric />
      </ProgressIndicatorContainer>
    );
  };

  return (
    <form
      name="f1"
      id="i0281"
      data-testid="remoteNgcForm"
      noValidate
      spellCheck="false"
      method="post"
      autoComplete="false"
      ref={formRef}
      action={postUrl}
    >
      <TitleFabric titleId="remoteNgcTitle" title={title} />

      {/* TO-DO: ESTS - add branding description */}

      <input type="hidden" name="slk" value={rngcSessionIdentifier} data-testid="slk" />
      <input type="hidden" name="uaid" value={unauthenticatedSessionId} data-testid="uaid" />

      <LoginFlowPostHiddenInputs
        canary={canary}
        cleansedUsername={cleansedUsername}
        context={context}
        flowTokenName={flowTokenName}
        flowTokenValue={flowTokenValue}
        foundMsas={foundMsas}
        isFidoSupported={isFidoSupported}
        isKmsiChecked={isKmsiChecked}
        loginOption={loginOption}
        paginatedState={paginatedState}
        postType={postType}
        postedForceSignIn={postedForceSignIn}
        randomBlob={randomBlob}
        displayUsername={displayUsername}
        rngcDefaultType={rngcDefaultType}
        rngcEntropy={rngcEntropy}
        rngcSessionIdentifier={rngcSessionIdentifier}
        showCookieBanner={showCookieBanner}
      />

      {renderMainContent()}
      {secondaryContent}

      {!isRequestPending && error && showButtons && (
        <div className={styles.buttonMargin}>
          <div className={styles.row}>
            <TextButtonContainer>
              <TextButtonFabric
                isPrimary
                buttonId="idSIButton9"
                label={primaryButtonLabel}
                isButtonType
                ariaDescribedBy={primaryButtonAriaDescribedBy}
                onClick={setupAndStartPolling}
              />
            </TextButtonContainer>
          </div>
        </div>
      )}
    </form>
  );
};
