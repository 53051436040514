/**
 * Instrumentation keys are used to figure out which tenant client events should be routed to.
 * They are not used for authentication and are not considered "secret" in any way.
 */
const InstrumentationKeys = {
  AccountInt: "d68e57f471144df29d97e1621f2774fb-8c13d82e-3066-4beb-9b9e-cefdbccbad56-7511",
  AccountProd: "9be4f99c87354f6e94f8f99664545aa0-be890c9e-a0c3-4e93-a12a-040f61fd5120-7013",
  IdentityUXInt: "4e0044d70b9d4de19ed039a7b1117f35-75be6b9a-38c3-427d-a1af-112271490200-6655",
  IdentityUxProd: "69adc3c768bd4dc08c19416121249fcc-66f1668a-797b-4249-95e3-6c6651768c28-7293",
};

/**
 *
 * @param instrumentationKey Instrumentaion Key
 * @returns Remapped Instrumentation Key
 */
export const getInstrumentationKey = (instrumentationKey?: string): string => {
  let remappedInstrumentationKey = instrumentationKey;
  // For Axis code, re-map Account UX instrumentation key to IDUX key so that all data flows to the same table. To solve it, Re-map Account UX instrumentation key to IDUX key so that all data flows to the same table.
  if (instrumentationKey === InstrumentationKeys.AccountInt) {
    remappedInstrumentationKey = InstrumentationKeys.IdentityUXInt;
  } else if (instrumentationKey === InstrumentationKeys.AccountProd) {
    remappedInstrumentationKey = InstrumentationKeys.IdentityUxProd;
  }

  return remappedInstrumentationKey!;
};
