import React, { useContext, useEffect, useState } from "react";
import { LayoutTemplateType } from "../../../constants/constants";
import { FlowId, ViewId } from "../../../constants/routing-constants";
import FeaturesConfig from "../../../features-config";
import GlobalConfig from "../../../global-config";
import { GlobalContext } from "../../../global-context";
import { useRenderPromises } from "../../../hooks/use-render-promises";
import { initialPostRedirectState, PostRedirectProvider } from "../../../post-redirect-context";
import useFabricStyles, { useStaticFabricStyles } from "../../../styles/fabric/fabric.styles";
import { ExceptionHelper, GenericErrorCodes } from "../../../telemetry-helpers/exception-helper";
import { PerfHelper } from "../../../telemetry-helpers/perf-helper";
import {
  getStandardDimensions,
  TelemetryEventType,
} from "../../../telemetry-helpers/telemetry-helper";
import { useTelemetryState } from "../../../telemetry-helpers/use-telemetry-state";
import { useUnhandledRejection } from "../../../telemetry-helpers/use-unhandled-rejection";
import ErrorComponent from "../../error-component";
import LightboxLayout from "../../layout/fabric/lightbox-layout-fabric";
import VerticalSplitLayout from "../../layout/fabric/vertical-split-layout-fabric";
import { PostRedirectForm } from "../../post-redirect-form";

/**
 * Shell component for Fabric flavor
 * @param props The properties for this component
 * @param props.children The child elements to render inside this layout
 * @returns The rendered component (either vertical split or lightbox style depending on the layout template)
 */
const ShellFabric: React.FC = function ShellFabric({ children }) {
  const { telemetry, isRtlMarket } = GlobalConfig.instance;
  const { loadGamepadNavigationModule } = FeaturesConfig.instance;
  const {
    globalState: {
      activeFlavor,
      styles: { faviconUrl, layoutTemplate },
      renderPromises,
    },
  } = useContext(GlobalContext);

  const [isPageBlank, setPageBlank] = useState(false);
  const [isPltEventFired, setIsPltEventFired] = useState(false);
  const telemetryState = useTelemetryState();
  useUnhandledRejection(telemetryState); // Set up the unhandled rejection handler window event listener
  const LayoutComponent =
    layoutTemplate === LayoutTemplateType.VerticalSplit ? VerticalSplitLayout : LightboxLayout;

  useStaticFabricStyles();
  const styles = useFabricStyles();

  useRenderPromises(renderPromises);

  useEffect(() => {
    /**
     * Lazy loading Gamepad Navigation Module
     */
    async function loadModule() {
      if (loadGamepadNavigationModule) {
        const gamepadModule = await import("@gaming/react-gamepad-navigation-lite");
        gamepadModule.initGamepadNavigation({ isRTL: isRtlMarket });
      }
    }

    loadModule();
  }, [loadGamepadNavigationModule, isRtlMarket]);

  useEffect(() => {
    /** @internal */
    function logPltEvent() {
      const { view, flow } = telemetryState.dimensions;

      if (!isPltEventFired && (view !== ViewId.None || flow !== FlowId.None)) {
        /* We need to wait until the window's onLoad event handler complete so that the data
         * in PerformanceTiming is fully populated, so we schedule this to run 100ms after
         * the event handler ends.
         */
        setTimeout(
          () =>
            telemetry.addEvent({
              _table: TelemetryEventType.Plt,
              ...PerfHelper.getPerformanceMetrics(),
              dimensions: getStandardDimensions({
                activeView: view!,
                activeFlow: flow!,
                activeFlavor,
              }),
            }),
          100,
        );
        setIsPltEventFired(true);
      }
    }

    /** @private */
    function checkPageBlank() {
      const coreElement = document.getElementById("outer");

      if (
        coreElement?.offsetHeight === undefined ||
        coreElement?.offsetWidth === undefined ||
        coreElement?.getClientRects().length === undefined
      ) {
        ExceptionHelper.logPageIsBlankError(telemetryState);
        setPageBlank(true);
      }
    }

    /** @private */
    function errorEventHandler(event: ErrorEvent) {
      if (!ExceptionHelper.isErrorLogged(event)) {
        ExceptionHelper.logException(event, telemetryState);
      }
    }

    /** @internal */
    function loadEventHandler() {
      logPltEvent();
      checkPageBlank();
    }

    window.addEventListener("error", errorEventHandler);

    if (document.readyState === "complete") {
      loadEventHandler();
    } else {
      window.addEventListener("load", loadEventHandler);
    }

    return () => {
      window.removeEventListener("load", loadEventHandler);
      window.removeEventListener("error", errorEventHandler);
    };
  }, [telemetry, telemetryState, isPltEventFired, activeFlavor]);

  useEffect(() => {
    const favicon = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
    if (favicon && faviconUrl) {
      favicon.href = faviconUrl;
    }
  }, [faviconUrl]);

  return isPageBlank ? (
    <ErrorComponent errorCode={GenericErrorCodes.BlankPageError} />
  ) : (
    <LayoutComponent styles={styles}>
      <PostRedirectProvider initialState={initialPostRedirectState}>
        <PostRedirectForm />

        {children}
      </PostRedirectProvider>
    </LayoutComponent>
  );
};

export default ShellFabric;
