/**
 * Extracts the domain from the given URL
 * @param url URL from which to extract the domain
 * @returns Domain from the URL
 */
const extractOriginFromUrl = (url: string) => {
  if (url) {
    const element = document.createElement("a");
    element.href = url;

    let { origin } = element;
    if (!origin) {
      origin = `${element.protocol}//${element.hostname}${element.port ? `:${element.port}` : ""}`;
    }

    return origin;
  }

  return "";
};

export const doOriginsMatch = (requestUrl: string, responseOrigin: string) => {
  // Some browsers (mainly IE) do not have the origin property, so we need to build it manually
  // we run both origins through extractOriginFromUrl so they are normalized the same way. Some browsers include default ports and others do not.
  const requestUrlOrigin = extractOriginFromUrl(requestUrl);
  const dataOrigin = extractOriginFromUrl(responseOrigin);

  return dataOrigin === requestUrlOrigin;
};
