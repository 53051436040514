export const OUTLINE_WIDTH = "1px";
export const OUTLINE_COLOR = "#000";
export const OUTLINE_STYLE = "dashed";
export const DEFAULT_HOVER_FOCUS_BACKGROUND_COLOR = "rgba(0, 0, 0, 0.1)";
export const DEFAULT_ACTIVE_BACKGROUND_COLOR = "rgba(0, 0, 0, 0.3)";
export const TILE_ROW_MARGIN = "-44px";
export const TILE_ROW_MOBILE_MARGIN = "-24px";
export const TILE_IMAGE_SIZE = "48px";
export const HELP_BUTTON_MARGIN = "-11px";
export const HELP_BUTTON_MOBILE_MARGIN = "-6px";
