import { useContext } from "react";
import { AuthenticationContext } from "../../../../../authentication-context";
import {
  CredentialType,
  loginOption as LoginOption,
  postType as PostType,
} from "../../../../../constants/constants";
import { ViewId } from "../../../../../constants/routing-constants";
import GlobalConfig from "../../../../../global-config";
import { GlobalContext } from "../../../../../global-context";
import {
  useBackButtonControl,
  useNavigateDirection,
} from "../../../../../hooks/use-navigate-direction";
import { getFidoSupport } from "../../../../../utilities/browser-helper";
import {
  cleanseUsername,
  copyQueryStringParameters,
} from "../../../../../utilities/strings-helper";
import { useSelectAccount } from "../../../hooks/login-hooks";
import LoginConfig from "../../../login-config";
import { LoginState } from "../../../login-constants";
import { LoginContext } from "../../../login-context";
import { type ICommonLoginStrings } from "../../../login-interface";
import {
  getCommonDescription,
  getCommonTitle,
  getShowChangeUserLink,
  getShowPhoneDisambigLink,
} from "../../../login-util";
import {
  type IPasswordViewProperties,
  type IPasswordViewStrings,
} from "../password-view-interface";
import { updateMemberNamePrefill } from "../password-view-util";

/**
 * @returns password view properties
 * @param strings Flavored strings that are used by this hook
 * @param strings.commonLoginStrings Common login strings
 * @param strings.passwordViewStrings Password view strings
 */
export const usePasswordViewProperties = (strings: {
  commonLoginStrings: ICommonLoginStrings;
  passwordViewStrings: IPasswordViewStrings;
}): IPasswordViewProperties => {
  const {
    commonLoginStrings,
    passwordViewStrings: {
      loginPasswordPageTitle,
      primaryButtonText,
      noPasswordError,
      forgotPasswordLinkText,
      passwordHint,
    },
  } = strings;

  const {
    globalState: {
      user,
      styles: { friendlyAppName },
    },
  } = useContext(GlobalContext);

  const {
    context,
    canaryTokenValue: canary,
    flowTokenName,
    showCookieBanner,
    postUrl,
  } = GlobalConfig.instance;

  const {
    authState: { flowTokenValue },
  } = useContext(AuthenticationContext);

  const {
    passwordViewHeader,
    loginDescription,
    loginMode,
    loginStringsVariant,
    randomBlob,
    foundMsas,
    postedForceSignIn,
    isFidoSupportedHint,
    defaultLoginOptions,
    hasWizardBehavior,
    rawQueryString,
    resetPasswordUrl,
    resetPasswordPrefillParam,
  } = LoginConfig.instance;

  const {
    viewState: {
      credentials: { availableCredentials, useEvictedCredentials },
      remoteNgcParams,
    },
  } = useContext(LoginContext);

  const title = getCommonTitle(loginMode, friendlyAppName, commonLoginStrings);
  const header = passwordViewHeader || loginPasswordPageTitle;

  const brandingDescription = getCommonDescription(loginStringsVariant, loginDescription);
  const isFidoSupported = getFidoSupport(isFidoSupportedHint);
  const isKmsiChecked = defaultLoginOptions === LoginOption.rememberPwd;
  const cleansedUsername = cleanseUsername(user.username.unsafeUnescapedString);
  const displayName = user.displayUsername.safeHtmlEscapedString;
  const postType = hasWizardBehavior ? PostType.passwordInline : PostType.password;
  const loginOption = isKmsiChecked ? LoginOption.rememberPwd : LoginOption.nothingChecked;
  const updatedPostUrl = rawQueryString
    ? copyQueryStringParameters(rawQueryString, postUrl)
    : postUrl;
  const canGoBack = useBackButtonControl();
  const isInitialView = !canGoBack;

  const credentialSwitchLinksProps = {
    sourceViewId: ViewId.Password,
    availableCredentials,
    currentCredential: { credentialType: CredentialType.Password },
    setRequestPendingFlag: () => {}, // flag not used by fabric, likely will be by other flavors
    shouldUpdateOtcCredential: true,
  };

  const allowPhoneDisambiguation = getShowPhoneDisambigLink(displayName);
  const navigate = useNavigateDirection();
  const navigateToPhoneDisambig = () => {
    navigate(ViewId.Password, ViewId.PhoneDisambiguation);
  };

  const showChangeUserLink = getShowChangeUserLink(isInitialView);
  const selectAccount = useSelectAccount(ViewId.Password);

  return {
    allowPhoneDisambiguation,
    navigateToPhoneDisambig,
    showChangeUserLink,
    selectAccount,
    brandingDescription,
    credentialSwitchLinksProps,
    header,
    noPasswordErrorMessage: noPasswordError,
    placeholder: passwordHint,
    postUrl: updatedPostUrl,
    primaryButtonLabel: primaryButtonText,
    title,
    resetPasswordUrl: updateMemberNamePrefill(
      resetPasswordUrl,
      resetPasswordPrefillParam,
      user.displayUsername.unsafeUnescapedString,
    ),
    forgotPasswordLinkText,
    loginPostProps: {
      canary,
      cleansedUsername,
      context,
      flowTokenName,
      flowTokenValue,
      foundMsas,
      isFidoSupported,
      isKmsiChecked,
      loginOption,
      paginatedState: LoginState.Password,
      postType,
      postedForceSignIn,
      randomBlob,
      showCookieBanner,
      displayUsername: user.displayUsername.unsafeUnescapedString,
      rngcDefaultType: remoteNgcParams.defaultType,
      rngcEntropy: remoteNgcParams.entropy,
      rngcSessionIdentifier: remoteNgcParams.sessionIdentifier,
    },
    canGoBack,
    useEvictedCredentials,
  };
};
