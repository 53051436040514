import { useCallback, useEffect, useState } from "react";
import { type ICountryInfo } from "../utilities/country-helper";
import { getFullyQualifiedPhoneNumber } from "../utilities/phone-number-helper";
import useTextInputForm, { type IFormSubmissionProps } from "./use-text-input-form";

export interface IPhoneNumberSubmitProps extends IFormSubmissionProps {
  inputValue?: string;
  dropdownValue?: ICountryInfo;
}

export interface IPhoneNumberFormProps {
  defaultCountry: ICountryInfo;
  initialInputValue: string;
  inputValidationFunc?: (phoneNumber: string, inputValue?: string) => string;
}

/**
 * Hook that provides handlers for the phone number component
 * @param props The properties for this hook
 * @param props.defaultCountry The default phone country
 * @param props.initialInputValue The initial input value
 * @param props.inputValidationFunc The callback for validating the phone number value.
 * @returns Handlers for using the phone number component.
 */
export const usePhoneNumber = (props: IPhoneNumberFormProps) => {
  const { defaultCountry, initialInputValue, inputValidationFunc = () => "" } = props;

  const {
    onBlur,
    onFocus,
    focus,
    setFocus,
    validationError,
    setValidationError,
    externalError,
    setExternalError,
    showError,
    setShowError,
    onFormSubmission,
    errorHandler,
  } = useTextInputForm();

  const [dropdownValue, setdropdownValue] = useState(defaultCountry);
  const [inputValue, setInputvalue] = useState(initialInputValue);

  const initialPhoneNumberValue = getFullyQualifiedPhoneNumber(inputValue, dropdownValue);
  const [phoneNumberValue, setPhoneNumberValue] = useState(initialPhoneNumberValue);

  const phoneNumberChangeHandler = useCallback(
    (country: ICountryInfo, phoneNumber: string) => {
      setdropdownValue(country);
      setInputvalue(phoneNumber);
      setPhoneNumberValue(getFullyQualifiedPhoneNumber(phoneNumber, country));
      setExternalError("");
    },
    [setExternalError],
  );

  useEffect(() => {
    const errorMessage = inputValidationFunc(phoneNumberValue, inputValue);
    setValidationError(errorMessage);
  }, [inputValidationFunc, phoneNumberValue, inputValue, setValidationError]);

  return {
    focus,
    inputValue,
    dropdownValue,
    phoneNumberValue,
    showError,
    externalError,
    validationError,
    setExternalError,
    setValidationError,
    onBlur,
    onFocus,
    setShowError,
    setFocus,
    phoneNumberChangeHandler,
    onFormSubmission,
    inputValidationFunc,
    errorHandler,
  };
};
