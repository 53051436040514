import { type ICredentialPickerStrings } from "../../login-interface";

/**
 * @returns Credential picker view strings
 */
export const credentialPickerStringsFabric: ICredentialPickerStrings = {
  oneTimeCodeEmailLabel: getLocalString("Send_Ott_Email"),
  oneTimeCodeSMSLabel: getLocalString("Send_Ott_Sms"),
  oneTimeCodeVoiceLabel: getLocalString("Send_Ott_Voice"),
  passwordLabel: getLocalString("Login_CredPicker_Option_Password"),
  remoteNgcLabel: getLocalString("Login_CredPicker_Option_App"),
};
