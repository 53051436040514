export const postType = {
  password: 11,
  federation: 13,
  sha1: 15,
  strongAuth: 18,
  strongAuthTotp: 19,
  lwaConsent: 30,
  passwordInline: 20,
  remoteNGC: 21,
  sessionApproval: 22,
  ngc: 23,
  otcNoPassword: 24,
  remoteConnect_nativePlatform: 25,
  otc: 27,
  kmsi: 28,
  transferTokenOtc: 31,
};

export const loginOption = {
  doNotRemember: 0,
  rememberPwd: 1,
  nothingChecked: 3,
};

export enum ScenarioId {
  Account_CheckUpWizard = 100228,
  Reset_Password = 100103,
}

export enum HighContrastTheme {
  none = "",
  dark = "dark",
  light = "light",
}

export type MappedKeys<T, U = string> = {
  [prop in keyof T]: U;
};

export type DataApiError = { data?: string; code: number };

export type ResponseBody = Record<string, unknown> & { error?: string | DataApiError };

export interface ErrorWithResponse extends Error {
  response?: Response;
}

export interface ResponseError extends ErrorWithResponse {
  responseBody?: ResponseBody;
}

export enum LayoutTemplateType {
  Lightbox = 0,
  VerticalSplit = 1,
}

export enum ExternalClassName {
  backgroundAlwaysVisible = "ext-background-always-visible",
  backgroundImage = "ext-background-image",
  backgroundOverlay = "ext-background-overlay",
  bannerLogo = "ext-banner-logo",
  boilerPlateText = "ext-boilerplate-text",
  button = "ext-button",
  buttonFieldContainer = "ext-button-field-container",
  buttonItem = "ext-button-item",
  error = "ext-error",
  debugItem = "ext-debug-item",
  footer = "ext-footer",
  footerContent = "ext-footer-content",
  footerItem = "ext-footer-item",
  footerLinks = "ext-footer-links",
  hasBackground = "ext-has-background",
  header = "ext-header",
  headerLogo = "ext-header-logo",
  middle = "ext-middle",
  primary = "ext-primary",
  secondary = "ext-secondary",
  signInBox = "ext-sign-in-box",
  verticalSplitMainSection = "ext-vertical-split-main-section",
  verticalSplitBackgroundImageContainer = "ext-vertical-split-background-image-container",
}

export enum CredentialType {
  None = 0,
  Password = 1,
  RemoteNGC = 2,
  OneTimeCode = 3,
  Federation = 4,
  CloudFederation = 5,
  OtherMicrosoftIdpFederation = 6,
  Fido = 7,
  GitHub = 8,
  PublicIdentifierCode = 9,
  LinkedIn = 10,
  RemoteLogin = 11,
  Google = 12,
  AccessPass = 13,
  Facebook = 14,
  Certificate = 15,
  OfflineAccount = 16,

  // add new credential types above this
  NoPreferredCredential = 1000,
}

// API Framework custom error codes
export enum ApiErrorCodes {
  // From ApiErrorCode.cs
  GeneralError = 6000,
  AuthFailure = 6001,
  InvalidArgs = 6002,

  // From ApiRequest.js
  Generic = 8000,
  Timeout = 8001,
  Aborted = 8002,
}

export enum IfExistsResult {
  Unknown = -1,
  Exists = 0,
  NotExist = 1,
  Throttled = 2,
  Error = 4,
  ExistsInOtherMicrosoftIDP = 5,
  ExistsBothIDPs = 6,
}

export enum HipType {
  Unknown = "unknown",
  Enforcement = "enforcement",
  Sms = "sms",
  Audio = "audio",
  Visual = "visual",
}

export enum DefaultBrandingStyles {
  BackgroundColor = "#FAF9F8",
}

export enum RemoteNgcType {
  PushNotification = 1,
  ListSessions = 3,
}

export enum Flavors {
  None = "none",
  Fabric = "fabric",
  Xbox = "xbox",
  WindowsFabric = "windowsFabric",
}

export enum BindProvider {
  Unknown = -1,
  LinkedIn = 0,
  GitHub = 1,
  Google = 2,
  Samsung = 3,
  Facebook = 4,
}
