import type { ICustomizationFiles } from "./branding-helper";
import { loadCustomCss, removeCustomCss } from "./custom-loader";

/**
 * This method is used to determine the actions that should be completed once on an initial render.
 * If custom CSS is required, a promise is returned in the array that resolves once the CSS is loaded and applied.
 * If custom CSS is not required, any existing custom CSS style tag is immediately removed.
 * @param customizationFiles The customization files in the styles
 * @returns The array of Render Promises.
 */
export function getRenderPromises(customizationFiles: ICustomizationFiles) {
  const renderPromises = [];

  if (customizationFiles.customCssUrl) {
    renderPromises.push(loadCustomCss(customizationFiles.customCssUrl));
  } else {
    removeCustomCss();
  }

  return renderPromises;
}
