import { CredentialType } from "../../../constants/constants";
import { type UserCredential, ProofType } from "../../../types/credential-types";

// ESTS-only options (access pass, certificate, federation, remote login) will be added later on

export type CredentialTypeMap = {
  [id in CredentialType]: number;
};

export const priorityMap: Partial<CredentialTypeMap> = {};
priorityMap[CredentialType.Fido] = 2;
priorityMap[CredentialType.RemoteNGC] = 3;
priorityMap[CredentialType.Password] = 4;
priorityMap[CredentialType.OneTimeCode] = 12;

/**
 * @param credential credential, i.e. password or Auth app
 * @returns Priority of the credential for displaying in a list of options
 */
export const getCredentialPriority = (credential: UserCredential): number | undefined => {
  const { credentialType, proof } = credential;

  if (credentialType === CredentialType.OneTimeCode && proof?.type === ProofType.Email) {
    return 11; // email has priority one higher than SMS and Voice OTC
  }

  return priorityMap[credentialType];
};
