import React from "react";
import { TitleFabric } from "../../../../components/title/fabric/title-fabric";
import { FlowId, ViewId } from "../../../../constants/routing-constants";
import { useActivateView } from "../../../../hooks/use-activate-view";

/**
 * FetchSessionsProgressView component
 * @returns A rendered instance of this component
 */
export const FetchSessionsProgressViewFabric: React.FC =
  function FetchSessionsProgressViewFabric() {
    // @TODO: Implement this view
    // @TODO: Once this view has been fully implemented, update the integration tests
    //        for the following views that navigate to the Fetch Sessions Progress View,
    //        such that we validate that the test flow has indeed landed on this view.
    //            - IDP Disambiguation View
    useActivateView(ViewId.FetchSessionsProgress, FlowId.Login);

    return <TitleFabric titleId="loginHeader" title="Fetch Sessions Progress View" />;
  };
