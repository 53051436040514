import React, { useContext, useEffect, useState } from "react";
import { mergeClasses } from "@griffel/react";
import { AuthenticationContext } from "../../../authentication-context";
import { ViewId } from "../../../constants";
import GlobalConfig from "../../../global-config";
import { GlobalContext } from "../../../global-context";
import { GlobalActionType } from "../../../global-reducer";
import { useEffectOnce } from "../../../hooks/use-effect-once";
import { useBackButtonControl, useNavigateDirection } from "../../../hooks/use-navigate-direction";
import { redirect as getRedirectFunction, useRedirect } from "../../../hooks/use-redirect";
import { PostRedirectContext } from "../../../post-redirect-context";
import { PostRedirectActionType } from "../../../post-redirect-reducer";
import useFabricStyles from "../../../styles/fabric/fabric.styles";
import { useTelemetry } from "../../../telemetry-helpers/use-telemetry";
import { UserActionName } from "../../../telemetry-helpers/user-action-name";
import {
  type RedirectPostParams,
  getSignupRedirectGctResult,
} from "../../../utilities/api-helpers/get-credential-type-helper";
import { writeCookie } from "../../../utilities/cookie-helper";
import { FormattedTextWithBindings } from "../../../utilities/formatted-text-with-bindings";
import { fidoCredIcon } from "../../../utilities/image-helpers/accessible-images";
import { commonLoginStringsFabric } from "../fabric/common-login-strings-fabric";
import LoginConfig from "../login-config";
import { LoginContext } from "../login-context";
import { type ICommonLoginStrings } from "../login-interface";
import { LoginActionType } from "../login-reducer";
import { type IBrandingDescriptionProperties } from "../login-types";
import {
  createHelpDialogDescription,
  createHelpDialogHeader,
  getCommonDescription,
  getFidoTexts,
  getServerErrorText,
  sanitizePostParams,
} from "../login-util";

/**
 * Login hook for making a get or post redirect request.
 * @returns a function that can be used to submit a redirect request (GET/POST)
 */
export const useLoginFlowRedirect = () => {
  const { dispatchStateChange: dispatchGlobalStateChange } = useContext(GlobalContext);

  const { isCloudBuild } = GlobalConfig.instance;

  const {
    authState: { flowTokenValue },
  } = useContext(AuthenticationContext);

  const { dispatchStateChange: dispatchRedirectStateChange } = useContext(PostRedirectContext);

  const { flowTokenCookieName } = LoginConfig.instance;

  return (
    redirectUrl: string,
    postParams?: RedirectPostParams,
    isIdpRedirect?: boolean,
    useViewProgress?: boolean,
  ) => {
    if (isIdpRedirect && flowTokenCookieName && flowTokenValue) {
      // When redirecting to a federated IDP we write the flow token to a cookie so that the flow can be resumed after returning to ESTS.
      // We explicitly don't set a domain so that the page's domain is used. The server will clear the cookie after consumption.
      writeCookie(flowTokenCookieName, flowTokenValue, !isCloudBuild);
    }

    if (postParams && Object.keys(postParams).length) {
      // Set the props of the shell-level PostRedirect component.
      // This will cause the post redirect form in the shell to re-render and submit.
      dispatchRedirectStateChange({
        type: PostRedirectActionType.SubmitPostRedirect,
        payload: {
          url: redirectUrl,
          postParams: sanitizePostParams(postParams || {}),
          submitForm: true,
        },
      });
    } else {
      getRedirectFunction(redirectUrl);
    }

    dispatchGlobalStateChange({
      type: GlobalActionType.SetShowProgressIndicator,
      payload: !useViewProgress,
    });
  };
};

/**
 * Hook for the signup click handler
 * @returns A method for handling the signup redirect
 */
export const useSignupClickHandler = () => {
  const { telemetry } = GlobalConfig.instance;
  const {
    globalState: { activeView, activeFlow, activeFlavor },
  } = useContext(GlobalContext);
  const { signupUrl, signupUrlPostParams } = LoginConfig.instance;

  const { logUserAction } = useTelemetry(telemetry, {
    activeView,
    activeFlow,
    activeFlavor,
  });
  logUserAction({ actionName: UserActionName.SignupLinkClicked });

  const loginFlowRedirect = useLoginFlowRedirect();

  return (unsafeUsername: string) => {
    const gctResultAction = getSignupRedirectGctResult(
      unsafeUsername,
      signupUrl,
      signupUrlPostParams,
    );
    loginFlowRedirect(
      gctResultAction.redirectUrl || "",
      gctResultAction.redirectPostParams,
      gctResultAction.isIdpRedirect,
    );
  };
};

/**
 * @param commonLoginStrings common login strings
 * @param isUserKnown whether to show the fido strings for a known user, else show unknown user strings
 * @returns properties related to fido operations
 */
export const useFidoProperties = (
  commonLoginStrings: ICommonLoginStrings,
  isUserKnown: boolean = false,
) => {
  const [fidoTexts, setFidoTexts] = useState([""]);
  const fabricStyles = useFabricStyles();

  useEffect(() => {
    getFidoTexts(commonLoginStrings, isUserKnown).then((texts) => setFidoTexts(texts));
  }, [commonLoginStrings, isUserKnown]);

  const [fidoLinkText, fidoHelpText, fidoDialogText] = [fidoTexts[0], fidoTexts[1], fidoTexts[2]];
  const { fidoHelpUrl } = LoginConfig.instance;
  const { fidoHelpDialogDesc2 } = commonLoginStrings;

  const fidoHelpLink = (chunks: string[]) => (
    <a
      id="fidoHelpLink"
      datatest-id="fidoHelpLink"
      href={fidoHelpUrl}
      target="_blank"
      rel="noreferrer"
    >
      {chunks}
    </a>
  );

  const fidoDialogWithLink = (
    <FormattedTextWithBindings
      textWithBindings={fidoHelpDialogDesc2}
      embeddedBindings={{ fidoHelpLink }}
    />
  );

  const fidoDialogHeader = createHelpDialogHeader(fidoCredIcon, fidoLinkText);
  const fidoDialogDescription = createHelpDialogDescription(
    fidoDialogText,
    fidoDialogWithLink,
    mergeClasses(fabricStyles.row, fabricStyles.textBody),
  );

  return {
    fidoLinkText,
    fidoHelpText,
    fidoDialogHeader,
    fidoDialogDescription,
  };
};

/**
 * hook for creating and returning a handler that submits the fido post redirect
 * @param username username for fido submission
 * @returns a helper method to trigger the fido post redirect
 */
export const useFidoPostRedirect = (username?: string) => {
  const {
    allowedIdentities,
    fidoChallenge: fidoChallengeFromServer,
    fidoAllowList,
    fidoUseAllowedIdentities,
  } = LoginConfig.instance;

  const {
    context,
    loginUrl,
    postUrl,
    msaPostUrl,
    aadPostUrl,
    resumeUrl,
    fidoLoginUrl,
    correlationId,
  } = GlobalConfig.instance;

  const {
    authState: { flowTokenValue },
  } = useContext(AuthenticationContext);

  const { dispatchStateChange: dispatchRedirectStateChange } = useContext(PostRedirectContext);

  const fidoChallenge = fidoUseAllowedIdentities ? fidoChallengeFromServer : flowTokenValue;

  const postParams = {
    allowedIdentities: JSON.stringify(allowedIdentities),
    canary: fidoChallenge,
    serverChallenge: fidoChallenge,
    postBackUrl: postUrl,
    postBackUrlAad: aadPostUrl,
    postBackUrlMsa: msaPostUrl,
    cancelUrl: loginUrl,
    resumeUrl: resumeUrl || loginUrl,
    correlationId,
    credentialsJson: fidoAllowList,
    ctx: context,
    username,
  };

  const redirectCallback = () => {
    dispatchRedirectStateChange({
      type: PostRedirectActionType.SubmitPostRedirect,
      payload: { url: fidoLoginUrl, postParams, submitForm: true },
    });
  };

  return redirectCallback;
};

/**
 * @returns flag indicating whether the back (arrow) button should be shown.
 */
export const useShowBackButton = (): boolean => {
  // Global config data
  const { allowCancel, showButtons } = GlobalConfig.instance;

  const canGoBack = useBackButtonControl();
  const showBackButton = showButtons && (canGoBack || allowCancel);

  return showBackButton;
};

/**
 * Hook to set the server data error on an input using the supplied method to update the text input
 * and also updating the login context to indicate that the error has been shown so we don't show
 * it again on next views.
 * @param setExternalError method to set the external error on text input
 * @param resetPasswordUrl url to reset password
 */
export const useSetServerDataErrorOnInput = (
  setExternalError: (error: string | JSX.Element) => void,
  resetPasswordUrl?: string,
) => {
  const serverError = getServerErrorText(commonLoginStringsFabric);
  const {
    viewState: { serverErrorShown },
    dispatchStateChange: dispatchLoginStateChange,
  } = useContext(LoginContext);

  useEffectOnce(() => {
    if (serverError && !serverErrorShown) {
      dispatchLoginStateChange({
        type: LoginActionType.SetServerErrorShown,
        payload: true,
      });

      if (resetPasswordUrl) {
        const resetPasswordLink = (chunks: string[]) => (
          <a id="idA_IL_ForgotPassword0" href={resetPasswordUrl}>
            {chunks[0]}
          </a>
        );
        const serverErrorWithBinding = (
          <FormattedTextWithBindings
            textWithBindings={serverError}
            embeddedBindings={{ resetPasswordLink }}
          />
        );
        setExternalError(serverErrorWithBinding);
      } else {
        setExternalError(serverError);
      }
    }
  });
};

/**
 * Hook to set the server data error on a view using the supplied method to update the error string
 * and also updating the login context to indicate that the error has been shown so we don't show
 * it again on next views.
 * @param setError method to set the error string
 */
export const useSetServerDataErrorOnView = (setError: (error: string) => void) => {
  const serverError = getServerErrorText(commonLoginStringsFabric);
  const {
    viewState: { serverErrorShown },
    dispatchStateChange: dispatchLoginStateChange,
  } = useContext(LoginContext);

  useEffectOnce(() => {
    if (serverError && !serverErrorShown) {
      dispatchLoginStateChange({
        type: LoginActionType.SetServerErrorShown,
        payload: true,
      });

      setError(serverError);
    }
  });
};

/**
 * @returns Branding description properties for one of the following credential-related views
 *   1. Password view
 *   2. OTC view
 *   3. Remote NGC view
 *   4. IDP Redirect Speedbump view
 */
export const useBrandingDescriptionProperties = (): IBrandingDescriptionProperties => {
  // Login context data
  const {
    viewState: { showCredViewBrandingDesc },
    dispatchStateChange: dispatchLoginStateChange,
  } = useContext(LoginContext);

  // Login config data
  const { loginDescription, loginStringsVariant } = LoginConfig.instance;

  const [renderBrandingDescription] = useState<boolean>(showCredViewBrandingDesc);
  const brandingDescriptionId = "credViewBrandingDesc";
  const brandingDescription = getCommonDescription(loginStringsVariant, loginDescription);

  // if the branding description is rendered in this view, it will not be shown in succeeding views
  if (showCredViewBrandingDesc) {
    dispatchLoginStateChange({ type: LoginActionType.SetShowCredViewBrandingDesc, payload: false });
  }

  return {
    renderBrandingDescription,
    brandingDescriptionId,
    brandingDescription,
  };
};

/**
 * Hook that resets the remoteNgcParams.requestSent property in login context to false. This is used in the
 * password and IDP redirect speedbump views to set the requestSent property to false so that later if the
 * user lands on the remote NGC view we can trigger a code/pin request.
 */
export const useResetRemoteNgcRequestSent = (): void => {
  const {
    viewState: {
      remoteNgcParams: { requestSent },
    },
    dispatchStateChange: dispatchLoginStateChange,
  } = useContext(LoginContext);

  if (requestSent) {
    dispatchLoginStateChange({
      type: LoginActionType.SetRemoteNgcParams,
      payload: { requestSent: false },
    });
  }
};

/**
 * @param currentViewId The current ViewId using the hook
 * @returns A callback that will update the LoginContext with useEvictedCredentials as true before navigating to the CredentialPickerView
 */
export const useSwitchToEvictedCredPicker = (currentViewId: ViewId) => {
  const navigate = useNavigateDirection();
  const { dispatchStateChange: updateLoginContext } = useContext(LoginContext);

  return () => {
    updateLoginContext({
      type: LoginActionType.UpdateCredentials,
      payload: { useEvictedCredentials: true },
    });
    navigate(currentViewId, ViewId.CredentialPicker);
  };
};

/**
 * This hook is used to trigger the "select account" action.
 * @param currentViewId The current ViewId using the hook
 * @returns A callback method that will redirect to the switchUserUrl or navigate to AccountPickerView or the UsernameView
 */
export const useSelectAccount = (currentViewId: ViewId) => {
  const {
    viewState: {
      credentials: { sessions },
    },
  } = useContext(LoginContext);
  const navigate = useNavigateDirection();
  const redirect = useRedirect();

  return () => {
    const { switchUserUrl } = GlobalConfig.instance;
    const { lockUsername } = LoginConfig.instance;
    if (lockUsername && switchUserUrl) {
      redirect(switchUserUrl);
    } else if (sessions.length) {
      navigate(currentViewId, ViewId.AccountPicker);
    } else {
      navigate(currentViewId, ViewId.Username);
    }
  };
};
