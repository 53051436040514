import React from "react";
import useFabricStyles from "../styles/fabric/fabric.styles";

export interface IErrorContainerProps {
  /** Classname for the error div */
  className?: string;
  /** Id for error div */
  id?: string;
  /** Optional error code for testing attribute */
  errorCode?: string;
}

/**
 * ErrorContainer component
 * @param props IErrorContainerProps
 * @returns Returns a component for displaying errors
 */
const ErrorContainer: React.FC<React.PropsWithChildren<IErrorContainerProps>> =
  function ErrorContainer(props) {
    const { children, id = "errorId", className, errorCode } = props;
    const fabricStyles = useFabricStyles();
    const styleName = className || fabricStyles.alertError;

    if (!children) {
      return null;
    }

    return (
      <div role="alert" aria-live="assertive">
        <div id={id} className={styleName} data-testid={errorCode}>
          {children}
        </div>
      </div>
    );
  };

export default ErrorContainer;
