import { useContext } from "react";
import { ViewId } from "../../../../constants/routing-constants";
import { useNavigateDirection } from "../../../../hooks/use-navigate-direction";
import { getRouteFromViewId } from "../../../../utilities/routing-helper";
import { LoginContext } from "../../login-context";
import { LoginActionType } from "../../login-reducer";

/**
 * @returns a click handler for the link that says "I no longer own this phone number" and that takes the user to the
 * credential picker view.
 */
export const useSwitchToEvictedCredentialPickerLinkClickHandler = () => {
  const { dispatchStateChange: dispatchLoginStateChange } = useContext(LoginContext);

  const navigate = useNavigateDirection();

  return () => {
    dispatchLoginStateChange({
      type: LoginActionType.UpdateCredentials,
      payload: { useEvictedCredentials: true },
    });

    navigate(ViewId.ProofConfirmation, getRouteFromViewId(ViewId.CredentialPicker));
  };
};
