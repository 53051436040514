import { useEffect, useRef, useState } from "react";

/**
 * Hook that uses a timeout to invoke the input callback after the specified delay (in milliseconds)
 * and returns a function to cancel the timeout.
 *
 * One example where this is used is in the IDP redirect view - we wait 2 seconds after loading the view
 * to dispatch the redirect request. The callback in this case would contain the redirection logic and the
 * delay would be 2000 (in milliseconds) like so -
 *
 * const cancelRedirect = useTimeout(() => redirect(), 2000)
 *
 * The view also renders a cancel button that the user can use to stop the redirect before the 2 second
 * timeout is over. The `cancelRedirect` function returned by the hook can be used to achieve this.
 *
 * @param callback A function that is invoked after the delay.
 * @param delay Period of time, in milliseconds, before the callback is invoked.
 * @returns a function to cancel the timeout.
 */
export const useTimeout = (callback: () => void, delay: number) => {
  const savedCallback = useRef<() => void>();
  const [startTimeoutCountdown, toggleTimeoutCountdown] = useState(true);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const currentCallback = () => {
      savedCallback.current?.();
    };

    if (startTimeoutCountdown) {
      const timeoutId = setTimeout(currentCallback, delay);

      return () => clearTimeout(timeoutId);
    }

    // return no-op cleanup function to satisfy eslint's consistent-return rule
    return () => {};
  }, [callback, delay, startTimeoutCountdown]);

  return () => toggleTimeoutCountdown(false);
};
