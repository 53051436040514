import React from "react";
import useFabricStyles, { useStaticFabricStyles } from "../styles/fabric/fabric.styles";
import { replaceTokens } from "../utilities/strings-helper";
import { useBaseBackgroundStyles } from "./background-image";

/**
 * Page shown when a blocking error occurs
 * @param property Object with component properties.
 * @param property.errorCode Code of the error that occured.
 * @returns Error page
 */
const ErrorComponent: React.FC<{ errorCode: string }> = function ErrorComponent({ errorCode }) {
  useStaticFabricStyles();
  const styles = useFabricStyles();
  const backgroundStyles = useBaseBackgroundStyles();
  const innerClassName = styles.inner;

  return (
    <div
      id="background-container"
      itemID="backgroundContainer"
      data-testid="background-container"
      className={backgroundStyles.baseBackground}
      role="presentation"
      style={{ backgroundColor: "transparent" }}
    >
      <div id="outer" data-testid="outer" className={styles.outer} role="presentation">
        <div id="middle" className={styles.middle}>
          <div id="inner" data-testid="inner" className={innerClassName} role="main">
            <div id="pageContent" data-testid="paginationView" className={styles.paginationView}>
              {replaceTokens(
                getLocalString("Error_SomethingWentWrong_KnownError_GetSupport"),
                errorCode,
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorComponent;
