import { useContext, useState } from "react";
import { AuthenticationContext } from "../../../../authentication-context";
import { type TileComponent } from "../../../../components/tile";
import { defaultRequestTimeout } from "../../../../constants/request-constants";
import { ViewId } from "../../../../constants/routing-constants";
import { GlobalContext } from "../../../../global-context";
import { GlobalActionType } from "../../../../global-reducer";
import { useNavigateDirection } from "../../../../hooks/use-navigate-direction";
import { useRedirect } from "../../../../hooks/use-redirect";
import {
  GctResultAction,
  sendAsync,
} from "../../../../utilities/api-helpers/get-credential-type-helper";
import { getFidoSupport } from "../../../../utilities/browser-helper";
import {
  pickerAccountAddIcon,
  pickerAccountMsaIcon,
  pickerMoreIcon,
} from "../../../../utilities/image-helpers/accessible-images";
import { doOriginsMatch } from "../../../../utilities/origins";
import { get } from "../../../../utilities/request-helper";
import { getRouteFromViewId } from "../../../../utilities/routing-helper";
import {
  appendOrReplaceQueryStringParams,
  replaceTokens,
} from "../../../../utilities/strings-helper";
import { useLoginFlowRedirect } from "../../hooks/login-hooks";
import LoginConfig from "../../login-config";
import { LoginContext } from "../../login-context";
import { type LoginActions, LoginActionType } from "../../login-reducer";
import { type ISession } from "../../login-types";
import { getAccountPickerIframeSource } from "../account-picker-view-iframe-manager";
import { type IAccountPickerStrings } from "../fabric/account-picker-strings-fabric";
import {
  useForgetSessionHandler,
  useSignOutOrForgetSessionHandler,
} from "./account-picker-view-session-hooks";

/**
 * @returns Hook used for event listener
 */
export const useOnSignOutOrForgetEventListener = () => {
  const { logoutUrl, forgetUrl } = LoginConfig.instance;
  const {
    viewState: { credentials },
    dispatchStateChange: dispatchLoginStateChange,
  } = useContext(LoginContext);
  const signOutOrForgetSession = useSignOutOrForgetSessionHandler();
  return async (event: MessageEvent) => {
    // important security check
    // do not proceed if the iframe is from an unknown origin
    if (
      !event ||
      (!doOriginsMatch(logoutUrl, event.origin) && !doOriginsMatch(forgetUrl, event.origin))
    ) {
      return;
    }

    const eventData = event.data;
    if (eventData && eventData.sessionId) {
      await signOutOrForgetSession(event, credentials.sessions, dispatchLoginStateChange);
    }
  };
};

/**
 * @returns function for the GCT request, called when user signs out, goes back to account picker view, and tries to sign in with the same tile again
 */
export const useGctRequest = () => {
  const navigator = useNavigateDirection();
  const { dispatchStateChange } = useContext(GlobalContext);

  const {
    authState: { flowTokenValue: flowToken },
  } = useContext(AuthenticationContext);

  const {
    isFidoSupportedHint,
    gctFederationFlags,
    isFederationDisabled,
    isExternalFederationDisallowed,
    isRemoteNGCSupported,
    isOtcLoginDisabled: otclogindisallowed,
    getCredentialTypeUrl,
    postProofType,
    showSignup,
    signupUrl,
    fedQs,
  } = LoginConfig.instance;

  const {
    viewState: { otherIdpRedirectUrl },
    dispatchStateChange: dispatchLoginStateChange,
  } = useContext(LoginContext);

  const isFidoSupported = getFidoSupport(isFidoSupportedHint);

  const loginFlowRedirect = useLoginFlowRedirect();

  return async (unsafeUsername: string) => {
    const gctResult = await sendAsync(
      {
        unsafeUsername,
        flowToken,
        isFidoSupported,
        country: "",
        gctFederationFlags: gctFederationFlags || 0,
        isExternalFederationDisallowed,
        isFederationDisabled,
        isRemoteNGCSupported,
        otclogindisallowed,
        getCredentialTypeUrl,
        otherIdpRedirectUrl,
        fedQs,
      },
      {
        postProofType,
        showSignup,
        signupUrl,
      },
    );

    const { sharedData } = gctResult;

    switch (gctResult.action) {
      case GctResultAction.SwitchView:
        dispatchStateChange({
          type: GlobalActionType.SetUser,
          payload: { displayUsername: unsafeUsername },
        });

        if (sharedData) {
          dispatchLoginStateChange({
            type: LoginActionType.SetLocation,
            payload: sharedData.location!,
          });
          dispatchLoginStateChange({
            type: LoginActionType.SetRemoteNgcParams,
            payload: sharedData.remoteNgcParams!,
          });
          // Update login context with credentials from the GCT response
          dispatchLoginStateChange({
            type: LoginActionType.UpdateCredentials,
            payload: { availableCredentials: sharedData.availableCredentials! },
          });
        }

        switch (gctResult.viewId) {
          default:
            navigator(ViewId.AccountPicker, getRouteFromViewId(gctResult.viewId!));
        }

        break;
      case GctResultAction.Redirect:
        loginFlowRedirect(
          gctResult.redirectUrl || "",
          gctResult.redirectPostParams,
          gctResult.isIdpRedirect,
        );
        break;
      default:
      // do nothing for now
    }
  };
};

/**
 * @returns function that makes a GET request with forget URL
 */
export const useForgetRequest = () => {
  const forgetSession = useForgetSessionHandler();

  const forgetRequest = async (
    forgetUrl: string,
    sessions: ISession[],
    dispatchLoginStateChange: React.Dispatch<LoginActions>,
    setError: React.Dispatch<React.SetStateAction<string>>,
    accountPickerStrings: IAccountPickerStrings,
    sessionDisplayName?: string,
    sessionId?: string,
  ) => {
    const { forgetFailureErrorText } = accountPickerStrings;
    const forgetUrlWithSessionId = appendOrReplaceQueryStringParams(
      forgetUrl,
      {
        sessionId: encodeURIComponent(sessionId || ""),
      },
      true,
    );

    get(forgetUrlWithSessionId, {
      headers: { "Content-type": "application/json; charset=utf-8" },
      timeout: defaultRequestTimeout,
    }).then(
      () => {
        forgetSession(sessions, dispatchLoginStateChange, sessionId);
      },
      () => {
        setError(replaceTokens(forgetFailureErrorText, sessionDisplayName || ""));
      },
    );
  };

  return forgetRequest;
};

/**
 * @returns handler for sign out or forget clicks
 */
const useOnSignOutOrForgetClickHandler = () => {
  const { logoutUrl, forgetUrl, useForgetUserIframe } = LoginConfig.instance;
  let shouldInjectIframe = true;
  let iFrameSrc = "";
  const {
    viewState: { credentials },
    dispatchStateChange: dispatchLoginStateChange,
  } = useContext(LoginContext);
  const forgetRequest = useForgetRequest();

  return async (
    option: string,
    setError: React.Dispatch<React.SetStateAction<string>>,
    setIframeSource: React.Dispatch<React.SetStateAction<string>>,
    accountPickerStrings: IAccountPickerStrings,
    sessionId?: string,
    sessionDisplayName?: string,
  ) => {
    // AAD-TODO: add call to cookie write function based on ests userRoutingCookieConfig property

    if (option === "forget") {
      // if don't use iframe, make a GET request
      if (!useForgetUserIframe) {
        shouldInjectIframe = false;
        await forgetRequest(
          forgetUrl,
          credentials.sessions,
          dispatchLoginStateChange,
          setError,
          accountPickerStrings,
          sessionDisplayName,
          sessionId,
        );
      } else {
        // set iframe source
        iFrameSrc = getAccountPickerIframeSource(forgetUrl, sessionId);
      }
    } else if (option === "signout") {
      iFrameSrc = getAccountPickerIframeSource(logoutUrl, sessionId);
    } else if (option === "signoutForget") {
      iFrameSrc = getAccountPickerIframeSource(logoutUrl, sessionId, true);
    }

    if (shouldInjectIframe) {
      setIframeSource(iFrameSrc);
    }
  };
};

/**
 * Builds account picker tile list
 * @param sessions array of current active sessions
 * @param accountPickerStrings account picker strings for the current flavor
 * @returns account picker tile list
 */
export const useAccountPickerBuilder = (
  sessions: ISession[],
  accountPickerStrings: IAccountPickerStrings,
) => {
  const { protocolRefreshUrl, upgradeRedirectWithUsernameUrl } = LoginConfig.instance;
  const {
    alreadySignedInText,
    useAnotherAccountText,
    signOutText,
    signOutAndForgetText,
    forgetText,
    signInWithAccountAriaLabel,
  } = accountPickerStrings;
  const navigate = useNavigateDirection();
  const userTileClick = useRedirect();
  const gctCall = useGctRequest();
  const menuOptionOnClick = useOnSignOutOrForgetClickHandler();
  let onClickFunction: () => void;
  const [error, setError] = useState("");
  const [iFrameSource, setIframeSource] = useState("");

  const accountPickerList: TileComponent[] = [];
  sessions.forEach((session, index) => {
    let redirectUrl = "";

    // TODO: Add ESTS conditions/images and condition for WindowsSso when browserSSO/TokenBroker is implemented
    if (session.isSignedIn && session.id) {
      redirectUrl = appendOrReplaceQueryStringParams(protocolRefreshUrl, { sessionid: session.id });
      onClickFunction = () => userTileClick(redirectUrl, true);
    } else if (!session.isSignedIn && upgradeRedirectWithUsernameUrl) {
      redirectUrl = upgradeRedirectWithUsernameUrl;
      onClickFunction = () => userTileClick(redirectUrl, true);
    } else {
      onClickFunction = () => gctCall(session.name?.unsafeUnescapedString || "");
    }

    accountPickerList.push({
      mainText:
        (session.isSignedIn
          ? session.fullName?.unsafeUnescapedString
          : session.displayName?.unsafeUnescapedString) || "",
      helpText:
        session.isSignedIn && session.fullName ? session.displayName?.unsafeUnescapedString : "",
      subText: session.isSignedIn ? alreadySignedInText : "",
      imageUrl: pickerAccountMsaIcon,
      optionsMenuButton: {
        icon: pickerMoreIcon,
        menuItemList: [
          {
            itemId: "ForgetLink",
            itemText: forgetText,
            onClick: () =>
              menuOptionOnClick(
                "forget",
                setError,
                setIframeSource,
                accountPickerStrings,
                session.id,
                session.displayName?.unsafeUnescapedString,
              ),
            isVisible: !session.isSignedIn && session.id !== undefined,
            hasFocus: !session.isSignedIn,
          },
          {
            itemId: "SignOutLink",
            itemText: signOutText,
            onClick: () =>
              menuOptionOnClick(
                "signout",
                setError,
                setIframeSource,
                accountPickerStrings,
                session.id,
              ),
            isVisible: session.isSignedIn && session.id !== undefined,
            hasFocus: session.isSignedIn,
          },
          {
            itemId: "SignOutAndForgetLink",
            itemText: signOutAndForgetText,
            onClick: () =>
              menuOptionOnClick(
                "signoutForget",
                setError,
                setIframeSource,
                accountPickerStrings,
                session.id,
              ),
            isVisible: session.isSignedIn,
          },
        ],
        menuTestId: `menu${index}`,
      },
      ariaLabel: replaceTokens(
        signInWithAccountAriaLabel,
        session.fullName?.unsafeUnescapedString || "",
      ),
      onTileClick: onClickFunction,
    });
  });

  // Use another account
  accountPickerList.push({
    mainText: useAnotherAccountText,
    imageUrl: pickerAccountAddIcon,
    ariaLabel: useAnotherAccountText,
    onTileClick: () => navigate(ViewId.AccountPicker, ViewId.Username),
  });

  return { accountPickerList, error, setError, iFrameSource };
};
