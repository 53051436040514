import React, { useEffect, useRef } from "react";
import { mergeClasses } from "@griffel/react";
import { FOCUS_TIMEOUT } from "../styles/fabric/layout-animate-fabric.styles";
import { useLinkStyles } from "../styles/fabric/link-button-fabric.styles";

interface ILinkButtonProps {
  /** The aria label for the link */
  ariaLabel?: string;
  /** The ids of the labels or elements that are shorter label for context on the link */
  ariaLabelledBy?: string;
  /** The ids of the labels or elements that are lengthier describes the link */
  ariaDescribedBy?: string;
  /** The link button id. You probably don't need this. */
  linkId?: string;
  /** If the link has initial focus or not (false by default) */
  hasFocus?: boolean;
  /** Styles class to apply to the button */
  className?: string;
  /** Styles attribute */
  style?: Object;
  /** The link button display text */
  text: string;
  /** The click event handler */
  onClick: (event: React.SyntheticEvent<HTMLSpanElement>) => void;
  /** The focus event handler */
  onFocus?: (event: React.SyntheticEvent<HTMLSpanElement>) => void;
  /** The blur event handler */
  onBlur?: (event: React.SyntheticEvent<HTMLSpanElement>) => void;
}

/**
 * Link Button component
 * @param props The properties for this component
 * @returns an instance of the shared link button component
 */
export const LinkButton: React.FC<ILinkButtonProps> = function LinkButton(props: ILinkButtonProps) {
  const {
    text,
    ariaLabel,
    ariaLabelledBy,
    ariaDescribedBy,
    linkId,
    onClick,
    className,
    style = {},
    hasFocus = false,
    onFocus = () => {},
    onBlur = () => {},
  } = props;

  const linkStyles = useLinkStyles();

  const linkClassName = mergeClasses(
    linkStyles.link,
    linkStyles.noPadding,
    linkStyles.highContrast,
    className,
  );

  const elementReference = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    // We delay focus until animation is nearly completed. This prevents the browser from immediately switching to the focused element and pulling
    // it into view, which causes the identity banner and logo to pull right and interrupts the animation.
    if (hasFocus) {
      setTimeout(() => {
        elementReference?.current?.focus();
      }, FOCUS_TIMEOUT);
    }
  }, [hasFocus]);

  const onKeyDown = (event: React.KeyboardEvent<HTMLSpanElement>) => {
    if (event.key === "Enter" || event.key === " ") {
      onClick(event);
    }
  };

  return (
    <span
      tabIndex={0}
      role="button"
      onClick={onClick}
      onKeyDown={onKeyDown}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
      aria-describedby={ariaDescribedBy}
      ref={elementReference}
      id={linkId}
      className={linkClassName}
      style={style}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      {text}
    </span>
  );
};
