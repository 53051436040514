import { appBackgrounds } from "./image-helpers/app-backgrounds";
import { appLogos } from "./image-helpers/app-logos";
import { backgrounds } from "./image-helpers/backgrounds";
import { images } from "./image-helpers/images";
import { qrCodeLogos } from "./image-helpers/qr-code-logos";
import { isSvgSupported } from "./browser-helper";

export type ImagesExtension = "png" | "svg" | "jpg" | "gif" | "json";

/* istanbul ignore next */
/** @private */
function getSource(
  folderPath: Map<string, string>,
  name: string,
  extension: ImagesExtension,
): string {
  const imageName = `${name}_${extension}`;

  if (folderPath.has(imageName)) {
    return folderPath.get(imageName)!;
  }

  throw new Error(`Image not found: ${imageName}`);
}

/**
 * Get the image source path for a given image name
 * @param name The image name
 * @param extension The file extenstion of the image
 * @returns The source path
 */
export function getImageSource(
  name: string,
  extension: ImagesExtension = isSvgSupported ? "svg" : "png",
) {
  return getSource(images, name, extension);
}

/**
 * Get the background image source path for a given image name
 * @param name The image name
 * @param extension The file extenstion of the image
 * @returns The source path
 */
export function getBackgroundImageSource(
  name: string,
  extension: "svg" | "jpg" = isSvgSupported ? "svg" : "jpg",
) {
  return getSource(backgrounds, `background_${name}`, extension);
}

/**
 * Get the app background image source path for a given image name
 * @param name The image name
 * @returns The source path
 */
export function getAppBackgroundImageSource(name: string) {
  return getSource(appBackgrounds, `appBackground_${name}`, "jpg");
}

/**
 * Get the app logo source path for a given image name
 * @param name The image name
 * @returns The source path
 */
export function getAppLogoImageSource(name: string) {
  return getSource(appLogos, `appLogo_${name}`, "png");
}

/**
 * Get the QR code logo source path for a given image name.
 * Only SVG is accepted for QR Code logos.
 * @param name The image name
 * @returns The source path
 */
export function getQrCodeLogoImageSource(name: string) {
  return getSource(qrCodeLogos, name, "svg");
}
