import { ConfigWrapper } from "./utilities/config-wrapper";
import type { ServerData } from "./utilities/server-data";

export const defaultFeaturesConfig = {
  loadGamepadNavigationModule: false,
};
const featuresConfig = new ConfigWrapper(defaultFeaturesConfig);

/**
 * The config wrapper that should be used to access global config properties
 */
export default featuresConfig;

/* ********* ServerData helpers ********** */

/**
 * Initialize the features config object from ServerData. This function should be called once per App, outside
 * of the component render cycle.
 * @param serverData The IDP-specific server data object that should be used to initialize the features config
 */
export function initFeaturesConfig(serverData: ServerData) {
  const mappedServerData: Partial<typeof defaultFeaturesConfig> = {};

  if (serverData?.fLoadGamepadNavigationModule) {
    mappedServerData.loadGamepadNavigationModule = serverData.fLoadGamepadNavigationModule;
  }

  featuresConfig.initialize(mappedServerData);
}
