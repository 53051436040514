export const MINHEIGHT = 32;
export const MINWIDTH = 108;
export const DEFAULT_COLOR = "#000";
export const DEFAULT_BACKGROUND_COLOR = "rgba(0, 0, 0, 0.2)";
export const DEFAULT_HOVER_FOCUS_BACKGROUND_COLOR = "rgba(0, 0, 0, 0.3)";
export const DEFAULT_ACTIVE_BACKGROUND_COLOR = "rgba(0, 0, 0, 0.4)";
export const DEFAULT_DISABLED_COLOR = "rgba(0, 0, 0, 0.2)";
export const PRIMARY_COLOR = "#FFF";
export const PRIMARY_BACKGROUND_COLOR = "#0067B8";
export const PRIMARY_HOVER_FOCUS_BACKGROUND_COLOR = "#005DA6";
