import React, { useContext } from "react";
import { makeStyles, mergeClasses, shorthands } from "@griffel/react";
import { ExternalClassName } from "../constants/constants";
import { GlobalContext } from "../global-context";

const useHeaderStyles = makeStyles({
  headerContainer: {
    display: "table-cell",
    position: "absolute",
    width: "100%",
    zIndex: 10,
  },
  header: {
    width: "100%",
    height: "48px",
    ...shorthands.padding("12px", "24px"),
  },
  headerLogo: {
    maxHeight: "24px",
    maxWidth: "150px",
    verticalAlign: "middle",
    ...shorthands.border(0),
  },
});

/**
 * Renders a header component unless globalState.styles.showHeader is false
 * @returns Header component
 */
const Header: React.FC = function Header() {
  const headerStyles = useHeaderStyles();
  const {
    globalState: {
      styles: { headerLogoUrl, showHeader },
    },
  } = useContext(GlobalContext);

  if (!showHeader) {
    return null;
  }

  const headerClassNames = mergeClasses(headerStyles.header, ExternalClassName.header);
  const headerLogoClassNames = mergeClasses(headerStyles.headerLogo, ExternalClassName.headerLogo);

  return (
    <div className={headerStyles.headerContainer} data-testid="header" role="banner">
      <div id="header" className={headerClassNames}>
        <img
          src={headerLogoUrl}
          alt={getLocalString("Header_Logo_AltText")}
          className={headerLogoClassNames}
        />
      </div>
    </div>
  );
};

export default Header;
