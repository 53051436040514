import React, { createContext, useMemo, useReducer } from "react";
import { extractFlowToken } from "./utilities/flow-token-helper";
import type { ServerData } from "./utilities/server-data";
import authenticationReducer, { type AuthenticationActions } from "./authentication-reducer";

export type AuthenticationState = {
  flowTokenValue: string;
};

export interface IAuthenticationContext {
  authState: AuthenticationState;
  dispatchStateChange: React.Dispatch<AuthenticationActions>;
}

export const initialAuthenticationState: AuthenticationState = {
  flowTokenValue: "",
};

export const AuthenticationContext = createContext<IAuthenticationContext>({
  authState: initialAuthenticationState,
  dispatchStateChange: () => {
    throw new Error("AuthenticationContext not initialized");
  },
});

/**
 * @param props The input props for the authentication provider
 * @param props.initialState The initial state that the authentication provider should be initialized with
 * @param props.children The child components to render inside this provider
 * @returns The instantiated Provider component
 */
export const AuthenticationProvider: React.FC<{ initialState: AuthenticationState }> =
  function AuthenticationProvider({ initialState, children }) {
    const [state, dispatch] = useReducer(authenticationReducer, initialState);
    const value: IAuthenticationContext = useMemo(
      () => ({
        authState: state,
        dispatchStateChange: dispatch,
      }),
      [state],
    );

    return (
      <AuthenticationContext.Provider value={value}>{children}</AuthenticationContext.Provider>
    );
  };

/**
 * Create an authentication state object from ServerData. This function should be called once per App,
 * outside of the component render cycle.
 * Any properties related to authentication (such as flow token names and values) that are used inside
 * a React component's lifecycle (hook or render function) AND can change during the component's lifecycle
 * should go into the state. Constant authentication properties or properties that are not used by a React
 * component can be stored in global or flow-specific config instead to improve performance and prevent
 * unnecessary re-rendering.
 * @param serverData The IDP-specific server data object that should be used to create the auth state
 * @returns The IDP-agnostic auth state object created from the server data
 */
export function createAuthenticationState(serverData: ServerData): AuthenticationState {
  const authenticationState = {
    ...initialAuthenticationState,
  };

  // Get flow token from sFT if available
  if (serverData?.sFT) {
    authenticationState.flowTokenValue = extractFlowToken(serverData.sFT) || serverData.sFT;
  }

  // Fallback to sFTTag property if the flow token cannot be derived from sFT above
  if (!authenticationState.flowTokenValue && serverData?.sFTTag) {
    authenticationState.flowTokenValue = extractFlowToken(serverData.sFTTag);
  }

  return authenticationState;
}
