import { useContext } from "react";
import { GlobalContext } from "../../../../global-context";
import { type RedirectPostParams } from "../../../../utilities/api-helpers/get-credential-type-helper";
import LoginConfig from "../../login-config";
import { LoginContext } from "../../login-context";
import { type ICommonLoginStrings } from "../../login-interface";
import { getCommonTitle } from "../../login-util";
import { getBindProviderSpeedbumpProperties } from "../idp-redirect-speedbump-view-util";
import {
  type ISecondaryContentProperties,
  useSecondaryContentProperties,
} from "./use-secondary-content-properties";

export interface IIdpRedirectSpeedbumpProperties extends ISecondaryContentProperties {
  documentTitle: string;
  lightboxTitleId: string;
  lightboxTitle: string;
  lightboxDescriptionId: string;
  lightboxDescription?: string;
  redirectUrl: string;
  redirectPostParams: RedirectPostParams | undefined;
}

/**
 * @returns Login Idp Redirect Speedbump view properties
 * @param strings Flavored strings used by this hook
 * @param strings.commonLoginStrings Common login strings
 */
export const useIdpRedirectSpeedbumpViewProperties = (strings: {
  commonLoginStrings: ICommonLoginStrings;
}): IIdpRedirectSpeedbumpProperties => {
  const { commonLoginStrings } = strings;
  // Global context data
  const {
    globalState: {
      styles: { friendlyAppName },
    },
  } = useContext(GlobalContext);

  // Login context data
  const {
    viewState: {
      idpRedirectPostParams: initialRedirectPostParams,
      idpRedirectProvider,
      idpRedirectUrl: initialRedirectUrl,
    },
  } = useContext(LoginContext);

  // Login config data
  const { facebookFedUrl, githubFedUrl, googleFedUrl, linkedInFedUrl, loginMode } =
    LoginConfig.instance;

  const documentTitle = getCommonTitle(loginMode, friendlyAppName, commonLoginStrings);

  const lightboxTitleId = "loginHeader";
  const lightboxDescriptionId = "loginDescription";
  const {
    title: lightboxTitle,
    description: lightboxDescription,
    currentCredentialType,
    redirectUrl,
    redirectPostParams,
  } = getBindProviderSpeedbumpProperties(
    initialRedirectUrl,
    initialRedirectPostParams,
    idpRedirectProvider,
    {
      linkedInFedUrl,
      githubFedUrl,
      googleFedUrl,
      facebookFedUrl,
    },
  );

  const { renderSecondaryContent, isRequestPending, credentialSwitchLinksProps } =
    useSecondaryContentProperties(currentCredentialType);

  return {
    documentTitle,
    lightboxTitleId,
    lightboxTitle,
    lightboxDescriptionId,
    lightboxDescription,
    redirectUrl,
    redirectPostParams,
    renderSecondaryContent,
    isRequestPending,
    credentialSwitchLinksProps,
  };
};
