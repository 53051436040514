import React, { useCallback, useEffect, useRef, useState } from "react";
import { mergeClasses } from "@griffel/react";
import useFabricStyles from "../styles/fabric/fabric.styles";
import { FOCUS_TIMEOUT } from "../styles/fabric/layout-animate-fabric.styles";
import { usePhoneCountryDropdownStyles } from "../styles/fabric/phone-country-dropdown-fabric.styles";
import { type ICountryInfo } from "../utilities/country-helper";
import { downArrowIcon } from "../utilities/image-helpers/accessible-images";
import { AccessibleImage } from "./accessible-image";

export interface IPhoneCountryDropdownProps {
  defaultCountryData: ICountryInfo;
  countryData: ICountryInfo[];
  useInlinePhoneNumber: boolean;
  hasInitialFocus?: boolean;
  hasError?: boolean;
  hasFocus?: boolean;
  onChangeHandler?: (country: ICountryInfo) => void;
}

/**
 * PhoneCountryDropdown component
 * @param props The properties for this component.
 * @param props.defaultCountryData The default country information.
 * @param props.countryData The array of country information.
 * @param props.useInlinePhoneNumber Whether the country code and phone number are shown inline. If true, the component will show the label text (e.g., +1).
 * If false, the component will show the full country display text (e.g., United States (+1)).
 * @param props.hasError Whether the country code dropdown is showing error.
 * @param props.hasInitialFocus Whether the country code dropdown is focused by default.
 * @param props.hasFocus Whether the country code dropdown is currently focused.
 * @param props.onChangeHandler The value change callback. Enables the external component to do something with the selected country value.
 * @returns A dropdown component with a list of phone country codes.
 */
export const PhoneCountryDropdown: React.FC<IPhoneCountryDropdownProps> =
  function PhoneCountryDropdown(props) {
    const {
      defaultCountryData,
      countryData,
      useInlinePhoneNumber,
      hasError = false,
      hasFocus = false,
      hasInitialFocus = false,
      onChangeHandler = () => {},
    } = props;

    const fabricStyles = useFabricStyles();
    const styles = usePhoneCountryDropdownStyles();

    const dropdownRef = useRef<HTMLSelectElement>(null);

    const [countryMap, setCountryMap] = useState({} as Record<string, ICountryInfo>);
    const [selectedCountry, setSelectedCountry] = useState(defaultCountryData);

    const [labelCss, setLabelCss] = useState(styles.phoneCountryCodeLabel);

    // Maps the country iso code with the corresponding country data
    // (e.g., "AF": { iso: "AF", displayValue: "Afghanistan (+93)", code: "93" })
    const initializeCountryMap = useCallback(() => {
      const map: Record<string, ICountryInfo> = {};
      countryData.forEach((country) => {
        map[country.iso] = country;
      });
      setCountryMap(map);
    }, [countryData]);

    const onDropdownValueChange = (
      e: React.ChangeEvent<HTMLSelectElement>,
      handleDropdownValueChange: (countryValue: ICountryInfo) => void,
    ) => {
      const currentCountry = countryMap[e.target.value];
      setSelectedCountry(currentCountry);
      // Call the callback passed in as props by consuming component
      handleDropdownValueChange(currentCountry);
    };

    const focusCss = hasError
      ? mergeClasses(styles.phoneCountryCodeLabel, styles.labelHasErrorFocusBox)
      : mergeClasses(styles.phoneCountryCodeLabel, styles.labelHasFocus);

    const errorCss = mergeClasses(styles.phoneCountryCodeLabel, styles.labelHasError);

    const getLabelCss = useCallback(() => {
      if (hasFocus) {
        return focusCss;
      }

      if (hasError) {
        return errorCss;
      }

      return styles.phoneCountryCodeLabel;
    }, [focusCss, hasError, hasFocus, errorCss, styles.phoneCountryCodeLabel]);

    // When useInlinePhoneNumber is true, the component displays the label text (e.g., +1) and hides the
    // select/dropdown display text (e.g., United States (+1)). The mouse focus and blur handler are used to apply the
    // CSS focus styles to the label element for dropdown mouse click events.
    const onFocusHandler = () => {
      setLabelCss(focusCss);
    };

    const onBlurHandler = () => {
      setLabelCss(mergeClasses(styles.phoneCountryCodeLabel, hasError ? errorCss : ""));
    };

    useEffect(() => {
      if (hasFocus) {
        dropdownRef?.current?.focus();
      }
    }, [hasFocus]);

    useEffect(() => {
      if (hasInitialFocus) {
        setTimeout(() => {
          dropdownRef?.current?.focus();
        }, FOCUS_TIMEOUT);
      }

      setLabelCss(getLabelCss());
      initializeCountryMap();
    }, [hasInitialFocus, initializeCountryMap, getLabelCss]);

    const renderDropdown = () => (
      <select
        id="phoneCountry"
        name="PhoneCountry"
        aria-label={getLocalString("General_CountryCode")}
        value={selectedCountry.iso}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
          onDropdownValueChange(e, onChangeHandler)
        }
        onFocus={onFocusHandler}
        onBlur={onBlurHandler}
        className={
          hasError ? mergeClasses(fabricStyles.formControl, "has-error") : fabricStyles.formControl
        }
        ref={dropdownRef}
      >
        {countryData.map((country) => (
          <option key={country.iso} value={country.iso}>
            {country.displayValue}
          </option>
        ))}
      </select>
    );

    return useInlinePhoneNumber ? (
      <div className={mergeClasses(styles.phoneCountryBoxInline)}>
        <label
          htmlFor="phoneCountry"
          aria-hidden="true"
          className={labelCss}
          data-testid="phoneCountryLabel"
        >
          {`\u200E+${selectedCountry.code}`}
        </label>
        <AccessibleImage
          accessibleImages={downArrowIcon}
          role="presentation"
          style={styles.downArrow}
        />
        {renderDropdown()}
      </div>
    ) : (
      <div className={mergeClasses(fabricStyles.formGroup, styles.phoneCountryBoxNotInline)}>
        {renderDropdown()}
      </div>
    );
  };

export default PhoneCountryDropdown;
