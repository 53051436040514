import React, { useContext, useRef } from "react";
import { makeStyles, shorthands } from "@griffel/react";
import GlobalConfig from "../global-config";
import { GlobalContext } from "../global-context";
import { useNavigateBack } from "../hooks/use-navigate-back";
import { useRouteAnimation } from "../hooks/use-route-animation";
import * as styleConstants from "../styles/fabric/constants-fabric.styles";
import { isCssAnimationSupported } from "../utilities/browser-helper";
import { type IBackArrowButtonProps, BackArrowButton } from "./back-arrow-button";

export type IIdentityBannerProps = {
  /** The props for the back arrow button */
  backArrowButtonProps?: Partial<IBackArrowButtonProps>;
};

export const useIdentityBannerStyles = makeStyles({
  identityBanner: {
    height: `${styleConstants.IDENTITY_BANNER_HEIGHT}px`,
    ...shorthands.margin(
      `${styleConstants.FORM_GROUP_MARGIN}px`,
      0,
      `${styleConstants.FORM_GROUP_MARGIN}px`,
    ),
    lineHeight: `${styleConstants.IDENTITY_BANNER_HEIGHT}px`,
    whiteSpace: "nowrap",
    ...shorthands.overflow("hidden"),
    textOverflow: "ellipsis",
  },
});

/**
 * React IdentityBanner component
 * @param props IIdentityBannerProps
 * @returns an instance of the shared identity banner component
 */
const IdentityBanner: React.FC<IIdentityBannerProps> = function IdentityBanner(props) {
  const { backArrowButtonProps = {} } = props;
  const { buttonId, ariaDescribedBy, hasFocus } = backArrowButtonProps;

  const identityBannerStyles = useIdentityBannerStyles();
  const {
    globalState: { user, showBackButtonOnActiveView },
  } = useContext(GlobalContext);

  const [, , onAnimationEnd, transitionBanner] = useRouteAnimation();
  const animationClassName = isCssAnimationSupported() ? transitionBanner : "";

  const backArrowButtonRef = useRef<HTMLButtonElement>(null);
  const { isRtlMarket } = GlobalConfig.instance;
  const backButtonOnClick = useNavigateBack();

  return (
    <div
      data-testid="identityBanner"
      className={animationClassName}
      onAnimationEnd={onAnimationEnd}
    >
      {showBackButtonOnActiveView && (
        <BackArrowButton
          ref={backArrowButtonRef}
          buttonId={buttonId}
          ariaDescribedBy={ariaDescribedBy}
          hasFocus={hasFocus}
          isRtlMarket={isRtlMarket}
          onClick={backButtonOnClick}
        />
      )}
      <div id="userDisplayName" className={identityBannerStyles.identityBanner}>
        {user.displayUsername.unsafeUnescapedString}
      </div>
    </div>
  );
};

export default IdentityBanner;
