import { useCallback } from "react";
import { type ITelemetryProvider } from "@msidentity/telemetry";
import { type Flavors } from "../constants";
import { type FlowId, type ViewId } from "../constants/routing-constants";
import { type GlobalState } from "../global-context";
import { type ServiceDiagEventNames } from "./service-diag-event-names";
import { type SystemActionName } from "./system-action-name";
import { getStandardDimensions, TelemetryEventType } from "./telemetry-helper";
import { type UserActionName } from "./user-action-name";

export type TelemetryData =
  | {
      [key: string]: string | number | boolean | object | string[] | number[] | boolean[];
    }
  | undefined;

export interface UserActionTelemetryProps {
  actionName: UserActionName | SystemActionName;
  actionValue?: string | {};
}

export interface ServiceDiagTelemetryProps {
  metricName: ServiceDiagEventNames;
  metricValue?: string | {};
}

export const stringOrStringify = (value?: string | {}) => {
  if (!value) {
    return "";
  }

  return typeof value === "string" ? value : JSON.stringify(value);
};

export const useTelemetry = (
  telemetryProvider: ITelemetryProvider,
  dimensions: {
    activeView: ViewId;
    activeFlow: FlowId;
    activeFlavor: Flavors;
  } & Partial<GlobalState>,
) => ({
  logUserAction: useCallback(
    ({ actionName, actionValue }: UserActionTelemetryProps): void => {
      telemetryProvider.addEvent({
        _table: TelemetryEventType.UserAction,
        dimensions: getStandardDimensions(dimensions),
        actionName,
        actionValue: stringOrStringify(actionValue),
      });
    },
    [telemetryProvider, dimensions],
  ),
  logServiceDiagEvent: useCallback(
    ({ metricName, metricValue }: ServiceDiagTelemetryProps): void => {
      telemetryProvider.addEvent({
        _table: TelemetryEventType.ServiceDiag,
        dimensions: getStandardDimensions(dimensions),
        metricName,
        metricValue: stringOrStringify(metricValue),
      });
    },
    [telemetryProvider, dimensions],
  ),
});
