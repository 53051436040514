import { useRef } from "react";
import { NonRetryableError, RetryableError } from "../custom-errors";
import { getCurrentTimeMs } from "../datetime-helper";

export interface IPollingProps {
  maxPollingErrors?: number;
  pollingIntervalMs?: number;
  pollingTimeoutMs?: number;
}

/**
 * usePollingHook
 * @param props IPollingProps
 * @returns startPolling promise, stopPolling handler
 */
export function usePolling(props: IPollingProps) {
  const { pollingIntervalMs = 1000, maxPollingErrors = 5, pollingTimeoutMs = 60000 } = props;

  // We'll check if we need to schedule a new poll request every tenth of the polling interval
  const pollingIntervalTick = Math.floor(pollingIntervalMs / 10);
  let nextPollTime = getCurrentTimeMs();

  const continuePolling = useRef(true);
  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

  const stopPolling = () => {
    continuePolling.current = false;
  };

  const clearTimers = () => {
    if (intervalIdRef.current) {
      clearTimeout(intervalIdRef.current);
      intervalIdRef.current = null;
    }

    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = null;
    }
  };

  /**
   *  startPolling initiates the polling interval
   * @param requestCallback The callback that will be run with each interval
   * @returns a Promise
   */
  function startPolling(requestCallback: () => Promise<void>): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      let pollingErrorCount = 0;
      let hitNonRetryableError = false;
      let hitTimeout = false;

      continuePolling.current = true;

      timeoutIdRef.current = setTimeout(() => {
        hitTimeout = true;
      }, pollingTimeoutMs);

      intervalIdRef.current = setInterval(async () => {
        if (!continuePolling.current) {
          clearTimers();
          resolve();
        }

        const now = getCurrentTimeMs();

        if (now >= nextPollTime) {
          nextPollTime = now + pollingIntervalMs;

          try {
            await requestCallback();
            stopPolling();
          } catch (err) {
            const isRetryable = err instanceof RetryableError;

            if (isRetryable && err.message !== "RetryableError pending") {
              pollingErrorCount += 1;
            }

            if (!isRetryable || pollingErrorCount >= maxPollingErrors) {
              hitNonRetryableError = true;
              stopPolling();
            }
          }

          if (hitNonRetryableError && pollingErrorCount >= maxPollingErrors) {
            clearTimers();
            reject(NonRetryableError("maxRetryableErrors"));
          } else if (hitNonRetryableError) {
            clearTimers();
            reject(NonRetryableError("unknownError"));
          } else if (hitTimeout) {
            clearTimers();
            reject(NonRetryableError("timeout"));
          }
        }
      }, pollingIntervalTick);
    });
  }

  return { startPolling, stopPolling };
}
