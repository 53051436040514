import { useCallback, useEffect, useState } from "react";

type RenderPromiseData = {
  readyToRender: boolean;
};

/**
 * This hook takes an array of Promises that should occur once and tracks their resolution.
 * It returns an indicator, `readyToRender`, which can be used to conditionally render placeholder content until the promises are resolved.
 * Note: The use of `readyToRender` and placeholder content is awaiting a design review.
 * @param renderPromises An array of promises that should be completed once on initial render
 * @returns An object containing an indicator, `readyToRender`, which can be used to conditionally render placeholder content.
 */
export const useRenderPromises = (renderPromises: Promise<void>[]): RenderPromiseData => {
  // Don't bother resolving if the array is empty
  const needsResolved = renderPromises.length > 0;
  const [resolvedOnce, setResolvedOnce] = useState(!needsResolved);
  const [readyToRender, setReadyToRender] = useState(!needsResolved);

  const resolvePromises = useCallback(
    async () =>
      Promise.all(renderPromises).then(() => {
        setResolvedOnce(true);
        setReadyToRender(true);
      }),
    [renderPromises],
  );

  useEffect(() => {
    if (!resolvedOnce) {
      resolvePromises();
    }
  }, [resolvePromises, resolvedOnce]);

  return {
    readyToRender,
  };
};
