import { ProofType } from "../../../types/credential-types";
import { doubleJoin } from "../../object-helper";
import { PARSE_FAILED, post } from "../../request-helper";
import { addQueryStrings, extractQueryStringParam } from "../../strings-helper";
import { OneTimeCodeTimeout, OneTimeCodeUrl } from "./one-time-code-constants";
import {
  type BaseOtcHelperParams,
  type OneTimeCodeFormResponse,
  type OtcFailureParams,
  type OtcSuccessParams,
  OtcChannel,
  OtcProperties,
  OtcPurposes,
  OtcRequestParams,
  OtcStatus,
  OtcType,
} from "./one-time-code-types";

export type BaseOtcFormParams = {
  siteId: string;
  clientId: string;
  forwardedClientId: string;
  noPaBubbleVersion: string;
};

export type OtcFormParams = BaseOtcFormParams &
  BaseOtcHelperParams & {
    username?: string;
    proofData?: string;
    purpose?: string;
    canaryFlowToken?: string;
    isEncrypted?: boolean;
    uiMode?: number;
    localeId?: number; // MSA-only
    unauthSessionId?: string;
    proofConfirmation?: string;
    proofType: ProofType;
    phoneCountry?: string;
    phoneCountryCode?: string;
    telemetryCallback?: (telemetryProps: OtcFormTelemetryProps) => void;
  };

export type OtcFormTelemetryProps = Pick<OtcFormParams, "localeId"> &
  Pick<OtcFormParams, "phoneCountry"> &
  Pick<OtcFormParams, "phoneCountryCode"> &
  Pick<OtcFormParams, "proofType"> &
  Pick<OtcFormParams, "purpose"> &
  Pick<OtcFormParams, "uiMode">;

export type OtcFormBodyParams = BaseOtcFormParams & {
  timeout?: number;
  data: Record<string, string | number>;
};

/**
 * This creates a map of query string parameters to use in the OTC request.
 * These query strings are added to the target URL.
 * @param params the OTC query string parameters
 * @returns a map of query string parameters to use
 */
export const getQueryStringParams = function getQueryStringParams(params: BaseOtcFormParams) {
  const queryStringParams = new Map<string, string>();

  const market = extractQueryStringParam("mkt");
  const lcid = extractQueryStringParam("lc");

  if (market) {
    queryStringParams.set("mkt", market);
  }

  if (lcid) {
    queryStringParams.set("lcid", lcid);
  }

  if (params.siteId) {
    queryStringParams.set("id", params.siteId);
  }

  if (params.clientId) {
    queryStringParams.set("client_id", params.clientId);
  }

  if (params.forwardedClientId) {
    queryStringParams.set("fci", params.forwardedClientId);
  }

  if (params.noPaBubbleVersion) {
    queryStringParams.set("nopa", params.noPaBubbleVersion);
  }

  return queryStringParams;
};

/**
 * This method converts a ProofType into an OtcChannel
 * @param type the ProofType to convert
 * @returns the corresponding OtcChannel
 */
export const proofTypeToChannel = function proofTypeToChannel(type: ProofType) {
  let channel = null;

  switch (type) {
    case ProofType.SMS:
      channel = OtcChannel.mobileSms;
      break;
    case ProofType.Voice:
      channel = OtcChannel.voiceCall;
      break;
    case ProofType.Email:
    case ProofType.AltEmail:
      channel = OtcChannel.emailAddress;
      break;
    case ProofType.TOTPAuthenticatorV2:
      channel = OtcChannel.pushNotifications;
      break;
    default:
      break;
  }

  return channel;
};

/**
 * This method converts a ProofType into an OtcType
 * @param type the ProofType to convert
 * @param isEncrypted whether the OTC is encrypted
 * @returns the corresponding OtcType
 */
export const proofTypeToOtcType = function proofTypeToOtcType(
  type: ProofType,
  isEncrypted?: boolean,
) {
  let otcType = null;

  switch (type) {
    case ProofType.Voice:
    case ProofType.SMS:
      otcType = isEncrypted ? OtcType.mobileEncrypted : OtcType.mobile;
      break;
    case ProofType.Email:
    case ProofType.AltEmail:
      otcType = isEncrypted ? OtcType.emailAddressEncrypted : OtcType.emailAddress;
      break;
    case ProofType.TOTPAuthenticatorV2:
      otcType = OtcType.sessionApprover;
      break;
    default:
      break;
  }

  return otcType;
};

/**
 * This method generates the OTC "proof" used in the OTC request body
 * @param params the OTC query string parameters
 * @returns the OTC proof object
 */
export const getOtcProof = function getOtcProof(params: OtcFormParams) {
  const otcProof = {} as Record<string, string | number>;
  const { isEncrypted, proofType } = params;
  const otcType = proofTypeToOtcType(params.proofType, isEncrypted);

  otcProof[OtcRequestParams.username] = params.username || "";
  otcProof[OtcRequestParams.flowToken] = params.flowToken || "";
  otcProof[OtcRequestParams.purpose] = params.purpose || OtcPurposes.password;
  otcProof[OtcRequestParams.channel] = proofTypeToChannel(proofType) || "";

  if (otcType !== null) {
    otcProof[otcType] = params.proofData || "";
  }

  if (params.uiMode) {
    otcProof[OtcRequestParams.uiMode] = params.uiMode;
  }

  if (params.localeId) {
    otcProof.lcid = params.localeId;
  }

  if (!isEncrypted && (proofType === ProofType.SMS || proofType === ProofType.Voice)) {
    otcProof[OtcRequestParams.phoneCountry] = params.phoneCountry || "";
    otcProof[OtcRequestParams.phoneCountryCode] = params.phoneCountryCode || "";
  }

  if (params.unauthSessionId) {
    otcProof[OtcRequestParams.unauthSessionId] = params.unauthSessionId;
  }

  if (params.proofConfirmation) {
    otcProof[OtcRequestParams.proofConfirmation] = params.proofConfirmation;
  }

  if (params.canaryFlowToken) {
    otcProof[OtcRequestParams.canaryFlowToken] = params.canaryFlowToken;
  }

  return otcProof;
};

/**
 * This method generates the lower-level OTC params from the high-level "helper" params.
 * As part of this, it defaults the "isEncrypted" parameter to true and generates the OTC proof.
 * @param params the OTC query string helper params
 * @returns the low-level OTC query string params to use for "sendRequest"
 */
export const getOtcParams = function getOtcParams(params: OtcFormParams) {
  const isEncrypted = params.isEncrypted !== false;
  const updatedParams = { ...params, isEncrypted };
  const otcParams: OtcFormBodyParams = {
    data: getOtcProof(updatedParams),
    siteId: updatedParams.siteId,
    clientId: updatedParams.clientId,
    forwardedClientId: updatedParams.forwardedClientId,
    noPaBubbleVersion: updatedParams.noPaBubbleVersion,
  };
  return otcParams;
};

/**
 * This method makes the POST request by generating the query string parameters and adding them to the target URL.
 * Then it takes the OTC proof in "data" and applies a doubleJoin to generate the request body.
 * @param params the OTC query string params
 * @returns a promise that will resolve to the request response or reject with an error
 */
export const sendRequest = function sendRequest(params: OtcFormBodyParams) {
  const queryStringParams = getQueryStringParams(params);
  const targetUrl = addQueryStrings(OneTimeCodeUrl, queryStringParams);
  const body = doubleJoin(params.data, "&", "=");
  const timeout = params.timeout || OneTimeCodeTimeout;
  const headers = {
    "Content-type": "application/x-www-form-urlencoded",
  };

  return post(targetUrl, { headers, body, timeout });
};

/**
 * This method returns an object to be used while logging telemetry
 * @param params the OTC query string helper params
 * @returns a object to be used for telemetry
 */
export const getTelemetryProperties = function getTelemetryProperties(
  params: OtcFormParams,
): OtcFormTelemetryProps {
  const { proofType, purpose, uiMode, localeId: lcid, phoneCountry, phoneCountryCode } = params;
  return { proofType, purpose, uiMode, localeId: lcid, phoneCountry, phoneCountryCode };
};

/**
 * This method takes the response from the OTC POST and parses the response into the OTC success parameters.
 * In the event the request isn't a success, the response's "flowToken" will be added as possible to the throw Error.
 * @param response The response from the OTC POST
 */
export const parseResponse = async function parseResponse(
  response: Response,
): Promise<OtcSuccessParams> {
  let failed = false;
  let displaySign;
  let flowToken;
  let otcStatus = OtcStatus.none;
  let responseJson: OneTimeCodeFormResponse = {};
  let haveParsedResponse = false;
  let failedOnParse = false;

  try {
    responseJson = await response.json();
    haveParsedResponse = true;
  } catch (e) {
    failedOnParse = true;
    failed = true;
  }

  if (haveParsedResponse) {
    if (responseJson[OtcProperties.flowToken]) {
      flowToken = responseJson[OtcProperties.flowToken];
    }

    otcStatus = responseJson[OtcProperties.state] || OtcStatus.none;
    if (otcStatus) {
      if (responseJson[OtcProperties.displaySignForUI]) {
        displaySign = responseJson[OtcProperties.displaySignForUI];
      }

      failed = otcStatus !== OtcStatus.success;
    } else {
      failed = true;
    }
  }

  if (failed) {
    const errorMessage = failedOnParse
      ? PARSE_FAILED
      : "response did not contain a successful state";
    const error: OtcFailureParams = new Error(errorMessage);

    if (flowToken) {
      error.flowToken = flowToken;
    }

    error.otcStatus = otcStatus;

    throw error;
  } else {
    const successParams: OtcSuccessParams = { response: responseJson };

    if (flowToken) {
      successParams.flowToken = flowToken;
    }

    if (displaySign) {
      successParams.displaySign = displaySign;
    }

    return successParams;
  }
};

/**
 * This method makes a POST to GetOneTimeCode.srf using a querystring for the request body.
 * The response is parsed into a common OTC success type and resolved upon success.
 * In the event the request isn't a success, the response's "flowToken" will be added as possible to the throw Error.
 * Note: This is currently only used by MSA
 * @param params The parameters required to make the request
 * @returns a promise that will resolve to the OTC success parameters (or throw an error on failure)
 */
export const getOneTimeCode = function getOneTimeCode(
  params: OtcFormParams,
): Promise<OtcSuccessParams> {
  const otcParams = getOtcParams(params);

  if (params.telemetryCallback) {
    params.telemetryCallback(getTelemetryProperties(params));
  }

  return sendRequest(otcParams).then(parseResponse);
};
