/**
 * Helper function to get a UTC string
 * @returns The current time in UTC (ISO formatted)
 */
export function getUTCString(): string {
  return new Date().toISOString();
}

/**
 * Helper function to retrieve the current time
 * @returns The current time in milliseconds
 */
export function getCurrentTimeMs(): number {
  return new Date().getTime();
}
