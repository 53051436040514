import { doubleSplit } from "./strings-helper";

const isoUnitedStates = "US";
const isoJapan = "JP";

export interface ICountryInfo {
  iso: string;
  displayValue: string;
  code: string;
}

/**
 * Gets the formatted phone country display value that will be used in the phone dropdown option
 * @param countryName The country name
 * @param countryCode The country code
 * @returns A formatted string with the country display value (e.g., United States (+1))
 */
export function getCountryDisplayValue(countryName: string, countryCode: string) {
  return countryName + (countryCode ? ` \u200F(\u200E+${countryCode})` : "");
}

/**
 * Parses a string containing a list of phone country data
 * @param countryList The string containing  a list of phone country data
 * @param alwaysShowCountryCode Indicates if the country code/prefix should be always be shown
 * @returns An array of phone country information
 */
export function parseCountryList(countryList: string, alwaysShowCountryCode: boolean) {
  const countries: ICountryInfo[] = [];
  const parsedInfo = doubleSplit(countryList, "!!!", "~", true);

  Object.entries(parsedInfo).forEach(([key, value]) => {
    const countryData = value as string[];
    const countryCode = !alwaysShowCountryCode && key === isoJapan ? "" : countryData[1];
    const countryInfo = {
      iso: key,
      displayValue: getCountryDisplayValue(countryData[0], countryCode),
      code: countryCode,
    };
    countries.push(countryInfo);
  });

  return countries;
}

/**
 * Gets the first matching phone country data for a given country code
 * @param countries An array with phone country information
 * @param defaultCountryIso The country code for the default country (e.g., "US")
 * @returns The phone country information for the default country code. If a match is not found, returns US country information.
 */
export function getDefaultCountry(countries: ICountryInfo[], defaultCountryIso: string) {
  const formattedDefaultCountryIso = (defaultCountryIso || "").toUpperCase();
  const displayValueUnitedStates = getCountryDisplayValue("United States", "1");
  let countryUnitedStates: ICountryInfo = { iso: "US", displayValue: displayValueUnitedStates, code: "1" };

  const defaultCountry = countries.find((country: ICountryInfo) => {
    if (country.iso === isoUnitedStates) {
      countryUnitedStates = country;
    }

    return country.iso.toUpperCase() === formattedDefaultCountryIso;
  });

  return defaultCountry || countryUnitedStates;
}

/**
 * Removes the `+` from the fully qualified phone number. 
 * The `+` is typically included during form submissions with exception of select flows.
 * @param value The phone number value
 * @returns The phone number with the `+` removed
 */
export function formatPhoneNumberValue(value: string): string {
  return value.replace("+", "");
}
