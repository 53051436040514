export enum UserActionName {
  None = "",
  AccountTransferErrorOk = "AccountTransferError_Ok",
  AccountTransferOtcCancelled = "AccountTransfer_OtcCancelled",
  AccountTransferQrCancelled = "AccountTransfer_QrCancelled",
  AccountTransferQrCodeScanned = "AccountTransfer_QrCodeScanned",
  AccountTransferQrSkipped = "AccountTransfer_QrSkipped",
  AcwAddEmailBackClicked = "Acw_AddEmail_BackClicked",
  AcwAddEmailCancelClicked = "Acw_AddEmail_CancelClicked",
  AcwAddEmailPageLoad = "Acw_AddEmail_PageLoad",
  AcwAddEmailVerificationComplete = "Acw_AddEmail_VerificationComplete",
  AcwAddPhoneBackClicked = "Acw_AddPhone_BackClicked",
  AcwAddPhoneCancelClicked = "Acw_AddPhone_CancelClicked",
  AcwAddPhonePageLoad = "Acw_AddPhone_PageLoad",
  AcwAddPhoneVerificationComplete = "Acw_AddPhone_VerificationComplete",
  AppStoreRedirectSubmit = "AppStoreRedirect_Submit",
  BeginNavigation = "BeginNavigation",
  ChallengeBack = "ChallengeBack",
  ChallengeValidate = "ChallengeValidate",
  KmsiAccepted = "Kmsi_Accepted",
  KmsiDeclined = "Kmsi_Declined",
  ResetPasswordSignInNameCancelClicked = "ResetPasswordSignInNameCancelClicked",
  ResetPasswordSignInNameSubmitClicked = "ResetPasswordSignInNameSubmitClicked",
  SignupLinkClicked = "Signup_LinkClicked",
  TransferLoginDone = "TransferLogin_Done",
}
