/**
 * Checks if an object has a matching property name
 * @param object The object to search
 * @param propertyName The property name to search
 * @param ignoreCase Whether or not to ignore case when searching for a match
 * @returns The matching object key or null
 */
export const findOwnProperty = (
  object: Record<string, unknown>,
  propertyName: string,
  ignoreCase: boolean,
): string | null => {
  let matchedKey = null;
  Object.keys(object).forEach((key: string) => {
    if (key === propertyName || (ignoreCase && key.toLowerCase() === propertyName.toLowerCase())) {
      matchedKey = key;
    }
  });

  return matchedKey;
};

/**
 * Joins the key-value pairs of an object into a string
 * @param object The object to join
 * @param delimiter The string separating each key-value pair
 * @param separator The string separating a key and its value
 * @returns A string with the key-value pairs of the object
 */
export const doubleJoin = (
  object: Record<string, unknown> | null | undefined,
  delimiter: string,
  separator: string,
): string => {
  let result = "";
  if (object) {
    Object.keys(object).forEach((key: string) => {
      if (result) {
        result += delimiter;
      }

      result += key + separator + ((object as Record<string, unknown>)[key] || "");
    });
  }

  return result;
};
