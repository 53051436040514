import { type RedirectPostParams } from "../../../../utilities/api-helpers/get-credential-type-helper";
import LoginConfig from "../../login-config";
import { useCurrentIdpTileClickHandler } from "./use-current-idp-tile-click-handler";
import { useOtherIdpTileClickHandler } from "./use-other-idp-tile-click-handler";

export interface ITileClickHandlers {
  aadTileClickHandler: () => void;
  msaTileClickHandler: () => void;
}

/**
 * @param redirectingToIdp flag indicating whether the user is being redirected to another IDP
 * @param idpRedirectUrl the IDP redirect url
 * @param idpRedirectPostParams the IDP redirect post params
 * @param unsafeDesktopSsoDomain the unsafe desktop SSO domain
 * @returns The AAD and MSA tile click handlers for the login IDP disambiguation view
 */
export const useTileClickHandlers = (
  redirectingToIdp: boolean,
  idpRedirectUrl: string,
  idpRedirectPostParams: RedirectPostParams,
  unsafeDesktopSsoDomain: string,
): ITileClickHandlers => {
  // Login config data
  const { switchDisambig } = LoginConfig.instance;

  const currentIdpTileClickHandler = useCurrentIdpTileClickHandler(
    redirectingToIdp,
    idpRedirectUrl,
    idpRedirectPostParams,
    unsafeDesktopSsoDomain,
  );

  const otherIdpTileClickHandler = useOtherIdpTileClickHandler();

  const aadTileClickHandler = switchDisambig
    ? currentIdpTileClickHandler
    : otherIdpTileClickHandler;

  const msaTileClickHandler = switchDisambig
    ? otherIdpTileClickHandler
    : currentIdpTileClickHandler;

  return {
    aadTileClickHandler,
    msaTileClickHandler,
  };
};
