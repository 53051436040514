import { useContext } from "react";
import { GlobalContext } from "../global-context";
import { useNavigateDirection } from "./use-navigate-direction";

/**
 * React hook used to navigate back to the previous view.
 * @returns A function that changes the location to the previous view.
 */
export const useNavigateBack = (): (() => void) => {
  const {
    globalState: { activeView },
  } = useContext(GlobalContext);
  const navigate = useNavigateDirection();
  return () => navigate(activeView, -1, true);
};
