/**
 * This class creates a singleton wrapper around a config object, ensuring that the object is not modified after initialization. Config Wrappers should be
 * initialized near the root of the app, outside of the component render cycle
 */
export class ConfigWrapper<T extends Record<string, unknown>> {
  private isInitialized: boolean = false;

  /**
   * Construct a ConfigWrapper instance
   * @param config The *default* config object that should be used until initialize is called
   */
  constructor(private config: T) {}

  /**
   * Initialize the config wrapper
   * @param config The config object that should be wrapped (replacing the default object passed to the constructor)
   */
  initialize(config: Partial<T>) {
    if (this.isInitialized) {
      throw new Error("Config already initialized");
    }

    this.isInitialized = true;

    this.config = { ...this.config, ...config };
  }

  /**
   * Get the current config. Config properties are READ-ONLY unless you are very sure you know what you are doing.
   * @returns The wrapped config object
   */
  get instance(): T {
    return this.config;
  }
}
