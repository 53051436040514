import React, { useContext, useEffect } from "react";
import { mergeClasses } from "@griffel/react";
import ErrorContainer from "../../../../components/error-container";
import { TileList } from "../../../../components/tile-list";
import { FlowId, ViewId } from "../../../../constants/routing-constants";
import { useActivateView } from "../../../../hooks/use-activate-view";
import useFabricStyles, { useStaticFabricStyles } from "../../../../styles/fabric/fabric.styles";
import { useTileStyles } from "../../../../styles/fabric/tile-fabric.styles";
import { LoginTitleFabric } from "../../components/fabric/login-title-fabric";
import { useSetServerDataErrorOnView } from "../../hooks/login-hooks";
import { LoginContext } from "../../login-context";
import {
  useAccountPickerBuilder,
  useOnSignOutOrForgetEventListener,
} from "../hooks/account-picker-view-hooks";
import { accountPickerStringsFabric } from "./account-picker-strings-fabric";

/**
 * AccountPickerViewFabric component
 * Note: This was formerly known as the "TilesView" in Knockout
 * @returns A rendered instance of this component
 */
export const AccountPickerViewFabric: React.FC = function AccountPickerViewFabric() {
  const {
    viewState: { credentials },
  } = useContext(LoginContext);

  const { accountPickerTitle } = accountPickerStringsFabric;

  useActivateView(ViewId.AccountPicker, FlowId.Login, { showIdentityBanner: false });

  useStaticFabricStyles();
  const fabricStyles = useFabricStyles();
  const tileStyles = useTileStyles();

  const signOutOrForgetEvent = useOnSignOutOrForgetEventListener();

  // listens for sign out/forget message event from iframe
  useEffect(() => {
    window.addEventListener("message", signOutOrForgetEvent);

    return () => {
      window.removeEventListener("message", signOutOrForgetEvent);
    };
  }, [signOutOrForgetEvent]);

  const { accountPickerList, error, setError, iFrameSource } = useAccountPickerBuilder(
    credentials.sessions,
    accountPickerStringsFabric,
  );

  // If there is an error from server in server data, show it on initialize
  useSetServerDataErrorOnView(setError);

  return (
    <div>
      <LoginTitleFabric titleId="accountPickerTitle" title={accountPickerTitle} />
      {error && (
        <ErrorContainer
          id="accountPickerError"
          className={mergeClasses(tileStyles.tileError, fabricStyles.alertError)}
        >
          {error}
        </ErrorContainer>
      )}
      <div className={fabricStyles.formGroup}>
        <TileList
          ariaLabelledBy={accountPickerTitle}
          ariaDescribedBy={accountPickerTitle}
          tileList={accountPickerList}
        />
      </div>
      {iFrameSource && ( // inject iframe for logout/forget
        <iframe
          style={{
            visibility: "hidden",
            width: "0px",
            height: "0px",
            display: "none",
          }}
          src={iFrameSource}
          title="accountPickerIframe"
          id="accountPickerIframeId"
          data-testid="accountPickerIframeTestId"
        />
      )}
    </div>
  );
};
