import React, { useContext, useEffect, useRef, useState } from "react";
import { makeStyles, mergeClasses, shorthands } from "@griffel/react";
import { LayoutTemplateType } from "../constants/constants";
import GlobalConfig from "../global-config";
import { type IDebugInfo, GlobalContext } from "../global-context";
import * as styleConstants from "../styles/fabric/constants-fabric.styles";
import { getTextAreaAndCopyToClipboard } from "../utilities/clipboard-helper";
import { getUTCString } from "../utilities/datetime-helper";
import { checkMarkIcon, closeIcon } from "../utilities/image-helpers/accessible-images";
import { AccessibleImage } from "./accessible-image";
import { DebugTrace } from "./debug-trace";
import { ImageButton } from "./image-button";

type DebugDetailsBannerProps = {
  debugInfo: IDebugInfo;
  onDebugCloseClick?: (event: React.SyntheticEvent<HTMLElement>) => void;
  focusOnCopyLink?: boolean;
};

const useDebugDetailsBannerStyles = makeStyles({
  banner: {
    width: "100%",
    display: "table",
    tableLayout: "fixed",
    position: "relative",
    marginLeft: "auto",
    marginRight: "auto",
    color: "#1B1B1B",
    backgroundColor: "#ffffff",
    ...shorthands.padding(
      `${styleConstants.LIGHTBOX_MOBILE_PADDING}px`,
      `${styleConstants.LIGHTBOX_PADDING}px`,
    ),
    fontSize: "13px",
    maxWidth: `${styleConstants.LIGHTBOX_MAX_WIDTH}px`,
    minWidth: `${styleConstants.LIGHTBOX_MIN_WIDTH}px`,
    WebkitBoxShadow: styleConstants.LIGHTBOX_SHADOW,
    "-moz-box-shadow": styleConstants.LIGHTBOX_SHADOW,
    boxShadow: styleConstants.LIGHTBOX_SHADOW,
    marginBottom: `${styleConstants.FOOTER_HEIGHT}px`,

    [styleConstants.MOBILE_LAYOUT_SIZE]: {
      backgroundColor: "#f2f2f2",
      ...shorthands.padding(`${styleConstants.LIGHTBOX_MOBILE_PADDING}px`),
      WebkitBoxShadow: "none",
      "-moz-box-shadow": "none",
      boxShadow: "none",
      tableLayout: "auto",
    },
  },
  bannerVerticalSplit: {
    paddingLeft: "14px",
    paddingRight: "14px",
    tableLayout: "auto",
  },
  tableRow: {
    display: "table-row",
  },
  tableCell: {
    display: "table-cell",
    verticalAlign: "middle",
    ":first-child": {
      width: "100%",
    },
  },
  bold: {
    fontWeight: "bold",
  },
  overrideLtr: {
    "text-align": "left",
  },
  header: {
    marginBottom: "10px",
  },
  headingText: {
    fontSize: "15px",
    fontWeight: "bold",
  },
  traceSection: {
    marginTop: "10px",
  },
  detailsNotification: {
    marginLeft: "5px",
    color: "#107C10",
  },
  copyLink: {
    "text-decoration": "none",
    fontSize: "13px",
  },
  closeButton: {
    ...shorthands.border("0"),
    backgroundColor: "transparent",
    cursor: "pointer",
    minWidth: "fit-content",
    ...shorthands.padding("0"),
  },
  wide: {
    maxWidth: `${styleConstants.LIGHTBOX_WIDE_MAX_WIDTH}px`,
  },
});

/**
 * DebugDetailsBanner component
 * @param props The properties for this component
 * @param props.debugInfo The debug info to render in the details pane
 * @param props.onDebugCloseClick The callback to execute when the debug details pane close button is clicked
 * @param props.focusOnCopyLink Whether focus is set on the copy details link. Default is false.
 * @returns an instance of this component
 */
const DebugDetailsBanner: React.FC<DebugDetailsBannerProps> = function DebugDetailsBanner({
  debugInfo,
  onDebugCloseClick,
  focusOnCopyLink = false,
}) {
  const { sessionId, correlationId, showCopyDebugDetailsLink } = GlobalConfig.instance;
  const debugDetailsBannerStyles = useDebugDetailsBannerStyles();
  const [showCopyMessage, setShowCopyMessage] = useState(false);
  const { errorCode, errorMessage: exceptionMessage } = debugInfo;
  const {
    globalState: {
      isWideView,
      styles: { layoutTemplate },
    },
  } = useContext(GlobalContext);

  /** @private  */
  function clickCopyTextToClipboard(e: React.SyntheticEvent<HTMLElement>) {
    e.preventDefault();
    getTextAreaAndCopyToClipboard("debugDetailsText");
    setShowCopyMessage(true);
  }

  const bannerStyles = mergeClasses(
    debugDetailsBannerStyles.banner,
    layoutTemplate === LayoutTemplateType.VerticalSplit
      ? debugDetailsBannerStyles.bannerVerticalSplit
      : "",
    isWideView ? debugDetailsBannerStyles.wide : "",
  );

  // Default action is to set focus on the copy details link when pressing enter on the debug button (ellipsis).
  // If the copy link element is not available, check if close debug button is available and set focus on that instead.
  const copyLinkRef = useRef<HTMLAnchorElement>(null);
  const closeDebugRef = useRef<HTMLButtonElement>(null);
  const getFocusableItem = () => {
    const element = copyLinkRef?.current ?? closeDebugRef?.current;
    return element;
  };

  useEffect(() => {
    if (focusOnCopyLink) {
      const elementRef = getFocusableItem();
      elementRef?.focus();
    }
  }, [focusOnCopyLink]);

  return (
    <div data-testid="debugDetailsBanner" className={bannerStyles}>
      <div className={debugDetailsBannerStyles.tableRow}>
        <div className={debugDetailsBannerStyles.tableCell}>
          {showCopyDebugDetailsLink && (
            <div className={debugDetailsBannerStyles.header}>
              <div
                id="debugDetailsHeader"
                className={debugDetailsBannerStyles.headingText}
                role="heading"
                aria-level={2}
              >
                {getLocalString("DebugDetails_Title")}
              </div>
              <div>{getLocalString("DebugDetails_Desc")}</div>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                href="#"
                onClick={clickCopyTextToClipboard}
                role="button"
                className={debugDetailsBannerStyles.copyLink}
                aria-describedby="debugDetailsHeader"
                aria-label={getLocalString("DebugDetails_CopyLink")}
                ref={copyLinkRef}
              >
                {getLocalString("DebugDetails_CopyLink")}
              </a>
              {showCopyMessage && (
                <span
                  id="debugDetailsCopyMessage"
                  className={debugDetailsBannerStyles.detailsNotification}
                  aria-live="assertive"
                >
                  <AccessibleImage accessibleImages={checkMarkIcon} role="presentation" />
                  <span
                    role="alert"
                    className={debugDetailsBannerStyles.detailsNotification}
                    aria-label={getLocalString("DebugDetails_Notification_ScreenReader")}
                  >
                    {getLocalString("DebugDetails_Notification_CopySuccessful")}
                  </span>
                </span>
              )}
            </div>
          )}
          <div id="debugDetailsText" className={debugDetailsBannerStyles.overrideLtr}>
            {errorCode && (
              <div>
                <span className={debugDetailsBannerStyles.bold}>Error Code: </span>
                <span>{errorCode}</span>
              </div>
            )}
            {sessionId && (
              <div>
                <span className={debugDetailsBannerStyles.bold}>Request Id: </span>
                <span>{sessionId}</span>
              </div>
            )}
            <div>
              <span className={debugDetailsBannerStyles.bold}>Correlation Id: </span>
              <span>{correlationId}</span>
            </div>
            <div>
              <span className={debugDetailsBannerStyles.bold}>Timestamp: </span>
              <span data-testid="timeStamp">{getUTCString()}</span>
            </div>
            {exceptionMessage && (
              <div>
                <span className={debugDetailsBannerStyles.bold}>Message: </span>
                <span>{exceptionMessage}</span>
              </div>
            )}
          </div>
          <DebugTrace />
        </div>
        <div>
          <ImageButton
            accessibleImageProps={{
              accessibleImages: closeIcon,
              altText: getLocalString("DebugDetails_Close_AlternateText"),
            }}
            buttonId="errorBannerClose"
            className={debugDetailsBannerStyles.closeButton}
            ariaLabel={getLocalString("DebugDetails_Close_AlternateText")}
            onClick={onDebugCloseClick}
            ref={closeDebugRef}
          />
        </div>
      </div>
    </div>
  );
};

export default DebugDetailsBanner;
