import { createContext } from "react";
import { type RemoteNgcType, BindProvider, CredentialType } from "../../constants/constants";
import { type UserCredentials } from "../../types/credential-types";
import {
  type RedirectPostParams,
  transformCredentialResponse,
} from "../../utilities/api-helpers/get-credential-type-helper";
import { getFidoSupport } from "../../utilities/browser-helper";
import { type ServerData } from "../../utilities/server-data";
import { setUntrustedExternalInputText } from "../../utilities/untrusted-external-input-text";
import { type IViewContext, createViewProvider } from "../view-context";
import { transformCredentialFlags } from "./login-constants";
import loginReducer, { type LoginActions } from "./login-reducer";
import { type ISession } from "./login-types";

export interface IRemoteNgcParams {
  showAnimatedGifWhilePolling: boolean;
  defaultType: RemoteNgcType | null;
  entropy: string;
  sessionIdentifier: string;
  requestSent: boolean;
  styleCredSwitchLinkAsButton: boolean;
}

export type ILoginCredentials = UserCredentials & {
  sessions: Array<ISession>;
};

export type LoginState = {
  credentials: ILoginCredentials;
  desktopSsoEnabled: boolean;
  desktopSsoExecuted: boolean;
  hasIdpDisambigError: boolean;
  idpRedirectPostParams: RedirectPostParams;
  idpRedirectProvider: BindProvider;
  idpRedirectUrl: string;
  location: string;
  otherIdpRedirectUrl: string;
  remoteNgcParams: IRemoteNgcParams;
  serverErrorShown: boolean;
  showCredViewBrandingDesc: boolean;
  unsafeDesktopSsoDomainToUse: string;
};

export const defaultCredentials: ILoginCredentials = {
  availableCredentials: [],
  evictedCredentials: [],
  preferredCredential: CredentialType.Password,
  proofConfirmation: "",
  sessions: [],
  useEvictedCredentials: false,
};

export const defaultLoginState: LoginState = {
  credentials: {
    ...defaultCredentials,
  },
  desktopSsoEnabled: false,
  desktopSsoExecuted: false,
  hasIdpDisambigError: false,
  idpRedirectPostParams: {},
  idpRedirectProvider: BindProvider.Unknown,
  idpRedirectUrl: "",
  location: "",
  otherIdpRedirectUrl: "",
  remoteNgcParams: {
    showAnimatedGifWhilePolling: false,
    defaultType: null,
    entropy: "",
    sessionIdentifier: "",
    requestSent: false,
    styleCredSwitchLinkAsButton: false,
  },
  showCredViewBrandingDesc: false,
  serverErrorShown: false,
  unsafeDesktopSsoDomainToUse: "",
};

export const LoginContext = createContext<IViewContext<LoginState, LoginActions>>({
  viewState: defaultLoginState,
  dispatchStateChange: () => {
    throw new Error("Login Context not initialized");
  },
});

export const LoginProvider: React.FC<{ initialState: LoginState }> = createViewProvider<
  LoginState,
  LoginActions
>(LoginContext, loginReducer);

/* ********* ServerData helpers  ********** */

/**
 * This method is used to initialize the LoginContext credentials
 * If a GCT response is provided in ServerData, that will be transformed into the associated state
 * If sessions are provided in ServerData, those will be transformed similarly
 * @param serverData The ServerData object
 * @returns The LoginContext.credentials for the initial state
 */
export const getCredentials = function getCredentials(serverData: ServerData): ILoginCredentials {
  if (serverData.oGetCredTypeResult) {
    // TODO: get some of these props from LoginConfig when generating together
    const {
      availableCredentials,
      evictedCredentials,
      preferredCredential,
      useEvictedCredentials,
      otcCredential,
    } = transformCredentialResponse(
      serverData.oGetCredTypeResult,
      {
        postProofType: serverData.sProofType ? parseInt(serverData.sProofType, 10) : 0,
        showSignup: serverData.fCBShowSignUp,
        signupUrl: serverData.urlSignUp,
        improvePhoneDisambiguation: serverData.fImprovePhoneDisambig,
      },
      getFidoSupport(!!serverData.fIsFidoSupported),
      false,
      transformCredentialFlags,
    );

    return {
      ...defaultCredentials,
      availableCredentials,
      evictedCredentials,
      preferredCredential,
      useEvictedCredentials,
      otcCredential,
    };

    // TODO: parse more properties as we use them
  }

  const credentials: ILoginCredentials = { ...defaultCredentials };

  return credentials;
};

/**
 * Create a Login state object from ServerData
 * @param serverData The IDP-specific server data object that should be used to create the Login state
 * @returns The IDP-agnostic Login state object created from the server data
 */
export function createLoginState(serverData: ServerData): LoginState {
  const loginState = { ...defaultLoginState };

  if (serverData?.urlGoToAADError) {
    loginState.otherIdpRedirectUrl = serverData.urlGoToAADError;
  }

  if (serverData?.desktopSsoConfig) {
    const hintedDomainName = serverData.desktopSsoConfig?.hintedDomainName || "";

    loginState.unsafeDesktopSsoDomainToUse = serverData.desktopSsoConfig?.startDesktopSsoOnPageLoad
      ? hintedDomainName
      : "";
  }

  loginState.credentials = getCredentials(serverData);

  if (serverData?.arrSessions) {
    const sessions = serverData.arrSessions;

    loginState.credentials.sessions = sessions.map((session) => {
      const configSession = {} as ISession;

      if (session?.displayName) {
        configSession.displayName = setUntrustedExternalInputText(session.displayName);
      }

      if (session?.fullName) {
        configSession.fullName = setUntrustedExternalInputText(session.fullName);
      }

      if (session?.id) {
        configSession.id = session.id;
      }

      if (session?.isSamsungSso !== undefined) {
        configSession.isSamsungSso = session.isSamsungSso;
      }

      if (session?.isSignedIn !== undefined) {
        configSession.isSignedIn = session.isSignedIn;
      }

      if (session?.name) {
        configSession.name = setUntrustedExternalInputText(session.name);
      }

      return configSession;
    });
  }

  if (serverData?.oAppCobranding?.showDescOnCredViews !== undefined) {
    loginState.showCredViewBrandingDesc = serverData.oAppCobranding.showDescOnCredViews;
  }

  if (serverData?.sProofConfirm) {
    loginState.credentials.proofConfirmation = serverData.sProofConfirm;
  }

  return loginState;
}
