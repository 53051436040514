import { type ChangeEvent, type FormEvent, useContext, useState } from "react";
import { type ViewId } from "../constants/routing-constants";
import { GlobalContext } from "../global-context";
import { GlobalActionType } from "../global-reducer";
import { type IPhoneNumberSubmitProps } from "./use-phone-number";

export interface IFormSubmissionProps {
  /**
   * A value that should be sent to the server when the form is submitted
   */
  value: string;
  /**
   * Error handler that should be invoked if the form submission fails
   * @param error A string or element to display as an error message
   */
  errorHandler: (error: string | JSX.Element) => void;
}

export type SubmitTask = (args: IFormSubmissionProps | IPhoneNumberSubmitProps) => void;

const useTextInputForm = (defaultValue = "") => {
  const [focus, setFocus] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const [showError, setShowError] = useState(false);
  const [externalError, setExternalError] = useState<string | JSX.Element>("");
  const [validationError, setValidationError] = useState("");
  const { dispatchStateChange: dispatchGlobal } = useContext(GlobalContext);

  const onBlur = () => setFocus(false);
  const onFocus = () => setFocus(true);
  const onTextChange = (event: ChangeEvent<HTMLInputElement> | string) => {
    setValue(typeof event === "string" ? event : event.target.value);
    setExternalError("");
  };

  const errorHandler = (error: string | JSX.Element) => {
    setExternalError(error);
  };

  const onFormSubmission =
    (
      submitTask: SubmitTask,
      viewId: ViewId,
      args?: IFormSubmissionProps | IPhoneNumberSubmitProps,
    ) =>
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      // show progress indicator during call
      dispatchGlobal({
        type: GlobalActionType.BeginNavigate,
        source: viewId,
      });

      // initiate validation only upon submission
      setShowError(true);
      setFocus(true);

      if (!externalError && !validationError) {
        if (args) {
          await submitTask(args);
        } else {
          await submitTask({ value, errorHandler });
        }
      }

      // re-enable after api completion
      dispatchGlobal({
        type: GlobalActionType.DataLoaded,
        view: viewId,
      });
    };

  return {
    focus,
    value,
    showError,
    externalError,
    validationError,
    onBlur,
    onFocus,
    onTextChange,
    onFormSubmission,
    setExternalError,
    setValidationError,
    setFocus,
    setShowError,
    errorHandler,
  };
};

export default useTextInputForm;
