/**
 * Extracts flow token value from a HTML string
 * @param html HTML string from server data that could contain the flow token
 * @returns flow token
 */
export const extractFlowToken = (html: string) => {
  let token = "";

  try {
    const div = document.createElement("div");
    div.innerHTML = html;

    if (div.children.length > 0 && div.children[0].hasAttribute("value")) {
      token = div.children[0].getAttribute("value") as string;
    }
  } catch (exception) {
    // do nothing, simply let the function return an empty token
  }

  return token;
};
