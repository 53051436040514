import { useContext } from "react";
import GlobalConfig from "../../../../../global-config";
import { GlobalContext } from "../../../../../global-context";
import { useBackButtonControl } from "../../../../../hooks/use-navigate-direction";
import { htmlUnescape } from "../../../../../utilities/strings-helper";
import { useFidoProperties } from "../../../hooks/login-hooks";
import LoginConfig from "../../../login-config";
import { type ICommonLoginStrings } from "../../../login-interface";
import { getCommonTitle } from "../../../login-util";
import {
  type IUsernameViewProperties,
  type IUsernameViewStrings,
} from "../username-view-interface";

/**
 * @returns username view properties
 * @param strings Flavored strings that are used by this hook
 * @param strings.usernameViewStrings Strings that are specific to the username view
 * @param strings.commonLoginStrings Strings that are common to all login views
 */
export const useUsernameViewProperties = ({
  usernameViewStrings,
  commonLoginStrings,
}: {
  usernameViewStrings: IUsernameViewStrings;
  commonLoginStrings: ICommonLoginStrings;
}): IUsernameViewProperties => {
  // context data
  const {
    globalState: {
      styles: { friendlyAppName },
      user,
    },
  } = useContext(GlobalContext);

  // AAD-TODO: CustomStrings
  const { loginSignupLink, signupLinkAriaLabel, header, placeholder, signInOptionsText } =
    usernameViewStrings;

  // config data
  const { checkProofForAliases } = GlobalConfig.instance;
  const { doIfExists, loginDescription, loginMode, showSignup } = LoginConfig.instance;

  const title = getCommonTitle(loginMode, friendlyAppName, commonLoginStrings);
  const { fidoLinkText, fidoDialogHeader, fidoDialogDescription } =
    useFidoProperties(commonLoginStrings);
  const unsafeLoginDescription = htmlUnescape(loginDescription);
  // sign up link related properties
  const signupLinkText = showSignup && !doIfExists && !checkProofForAliases ? loginSignupLink : "";

  const canGoBack = useBackButtonControl();

  return {
    canGoBack,
    fidoDialogDescription,
    fidoDialogHeader,
    fidoLinkText,
    header,
    loginDescription: unsafeLoginDescription,
    placeholder,
    signInOptionsText,
    signupLinkAriaLabel,
    signupLinkText,
    title,
    username: user?.username?.safeHtmlEscapedString,
  };
};
