import React, { useContext } from "react";
import { LinkButton } from "../../../../components/link-button";
import ProgressIndicatorFabric, {
  ProgressIndicatorContainer,
} from "../../../../components/loading-progress/fabric/progress-indicator-fabric";
import { TitleFabric } from "../../../../components/title/fabric/title-fabric";
import { FlowId, ViewId } from "../../../../constants/routing-constants";
import { useActivateView } from "../../../../hooks/use-activate-view";
import { useDocumentTitle } from "../../../../hooks/use-document-title";
import { useStaticFabricStyles } from "../../../../styles/fabric/fabric.styles";
import { commonLoginStringsFabric } from "../../fabric/common-login-strings-fabric";
import { LoginContext } from "../../login-context";
import { useIdpRedirectViewProperties } from "../hooks/use-idp-redirect-view-properties";
import { useRedirectTimeout } from "../hooks/use-redirect-timeout";

/**
 * IdpRedirectViewFabric component
 * This view is shown when
 * 1. the user is switching IDPs such as changing from AAD to MSA or vice versa
 * 2. redirecting the user to a federated login page.
 * @returns A rendered instance of this component
 */
export const IdpRedirectViewFabric: React.FC = function IdpRedirectViewFabric() {
  // Global context data
  const {
    viewState: { idpRedirectProvider },
  } = useContext(LoginContext);

  const { documentTitle, lightboxTitle } = useIdpRedirectViewProperties({
    commonLoginStrings: commonLoginStringsFabric,
  });
  const cancelRedirectClickHandler = useRedirectTimeout();

  useActivateView(ViewId.IdpRedirect, FlowId.Login, { showIdentityBanner: false });
  useDocumentTitle(documentTitle);
  useStaticFabricStyles();

  return (
    <>
      <div data-test-redirect-provider-id={idpRedirectProvider}>
        <TitleFabric titleId="loginHeader" title={lightboxTitle} />
      </div>

      <ProgressIndicatorContainer>
        <ProgressIndicatorFabric />
      </ProgressIndicatorContainer>

      <LinkButton
        linkId="redirectCancel"
        text={getLocalString("General_Buttons_Cancel")}
        ariaDescribedBy="loginHeader"
        onClick={cancelRedirectClickHandler}
        hasFocus
      />
    </>
  );
};
