import React, { useContext } from "react";
import { makeStyles, mergeClasses } from "@griffel/react";
import { ExternalClassName, LayoutTemplateType } from "../constants/constants";
import { GlobalContext } from "../global-context";
import { MOBILE_LAYOUT_SIZE } from "../styles/fabric/constants-fabric.styles";

export const useBaseBackgroundStyles = makeStyles({
  baseBackground: {
    top: 0,
    width: "100%",
    height: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",

    [MOBILE_LAYOUT_SIZE]: { display: "none" },
  },

  normalBackground: {
    position: "fixed",
    left: 0,
  },

  verticalSplitBackground: {
    position: "absolute",
  },

  alwaysShow: {
    [MOBILE_LAYOUT_SIZE]: { display: "inherit" },
  },

  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.55)",
    filter:
      "progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#8C000000', endColorstr='#8C000000')",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
});

/**
 * This method adds necessary app branding overrides to styles as needed to avoid redundant logic
 * @param alwaysShowBackground - Provide globalState.styles.useAppBranding
 * @param layoutTemplate - Provide globalState.styles.layoutTemplate
 * @returns Background styles to use directly on background elements
 */
export const useBackgroundStyles = function useBackgroundStyles(
  alwaysShowBackground: boolean,
  layoutTemplate: LayoutTemplateType,
) {
  const styles = useBaseBackgroundStyles();
  return {
    ...styles,
    background: mergeClasses(
      styles.baseBackground,
      layoutTemplate === LayoutTemplateType.VerticalSplit
        ? styles.verticalSplitBackground
        : styles.normalBackground,
      alwaysShowBackground ? styles.alwaysShow : "",
    ),
  };
};

/**
 * Background image component
 *  - Returns null if no `backgroundImageUrl` is defined in `globalState.styles`
 *  - Contains a background image div which flexes styling based on `globalState.styles.alwaysShowBackground`
 *  - May contain a background overlay / image mask based on `globalState.styles.userBackgroundImageMask`
 * @returns The React component or null
 */
export const BackgroundImage: React.FC = function BackgroundImage() {
  const {
    globalState: {
      styles: { backgroundImageUrl, alwaysShowBackground, layoutTemplate, useBackgroundImageMask },
    },
  } = useContext(GlobalContext);
  const backgroundStyles = useBackgroundStyles(alwaysShowBackground, layoutTemplate);

  if (!backgroundImageUrl) {
    return null;
  }

  const backgroundImageClassNames = mergeClasses(
    backgroundStyles.background,
    ExternalClassName.backgroundImage,
  );
  const overlayClassNames = mergeClasses(
    backgroundStyles.overlay,
    ExternalClassName.backgroundOverlay,
  );

  return (
    <div
      data-testid="backgroundImage"
      id="background-image"
      className={backgroundImageClassNames}
      style={{
        backgroundImage: `url(${backgroundImageUrl})`,
      }}
    >
      {useBackgroundImageMask ? (
        <div data-testid="backgroundOverlay" className={overlayClassNames} />
      ) : null}
    </div>
  );
};
