import { type ICommonLoginStrings } from "../login-interface";

export const commonLoginStringsFabric: ICommonLoginStrings = {
  switchAccountsPageTitle: getLocalString("SwitchAccounts_PageTitle"),
  signInPageTitle: getLocalString("SignIn_PageTitle"),
  signInToAppTokenized: getLocalString("Login_SignInToApp"),
  toContinueToApp: getLocalString("Login_ToContinueToApp"),
  continueToApp: getLocalString("Login_ContinueToApp"),
  fidoHelpDialogDesc2: getLocalString("Login_HelpDialog_Fido_Desc2"),
  loginCredPickerOptionFidoNoHelloKnownUser: getLocalString(
    "Login_CredPicker_Option_Fido_NoHello_KnownUser",
  ),
  loginCredPickerOptionFidoNoHello: getLocalString("Login_CredPicker_Option_Fido_NoHello"),
  loginCredPickerOptionFidoKnownUser: getLocalString("Login_CredPicker_Option_Fido_KnownUser"),
  loginCredPickerOptionFido: getLocalString("Login_CredPicker_Option_Fido"),
  fidoHelpWithSecurityKey: getLocalString("Login_CredPicker_Option_Help_Fido_NoHello"),
  fidoHelpWithWindowsHelloAndSecurityKey: getLocalString("Login_CredPicker_Option_Help_Fido"),
  fidoHelpDescWithWindowsHelloAndSecurityKey: getLocalString("Login_HelpDialog_Fido_Desc"),
  fidoHelpDescWithSecurityKey: getLocalString("Login_HelpDialog_Fido_Desc_NoHello"),
  invalidMemberName: getLocalString("SignIn_Error_EmailMissing"),
  incorrectOneTimeCodeError: getLocalString("IncorrectOTC_Error"),
  wrongPasswordError: getLocalString("Login_WrongPasswordError"),
  wrongPasswordLockedUserError: getLocalString("Login_WrongPasswordError_LockedUser"),
  oldSkypePasswordError: getLocalString("Login_Password_OldSkypePasswordError"),
  aliasdisabledError: getLocalString("Login_AliasDisabled_Error"),
  fedNotAllowedError: getLocalString("Login_FederationNotAllowed_Error"),
  missingPasswordError: getLocalString("Login_MissingPassword_Error"),
  linkedInFedUserNotFoundError: getLocalString("Login_FedUserNotFoundError_LinkedIn"),
  googleFedUserNotFoundError: getLocalString("Login_FedUserNotFoundError_Google"),
  githubFedUserNotFoundError: getLocalString("Login_FedUserNotFoundError_GitHub"),
  evictedCredPickerLinkText: getLocalString("Login_SwitchToCredPicker_Link_EvictedAcct"),
  phoneDisambiguationLinkText: getLocalString("Login_PhoneDisambiguation_Link"),
  signInDifferentAccount: getLocalString("Login_SignInWithAnotherAccount"),
};
