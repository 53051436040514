import React, { type FormEvent, useContext } from "react";
import { LinkButton } from "../../../../components/link-button";
import { PhoneNumber } from "../../../../components/phone-number";
import {
  TextButtonContainer,
  TextButtonFabric,
} from "../../../../components/text-button/fabric/text-button-fabric";
import { TitleFabric } from "../../../../components/title/fabric/title-fabric";
import { FlowId, ViewId } from "../../../../constants/routing-constants";
import { GlobalContext } from "../../../../global-context";
import { useActivateView } from "../../../../hooks/use-activate-view";
import { useDocumentTitle } from "../../../../hooks/use-document-title";
import { useNavigateDirection } from "../../../../hooks/use-navigate-direction";
import { usePhoneNumber } from "../../../../hooks/use-phone-number";
import useFabricStyles, { useStaticFabricStyles } from "../../../../styles/fabric/fabric.styles";
import { getPhoneInputValidation } from "../../../../utilities/phone-number-helper";
import { SecondaryContentContainerFabric } from "../../components/fabric/secondary-content-container-fabric";
import { commonLoginStringsFabric } from "../../fabric/common-login-strings-fabric";
import { useSignupClickHandler } from "../../hooks/login-hooks";
import LoginConfig from "../../login-config";
import {
  useGctRequest,
  useGetDefaultCountry,
  usePhoneDisambiguationStrings,
  useSignInDifferentUserHandler,
} from "../hooks/phone-disambiguation-view-hooks";

/**
 * PhoneDisambiguationView component
 * @returns A rendered instance of this component
 */
export const PhoneDisambiguationViewFabric: React.FC = function PhoneDisambiguationViewFabric() {
  const {
    documentTitle,
    errorMessage,
    title,
    description,
    placeholder,
    ariaLabel,
    signInWithDifferentUser,
    createNewAccountLabel,
    backLabel,
    nextLabel,
  } = usePhoneDisambiguationStrings({ commonLoginStrings: commonLoginStringsFabric });

  const defaultCountry = useGetDefaultCountry();

  useActivateView(ViewId.PhoneDisambiguation, FlowId.Login, { showIdentityBanner: false });
  useDocumentTitle(documentTitle);
  useStaticFabricStyles();

  const { useInlinePhoneNumber, countryList, signupUrl, showSignup } = LoginConfig.instance;

  const {
    globalState: { user },
  } = useContext(GlobalContext);

  const {
    focus,
    inputValue,
    dropdownValue,
    showError,
    externalError,
    onBlur,
    onFocus,
    onFormSubmission,
    validationError,
    phoneNumberChangeHandler,
    phoneNumberValue,
    errorHandler,
  } = usePhoneNumber({
    defaultCountry,
    initialInputValue: user.username.unsafeUnescapedString,
    inputValidationFunc: getPhoneInputValidation(errorMessage),
  });

  const fabricStyles = useFabricStyles();
  const gtcRequest = useGctRequest();
  const navigate = useNavigateDirection();
  const submitProps = { value: phoneNumberValue, inputValue, dropdownValue, errorHandler };
  const onSubmit = (e: FormEvent<HTMLFormElement>) =>
    onFormSubmission(gtcRequest, ViewId.PhoneDisambiguation, submitProps)(e);
  const onSignInDifferentUserClick = useSignInDifferentUserHandler();
  const onSignupClicked = useSignupClickHandler();

  return (
    <form
      name="phoneDisambiguationForm"
      data-testid="phoneDisambiguationForm"
      noValidate
      spellCheck="false"
      method="post"
      autoComplete="false"
      onSubmit={onSubmit}
    >
      <TitleFabric
        titleId="phoneDisambiguationTitle"
        title={title}
        descriptionId="phoneDisambiguationDescription"
        description={description}
      />
      <PhoneNumber
        phoneNumberChangeHandler={phoneNumberChangeHandler}
        errorMessage={validationError || externalError}
        dropdownProps={{
          useInlinePhoneNumber,
          defaultCountryData: dropdownValue,
          countryData: countryList,
        }}
        inputProps={{
          id: "i0116",
          name: "loginfmt",
          placeholder,
          type: "tel",
          showErrorInline: showError,
          externalError,
          value: inputValue,
          ariaLabel,
          ariaDescribedBy: "phoneDisambiguationTitle phoneDisambiguationDescription",
          hasFocus: focus,
          hasInitialFocus: true,
          focusHandler: onFocus,
          blurHandler: onBlur,
        }}
      />
      <div className={fabricStyles.buttonMargin}>
        <div className={fabricStyles.row}>
          <SecondaryContentContainerFabric>
            <div className={fabricStyles.formGroup}>
              <LinkButton
                linkId="signinWithDifferentUsername"
                text={signInWithDifferentUser}
                onClick={() => onSignInDifferentUserClick(inputValue)}
              />
            </div>
            {showSignup && !!signupUrl && (
              <div className={fabricStyles.formGroup}>
                <LinkButton
                  text={createNewAccountLabel}
                  onClick={() => onSignupClicked(phoneNumberValue)}
                  linkId="createAccount"
                />
              </div>
            )}
          </SecondaryContentContainerFabric>
        </div>

        <div className={fabricStyles.row}>
          <TextButtonContainer>
            <TextButtonFabric
              buttonId="backButton"
              label={backLabel}
              isPrimary={false}
              isButtonType
              ariaLabel={backLabel}
              onClick={() => navigate(ViewId.PhoneDisambiguation, -1)}
            />
            <TextButtonFabric
              buttonId="nextButton"
              label={nextLabel}
              isPrimary
              isButtonType={false}
              ariaLabel={nextLabel}
            />
          </TextButtonContainer>
        </div>
      </div>
    </form>
  );
};
