import { makeStyles } from "@griffel/react";

export const usePhoneNumberStyles = makeStyles({
  phoneNumberContainer: {
    float: "left",
    position: "relative",
    minHeight: "1px",
    paddingLeft: "2px",
    paddingRight: "2px",
    width: "100%",
    marginBottom: "9px",
  },
  inlineInput: {
    display: "inline-block",
    paddingLeft: "16px",
    width: "79.16667%",
    float: "left",
    position: "relative",
    minHeight: "1px",
    paddingRight: "2px",
    ":global([dir='rtl'])": {
      paddingRight: "16px",
      paddingLeft: "0px",
      float: "right",
    },
  },
  nonInlineInput: {
    width: "100%",
    float: "left",
    position: "relative",
    minHeight: "1px",
    paddingRight: "2px",
  },
  errorContainer: {
    position: "relative",
    minHeight: "1px",
    paddingLeft: "2px",
  },
});
