import { useContext, useEffect, useState } from "react";
import { AuthenticationContext } from "../../authentication-context";
import { AuthenticationActionType } from "../../authentication-reducer";
import { CredentialType } from "../../constants/constants";
import { ViewId } from "../../constants/routing-constants";
import { useFidoPostRedirect } from "../../flows/login/hooks/login-hooks";
import { LoginContext } from "../../flows/login/login-context";
import { LoginActionType } from "../../flows/login/login-reducer";
import globalConfig from "../../global-config";
import { GlobalContext } from "../../global-context";
import { useNavigateDirection } from "../../hooks/use-navigate-direction";
import {
  type OneTimeCodeCredential,
  type OneTimeCodeProof,
  type UserCredential,
  ProofType,
} from "../../types/credential-types";
import { proofTypeToChannel } from "../../utilities/api-helpers/one-time-code/get-one-time-code-form";
import {
  type GetOtcParams,
  getOneTimeCode,
  getProofConfirmation,
} from "../../utilities/api-helpers/one-time-code/get-one-time-code-helper";
import {
  type OtcFailureParams,
  type OtcSuccessParams,
  OtcPurposes,
  OtcStatus,
} from "../../utilities/api-helpers/one-time-code/one-time-code-types";
import { isPlatformAuthenticatorAvailable } from "../../utilities/browser-helper";
import { getRouteFromViewId } from "../../utilities/routing-helper";
import { replaceTokens } from "../../utilities/strings-helper";
import { type UntrustedExternalInputText } from "../../utilities/untrusted-external-input-text";
import { type ICredentialSwitchLinkProperties } from "./credential-switch-links";

/**
 * @param isPlatformFidoSupported flag to indicate whether fido platform credentials are supported
 * @returns map of credential type to the properties to be used in the credential switch link
 */
export const getSupportedCredentialMap = (
  isPlatformFidoSupported: boolean,
): {
  [key in CredentialType]?: ICredentialSwitchLinkProperties;
} => {
  const credentialMap: { [key in CredentialType]?: ICredentialSwitchLinkProperties } = {};

  credentialMap[CredentialType.Password] = {
    viewId: ViewId.Password,
    linkId: "idA_PWD_SwitchToPassword",
    linkText: getLocalString("Login_SwitchToPasswordLink"),
  };

  credentialMap[CredentialType.RemoteNGC] = {
    viewId: ViewId.RemoteNgc,
    linkId: "idA_PWD_SwitchToRemoteNGC",
    // @TODO - use localization string
    linkText: "Use my Microsoft Authenticator app",
  };

  credentialMap[CredentialType.Fido] = {
    linkId: "idA_PWD_SwitchToFido",
    linkText: isPlatformFidoSupported
      ? getLocalString("Login_CredPicker_Option_Fido")
      : getLocalString("Login_CredPicker_Option_Fido_NoHello"),
  };

  credentialMap[CredentialType.Certificate] = {
    linkId: "idA_PWD_SwitchToCertificate",
    // @TODO - use localization string
    linkText: "Use a certificate or smart card",
  };

  credentialMap[CredentialType.OtherMicrosoftIdpFederation] = {
    linkId: "useMicrosoftLink",
    // @TODO - use localization string
    linkText: "Sign in with Microsoft",
  };

  credentialMap[CredentialType.LinkedIn] = {
    linkId: "useLinkedInLink",
    linkText: getLocalString("Login_UseLinkedIn_Link"),
  };

  credentialMap[CredentialType.GitHub] = {
    linkId: "useGitHubLink",
    linkText: getLocalString("Login_UseGitHub_Link"),
  };

  credentialMap[CredentialType.Google] = {
    linkId: "useGoogleLink",
    linkText: getLocalString("Login_UseGoogle_Link"),
  };

  credentialMap[CredentialType.Facebook] = {
    linkId: "useFacebookLink",
    linkText: getLocalString("Login_UseFacebook_Link"),
  };

  credentialMap[CredentialType.Federation] = {
    // @TODO - add viewId: ViewId.IdpRedirect,
    linkId: "redirectToIdpLink",
    // @TODO - use localization string
    linkText: "Sign in using my password",
  };

  credentialMap[CredentialType.RemoteLogin] = {
    // @TODO - add viewId: ViewId.RemoteLoginPolling,
    linkId: "remoteLoginLink",
    // @TODO - use localization string
    linkText: "Sign in from another device",
  };

  credentialMap[CredentialType.OneTimeCode] = {
    viewId: ViewId.OneTimeCode,
    linkId: "otcLoginLink",
    // @TODO - use localization string
    linkText: "Sign in with a single-use code",
  };

  credentialMap[CredentialType.AccessPass] = {
    // @TODO - add viewId: ViewId.AccessPass,
    linkId: "accessPassLink",
    // @TODO - use localization string
    linkText: "Use your Temporary Access Pass instead",
  };

  return credentialMap;
};

/**
 * build request params for the one time code API call
 * @param selectedCredential credential selected for the switch link
 * @param flowToken flow token
 * @param username current user (if available)
 * @param onSuccess success handler for the OTC call
 * @param onFailure failure handler for the OTC call
 * @returns built params object
 */
export const getOneTimeCodeRequestParams = (
  selectedCredential: OneTimeCodeCredential,
  flowToken: string,
  username: UntrustedExternalInputText,
  onSuccess: (successParams: OtcSuccessParams) => void,
  onFailure: (failureParams: OtcFailureParams) => void,
) => {
  const proof: OneTimeCodeProof = selectedCredential.proof!;

  const params: GetOtcParams = {
    username,
    flowToken,
    isEncrypted: proof.isEncrypted,
    proofData: proof.data,
    proofType: proof.type,
    purpose: proof.isNopa ? OtcPurposes.noPassword : OtcPurposes.otcLogin,
    channel: proofTypeToChannel(proof.type)!,
    onSuccess,
    onFailure,
  };

  if (selectedCredential.proof?.isEncrypted) {
    params.proofConfirmation = getProofConfirmation(
      selectedCredential,
      selectedCredential.proof.type,
    );
  }

  return params;
};

/**
 * @param sourceViewId view where the credential switch links component resides
 * @returns click handler for when credential picker link is clicked
 */
export const useCredentialPickerClickHandler = (sourceViewId: ViewId) => {
  const navigator = useNavigateDirection();

  return () => {
    navigator(sourceViewId, ViewId.CredentialPicker);
  };
};

/**
 * @param sourceViewId view where the credential switch links component resides
 * @param selectedCredential credential selected for the switch link
 * @param supportedCredentialMap map for all the supported credentials
 * @param setErrorMessage callback for setting error message when error occurs in one time code call
 * @param setRequestPendingFlag callback for informing wrapper component when an external request is in progress
 * @param shouldUpdateOtcCredential flag that indicates whether to update the `otcCredential` login context property. This should usually be set to true
 * unless the current view does not need to store the next one-time code credential - for example when it redirects to a different flow or website
 * @returns handler for when credential switch link is clicked
 */
export const useCredentialSwitchClickHandler = (
  sourceViewId: ViewId,
  selectedCredential: UserCredential | undefined,
  supportedCredentialMap: { [key in CredentialType]?: ICredentialSwitchLinkProperties },
  setErrorMessage: (message: string) => void,
  setRequestPendingFlag: (isRequestPending: boolean) => void,
  shouldUpdateOtcCredential?: boolean,
) => {
  const navigator = useNavigateDirection();
  const fidoRedirectHandler = useFidoPostRedirect();
  const {
    globalState: {
      user: { username },
    },
  } = useContext(GlobalContext);

  const {
    authState: { flowTokenValue },
    dispatchStateChange,
  } = useContext(AuthenticationContext);

  const { dispatchStateChange: dispatchLoginStateChange } = useContext(LoginContext);

  const updateOtcCredential = () => {
    if (shouldUpdateOtcCredential) {
      dispatchLoginStateChange({
        type: LoginActionType.UpdateCredentials,
        payload: {
          otcCredential: {
            ...selectedCredential,
            credentialType: CredentialType.OneTimeCode,
            proof: (selectedCredential as OneTimeCodeCredential).proof,
          },
        },
      });
    }
  };

  const otcRequestSuccessHandler = (successParams: OtcSuccessParams) => {
    setRequestPendingFlag(false);
    if (successParams?.flowToken) {
      dispatchStateChange({
        type: AuthenticationActionType.SetFlowTokenValue,
        payload: successParams.flowToken,
      });
    }

    updateOtcCredential();
    navigator(sourceViewId, getRouteFromViewId(ViewId.OneTimeCode));
  };

  const otcRequestFailureHandler = (failureParams: OtcFailureParams) => {
    const errorId: number = failureParams.otcStatus || -1;

    setRequestPendingFlag(false);
    if (failureParams?.flowToken) {
      dispatchStateChange({
        type: AuthenticationActionType.SetFlowTokenValue,
        payload: failureParams.flowToken,
      });
    }

    if (errorId === OtcStatus.ftError) {
      setErrorMessage(getLocalString("OneTimeCode_SessionExpiredError"));
    } else {
      setErrorMessage(getLocalString("OneTimeCode_SendFailedError"));
    }
  };

  return async () => {
    const credentialType =
      (selectedCredential && selectedCredential.credentialType) || CredentialType.Password;

    // clear error before another call
    setErrorMessage("");

    switch (credentialType) {
      case CredentialType.OneTimeCode:
        if (selectedCredential?.proof?.clearDigits) {
          updateOtcCredential();
          navigator(sourceViewId, getRouteFromViewId(ViewId.ProofConfirmation));
        } else {
          const oneTimeCodeCredential = selectedCredential as OneTimeCodeCredential;
          const otcParams = getOneTimeCodeRequestParams(
            oneTimeCodeCredential,
            flowTokenValue,
            username,
            otcRequestSuccessHandler,
            otcRequestFailureHandler,
          );

          setRequestPendingFlag(true);
          await getOneTimeCode(otcParams);
        }

        break;
      case CredentialType.Fido:
        fidoRedirectHandler();
        break;
      case CredentialType.OtherMicrosoftIdpFederation:
      case CredentialType.LinkedIn:
      case CredentialType.GitHub:
      case CredentialType.Google:
      case CredentialType.Facebook:
      case CredentialType.Certificate:
        // @TODO - update with the latest redirect implementation when available
        break;
      default:
        navigator(
          sourceViewId,
          getRouteFromViewId(supportedCredentialMap[credentialType]!.viewId!),
        );
    }
  };
};

/**
 *
 * @param availableCredentials credentials available to the user
 * @param currentCredential credential the user is currently using
 * @param supportedCredentialMap map for all the supported credentials
 * @returns a list of alternative credentials (available credentials - current credential) can be used by the user
 */
const getAlternativeCredentials = (
  availableCredentials: UserCredential[],
  currentCredential: UserCredential,
  supportedCredentialMap: { [key in CredentialType]?: ICredentialSwitchLinkProperties },
) => {
  const alternativeCredentials: UserCredential[] = [];

  // go through the list of credentials available to the user
  // and remove the credential user is currently using
  availableCredentials.forEach((credential) => {
    if (supportedCredentialMap[credential.credentialType]) {
      const credentialMatched = credential.credentialType === currentCredential?.credentialType;
      const isCredTypeOtc = credential.credentialType === CredentialType.OneTimeCode;
      const proofDataMatch =
        credentialMatched &&
        isCredTypeOtc &&
        credential.proof!.data === currentCredential.proof!.data;
      const proofTypesMatch =
        credentialMatched &&
        isCredTypeOtc &&
        credential.proof!.type === currentCredential.proof!.type;

      // there could be multiple one time code credentials available, need to check
      // proof data to verify that it's not the one user is currently using
      if (
        !credentialMatched ||
        (isCredTypeOtc && !proofDataMatch) ||
        (isCredTypeOtc && !proofTypesMatch)
      ) {
        alternativeCredentials.push(credential);
      }
    }

    if (credential.credentialType === CredentialType.OfflineAccount) {
      alternativeCredentials.push({
        credentialType: CredentialType.OfflineAccount,
        shownOnlyOnPicker: true,
      });
    }
  });

  return alternativeCredentials;
};

/**
 * @param sourceViewId parent view of the component
 * @param currentCredential credential the user is currently using
 * @param alternativeCredentials list of alternative credentials available to user
 * @param showForgotUsername flag that indicates whether to show forgot username link
 * @returns all properties needed for displaying credential picker link
 */
const useCredentialPickerLinkProperties = (
  sourceViewId: ViewId,
  currentCredential: UserCredential,
  alternativeCredentials: UserCredential[],
  showForgotUsername?: boolean,
) => {
  // when the current credential is known, user has been identified
  const isUserKnown = !!currentCredential?.credentialType;
  const { showCredentialPickerAsButton: showSignInOptionsAsButton } = globalConfig.instance;
  const credentialPickerLinkText = isUserKnown
    ? getLocalString("Login_SwitchToCredPicker_Link")
    : getLocalString("Login_CredPicker_Title_NoUser");

  // When the feature to show credential picker link on username view as a tile is false,
  // credential picker link will be shown only when user information is present AND
  // when alternate credentials are > 1 OR when UX can display forgot username link OR when
  // the credential can be displayed only on the credential picker view
  const showCredentialPickerLink =
    (!showSignInOptionsAsButton || isUserKnown) &&
    (alternativeCredentials.length > 1 ||
      (alternativeCredentials.length === 1 &&
        (!!showForgotUsername || !!alternativeCredentials[0].shownOnlyOnPicker)));

  return {
    showCredentialPickerLink,
    credentialPickerLinkText,
    credentialPickerLinkOnClickHandler: useCredentialPickerClickHandler(sourceViewId),
  };
};

/**
 * @param sourceViewId parent view of the component
 * @param alternativeCredentials list of alternative credentials available to user
 * @param setErrorMessage handler for one time code request errors
 * @param setRequestPendingFlag callback for informing wrapper component when an external request is in progress
 * @param supportedCredentialMap map for all the supported credentials
 * @param showForgotUsername flag that indicates whether to show forgot username link
 * @param shouldUpdateOtcCredential flag that indicates whether to update the `otcCredential` login context property. This should usually be set to true
 * unless the current view does not need to store the next one-time code credential - for example when it redirects to a different flow or website
 * @returns all properties needed for displaying credential switch link
 */
const useCredentialSwitchLinkProperties = (
  sourceViewId: ViewId,
  alternativeCredentials: UserCredential[],
  setErrorMessage: (message: string) => void,
  setRequestPendingFlag: (isRequestPending: boolean) => void,
  supportedCredentialMap: { [key in CredentialType]?: ICredentialSwitchLinkProperties },
  showForgotUsername?: boolean,
  shouldUpdateOtcCredential?: boolean,
) => {
  let credentialSwitchLinkId = "";
  let credentialSwitchLinkText = "";
  let showCredentialSwitchLink = false;
  const selectedCredential = alternativeCredentials[0];

  // when the alternative credentials list only contains one choice
  // then we will display a convenient link that would redirect the
  // user to that option
  if (alternativeCredentials.length === 1 && selectedCredential) {
    const credentialToSwitchTo =
      supportedCredentialMap[selectedCredential.credentialType] ||
      supportedCredentialMap[CredentialType.Password];

    credentialSwitchLinkId = credentialToSwitchTo!.linkId;
    credentialSwitchLinkText = credentialToSwitchTo!.linkText;

    // special text for one time code, depending where the code was sent
    if (
      selectedCredential.credentialType === CredentialType.OneTimeCode &&
      selectedCredential.proof?.display
    ) {
      switch (selectedCredential.proof?.type) {
        case ProofType.Email:
          // @TODO - use localization string
          credentialSwitchLinkText = replaceTokens(
            "Email code to {0}",
            selectedCredential.proof.display,
          );
          break;
        case ProofType.SMS:
          // @TODO - use localization string
          credentialSwitchLinkText = replaceTokens(
            "Text code to {0}",
            selectedCredential.proof.display,
          );
          break;
        case ProofType.Voice:
          // @TODO - use localization string
          credentialSwitchLinkText = replaceTokens(
            "Call {0} with a code",
            selectedCredential.proof.display,
          );
          break;
        default:
        // do nothing
      }
    }

    showCredentialSwitchLink =
      alternativeCredentials.length === 1 &&
      !showForgotUsername &&
      !selectedCredential.shownOnlyOnPicker;
  }

  return {
    showCredentialSwitchLink,
    credentialSwitchLinkId,
    credentialSwitchLinkText,
    credentialSwitchLinkOnClickHandler: useCredentialSwitchClickHandler(
      sourceViewId,
      selectedCredential,
      supportedCredentialMap,
      setErrorMessage,
      setRequestPendingFlag,
      shouldUpdateOtcCredential,
    ),
  };
};

/**
 * the main hook for credential switch links that returns all the necessary
 * properties for the component to render
 * @param sourceViewId parent view of the component
 * @param availableCredentials credentials available to the user
 * @param currentCredential credential the user is currently using
 * @param setRequestPendingFlag callback for informing wrapper component when an external request is in progress
 * @param showForgotUsername flag that indicates whether to show forgot username link
 * @param shouldUpdateOtcCredential flag that indicates whether to update the `otcCredential` login context property. This should usually be set to true
 * unless the current view does not need to store the next one-time code credential - for example when it redirects to a different flow or website
 * @returns all properties needed for rendering credential switch links component
 */
export const useCredentialSwitchLinks = (
  sourceViewId: ViewId,
  availableCredentials: UserCredential[],
  currentCredential: UserCredential,
  setRequestPendingFlag: (isRequestPending: boolean) => void,
  showForgotUsername?: boolean,
  shouldUpdateOtcCredential?: boolean,
) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isPlatformFidoSupported, setIsPlatformSupported] = useState(false);
  const [readyToRender, setReadyToRender] = useState(false);

  useEffect(() => {
    isPlatformAuthenticatorAvailable().then((isAvailable) => {
      setIsPlatformSupported(isAvailable);
      setReadyToRender(true);
    });
  });

  const supportedCredentialMap = getSupportedCredentialMap(isPlatformFidoSupported);

  const alternativeCredentials = getAlternativeCredentials(
    availableCredentials,
    currentCredential,
    supportedCredentialMap,
  );

  const { showCredentialPickerLink, credentialPickerLinkText, credentialPickerLinkOnClickHandler } =
    useCredentialPickerLinkProperties(
      sourceViewId,
      currentCredential,
      alternativeCredentials,
      showForgotUsername,
    );

  const {
    showCredentialSwitchLink,
    credentialSwitchLinkId,
    credentialSwitchLinkText,
    credentialSwitchLinkOnClickHandler,
  } = useCredentialSwitchLinkProperties(
    sourceViewId,
    alternativeCredentials,
    setErrorMessage,
    setRequestPendingFlag,
    supportedCredentialMap,
    showForgotUsername,
    shouldUpdateOtcCredential,
  );

  return {
    errorMessage,
    showCredentialSwitchLink,
    credentialSwitchLinkId,
    credentialSwitchLinkText,
    credentialSwitchLinkOnClickHandler,
    showCredentialPickerLink,
    credentialPickerLinkText,
    credentialPickerLinkOnClickHandler,
    readyToRender,
  };
};
