import { useContext } from "react";
import { ViewId } from "../../../../constants/routing-constants";
import { type RedirectPostParams } from "../../../../utilities/api-helpers/get-credential-type-helper";
import { LoginContext } from "../../login-context";
import { useOnSwitchView } from "./use-on-switch-view";

/**
 * @param redirectingToIdp flag indicating whether the user is being redirected to another IDP
 * @param idpRedirectUrl the IDP redirect url
 * @param idpRedirectPostParams the IDP redirect post params
 * @param unsafeDesktopSsoDomain the unsafe desktop SSO domain
 * @returns a handler for when the select account link button is clicked
 */
export const useSelectAccountLinkClickHandler = (
  redirectingToIdp: boolean,
  idpRedirectUrl: string,
  idpRedirectPostParams: RedirectPostParams,
  unsafeDesktopSsoDomain: string,
): (() => void) => {
  // Login flow context data
  const {
    viewState: {
      credentials: { sessions },
    },
  } = useContext(LoginContext);

  const onSwitchView = useOnSwitchView();

  return () => {
    onSwitchView(
      sessions.length ? ViewId.AccountPicker : ViewId.Username,
      redirectingToIdp,
      idpRedirectUrl,
      idpRedirectPostParams,
      unsafeDesktopSsoDomain,
    );
  };
};
