export enum ViewId {
  None = "None",
  AccountPicker = "AccountPicker",
  AccountTransferError = "AccountTransferError",
  AccountTransferOtc = "AccountTransferOtc",
  AccountTransferQrCode = "AccountTransferQrCode",
  AcwAddEmail = "AcwAddEmail",
  AcwAddPhone = "AcwAddPhone",
  AppStoreRedirect = "AppStoreRedirect",
  ConfirmSend = "ConfirmSend",
  CredentialPicker = "CredentialPicker",
  EnterRecoveryCode = "EnterRecoveryCode",
  Error = "Error",
  FetchSessionsProgress = "FetchSessionsProgress",
  Fido = "Fido",
  IdpDisambiguation = "IdpDisambiguation",
  IdpRedirect = "IdpRedirect",
  IdpRedirectSpeedbump = "IdpRedirectSpeedbump",
  Kmsi = "Kmsi",
  NewPassword = "NewPassword",
  OneTimeCode = "OneTimeCode",
  OwnershipChallenge = "OwnershipChallenge",
  Password = "Password",
  PhoneDisambiguation = "PhoneDisambiguation",
  ProofConfirmation = "ProofConfirmation",
  RecoveryCode = "RecoveryCode",
  RemoteConnectCanaryValidation = "RemoteConnectCanaryValidation",
  RemoteNgc = "RemoteNgc",
  ResetPassword = "ResetPassword",
  ResetPasswordSignInName = "ResetPwdSignInName",
  ResetPasswordSuccess = "ResetPasswordSuccess",
  SetupAuthenticator = "SetupAuthenticator",
  SignInOptions = "SignInOptions",
  SignUp = "SignUp",
  TransferLogin = "TransferLogin",
  Username = "Username",
  ValidateAccount = "ValidateAccount",
  ViewAgreement = "ViewAgreement",
}

export enum FlowId {
  None = "None",
  AccountTransfer = "AccountTransfer",
  Acw = "Acw",
  AppStoreRedirect = "AppStoreRedirect",
  Kmsi = "Kmsi",
  Login = "Login",
  OwnershipChallenge = "OwnershipChallenge",
  ResetPassword = "ResetPassword",
  ResetPasswordSignInName = "ResetPasswordSignInName",
  Signup = "Signup",
  TransferLogin = "TransferLogin",
}
