import React, { useContext, useState } from "react";
import { loginOption } from "../../../../constants/constants";
import { QueryString } from "../../../../constants/querystring-constants";
import { FlowId, ViewId } from "../../../../constants/routing-constants";
import { useEffectOnce } from "../../../../hooks/use-effect-once";
import { PostRedirectContext } from "../../../../post-redirect-context";
import { PostRedirectActionType } from "../../../../post-redirect-reducer";
import AgreementViewFabric from "../../../../views/fabric/agreement-view-fabric";
import { AccountPickerViewFabric } from "../../account-picker/fabric/account-picker-view-fabric";
import { ConfirmSendViewFabric } from "../../confirm-send/fabric/confirm-send-view-fabric";
import { CredentialPickerViewFabric } from "../../credential-picker/fabric/credential-picker-view-fabric";
import { ErrorViewFabric } from "../../error/fabric/error-view-fabric";
import { FetchSessionsProgressViewFabric } from "../../fetch-sessions-progress/fabric/fetch-sessions-progress-view-fabric";
import { useFidoPostRedirect, useLoginFlowRedirect } from "../../hooks/login-hooks";
import { IdpDisambiguationViewFabric } from "../../idp-disambiguation/fabric/idp-disambiguation-view-fabric";
import { IdpRedirectViewFabric } from "../../idp-redirect/fabric/idp-redirect-view-fabric";
import { IdpRedirectSpeedbumpViewFabric } from "../../idp-redirect-speedbump/fabric/idp-redirect-speedbump-view-fabric";
import LoginConfig from "../../login-config";
import { LoginMode, LoginState } from "../../login-constants";
import { LoginContext } from "../../login-context";
import { getNextActionFromPostedState, isForceSignIn } from "../../login-util";
import { OneTimeCodeViewFabric } from "../../one-time-code/fabric/one-time-code-view-fabric";
import { PhoneDisambiguationViewFabric } from "../../phone-disambiguation/fabric/phone-disambiguation-view-fabric";
import { ProofConfirmationViewFabric } from "../../proof-confirmation/fabric/proof-confirmation-view-fabric";
import { RemoteConnectCanaryValidationViewFabric } from "../../remote-connect-canary-validation/fabric/remote-connect-canary-validation-view-fabric";
import { RemoteNgcViewFabric } from "../../remote-ngc/fabric/remote-ngc-view-fabric";
import { PasswordViewFabric } from "../../views/password/fabric/password-view-fabric";
import { UsernameViewFabric } from "../../views/username";
import { useValidateExternalCanary } from "../hooks/login-initial-view-picker-hooks";

const fedStateRealmDiscoveryAutoredirect = 2; // fedState 2 represents FEDSTATE_REALMDISCOVERY_AUTOREDIRECT
let ipv6ExpChecked: boolean = false;

/**
 * @private
 */
export const getFedRedirectUrl = (username: string) => {
  const { fedQs, fedUrl, defaultLoginOptions } = LoginConfig.instance;
  const federationQs = new URLSearchParams(fedQs); // fedQs object from ServerData
  const federationUrlQs = new URL(fedUrl).searchParams; // the query string terms from urlFed from ServerData

  // Set the KMSI checkbox option for after federation
  if (defaultLoginOptions === loginOption.nothingChecked) {
    federationQs.set(
      QueryString.webServicesFederationContext,
      `LoginOptions=3&${federationQs.get(QueryString.webServicesFederationContext)}`,
    );
  }

  // Resetting these values to make sure we decode/encode the values. URL set and get do so implictly
  federationUrlQs.set(
    QueryString.cobrandingContext,
    federationUrlQs.get(QueryString.cobrandingContext) || "",
  );
  federationUrlQs.set(QueryString.username, username);
  federationUrlQs.set(QueryString.market, federationUrlQs.get(QueryString.market) || "");
  federationUrlQs.set(
    QueryString.languageCodeId,
    federationUrlQs.get(QueryString.languageCodeId) || "",
  );

  // Add the updated fedQs to the final federationUrlQs
  federationQs.forEach((value, key) => {
    federationUrlQs.set(key, value);
  });

  const federationUrl = new URL(fedUrl);
  federationUrl.search = federationUrlQs.toString();

  return federationUrl.href;
};

/**
 * Check if need to redirect
 * 1. For iFrame busting
 * 2. Federation
 * 3. If cookies are disabled, redirect to page to show messaging for it
 */
export const redirectIfNeeded = () => {
  const { fedState, fedUrl, noCookiesUrl } = LoginConfig.instance;

  // Check if the top.location is the same as the location.
  // Don't do this if we are in storybook since it is hosted within an iframe
  if (!IS_STORYBOOK && window.top !== window.self) {
    // We are in an iframe, try to bust out
    window?.top?.location.replace(window.self.location.href);
  }

  // Check if it is a Federation Redirection
  if (fedState === fedStateRealmDiscoveryAutoredirect && fedUrl) {
    const windowLocation = new URL(window.location.href);
    const federationUrl = getFedRedirectUrl(
      windowLocation.searchParams.get(QueryString.username) || "",
    );
    window.location.replace(federationUrl);
  }

  // Check if cookies are enabled
  if (!navigator.cookieEnabled) {
    window.location.replace(noCookiesUrl);
  }
};

/**
 * This experiment is to add an image tag on the DOM
 * it's data is used by the ISP team for their ML
 */
export const doAsyncIPv6ImageLoad = () => {
  ipv6ExpChecked = true; // Set to true to avoid re-running this code on re-renders, if any
  const { ipv6ExperimentUrl } = LoginConfig.instance;
  if (ipv6ExperimentUrl) {
    const img = new Image();
    img.src = ipv6ExperimentUrl;
    document.body.appendChild(img);
  }
};

/**
 * The view ID returned by the LoginStateToViewIdMap map is used by the initial view picker to choose the
 * corresponding View Component from this map. Please ensure that this map is complete, i.e. it contains
 * an entry for every view ID in the LoginStateToViewIdMap map where appropriate.
 */
export const ViewIdToViewMap: { [key: string]: JSX.Element } = {
  [ViewId.Username]: <UsernameViewFabric />,
  [ViewId.Password]: <PasswordViewFabric />,
  [ViewId.OneTimeCode]: <OneTimeCodeViewFabric />,
  [ViewId.RemoteNgc]: <RemoteNgcViewFabric />,
  [ViewId.PhoneDisambiguation]: <PhoneDisambiguationViewFabric />,
  [ViewId.IdpDisambiguation]: <IdpDisambiguationViewFabric />,
  [ViewId.IdpRedirect]: <IdpRedirectViewFabric />,
  [ViewId.ViewAgreement]: <AgreementViewFabric hostingFlow={FlowId.Login} />,
  [ViewId.AccountPicker]: <AccountPickerViewFabric />,
  [ViewId.ConfirmSend]: <ConfirmSendViewFabric />,
  [ViewId.CredentialPicker]: <CredentialPickerViewFabric />,
  [ViewId.Error]: <ErrorViewFabric />,
  [ViewId.IdpRedirectSpeedbump]: <IdpRedirectSpeedbumpViewFabric />,
  [ViewId.ProofConfirmation]: <ProofConfirmationViewFabric />,
  [ViewId.FetchSessionsProgress]: <FetchSessionsProgressViewFabric />,
};

/**
 * Inital view picker for the login flow
 * @returns The first view instance for the login flow
 */
export const LoginInitialViewPickerFabric: React.FC = function LoginInitialViewPickerFabric() {
  const {
    viewState: { credentials },
  } = useContext(LoginContext);
  const { dispatchStateChange: dispatchRedirectStateChange } = useContext(PostRedirectContext);

  const { loginMode, externalCanary, signupUrl, signupUrlPostParams } = LoginConfig.instance;
  const fidoRedirectCallback = useFidoPostRedirect();
  const loginFlowRedirect = useLoginFlowRedirect();

  // External canary validation happens asynchronously, so we need to use a state variable
  // to store the result and track when we're ready to render.
  const [viewAfterCanaryValidation, setViewAfterCanaryValidation] = useState(LoginState.Unknown);
  const invokeExternalCanaryValidation = useValidateExternalCanary();
  const hasExternalCanary = !!externalCanary;
  const [readyToRender, setReadyToRender] = useState(false);
  // useEffectOnce is used to only run this async process once, so we don't do it every time the
  // invokeExternalCanaryValidation callback is regenerated in useValidateExternalCanary in each render
  useEffectOnce(() => {
    if (hasExternalCanary) {
      invokeExternalCanaryValidation().then((nextView) => {
        setViewAfterCanaryValidation(nextView);
        setReadyToRender(true);
      });
    } else {
      setReadyToRender(true);
    }
  });

  redirectIfNeeded();

  if (!ipv6ExpChecked) {
    doAsyncIPv6ImageLoad();
  }

  if (!readyToRender) {
    return <div data-testid="initial-view-placeholder" />;
  }

  const isForceSignin = isForceSignIn();

  // Default to either Account Picker or Username view
  let nextViewId =
    !isForceSignin && credentials.sessions && credentials.sessions.length
      ? ViewId.AccountPicker
      : ViewId.Username;

  // This flag is to ensure that LoginMode has higher priority and certain views indicated
  // by LoginMode should be prioritized instead of trying to get next action from posted state
  let viewIdLocked = false;

  if (loginMode === LoginMode.Tiles) {
    nextViewId = ViewId.AccountPicker;
    viewIdLocked = true;
  }

  if (loginMode === LoginMode.Fido) {
    fidoRedirectCallback();
    return <div />;
  }

  if (
    loginMode === LoginMode.UserCredentialPolicyBlocked ||
    loginMode === LoginMode.CredentialPicker
  ) {
    nextViewId = ViewId.CredentialPicker;
    viewIdLocked = true;
  }

  if (loginMode === LoginMode.FetchSessionsProgress) {
    nextViewId = ViewId.FetchSessionsProgress;
  }

  if (
    loginMode === LoginMode.GenericError ||
    loginMode === LoginMode.GenericErrorMobile ||
    loginMode === LoginMode.GenericErrorHost ||
    loginMode === LoginMode.SwitchUser ||
    loginMode === LoginMode.SwitchUserMobile ||
    loginMode === LoginMode.SwitchUserHost ||
    loginMode === LoginMode.InviteBlocked ||
    loginMode === LoginMode.ServiceBlocked ||
    loginMode === LoginMode.IDPFailed ||
    loginMode === LoginMode.HIP_Lockout ||
    loginMode === LoginMode.HIP_LockoutMobile ||
    loginMode === LoginMode.HIP_LockoutHost ||
    loginMode === LoginMode.BindFailed
  ) {
    nextViewId = ViewId.Error;
    viewIdLocked = true;
  }

  if (viewAfterCanaryValidation === LoginState.RemoteConnectCanaryValidation) {
    return <RemoteConnectCanaryValidationViewFabric nextView={nextViewId} />;
  }

  if (!viewIdLocked) {
    const nextAction = getNextActionFromPostedState(nextViewId);
    const viewFromPostedState = nextAction.initialViewId;

    if (nextAction.doLoginFlowRedirect) {
      const redirectParams = nextAction.loginFlowRedirectParams;
      loginFlowRedirect(
        redirectParams.redirectUrl || "",
        redirectParams.redirectPostParams,
        redirectParams.isIdpRedirect,
      );

      return <div />;
    }

    if (viewFromPostedState !== ViewId.None) {
      nextViewId = viewFromPostedState;
    }
  }

  if (viewAfterCanaryValidation === LoginState.PostRedirect) {
    dispatchRedirectStateChange({
      type: PostRedirectActionType.SubmitPostRedirect,
      payload: { url: signupUrl, postParams: signupUrlPostParams, submitForm: true },
    });

    return <div />;
  }

  // We did not need to do canary validation, we can return the initial evaluated view
  return ViewIdToViewMap[nextViewId] || <UsernameViewFabric />;
};
