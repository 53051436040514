import React from "react";
import { TitleFabric } from "../../../../components/title/fabric/title-fabric";
import { FlowId, ViewId } from "../../../../constants/routing-constants";
import { useActivateView } from "../../../../hooks/use-activate-view";

/**
 * FidoView component
 * @returns A rendered instance of this component
 */
export const FidoViewFabric: React.FC = function FidoViewFabric() {
  // @TODO: Implement this view
  // @TODO: Once this view has been fully implemented, update the integration tests
  //        for the following views that navigate to the Fido View, such that we
  //        validate that the test flow has indeed landed on this view.
  //            - IDP Disambiguation View
  useActivateView(ViewId.Fido, FlowId.Login);

  return <TitleFabric titleId="loginHeader" title="Fido View" />;
};
