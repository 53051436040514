import React, { useContext } from "react";
import {
  type ITitleFabricProps,
  TitleFabric,
} from "../../../../components/title/fabric/title-fabric";
import { GlobalContext } from "../../../../global-context";
import { commonLoginStringsFabric } from "../../fabric/common-login-strings-fabric";
import LoginConfig from "../../login-config";
import { getCommonSubtitle } from "../../login-util";

/**
 * Title component specifically for Login.
 * This defaults the subtitle to the CommonSubtitle if not defined.
 * @param props The properties for this component
 * @returns Title component
 */
export const LoginTitleFabric: React.FC<ITitleFabricProps> = function LoginTitleFabric(props) {
  const { subtitle } = props;
  const {
    globalState: {
      styles: { friendlyAppName },
    },
  } = useContext(GlobalContext);
  const { breakBrandingSigninString } = LoginConfig.instance;
  const titleProps: ITitleFabricProps = {
    ...props,
    subtitle:
      subtitle ||
      getCommonSubtitle(friendlyAppName, breakBrandingSigninString, commonLoginStringsFabric),
  };
  return <TitleFabric {...titleProps} />;
};
