import { type ISignInOptionsStrings } from "../../login-interface";

/**
 * @returns Sign in options strings
 */
export const signInOptionsStringsFabric: ISignInOptionsStrings = {
  // Replace with localized strings when strings PR is merged and strings are localized
  backLabel: getLocalString("General_Back"),
  signInOptionsTitle: getLocalString("Login_CredPicker_Title_NoUser"),
  fidoHelpDialogDesc2: getLocalString("Login_HelpDialog_Fido_Desc2"),
  linkedInMainText: getLocalString("Login_UseLinkedIn_Link"),
  gitHubMainText: getLocalString("Login_UseGitHub_Link"),
  githubHelpDialogDesc: getLocalString("Login_CredPicker_Option_Help_GitHub"),
  githubHelpDialogDesc2: getLocalString("Login_CredPicker_Option_Help_GitHub2"),
  forgotUsernameMainText: getLocalString("Login_CredPicker_Option_ForgotUsername"),
};
