import { useContext, useState } from "react";
import { GlobalContext } from "../../../../global-context";
import { type OneTimeCodeCredential } from "../../../../types/credential-types";
import { isCredentialEmailProofType } from "../../../../utilities/credential-helper";
import LoginConfig from "../../login-config";
import { LoginContext } from "../../login-context";
import { type ICommonLoginStrings } from "../../login-interface";
import { getCommonTitle } from "../../login-util";
import {
  type ITextInputAttributes,
  type ITitleDescStrings,
  getLightboxStrings,
  getTextInputAttributes,
} from "../proof-confirmation-view-util";

export type IProofConfirmationViewProperties = ITitleDescStrings &
  ITextInputAttributes & {
    documentTitle: string;
    lightboxTitleId: string;
    lightboxDescriptionId: string;
    inputAriaLabel: string;
    inputAriaDescribedBy: string;
    renderCredentialLinks: boolean;
  };

/**
 * @param otcCredential the one time code credential
 * @param strings Flavored strings used by this hook
 * @param strings.commonLoginStrings Common login strings
 * @returns Proof Confirmation view properties
 */
export const useProofConfirmationViewProperties = (
  otcCredential: OneTimeCodeCredential,
  strings: {
    commonLoginStrings: ICommonLoginStrings;
  },
): IProofConfirmationViewProperties => {
  // Global context data
  const {
    globalState: {
      styles: { friendlyAppName },
    },
  } = useContext(GlobalContext);

  // Login context data
  const {
    viewState: {
      credentials: { useEvictedCredentials },
    },
  } = useContext(LoginContext);

  // Login config data
  const { loginMode } = LoginConfig.instance;

  const {
    proof: { clearDigits = "", display },
  } = otcCredential;

  const { commonLoginStrings } = strings;

  const documentTitle = getCommonTitle(loginMode, friendlyAppName, commonLoginStrings);

  const lightboxTitleId = "proofConfirmationTitle";
  const lightboxDescriptionId = "proofConfirmationDesc";
  const inputAriaLabel = getLocalString("EmailTextbox_Placeholder");
  const inputAriaDescribedBy = `${lightboxTitleId} ${lightboxDescriptionId}`;

  const isEmailProof = isCredentialEmailProofType(otcCredential);
  const lightboxStrings = getLightboxStrings(isEmailProof, display, clearDigits);
  const textInputAttributes = getTextInputAttributes(isEmailProof);

  const [renderCredentialLinks] = useState<boolean>(!useEvictedCredentials);

  return {
    ...lightboxStrings,
    ...textInputAttributes,
    documentTitle,
    lightboxTitleId,
    lightboxDescriptionId,
    inputAriaLabel,
    inputAriaDescribedBy,
    renderCredentialLinks,
  };
};
