import React from "react";
import { LinkButton } from "../../../../components/link-button";
import { FormattedTextWithBindings } from "../../../../utilities/formatted-text-with-bindings";
import { useSignupClickHandler } from "../../hooks/login-hooks";
import { type IFormattedSignupMessageProps } from "./components-interface";

/**
 * Create formatted sign up message with sign up link embedded
 * @param props properties for the sign up message (including text and id)
 * @returns sign up message component
 */
export const FormattedSignupMessageFabric: React.FC<IFormattedSignupMessageProps> =
  function FormattedSignupMessageFabric(props) {
    const { id, username, text, arialLabel } = props;
    const onSignupClicked = useSignupClickHandler();
    const signupLink = (chunks: string[]) => (
      <LinkButton
        text={chunks[0]}
        onClick={() => onSignupClicked(username)}
        linkId={id}
        ariaLabel={arialLabel}
      />
    );

    return <FormattedTextWithBindings textWithBindings={text} embeddedBindings={{ signupLink }} />;
  };
