import { customErrorFactory } from "ts-custom-error";

export const RetryableError = customErrorFactory(function RetryableError(code = "", message = "") {
  this.code = code;
  this.message = `RetryableError${code ? ` ${code}` : ""}${message ? ` ${message}` : ""}`;
  // Set name explicitly as minification can remove function expression names
  Object.defineProperty(this, "name", { value: "RetryableError" });
});

export const NonRetryableError = customErrorFactory(function NonRetryableError(
  code = "",
  message = "",
) {
  this.code = code;
  this.message = `NonRetryableError${code ? ` ${code}` : ""}${message ? ` ${message}` : ""}`;
  // Set name explicitly as minification can remove function expression names
  Object.defineProperty(this, "name", { value: "NonRetryableError" });
});
