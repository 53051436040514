import { type ChangeEvent, useContext } from "react";
import useTextInputForm from "../../../../hooks/use-text-input-form";
import { LoginContext } from "../../login-context";
import { LoginActionType } from "../../login-reducer";

/**
 * @returns Properties used to render the text input form for the proof confirmation view
 */
export const useProofConfirmationInputForm = () => {
  // Global context data
  const {
    viewState: {
      credentials: { proofConfirmation },
    },
    dispatchStateChange: dispatchLoginStateChange,
  } = useContext(LoginContext);

  const {
    focus,
    showError,
    externalError,
    onBlur,
    onFocus,
    value,
    onTextChange,
    setValidationError,
    onFormSubmission,
  } = useTextInputForm(proofConfirmation);

  const changeHandler = (event: ChangeEvent<HTMLInputElement> | string) => {
    const newValue = typeof event === "string" ? event : event.target.value;

    onTextChange(newValue);

    dispatchLoginStateChange({
      type: LoginActionType.UpdateCredentials,
      payload: { proofConfirmation: newValue },
    });
  };

  return {
    focus,
    showError,
    externalError,
    onBlur,
    onFocus,
    value,
    changeHandler,
    setValidationError,
    onFormSubmission,
  };
};
