import { type FormEvent, useCallback, useContext, useMemo, useState } from "react";
import { AuthenticationContext } from "../../../authentication-context";
import { AuthenticationActionType } from "../../../authentication-reducer";
import { CredentialType } from "../../../constants/constants";
import { ViewId } from "../../../constants/routing-constants";
import GlobalConfig from "../../../global-config";
import { type IUser, GlobalContext } from "../../../global-context";
import { useBackButtonControl, useNavigateDirection } from "../../../hooks/use-navigate-direction";
import { useTelemetryState } from "../../../telemetry-helpers/use-telemetry-state";
import { type UserCredential, ProofType } from "../../../types/credential-types";
import {
  getOneTimeCode,
  getProofConfirmation,
  isNoPassword,
  isProofEncrypted,
} from "../../../utilities/api-helpers/one-time-code/get-one-time-code-helper";
import {
  type OtcFailureParams,
  type OtcSuccessParams,
  OtcPurposes,
} from "../../../utilities/api-helpers/one-time-code/one-time-code-types";
import { htmlEscape, replaceTokens } from "../../../utilities/strings-helper";
import { useSelectAccount } from "../hooks/login-hooks";
import LoginConfig from "../login-config";
import { LoginContext } from "../login-context";
import { type ICommonLoginStrings } from "../login-interface";
import { LoginActionType } from "../login-reducer";
import { getCommonTitle, getShowChangeUserLink } from "../login-util";
import {
  type ConfirmSendCredential,
  type ConfirmSendCredentialTypes,
} from "./confirm-send-view-types";
import {
  getButtonText,
  getChannel,
  getDescriptionString,
  getProofData,
  getProofType,
  validateCredentials,
} from "./confirm-send-view-util";

/**
 * This hook is used to determine the description text to use in the ConfirmSendView
 * @param currentCredential The current credential being used
 * @param preferredCredential The preferred credential for the user
 * @param user The reference to the user
 * @param hasError Whether there has been an error using the GetOneTimeCode API
 * @param proofType The proof type of the user's current credential
 * @returns The description text to use in the ConfirmSendView
 */
export const useDescription = (
  currentCredential: ConfirmSendCredential,
  preferredCredential: ConfirmSendCredentialTypes,
  user: IUser,
  hasError: boolean,
  proofType: ProofType,
): string => {
  const isEmailDestination = proofType === ProofType.Email;
  const {
    globalState: {
      debugInfo: { errorCode },
    },
  } = useContext(GlobalContext);

  if (currentCredential.credentialType === CredentialType.OneTimeCode) {
    if (hasError) {
      if (isEmailDestination) {
        return getLocalString("Confirm_Send_Description_Otc_SendError_Email");
      }

      return getLocalString("Confirm_Send_Description_Otc_SendError");
    }

    return replaceTokens(
      getDescriptionString(preferredCredential, isEmailDestination, errorCode),
      currentCredential.proof.display,
    );
  }

  return replaceTokens(
    getDescriptionString(preferredCredential, isEmailDestination, errorCode),
    htmlEscape(user.displayUsername.unsafeUnescapedString),
  );
};

export type UseOneTimeCodeParams = {
  availableCredentials: Array<UserCredential>;
  currentCredential: ConfirmSendCredential;
  preferredCredential: ConfirmSendCredentialTypes;
  proofType: ProofType;
  requestIsPending: boolean;
  setButtonText: React.Dispatch<React.SetStateAction<string>>;
  setHasError: React.Dispatch<React.SetStateAction<boolean>>;
  setRequestIsPending: React.Dispatch<React.SetStateAction<boolean>>;
  user: IUser;
};

/**
 * This hook is used to provide a callback to make a OneTimeCode API call.
 * @param params The parameters required for the hook. These contain values to use as well as callbacks required for success/failure.
 * @returns A callback used to make a OneTimeCode API call.
 * This callback has "debouncing", so subsequent calls won't trigger another request while the current request is in progress.
 */
export const useSendOneTimeCode = (params: UseOneTimeCodeParams) => {
  const {
    availableCredentials,
    currentCredential,
    preferredCredential,
    proofType,
    requestIsPending,
    setButtonText,
    setHasError,
    setRequestIsPending,
    user,
  } = params;
  const {
    authState: { flowTokenValue },
    dispatchStateChange: updateAuthContext,
  } = useContext(AuthenticationContext);
  const navigate = useNavigateDirection();
  const isEncrypted = isProofEncrypted(currentCredential);
  const purpose = isNoPassword(currentCredential, availableCredentials)
    ? OtcPurposes.noPassword
    : OtcPurposes.otcLogin;
  const proofData = getProofData(currentCredential, user);
  const proofConfirmation = isEncrypted
    ? getProofConfirmation(currentCredential, proofType)
    : undefined;

  const updateFlowToken = useCallback(
    (flowToken: string) => {
      updateAuthContext({ type: AuthenticationActionType.SetFlowTokenValue, payload: flowToken });
    },
    [updateAuthContext],
  );

  const otcFailure = useCallback(
    ({ flowToken: returnedFlowToken }: Partial<OtcFailureParams>) => {
      if (returnedFlowToken) {
        updateFlowToken(returnedFlowToken);
      }

      setRequestIsPending(false);
      setHasError(true);
      setButtonText(getLocalString("General_Buttons_Next"));
    },
    [updateFlowToken, setRequestIsPending, setHasError, setButtonText],
  );

  const otcSuccess = useCallback(
    (successParams: OtcSuccessParams) => {
      const { flowToken: returnedFlowToken, response } = successParams;
      const success = !!response.SasParams?.Success;

      // If the preferred credential is PublicIdentifierCode and the request wasn't a success, we'll treat this like a failure.
      // This is because the endpoint wasn't returning a 4xx status code for this use-case like the rest.
      // AAD-TODO: Follow-up with Paulo to see if this has been fixed and we can remove this logic
      if (preferredCredential === CredentialType.PublicIdentifierCode && !success) {
        otcFailure({ flowToken: returnedFlowToken, responseBody: response });
      } else {
        if (returnedFlowToken) {
          updateFlowToken(returnedFlowToken);
        }

        setRequestIsPending(false);
        navigate(ViewId.ConfirmSend, ViewId.OneTimeCode);
      }
    },
    [preferredCredential, otcFailure, navigate, updateFlowToken, setRequestIsPending],
  );

  const sendOneTimeCode = useCallback(() => {
    if (!requestIsPending) {
      setRequestIsPending(true);
      getOneTimeCode({
        channel: getChannel(proofType),
        flowToken: flowTokenValue,
        proofType,
        purpose,
        username: user.displayUsername,
        isEncrypted,
        proofData,
        proofConfirmation,
        onSuccess: otcSuccess,
        onFailure: otcFailure,
      });
    }
  }, [
    flowTokenValue,
    requestIsPending,
    proofType,
    purpose,
    isEncrypted,
    proofData,
    proofConfirmation,
    user,
    otcSuccess,
    otcFailure,
    setRequestIsPending,
  ]);

  return sendOneTimeCode;
};

/**
 * This hook is used when clicking the primary (only) button on the ConfirmSendView.
 * @param params The OneTimeCode parameters to pass to the "useSendOneTimeCode" hook
 * @returns A callback that will navigate to the RemoteNGCView if the preferred credential is RemoteNGC.
 * Otherwise the callback will send a one time code request.
 */
export const useOnClickPrimaryButton = (params: UseOneTimeCodeParams) => {
  const sendOneTimeCode = useSendOneTimeCode(params);
  const navigate = useNavigateDirection();
  const { preferredCredential } = params;

  return (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (preferredCredential === CredentialType.RemoteNGC) {
      navigate(ViewId.ConfirmSend, ViewId.RemoteNgc);
    } else {
      // OneTimeCode or PublicIdentifierCode
      sendOneTimeCode();
    }
  };
};

export type ConfirmSendCredentials = {
  availableCredentials: UserCredential[];
  currentCredential: ConfirmSendCredential;
  evictedCredentials: UserCredential[];
  preferredCredential: ConfirmSendCredentialTypes;
  useEvictedCredentials: boolean;
};

/**
 * This hook is used to get the credentials for ConfirmSendView
 * @returns The credentials for ConfirmSendView
 */
export const useCredentials = (): ConfirmSendCredentials => {
  const {
    viewState: { credentials },
  } = useContext(LoginContext);
  const telemetryState = useTelemetryState();

  // Validate the state properly, but memoize the execution so we don't throw more errors than necessary
  const validatedCreds = useMemo(
    () => validateCredentials(credentials, telemetryState),
    [credentials, telemetryState],
  );

  const {
    availableCredentials,
    evictedCredentials,
    useEvictedCredentials,
    preferredCredential,
    otcCredential,
  } = validatedCreds;

  const currentCredential =
    preferredCredential === CredentialType.OneTimeCode
      ? (otcCredential as ConfirmSendCredential)
      : { credentialType: preferredCredential };

  return {
    availableCredentials,
    currentCredential,
    evictedCredentials,
    preferredCredential,
    useEvictedCredentials,
  };
};

/**
 * This hook is used to generate the display properties for the ConfirmSendView
 * @param credentials The ConfirmSendView credentials
 * @param commonLoginStrings The common login strings to use for the ConfirmSendView
 * @returns The display properties to use for the ConfirmSendView
 */
export const useDisplayProperties = (
  credentials: ConfirmSendCredentials,
  commonLoginStrings: ICommonLoginStrings,
) => {
  const [requestIsPending, setRequestIsPending] = useState(false);
  const [hasError, setHasError] = useState(false);
  const {
    globalState: {
      user,
      styles: { friendlyAppName },
    },
  } = useContext(GlobalContext);
  const { evictedCredentials, currentCredential, preferredCredential } = credentials;
  const { loginMode } = LoginConfig.instance;
  const { showButtons } = GlobalConfig.instance;
  const isInitialView = !useBackButtonControl();
  const showChangeUserLink = getShowChangeUserLink(isInitialView);

  const proofType = getProofType(currentCredential, user);
  const [buttonText, setButtonText] = useState(getButtonText(preferredCredential));

  const description = useDescription(
    currentCredential,
    preferredCredential,
    user,
    hasError,
    proofType,
  );

  return {
    showSwitchToEvictedCredPicker: evictedCredentials.length > 0,
    showChangeUserLink,
    buttonText,
    setButtonText,
    hasError,
    setHasError,
    description,
    documentTitle: getCommonTitle(loginMode, friendlyAppName, commonLoginStrings),
    requestIsPending,
    setRequestIsPending,
    showButton: showButtons && !requestIsPending,
  };
};

export type EventHandlerProps = {
  setHasError: React.Dispatch<React.SetStateAction<boolean>>;
  setButtonText: React.Dispatch<React.SetStateAction<string>>;
  requestIsPending: boolean;
  setRequestIsPending: React.Dispatch<React.SetStateAction<boolean>>;
  credentials: ConfirmSendCredentials;
};

/**
 * This method takes the credentials from `useCredentials` and the display properties from `useDisplayProperties`
 * and returns the callbacks to use in the ConfirmSendView
 * @param props The event handler props required for this hook
 * @returns The callbacks to use in the ConfirmSendView
 */
export const useEventHandlers = (props: EventHandlerProps) => {
  const { setHasError, setButtonText, requestIsPending, setRequestIsPending, credentials } = props;
  const navigate = useNavigateDirection();
  const { dispatchStateChange: updateLoginContext } = useContext(LoginContext);
  const {
    globalState: { user },
  } = useContext(GlobalContext);
  const { availableCredentials, preferredCredential, currentCredential } = credentials;
  const proofType = getProofType(currentCredential, user);

  const selectAccount = useSelectAccount(ViewId.ConfirmSend);

  const onClickPrimaryButton = useOnClickPrimaryButton({
    availableCredentials,
    currentCredential,
    preferredCredential,
    proofType,
    requestIsPending,
    setButtonText,
    setHasError,
    setRequestIsPending,
    user,
  });

  const navigateToPhoneDisambig = () => {
    navigate(ViewId.ConfirmSend, ViewId.PhoneDisambiguation);
  };

  const switchToEvictedCredPicker = () => {
    updateLoginContext({
      type: LoginActionType.UpdateCredentials,
      payload: { useEvictedCredentials: true },
    });
    navigate(ViewId.ConfirmSend, ViewId.CredentialPicker);
  };

  return {
    onClickPrimaryButton,
    navigateToPhoneDisambig,
    selectAccount,
    switchToEvictedCredPicker,
  };
};

/**
 * This hook is used to aggregate the `useCredentials`, `useDisplayProperties`, and `useEventHandlers` hooks into a single hook.
 * @param commonLoginStrings The common login strings to use for the ConfirmSendView
 * @returns An object containing hook-generated values and callbacks to use in the ConfirmSendView
 */
export const useConfirmSendViewProperties = (commonLoginStrings: ICommonLoginStrings) => {
  const credentials = useCredentials();
  const {
    buttonText,
    description,
    requestIsPending,
    documentTitle,
    showChangeUserLink,
    showButton,
    showSwitchToEvictedCredPicker,
    setButtonText,
    setHasError,
    setRequestIsPending,
  } = useDisplayProperties(credentials, commonLoginStrings);
  const eventHandlers = useEventHandlers({
    credentials,
    requestIsPending,
    setButtonText,
    setHasError,
    setRequestIsPending,
  });

  return {
    // Credentials
    ...credentials,
    // Display Props
    buttonText,
    description,
    documentTitle,
    requestIsPending,
    setRequestIsPending,
    showButton,
    showChangeUserLink,
    showSwitchToEvictedCredPicker,
    // Event handlers
    ...eventHandlers,
  };
};
