import { type IRemoteConnectCanaryValidationStrings } from "../../login-interface";

/**
 * @returns Remote connect canary validation view strings
 */
export const remoteConnectCanaryValidationStringsFabric: IRemoteConnectCanaryValidationStrings = {
  documentTitle: getLocalString("SignIn_PageTitle"),
  title: getLocalString("RemoteConnect_CanaryValidation_Title"),
  description: getLocalString("RemoteConnect_CanaryValidation_Body"),
  cancelLabel: getLocalString("General_Buttons_Cancel"),
  nextLabel: getLocalString("General_Buttons_Next"),
};
