import React from "react";
import { mergeClasses } from "@griffel/react";
import ErrorContainer from "../../../../components/error-container";
import {
  TextButtonContainer,
  TextButtonFabric,
} from "../../../../components/text-button/fabric/text-button-fabric";
import { TileList } from "../../../../components/tile-list";
import { TitleFabric } from "../../../../components/title/fabric/title-fabric";
import { FlowId, ViewId } from "../../../../constants/routing-constants";
import { useActivateView } from "../../../../hooks/use-activate-view";
import { useNavigateDirection } from "../../../../hooks/use-navigate-direction";
import useFabricStyles, { useStaticFabricStyles } from "../../../../styles/fabric/fabric.styles";
import { useTileStyles } from "../../../../styles/fabric/tile-fabric.styles";
import { commonLoginStringsFabric } from "../../fabric/common-login-strings-fabric";
import { useSetServerDataErrorOnView } from "../../hooks/login-hooks";
import {
  useCredentialPickerTileListBuilder,
  useCredentialPickerViewProperties,
} from "../hooks/credential-picker-view-hooks";
import { credentialPickerStringsFabric } from "./credential-picker-strings-fabric";

/**
 * CredentialPicker component
 * @returns A rendered instance of this component
 */
export const CredentialPickerViewFabric: React.FC = function CredentialPickerViewFabric() {
  const { availableCredentials, backLabel, redirect, title, setError, error } =
    useCredentialPickerViewProperties();

  useActivateView(ViewId.CredentialPicker, FlowId.Login);

  const navigate = useNavigateDirection();

  useStaticFabricStyles();
  const fabricStyles = useFabricStyles();
  const tileStyles = useTileStyles();

  const credentials = useCredentialPickerTileListBuilder(
    availableCredentials,
    redirect,
    {
      commonLoginStrings: commonLoginStringsFabric,
      credentialPickerStrings: credentialPickerStringsFabric,
    },
    setError,
  );

  // If there is an error from server in server data, show it on initialize
  useSetServerDataErrorOnView(setError);

  return (
    <>
      <TitleFabric titleId="credentialPickerTitle" title={title} />

      {error && (
        <ErrorContainer
          id="errorText"
          aria-relevant="text"
          aria-atomic="true"
          className={mergeClasses(
            fabricStyles.row,
            fabricStyles.textBody,
            fabricStyles.textBlockBody,
            tileStyles.tileError,
            fabricStyles.alertError,
          )}
        >
          {error}
        </ErrorContainer>
      )}

      <div className={fabricStyles.formGroup}>
        <TileList
          ariaLabelledBy={`errorText ${title}`}
          ariaDescribedBy={title}
          tileList={credentials}
        />
      </div>

      <div className={fabricStyles.buttonMargin}>
        <TextButtonContainer>
          <TextButtonFabric
            buttonId="backButton"
            label={backLabel}
            isPrimary={false}
            isButtonType
            ariaLabel={backLabel}
            onClick={() => navigate(ViewId.CredentialPicker, -1)}
          />
        </TextButtonContainer>
      </div>
    </>
  );
};
