export interface TransferInfo {
  TransferSize: number;
  TransferTime: number;
}

export interface ResourceMetrics {
  [key: string]: TransferInfo;
}

export const PerfHelper = {
  maxCachedResourceThresholdTimeMs: 50.0,
  bucketTransferRateIncreaseValue: 12.5, // 12.5 Kb/sec (or 0.1 Mbit/s)

  /**
   * Get performance metrics
   * @returns {object} - The object holding performance metrics
   */
  getPerformanceMetrics(): {} {
    let isPlt1: boolean | null = null;
    let pltTransferBucket: number = 0;
    let timingObject: PerformanceEntry | PerformanceTiming | {};

    // Very old browsers (IE8 and below) that don't support the performance API at all
    if (!window.performance) {
      return { entryType: "unsupported_browser" };
    }

    if (window.performance.getEntries) {
      const entries = window.performance.getEntries();

      isPlt1 = PerfHelper.isPlt1(entries);
      pltTransferBucket = PerfHelper.getPltTransferBucketData(entries);
      timingObject = entries.find((entry) => entry.entryType === "navigation") || {};
    } else {
      timingObject = window.performance.timing || {};
    }

    return { isPlt1, pltTransferBucket, ...PerfHelper.toJSON_safe(timingObject) };
  },

  /**
   * Checks if is Plt1
   * @param {PerformanceEntry[]} [entries] - The performance entries to check if is plt1
   * @returns {boolean | null} - True if resources cached, false if no cached resources and null if unknown
   */
  isPlt1(entries: PerformanceEntry[]): boolean | null {
    let isPlt1: boolean | null = null; // Default to unknown as we don't know if we have any cached resources yet
    const { length } = entries;

    if (!entries || length < 1) {
      return null;
    }

    for (let i = 0; i < length; i += 1) {
      const { [i]: entry } = entries;
      // We'll only check resources that were not loaded inside of an iframe.
      if (
        PerfHelper.isResource(entry.entryType) &&
        !PerfHelper.isSubDocument(PerfHelper.toJSON_safe(entry).initiatorType as string) &&
        !PerfHelper.isDocument(entry.name)
      ) {
        // We are only going to consider Plt1 to be when ALL of the resources are cached
        // This is because with GFX Hashing some of the files should always be cached between releases
        const resourcePlt1 = PerfHelper.isResourcePlt1(entry);
        if (resourcePlt1 === true) {
          // We have a cached resource so change the default return value to true
          isPlt1 = true;
        } else if (resourcePlt1 === false) {
          // As we have at least 1 non-cached resource so set return to false and break
          isPlt1 = false;
          break;
        }
      }
    }

    return isPlt1;
  },

  /**
   * Checks if the string given equals resource
   * @param {string} [entryType] - The string to check if is "resource"
   * @returns {boolean} - True if string equals "resource", false if otherwise
   */
  isResource(entryType: string): boolean {
    return entryType === "resource";
  },

  /**
   * Checks if the string given equals subdocument
   * @param {string} [initiatorType] - The string to check if is "subdocument"
   * @returns {boolean} - True if string equals "subdocument", false if otherwise
   */
  isSubDocument(initiatorType: string): boolean {
    return initiatorType === "subdocument";
  },

  /**
   * Checks if the string given equals document
   * @param {string} [name] - The string to check if is "document"
   * @returns {boolean} - True if string equals "document", false if otherwise
   */
  isDocument(name: string): boolean {
    return name === "document";
  },

  /**
   * Checks if the resource is Plt1
   * @param {PerformanceEntry} [entry] - The performance entry to check if the resource is plt1
   * @returns {boolean | null} - True if resource is cached, false if resource is not cached and null if unknown
   */
  isResourcePlt1(entry: PerformanceEntry): boolean | null {
    if (entry.duration > 0) {
      if (entry.duration < this.maxCachedResourceThresholdTimeMs) {
        // Duration is less than the threshold so it's Plt1
        return true;
      }

      return false;
    }

    return null;
  },

  /**
   * Type guard indivating whether a PerformanceEntry object is a navigation or resource timing
   * @param entry The PerformanceEntry to check
   * @returns Whether the entry is a navigation or resource timing
   */
  isResourceOrNavigationTiming(
    entry: PerformanceEntry,
  ): entry is PerformanceNavigationTiming | PerformanceResourceTiming {
    return entry.entryType === "resource" || entry.entryType === "navigation";
  },

  /**
   * Gets the plt transfer bucket
   * @param {PerformanceEntry[]} [entries] - The overall transfer rate
   * @returns {number} - The overall transfer bucket
   */
  getPltTransferBucketData(entries: PerformanceEntry[]): number {
    if (!entries || entries.length === 0) {
      return 0;
    }

    const overallTransferRateData = PerfHelper.getOverallTransferRate(entries);
    const overallTransferBucket = PerfHelper.getTransferBucket(overallTransferRateData);

    return overallTransferBucket;
  },

  /**
   * Gets the transfer bucket
   * @param {number} [transferRate] - The overall transfer rate
   * @returns {number} - The transfer bucket
   */
  getTransferBucket(transferRate: number): number {
    if (transferRate === 0) {
      return 0;
    }

    let transferRateIncreaseValue = this.bucketTransferRateIncreaseValue;
    let previous = this.bucketTransferRateIncreaseValue;
    let bucket = 0;

    while (transferRate >= transferRateIncreaseValue && bucket < 20) {
      bucket += 1;
      const hold = transferRateIncreaseValue;
      transferRateIncreaseValue += previous;
      previous = hold;
    }

    return bucket;
  },

  /**
   * Gets the overall transfer rate in Kb per second
   * @param {PerformanceEntry[]} [entries] - Entries from which to get the transfer rate
   * @returns {number} - The overall transfer rate
   */
  getOverallTransferRate(entries: PerformanceEntry[]): number {
    let totalTransferSize = 0;
    let totalTransferTime = 0;

    const uniqueDomains: Record<string, boolean> = {};

    for (let i = 0; i < entries.length; i += 1) {
      const entry = entries[i];

      // ignore cached resources
      if (!PerfHelper.isResourcePlt1(entry) && PerfHelper.isResourceOrNavigationTiming(entry)) {
        const domain = PerfHelper.getDomainHost(entry.name);

        const { transferSize, responseEnd } = entry as
          | PerformanceResourceTiming
          | PerformanceNavigationTiming;
        const transferTime = PerfHelper.calcTimespan(
          PerfHelper.getStart(entry),
          responseEnd as number,
        );

        if (transferTime > 0 && transferSize > 0 && !uniqueDomains[domain]) {
          totalTransferSize += transferSize;
          totalTransferTime += transferTime;

          uniqueDomains[domain] = true;
        }
      }
    }

    if (totalTransferTime > 0 && totalTransferSize > 0) {
      // Get Kb per second
      return PerfHelper.calcKbRatePerSecond(totalTransferSize, totalTransferTime);
    }

    return 0;
  },

  /**
   * Calculates transfer rate in Kb per second
   * @param {number} [transferSize] - The size of the transfer made to load the page
   * @param {number} [transferTime] - The time taken to load the page
   * @returns {number} - The transfer rate in Kb per second
   */
  calcKbRatePerSecond(transferSize: number, transferTime: number): number {
    // bytes per millisecond
    const millisecondRate = transferSize / transferTime;

    // Convert to Kb per second
    return (millisecondRate * 1000.0) / 1024.0;
  },

  /**
   * Gets the response start time of a PerformanceEntry
   * @param {PerformanceEntry} [entry] - Entry from which to get the response start time
   * @returns {number} - The response start time
   */
  getStart(entry: PerformanceNavigationTiming | PerformanceResourceTiming): number {
    const start = entry.responseStart as number;

    if (!start) {
      const fetchStart = PerfHelper.getBaseStartTime(entry);

      if (fetchStart > 0) {
        return fetchStart;
      }
    }

    return start;
  },

  /**
   * Gets the base start time of a PerformanceEntry using the fetchStart value
   * @param {PerformanceEntry} [entry] - Entry from which to get the base start time
   * @returns {number} - The base start time
   */
  getBaseStartTime(entry: PerformanceNavigationTiming | PerformanceResourceTiming): number {
    if (entry.fetchStart > 0 && entry.fetchStart < Number.MAX_VALUE) {
      return entry.fetchStart;
    }

    return 0;
  },

  /**
   * Calculates the time span between two time values.
   * @param {number} [start] - Number from which to calculate the time span from
   * @param {number} [end] - Number from which to calculate the time span to
   * @returns {number} - The time span between the time values given
   */
  calcTimespan(start: number, end: number): number {
    if (!start || !end || end < start) {
      return 0;
    }

    return end - start;
  },

  /**
   * Gets the domain host from the given string or fetches document location
   * @param {string} [name] - String from which to get the domain host
   * @returns {string} - The domain host
   */
  getDomainHost(name: string): string {
    if (name === "document") {
      return document.location.hostname;
    }

    if (PerfHelper.isAbsoluteUrl(name)) {
      return PerfHelper.extractHostname(name);
    }

    return document.location.hostname;
  },

  /**
   * Extracts the domain from the given URL
   * @param {string} [url] - URL from which to extract the domain
   * @returns {string} - The extracted hostname
   */
  extractHostname(url: string): string {
    const element = document.createElement("a");
    element.href = url;

    // Note: This returns an empty string in IE if 'url' is a relative path. Firefox/Chrome return the domain properly.
    return element.hostname;
  },

  /**
   * Checks if the given input string is an absolute url
   * @param {string} [input] - String to check if it is an absolute url
   * @returns {boolean} - True if input string is an absolute url, false otherwise
   */
  isAbsoluteUrl(input: string): boolean {
    const re = /^(?:[a-z]+:)?\/\//i;
    if (re.test(input)) {
      return true;
    }

    return false;
  },

  /**
   * Converts an object to JSON using the built-in method if available, and custom logic if not
   * @param input The object to convert to JSON
   * @returns The JSON object representing the input
   */
  toJSON_safe(
    input: Object & {
      toJSON?: Function;
    },
  ): Record<string, string | number | boolean> {
    // For browsers that don't support the native toJSON, construct the data object from PerformanceTiming ourselves.
    const result: Record<string, string | number | boolean> = {};

    if (input.toJSON) {
      return input.toJSON();
    }

    // PerformanceTiming objects are not regular JS objects. Calling Object.hasOwnProperty on them does not work.
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const prop in input) {
      const value = input[prop as keyof typeof input];
      if (typeof value === "string" || typeof value === "number" || typeof value === "boolean") {
        result[prop] = value;
      }
    }

    return result;
  },
};
