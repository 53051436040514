import { type AuthenticationState } from "./authentication-context";

/**
 * Action types that are accepted by the authentication reducer
 */
export enum AuthenticationActionType {
  SetFlowTokenValue,
}

type SetFlowTokenValueAction = {
  type: AuthenticationActionType.SetFlowTokenValue;
  payload: string;
};

/**
 * Union of all actions that can be dispatched to the authentication reducer to update authentication state
 */
export type AuthenticationActions = SetFlowTokenValueAction;

/**
 * Authentication state reducer
 * @param state The current state
 * @param action The action to perform on the current state
 * @returns The new state
 */
export default function authenticationReducer(
  state: AuthenticationState,
  action: AuthenticationActions,
): AuthenticationState {
  switch (action.type) {
    case AuthenticationActionType.SetFlowTokenValue:
      return { ...state, flowTokenValue: action.payload };

    default:
      throw new Error(`AuthenticationReducer received unexpected action ${action.type}`);
  }
}
