import { useCallback, useContext, useState } from "react";
import { AuthenticationContext } from "../../../../authentication-context";
import { AuthenticationActionType } from "../../../../authentication-reducer";
import { type TileComponent } from "../../../../components/tile";
import { CredentialType } from "../../../../constants/constants";
import { ViewId } from "../../../../constants/routing-constants";
import { GlobalContext } from "../../../../global-context";
import { GlobalActionType } from "../../../../global-reducer";
import { useNavigateDirection } from "../../../../hooks/use-navigate-direction";
import {
  type OneTimeCodeCredential,
  type UserCredential,
  ProofType,
} from "../../../../types/credential-types";
import { proofTypeToChannel } from "../../../../utilities/api-helpers/one-time-code/get-one-time-code-form";
import {
  type GetOtcParams,
  getOneTimeCode,
  getProofConfirmation,
} from "../../../../utilities/api-helpers/one-time-code/get-one-time-code-helper";
import {
  type OtcFailureParams,
  type OtcSuccessParams,
  OtcPurposes,
  OtcStatus,
} from "../../../../utilities/api-helpers/one-time-code/one-time-code-types";
import {
  authenticatorCredIcon,
  callIcon,
  emailIcon,
  fidoCredIcon,
  passwordCredIcon,
  smsIcon,
} from "../../../../utilities/image-helpers/accessible-images";
import { getRouteFromViewId } from "../../../../utilities/routing-helper";
import { useFidoPostRedirect, useFidoProperties } from "../../hooks/login-hooks";
import { LoginContext } from "../../login-context";
import { type ICommonLoginStrings, type ICredentialPickerStrings } from "../../login-interface";
import { LoginActionType } from "../../login-reducer";
import { getCredentialPriority } from "../credential-picker-view-util";

export interface ICredentialPickerViewProperties {
  availableCredentials: UserCredential[];
  backLabel: string;
  error: string;
  redirect: () => void;
  setError: React.Dispatch<React.SetStateAction<string>>;
  title: string;
}

/**
 * @returns a method for making a OneTimeCode request
 */
export const useSendOneTimeCode = () => {
  // Global state
  const {
    globalState: { user },
    dispatchStateChange: dispatchGlobalStateChange,
  } = useContext(GlobalContext);
  // Login state
  const { dispatchStateChange: dispatchLoginStateChange } = useContext(LoginContext);
  // Auth state
  const {
    authState: { flowTokenValue },
    dispatchStateChange: dispatchAuthStateChange,
  } = useContext(AuthenticationContext);

  const navigate = useNavigateDirection();

  const sendOneTimeCode = useCallback(
    (
      selectedCredential: OneTimeCodeCredential,
      setError: React.Dispatch<React.SetStateAction<string>>,
    ) => {
      const { proof } = selectedCredential;
      const { isEncrypted } = proof;
      const channel = proofTypeToChannel(proof.type)!;

      const onSuccess = (responseParams: OtcSuccessParams) => {
        if (responseParams.flowToken) {
          dispatchAuthStateChange({
            type: AuthenticationActionType.SetFlowTokenValue,
            payload: responseParams.flowToken,
          });
        }

        dispatchGlobalStateChange({
          type: GlobalActionType.SetShowProgressIndicator,
          payload: false,
        });
        dispatchLoginStateChange({
          type: LoginActionType.UpdateCredentials,
          payload: {
            otcCredential: {
              credentialType: CredentialType.OneTimeCode,
              proof: selectedCredential.proof,
            },
          },
        });
        navigate(ViewId.CredentialPicker, getRouteFromViewId(ViewId.OneTimeCode));
      };

      const onFailure = (responseParams: OtcFailureParams) => {
        if (responseParams.flowToken) {
          dispatchAuthStateChange({
            type: AuthenticationActionType.SetFlowTokenValue,
            payload: responseParams.flowToken,
          });
        }

        dispatchGlobalStateChange({
          type: GlobalActionType.SetShowProgressIndicator,
          payload: false,
        });

        const errorId = responseParams.otcStatus;
        if (errorId === OtcStatus.ftError) {
          setError(getLocalString("OneTimeCode_SessionExpiredError"));
        } else {
          setError(getLocalString("OneTimeCode_SendFailedError"));
        }
      };

      const otcParams: GetOtcParams = {
        username: user.username,
        proofData: proof.data,
        proofType: proof.type,
        purpose: proof.isNopa ? OtcPurposes.noPassword : OtcPurposes.otcLogin,
        flowToken: flowTokenValue,
        isEncrypted,
        channel,
        onSuccess,
        onFailure,
      };

      if (isEncrypted) {
        otcParams.proofConfirmation = getProofConfirmation(selectedCredential, proof.type);
      }

      getOneTimeCode(otcParams);
    },
    [
      dispatchAuthStateChange,
      dispatchGlobalStateChange,
      dispatchLoginStateChange,
      flowTokenValue,
      navigate,
      user.username,
    ],
  );

  return sendOneTimeCode;
};

/**
 * @returns the click handler for when a OneTimeCode credential type is selected
 */
export const useOneTimeCodeClickHandler = () => {
  const navigate = useNavigateDirection();
  const sendOneTimeCode = useSendOneTimeCode();

  const { dispatchStateChange: dispatchGlobalStateChange } = useContext(GlobalContext);
  const { dispatchStateChange: dispatchLoginStateChange } = useContext(LoginContext);

  return (
    selectedCredential: OneTimeCodeCredential,
    setError: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    setError("");
    if (selectedCredential.proof.clearDigits) {
      dispatchLoginStateChange({
        type: LoginActionType.UpdateCredentials,
        payload: {
          otcCredential: {
            credentialType: CredentialType.OneTimeCode,
            proof: selectedCredential.proof,
          },
        },
      });
      navigate(ViewId.CredentialPicker, getRouteFromViewId(ViewId.ProofConfirmation));
    } else {
      dispatchGlobalStateChange({
        type: GlobalActionType.SetShowProgressIndicator,
        payload: true,
      });
      sendOneTimeCode(selectedCredential, setError);
    }
  };
};

export const useCredentialPickerViewProperties = (): ICredentialPickerViewProperties => {
  // login state data
  const {
    viewState: {
      credentials: { availableCredentials },
    },
  } = useContext(LoginContext);

  const redirect = useFidoPostRedirect();
  const [error, setError] = useState("");

  // static strings
  const backLabel = getLocalString("General_Back");
  const title = getLocalString("Login_CredPicker_Title");

  return {
    availableCredentials,
    backLabel,
    redirect,
    title,
    error,
    setError,
  };
};

/**
 * Processes the list of user credentials returned by the GCT response. First, the credentials list is filtered and sorted by
 * priority, then it is mapped to tile components which will be displayed in the tile list on the view.
 * @param credentials the available user credentials
 * @param redirect the Fido redirect method
 * @param strings strings for the current flavor
 * @param strings.credentialPickerStrings credential picker strings
 * @param strings.commonLoginStrings common login strings
 * @param setError the method for setting the error message in the credential picker view
 * @returns a list of tile components used to build the credential picker options
 */
export const useCredentialPickerTileListBuilder = (
  credentials: UserCredential[],
  redirect: () => void,
  {
    credentialPickerStrings,
    commonLoginStrings,
  }: { credentialPickerStrings: ICredentialPickerStrings; commonLoginStrings: ICommonLoginStrings },
  setError: React.Dispatch<React.SetStateAction<string>>,
): TileComponent[] => {
  const {
    oneTimeCodeEmailLabel,
    oneTimeCodeSMSLabel,
    oneTimeCodeVoiceLabel,
    passwordLabel,
    remoteNgcLabel,
  } = credentialPickerStrings;

  const { fidoLinkText } = useFidoProperties(commonLoginStrings, true);

  const navigate = useNavigateDirection();
  const oneTimeCodeClickHandler = useOneTimeCodeClickHandler();

  const {
    viewState: {
      credentials: { useEvictedCredentials },
    },
  } = useContext(LoginContext);
  const showDefaultCredentials = !useEvictedCredentials;

  const filteredCredentials = credentials.filter((credential) =>
    credential.proof ? credential.proof.isDefault === showDefaultCredentials : true,
  );

  // credential priorities = importance of displaying them closer to the top of the list in the view
  // sort the credentials by priority so that they will be displayed accordingly.
  // if the credentials do not BOTH have a priority, they will be considered as equal.
  // ex. a has credentialType Fido, priorityA = 2.
  //     b has credentialType Password, priorityB = 4.
  //     priorityA - priorityB = -2 which is less than 0, so Fido is before Password in the list.
  filteredCredentials.sort((a, b) => {
    const priorityA = getCredentialPriority(a);
    const priorityB = getCredentialPriority(b);

    if (priorityA && priorityB) {
      return priorityA - priorityB;
    }

    return 0;
  });

  const tileList: TileComponent[] = [];

  filteredCredentials.forEach((credential) => {
    switch (credential.credentialType) {
      case CredentialType.Fido:
        tileList.push({
          mainText: fidoLinkText,
          imageUrl: fidoCredIcon,
          ariaLabel: fidoLinkText,
          onTileClick: redirect,
        });
        break;

      case CredentialType.RemoteNGC:
        tileList.push({
          mainText: remoteNgcLabel,
          imageUrl: authenticatorCredIcon,
          ariaLabel: remoteNgcLabel,
          onTileClick: () => {
            navigate(ViewId.CredentialPicker, getRouteFromViewId(ViewId.RemoteNgc));
          },
        });
        break;

      case CredentialType.Password:
        tileList.push({
          mainText: passwordLabel,
          imageUrl: passwordCredIcon,
          ariaLabel: passwordLabel,
          onTileClick: () => {
            navigate(ViewId.CredentialPicker, getRouteFromViewId(ViewId.Password));
          },
        });
        break;

      case CredentialType.OneTimeCode: {
        const selectedCredential = credential as OneTimeCodeCredential;
        const { proof } = selectedCredential;

        if (proof.type === ProofType.Email) {
          tileList.push({
            mainText: oneTimeCodeEmailLabel,
            imageUrl: emailIcon,
            ariaLabel: oneTimeCodeEmailLabel,
            onTileClick: () => {
              oneTimeCodeClickHandler(selectedCredential, setError);
            },
            mainTextReplaceValue: proof.display,
            ariaLabelReplaceValue: proof.display,
          });
        } else if (proof.type === ProofType.SMS) {
          tileList.push({
            mainText: oneTimeCodeSMSLabel,
            imageUrl: smsIcon,
            ariaLabel: oneTimeCodeSMSLabel,
            onTileClick: () => {
              oneTimeCodeClickHandler(selectedCredential, setError);
            },
            mainTextReplaceValue: proof.display,
            ariaLabelReplaceValue: proof.display,
          });
        } else if (proof.type === ProofType.Voice) {
          tileList.push({
            mainText: oneTimeCodeVoiceLabel,
            imageUrl: callIcon,
            ariaLabel: oneTimeCodeVoiceLabel,
            onTileClick: () => {
              oneTimeCodeClickHandler(selectedCredential, setError);
            },
            mainTextReplaceValue: proof.display,
            ariaLabelReplaceValue: proof.display,
          });
        }

        break;
      }

      default:
        break;
    }
  });

  return tileList;
};
