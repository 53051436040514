import { useContext } from "react";
import { type To, useLocation, useNavigate } from "react-router-dom";
import { type ViewId } from "../constants/routing-constants";
import { GlobalContext } from "../global-context";
import { GlobalActionType } from "../global-reducer";
import { getRouteFromViewId, isViewId } from "../utilities/routing-helper";

/**
 * React hook that returns a method for changing location and setting the navigation direction.
 * Sets the navigation to "forward" when a path is provided.
 * Sets the navigation to "back" when a delta is provided, or when a path and isPathBack parameter is provided.
 * @returns A function that changes the location and dispatches the BeginNavigate action.
 */
export const useNavigateDirection = () => {
  const navigate = useNavigate();
  const { dispatchStateChange: dispatchGlobal } = useContext(GlobalContext);

  return (
    source: ViewId | string,
    deltaOrTo: To | number | ViewId,
    // this is to account for edge case where we navigate back with a specific path instead of delta (e.g., agreement view)
    isPathBack = false,
    options?: { replace?: boolean; state?: unknown },
  ) => {
    const navigationDirection = deltaOrTo === -1 || isPathBack ? "back" : "forward";
    if (deltaOrTo instanceof Number) {
      navigate(deltaOrTo as number);
    } else if (isViewId(deltaOrTo)) {
      navigate(getRouteFromViewId(deltaOrTo) as To, options);
    } else {
      navigate(deltaOrTo as To, options);
    }

    dispatchGlobal({
      type: GlobalActionType.BeginNavigate,
      source,
      displayOptions: { navigationDirection },
    });
  };
};

/**
 * use location properties from React to determine if the view can go back
 * documentation - https://github.com/remix-run/history/blob/main/docs/api-reference.md#locationkey
 * @returns flag to indicate whether the view can go back
 */
export const useBackButtonControl = () => {
  const location = useLocation();
  return location.key !== "default";
};
