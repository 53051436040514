import { isChangingInputTypeSupported } from "../../../utilities/browser-helper";
import { replaceTokens } from "../../../utilities/strings-helper";
import { PhoneNumberConfirmationLength, UsernameMaxLength } from "../login-constants";

export interface ITitleDescStrings {
  lightboxTitle: string;
  lightboxDescription: string;
}

export interface ITextInputAttributes {
  maxLength: number;
  placeholder: string;
  type?: string;
}

/**
 * Given a flag indicating whether the proof is an email, the displayable proof string and the clear characters
 * in the proof string, returns the appropriate title and description strings specific to these inputs.
 * @param isEmailProof whether the proof is an email
 * @param display the proof string with the unknown characters obfuscated with a "*". For example, for emails this is "te******@live.com"
 * @param clearDigits the characters of the proof string that are clear and not obfuscated with a "*"
 * @returns the appropriate title and description strings specific to the inputs
 */
export const getLightboxStrings = (
  isEmailProof: boolean,
  display: string,
  clearDigits: string,
): ITitleDescStrings => {
  const title = isEmailProof
    ? getLocalString("General_VerifyEmail_Title")
    : getLocalString("General_VerifyPhone_Title");

  // Note that only the description for the phone / SMS proof contains a placeholder for clear digits
  const descriptionWithPlaceholders = isEmailProof
    ? getLocalString("General_VerifyEmail_Description")
    : getLocalString("General_VerifyPhone_Description");
  const description = replaceTokens(descriptionWithPlaceholders, display, clearDigits);

  return { lightboxTitle: title, lightboxDescription: description };
};

/**
 * Given a flag indicating whether the proof is an email, returns the appropriate maximum allowable length, placeholder
 * text and type for the input textbox element
 * @param isEmailProof whether the proof is an email
 * @returns the appropriate maximum allowable length, placeholder text and type for the input textbox element
 */
export const getTextInputAttributes = (isEmailProof: boolean): ITextInputAttributes => {
  const maxLength = isEmailProof ? UsernameMaxLength : PhoneNumberConfirmationLength;

  // Note that only the placeholder text for the phone / SMS proof contains a placeholder for the maximum input length
  const placeholderWithoutDigits = isEmailProof
    ? getLocalString("ExampleEmail")
    : getLocalString("Sms_Proof_Input_Placeholder");
  const placeholder = replaceTokens(placeholderWithoutDigits, maxLength.toString());

  const type = isEmailProof ? "email" : "tel";

  // specify the input type only if changing it is supported
  return { maxLength, placeholder, ...(isChangingInputTypeSupported() ? { type } : {}) };
};

/**
 * Given a flag indicating whether the proof is an email and the clear characters in the proof string,
 * returns the invalid proof error message specific to these inputs.
 * @param isEmailProof whether the proof is an email
 * @param clearDigits the characters of the proof string that are clear and not obfuscated with a "*"
 * @returns the invalid proof error message specific to the proof type and the clear characters from the proof
 */
export const getInvalidProofError = (isEmailProof: boolean, clearDigits: string): string => {
  const errorWithoutDigits = isEmailProof
    ? getLocalString("Email_Proof_Input_MismatchError")
    : getLocalString("Sms_Proof_Input_MismatchError");
  return replaceTokens(errorWithoutDigits, clearDigits);
};
