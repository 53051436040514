import React, { useContext, useState } from "react";
import { mergeClasses } from "@griffel/react";
import { ExternalClassName } from "../../../constants/constants";
import { GlobalContext } from "../../../global-context";
import useLayoutAnimateStyles from "../../../styles/fabric/layout-animate-fabric.styles";
import { useVerticalSplitFabricStyles } from "../../../styles/fabric/vertical-split-fabric.styles";
import { isCssAnimationSupported } from "../../../utilities/browser-helper";
import Background from "../../background";
import DirectionWrapper from "../../direction-wrapper";
import Footer from "../../footer/fabric/footer-fabric";
import Header from "../../header";
import LayoutContent, { useLayoutContentStyles } from "../../layout-content";

type VerticalSplitLayoutProps = {
  styles: {
    disableLightbox: string;
    inner: string;
    lightboxCover: string;
    middle: string;
    outer: string;
    paginationView: string;
    paginationViewWithIdentityBanner: string;
    wide: string;
    transparent: string;
  };
};

/**
 * @param root - Component properties
 * @param root.styles - CSS class names to apply to specific DOM elements
 * @param root.children - Child content for the layout, typically a view
 * @returns Vertical-split layout component
 */
const VerticalSplitLayoutFabric: React.FC<VerticalSplitLayoutProps> =
  function VerticalSplitLayoutFabric({ styles, children }) {
    const {
      globalState,
      globalState: {
        isWideView,
        styles: { useTransparentLightbox },
      },
    } = useContext(GlobalContext);
    const [showDebugInfo, setShowDebugInfo] = useState(false);
    const [focusOnDebugCopyLink, setFocusOnDebugCopyLink] = useState(false);
    const [focusOnEllipsis, setFocusOnEllipsis] = useState(false);
    const splitStyles = useVerticalSplitFabricStyles();

    const animateStyles = useLayoutAnimateStyles();
    const animationSupported = isCssAnimationSupported();

    const mainContainerClassNames = mergeClasses(
      splitStyles.mainContainer,
      ExternalClassName.verticalSplitMainSection,
    );
    const backgroundContainerClassNames = mergeClasses(
      splitStyles.backgroundContainer,
      ExternalClassName.verticalSplitBackgroundImageContainer,
    );
    let innerClassNames = mergeClasses(
      styles.inner,
      splitStyles.lightbox,
      isWideView ? styles.wide : "",
      useTransparentLightbox ? styles.transparent : "",
    );

    // Add animation styles for lightbox/inner container when animation is supported
    if (animationSupported) {
      innerClassNames = mergeClasses(innerClassNames, animateStyles.fadeIn);
    }

    return (
      <DirectionWrapper testID="vertical-split-layout">
        <div id="outer" data-testid="outer" className={styles.outer} role="presentation">
          <Header />
          <div data-testid="main-section" className={splitStyles.mainSection}>
            <div data-testid="vertical-split-main-container" className={mainContainerClassNames}>
              <div
                className={splitStyles.verticalContainer}
                data-testid="vertical-split-main-section"
              >
                <div
                  className={backgroundContainerClassNames}
                  data-testid="vertical-split-background-image-container"
                >
                  <Background />
                </div>
                <div
                  className={splitStyles.lightboxContainer}
                  data-testid="vertical-lightbox-container"
                  role="main"
                >
                  <LayoutContent
                    focusOnDebugCopyLink={focusOnDebugCopyLink}
                    setFocusOnEllipsis={setFocusOnEllipsis}
                    showDebugInfo={showDebugInfo}
                    setShowDebugInfo={setShowDebugInfo}
                    styles={useLayoutContentStyles(globalState, innerClassNames)}
                  >
                    {children}
                  </LayoutContent>
                </div>
              </div>
              <Footer
                isDebugInfoShown={showDebugInfo}
                focusOnEllipsis={focusOnEllipsis}
                onDebugItemClick={(e) => {
                  e.preventDefault();
                  setFocusOnDebugCopyLink(true);
                  setShowDebugInfo(!showDebugInfo);
                }}
              />
            </div>
          </div>
        </div>
      </DirectionWrapper>
    );
  };

export default VerticalSplitLayoutFabric;
