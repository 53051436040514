import { useContext } from "react";
import { ViewId } from "../../../../../constants";
import { useNavigateDirection } from "../../../../../hooks/use-navigate-direction";
import { LoginContext } from "../../../login-context";
import { LoginActionType } from "../../../login-reducer";
import { type IPasswordViewStrings } from "../password-view-interface";

/**
 * @returns evict account link text + link on click handler
 * @param passwordViewStrings Flavored strings that are used by this hook
 */
export const useEvictAccountLink = (passwordViewStrings: IPasswordViewStrings) => {
  const { loginSwitchToCredPickerLinkEvictedAcct } = passwordViewStrings;

  const navigate = useNavigateDirection();
  const {
    viewState: {
      credentials: { evictedCredentials },
    },
    dispatchStateChange,
  } = useContext(LoginContext);

  const evictAccountLinkText =
    evictedCredentials && evictedCredentials.length > 0
      ? loginSwitchToCredPickerLinkEvictedAcct
      : "";

  const evictAccountLinkOnClick = () => {
    dispatchStateChange({
      type: LoginActionType.UpdateCredentials,
      payload: { useEvictedCredentials: true },
    });

    navigate(ViewId.Password, ViewId.CredentialPicker);
  };

  return { evictAccountLinkText, evictAccountLinkOnClick };
};
