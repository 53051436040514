import React from "react";
import { mergeClasses } from "@griffel/react";
import useFabricStyles from "../../../styles/fabric/fabric.styles";
import { useProgressIndicatorStyles } from "../../../styles/fabric/progress-indicator-fabric.styles";
import { isCssAnimationSupported, isHighContrast } from "../../../utilities/browser-helper";
import { marchingAntsGif } from "../../../utilities/image-helpers/accessible-images";
import { AccessibleImage } from "../../accessible-image";

/**
 * ProgressIndicator component
 * This component is mainly shown
 * 1. on the upper edge of the lightbox when the user is switching views OR
 * 2. inside the lightbox itself when a request is pending.
 * @returns PLACEHOLDER - NEEDS TO BE UPDATED
 */
const ProgressIndicatorFabric: React.FC = function ProgressIndicatorFabric() {
  const styles = useProgressIndicatorStyles();
  const useCssAnimation = isCssAnimationSupported() && !isHighContrast();

  return (
    <div
      className={styles.progress}
      role="progressbar"
      aria-label={getLocalString("General_PleaseWait")}
    >
      {useCssAnimation ? (
        <>
          <div />
          <div />
          <div />
          <div />
          <div />
        </>
      ) : (
        <AccessibleImage accessibleImages={marchingAntsGif} role="presentation" />
      )}
    </div>
  );
};

export default ProgressIndicatorFabric;

/**
 * ProgressIndicatorContainer component
 * This is mainly used to wrap the progress indicator component when rendering the indicator inside
 * (NOT on the upper edge of) the lightbox.
 * @param props The properties for the container component
 * @param props.children The child elements to render inside the container
 * @returns an instance of the ProgressIndicatorContainer component
 */
export const ProgressIndicatorContainer: React.FC = function ProgressIndicatorContainer({
  children,
}) {
  const fabricStyles = useFabricStyles();
  const progressIndicatorStyles = useProgressIndicatorStyles();
  return (
    <div
      className={mergeClasses(fabricStyles.row, progressIndicatorStyles.progressIndicatorContainer)}
      data-testid="progressIndicatorContainer"
    >
      {children}
    </div>
  );
};
