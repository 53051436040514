import { useEffect, useState } from "react";

/**
 * React hook that runs only once during mount and not on re-renders
 * This is used when do not want the effect to run again when the dependencies change.
 * @param callback The callback function to run once
 */
export const useEffectOnce = function useEffectOnce(callback: () => void) {
  const [useEffectRan, setUseEffectRan] = useState(false);
  useEffect(() => {
    if (!useEffectRan) {
      callback();
      setUseEffectRan(true);
    }
  }, [callback, useEffectRan]);
};
