"use strict";
// This file is an auto-executing script that is used to add Nonce attributes for Webpack
// https://webpack.js.org/guides/csp/
// This is not possible to test with unit testing in a meaningful way and thus this unit testing file is left without any tests
Object.defineProperty(exports, "__esModule", { value: true });
exports.nonce = void 0;
var scriptWithNonce = window.document.querySelector("script[nonce]");
exports.nonce = scriptWithNonce
    ? scriptWithNonce.nonce || scriptWithNonce.getAttribute("nonce")
    : undefined;
// @ts-ignore
__webpack_nonce__ = exports.nonce;
