export const DEFAULT_COLOR = "#1b1b1b";
export const SUBTITLE_FONT_SIZE = ".8125rem";
export const SUBTITLE_FONT_WEIGHT = 400;
export const SUBTITLE_LINE_HEIGHT = "20px";
export const TITLE_FONT_WEIGHT = "600";
export const TITLE_FONT_SIZE = "1.5rem";
export const TITLE_PADDING = "0px";
export const TITLE_MARGIN_TOP = "16px";
export const TITLE_MARGIN_BOTTOM = "12px";
export const TITLE_LINE_HEIGHT = "28px";
