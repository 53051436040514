import React, { useRef, useState } from "react";
import { useOptionsMenuButtonStyles } from "../../styles/fabric/options-menu-button-fabric.styles";
import { type AccessibleImages } from "../accessible-image";
import { ImageButton } from "../image-button";
import { LinkButton } from "../link-button";
import { useCloseOnOutsideClick } from "./hooks/use-close-on-outside-click";

export interface IOptionsMenuItemProps {
  itemId: string;
  itemText: string;
  isVisible?: boolean;
  hasFocus?: boolean;
  onClick: (event: React.SyntheticEvent<HTMLElement>) => void;
}

export interface IOptionsMenuButtonProps {
  icon: AccessibleImages;
  menuButtonIconClassName?: string;
  menuItemList: IOptionsMenuItemProps[];
  menuTestId?: string;
}

/**
 *
 * @param props The properties required for this component
 * @returns OptionsMenuButton component
 */
export const OptionsMenuButton: React.FC<IOptionsMenuButtonProps> = function OptionsMenuButton(
  props,
) {
  const { icon, menuButtonIconClassName, menuItemList, menuTestId } = props;
  const menuButtonStyles = useOptionsMenuButtonStyles();
  const optionsMenu = useRef<HTMLInputElement>(null);
  const [openMenu, setOpenMenu] = useState(false);

  // call hook that closes menu when user clicks outside of it
  useCloseOnOutsideClick(optionsMenu, setOpenMenu);

  const menuItems = menuItemList.map((item) => (
    <div>
      {item.isVisible && (
        <li role="none" key={item.itemId} className={menuButtonStyles.menuMargin}>
          <LinkButton
            linkId={item.itemId}
            className={menuButtonStyles.menuLink}
            text={item.itemText}
            onClick={item.onClick}
            hasFocus={item.hasFocus}
          />
        </li>
      )}
    </div>
  ));

  return (
    <div ref={optionsMenu}>
      <span className={menuButtonIconClassName} data-testid="iconImage">
        <ImageButton
          className={menuButtonStyles.menuButtonImageStyle}
          accessibleImageProps={{
            accessibleImages: icon,
            dataTestId: menuTestId || "options-menu-button",
          }}
          onClick={() => setOpenMenu(!openMenu)}
        />
      </span>

      {openMenu && (
        <ul className={menuButtonStyles.menu} role="menu">
          {menuItems}
        </ul>
      )}
    </div>
  );
};
