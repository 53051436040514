import React from "react";
import { LinkButton } from "../../../../../components/link-button";
import { useSignupClickHandler } from "../../../hooks/login-hooks";
import { useRecoverUsernameClickHandler } from "./use-recover-username-click-handler";

export type UsernameErrorLinkBindings = {
  signupLink: (chunks: string[]) => JSX.Element;
  recoverUsername: (chunks: string[]) => JSX.Element;
};

/**
 * Helper method used for creating an embedded link for formatted messages.
 * @param id The id for the link button
 * @param clickHandler The click handler for the link button
 * @param ariaLabel The optional aria label for the link button
 * @returns a function that returns a link button with the embedded text
 */
export const createLinkBinding = (id: string, clickHandler: () => void, ariaLabel?: string) => {
  const binding = (chunks: string[]) => (
    <LinkButton text={chunks[0]} onClick={clickHandler} linkId={id} ariaLabel={ariaLabel} />
  );
  return binding;
};

/**
 * This hook is used to get the signup and recover username link bindings, which is shown in the username error message.
 * @returns a function that provides embedded links for the username error message
 */
export const useUsernameErrorBindings = () => {
  const onSignupClicked = useSignupClickHandler();
  const onRecoverUsernameClicked = useRecoverUsernameClickHandler();

  const getEmbeddedLinkBindings = (username: string): UsernameErrorLinkBindings => {
    // create the link button bindings for the formatted message
    const signupLink = createLinkBinding("idA_PWD_SignUp", () => onSignupClicked(username));
    const recoverUsername = createLinkBinding("recoverUsername", () =>
      onRecoverUsernameClicked(username),
    );

    return {
      signupLink,
      recoverUsername,
    };
  };

  return getEmbeddedLinkBindings;
};
