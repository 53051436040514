import { type ProofType } from "../../../types/credential-types";
import { postApiRequest } from "../../request-helper";
import { OneTimeCodeTimeout } from "./one-time-code-constants";
import {
  type BaseOtcHelperParams,
  type OneTimeCodeJsonError,
  type OneTimeCodeJsonResponse,
  type OtcChannel,
  type OtcFailureParams,
  type OtcSuccessParams,
} from "./one-time-code-types";

export type OtcJsonParams = BaseOtcHelperParams & {
  channel: OtcChannel;
  originalRequest: string;
  getOtcJsonUrl: string;
  proofType?: ProofType;
  sessionIdentifier?: string;
};

export type OtcJsonBody = {
  Channel: OtcChannel;
  OriginalRequest: string;
  FlowToken: string;
  OldDeviceCode?: string;
};

/**
 * This method builds the body for the OTC JSON POST
 * @param params the OTC params for the JSON POST
 * @returns the body for the OTC JSON POST
 */
export const getOtcBody = function getOtcBody(params: OtcJsonParams) {
  const otcBody: OtcJsonBody = {
    Channel: params.channel,
    OriginalRequest: params.originalRequest,
    FlowToken: params.flowToken,
  };

  if (params.sessionIdentifier) {
    otcBody.OldDeviceCode = params.sessionIdentifier;
  }

  return otcBody;
};

/**
 * This method checks if the response is part of the thrown error and tries to extract the returned flowToken.
 * @param error an error thrown from the call to postJSON
 * @returns the same error passed in, possibly with "flowToken" as a new property
 */
export const addFlowTokenToError = function addFlowTokenToError(error: OneTimeCodeJsonError) {
  const otcError: OtcFailureParams = error;

  if (error.responseBody?.FlowToken) {
    otcError.flowToken = error.responseBody.FlowToken;
  }

  return otcError;
};

/**
 * This method uses the "getOtcJsonUrl" to make a JSON POST.
 * The response is parsed into a common OTC success type and resolved upon success.
 * In the event the request isn't a success, the response's "flowToken" will be added as possible to the throw Error.
 * Note: This is currently only used by AAD
 * @param params the parameters required for the request
 * @returns a promise that will resolve to the OTC success parameters (or throw an error on failure)
 */
export const getOneTimeCode = function getOneTimeCode(
  params: OtcJsonParams,
): Promise<OtcSuccessParams> {
  return postApiRequest<OneTimeCodeJsonResponse>(params.getOtcJsonUrl, {
    body: JSON.stringify(getOtcBody(params)),
    timeout: OneTimeCodeTimeout,
    useApiCanary: true,
  }).then(
    (response) => {
      const flowToken = response.FlowToken;
      return { response, flowToken };
    },
    (error: OneTimeCodeJsonError) => {
      throw addFlowTokenToError(error);
    },
  );
};
