import { makeStyles, shorthands } from "@griffel/react";

export const useProgressIndicatorStyles = makeStyles({
  progress: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "5px",
    width: "100%",
    "& div": {
      position: "absolute",
      height: "5px",
      width: "5px",
      backgroundColor: "#0067b8",
      zIndex: "100",
      ...shorthands.borderRadius("50%"),
      opacity: 0,
      animationDuration: "2s",
      MozAnimationDuration: "2s",
      animationIterationCount: "infinite",
      MozAnimationIterationCount: "infinite",
      animationName: {
        "0%, 20%": {
          left: "0%",
          animationTimingFunction: "ease-out",
          opacity: 0,
        },
        "25%": {
          opacity: 1,
        },
        "35%": {
          left: "45%",
          animationTimingFunction: "linear",
        },
        "65%": {
          left: "60%",
          animationTimingFunction: "ease-in",
        },
        "75%": {
          opacity: 1,
        },
        "80%, 100%": {
          left: "100%",
          opacity: 0,
        },
      },
    },
    "& > div:nth-child(1)": {
      "-webkit-animation-delay": "0.05s",
      "-moz-animation-delay": "0.05s",
      "-o-animation-delay": "0.05s",
      animationDelay: "0.05s",
    },
    "& > div:nth-child(2)": {
      "-webkit-animation-delay": "0.2s",
      "-moz-animation-delay": "0.2s",
      "-o-animation-delay": "0.2s",
      animationDelay: "0.2s",
    },
    "& > div:nth-child(3)": {
      "-webkit-animation-delay": "0.35s",
      "-moz-animation-delay": "0.35s",
      "-o-animation-delay": "0.35s",
      animationDelay: "0.35s",
    },
    "& > div:nth-child(4)": {
      "-webkit-animation-delay": "0.5s",
      "-moz-animation-delay": "0.5s",
      "-o-animation-delay": "0.5s",
      animationDelay: "0.5s",
    },
    "& > div:nth-child(5)": {
      "-webkit-animation-delay": "0.65s",
      "-moz-animation-delay": "0.65s",
      "-o-animation-delay": "0.65s",
      animationDelay: "0.65s",
    },
  },

  progressIndicatorContainer: {
    width: "100%",
    position: "relative",
    marginTop: "48px",
    marginBottom: "24px",
    outlineColor: "transparent",
  },
});
