import { RemoteNgcType } from "../../../constants/constants";

/**
 * Returns the appropriate description based on the remote ngc type, display sign, and whether the animated gif is being shown.
 * @param remoteNgcType The remote ngc type.
 * @param displaySign The remote ngc entrop/display sign.
 * @param showAnimatedGifWhilePolling Whether the animated gif is shown while polling.
 * @returns The description string to display for the view.
 * TODO @mjg-flavors: This function should be "unflavored", which means extracting static strings. The strings should be passed as a
 * parameter which implements a common IRemoteNgcStrings interface. That way, this function can be used for all flavors (each of which will
 * pass their own version of the strings that implements the interface)
 */
export const getDescription = (
  remoteNgcType: RemoteNgcType,
  displaySign: string,
  showAnimatedGifWhilePolling: boolean,
): string => {
  let description = "";
  if (remoteNgcType === RemoteNgcType.PushNotification) {
    if (showAnimatedGifWhilePolling) {
      description = displaySign
        ? getLocalString("Login_RemoteNGC_Desc_UnfamiliarDeviceV2")
        : getLocalString("Login_RemoteNGC_Desc");
    } else {
      description = displaySign
        ? getLocalString("Login_RemoteNGC_SessionPolling_Description_UnfamiliarDevice")
        : getLocalString("Login_RemoteNGC_SessionPolling_Description");
    }
  }
  // We will eventually need to add the condition for strings when remoteNgcType is ListSessions (ESTS scenario).

  return description;
};

/**
 * Returns the ariaDescribedBy for use with the credentialSwitchLink or displaySign.
 * @param title The title shown in remote ngc view. Used to construct the ariaDescribedBy string.
 * @param error The error shown in remote ngc view. Used to construct the ariaDescribedBy string.
 * @returns Returns the ariaDescribedBy for the credentialSwitchLink or displayCode element.
 */
export const getDisplaySignCredSwitchAriaDescribedBy = (title: string, error: string): string => {
  // TO-DO: AAD/ESTS - Update the ariaDescribedBy with showCredViewBrandingDesc after adding the branding description
  let ariaDescribedBy = title ? "remoteNgcTitle" : "";
  ariaDescribedBy += error ? " errorDescription" : " pollingDescription displaySign";
  return ariaDescribedBy;
};
