import { type AuthenticationState, createAuthenticationState } from "../../authentication-context";
import { type Flavors } from "../../constants";
import { initFeaturesConfig } from "../../features-config";
import LoginConfig, { initLoginConfig } from "../../flows/login/login-config";
import { type LoginState, createLoginState } from "../../flows/login/login-context";
import GlobalConfig, { initGlobalConfig } from "../../global-config";
import { type GlobalState, createGlobalState } from "../../global-context";
import type { ServerData } from "../../utilities/server-data";
import { cleanseUsername, htmlUnescape } from "../../utilities/strings-helper";
import { setUntrustedExternalInputText } from "../../utilities/untrusted-external-input-text";

/**
 * This method checks GlobalConfig and LoginConfig for the most appropriate username to use.
 * @returns the username to use for Login, safe and HTML escaped
 */
export function getUsername(): string {
  const { postUsername, signInUsername } = GlobalConfig.instance;
  const { prefillUsername, ztdUpnHint } = LoginConfig.instance;

  return postUsername.safeHtmlEscapedString || signInUsername || prefillUsername || ztdUpnHint;
}

/**
 * Parse the ServerData object sent from the IDP and return the initial config/state for the app contexts.
 * IMPORTANT: This should be called at module scope, NOT inside the render function!!
 * @param serverData The IDP-specific ServerData object sent from the server and read from the window object.
 * @param activeFlavor Rendered flavor
 * @returns The initial state for the login, global and authentication contexts.
 */
export function initLoginFromServerData(
  serverData: ServerData,
  activeFlavor: Flavors,
): {
  initialAuthenticationState: AuthenticationState;
  initialGlobalState: GlobalState;
  initialLoginState: LoginState;
} {
  initGlobalConfig(serverData);
  initFeaturesConfig(serverData);
  initLoginConfig(serverData);

  const unsafeUsername = htmlUnescape(getUsername());
  const userOptions = {
    username: setUntrustedExternalInputText(cleanseUsername(unsafeUsername), false),
    displayUsername: setUntrustedExternalInputText(unsafeUsername, false),
  };

  const initialGlobalState = createGlobalState(serverData, activeFlavor, userOptions);
  const initialLoginState = createLoginState(serverData);
  const initialAuthenticationState = createAuthenticationState(serverData);

  return { initialAuthenticationState, initialGlobalState, initialLoginState };
}
