import React, { useContext } from "react";
import { mergeClasses } from "@griffel/react";
import { GlobalContext } from "../../global-context";
import useFabricStyles from "../../styles/fabric/fabric.styles";

/**
 * @param root Root props
 * @param root.children Input components to contain with dynamic styling
 * @returns A div containing the CSS required based on the usage of BoilerPlateText
 */
export const InputContainer: React.FC = function InputContainer({ children }) {
  const {
    globalState: {
      styles: { boilerPlateText },
    },
  } = useContext(GlobalContext);
  const fabricStyles = useFabricStyles();
  const buttonPositionClasses = mergeClasses(
    fabricStyles.positionButtons,
    boilerPlateText
      ? fabricStyles.inputContainerWithBoilerPlate
      : fabricStyles.inputContainerWithoutBoilerPlate,
  );

  return (
    <div data-testid="inputContainer" className={buttonPositionClasses}>
      {children}
    </div>
  );
};
