import React, { useContext, useState } from "react";
import { mergeClasses } from "@griffel/react";
import { CredentialSwitchLinksComponent } from "../../../../components/credential-switch-links/credential-switch-links";
import { TextInput } from "../../../../components/inputs/text-input";
import { LinkButton } from "../../../../components/link-button";
import {
  TextButtonContainer,
  TextButtonFabric,
} from "../../../../components/text-button/fabric/text-button-fabric";
import { CredentialType } from "../../../../constants/constants";
import { FlowId, ViewId } from "../../../../constants/routing-constants";
import GlobalConfig from "../../../../global-config";
import { useActivateView } from "../../../../hooks/use-activate-view";
import { useDocumentTitle } from "../../../../hooks/use-document-title";
import useFabricStyles, { useStaticFabricStyles } from "../../../../styles/fabric/fabric.styles";
import { ProofType } from "../../../../types/credential-types";
import { LoginTitleFabric } from "../../components/fabric/login-title-fabric";
import { SecondaryContentContainerFabric } from "../../components/fabric/secondary-content-container-fabric";
import { commonLoginStringsFabric } from "../../fabric/common-login-strings-fabric";
import { LoginContext } from "../../login-context";
import { useInputValidationFunction } from "../hooks/use-input-validation-function";
import { useProofConfirmationInputForm } from "../hooks/use-proof-confirmation-input-form";
import { useProofConfirmationViewProperties } from "../hooks/use-proof-confirmation-view-properties";
import { useSendOneTimeCode } from "../hooks/use-send-one-time-code";
import { useShowBackArrowButton } from "../hooks/use-show-back-arrow-button";
import { useSwitchToEvictedCredentialPickerLinkClickHandler } from "../hooks/use-switch-to-evicted-credential-picker-link-click-handler";
import { useSwitchToOtcLinkClickHandler } from "../hooks/use-switch-to-otc-link-click-handler";

/**
 * ProofConfirmationView component
 *
 * This view asks the user to confirm their one-time code proof prior to showing them the OneTimeCode view.
 * Upon successful confirmation of their proof, the one-time code server request is made and the code is
 * sent to their email address or phone number (SMS).
 *
 * @returns A rendered instance of this component
 */
export const ProofConfirmationViewFabric: React.FC = function ProofConfirmationViewFabric() {
  // Global config data
  const { showButtons } = GlobalConfig.instance;

  // Login context data
  const {
    viewState: {
      credentials: {
        availableCredentials,
        evictedCredentials,
        otcCredential = {
          credentialType: CredentialType.OneTimeCode,
          proof: { display: "", type: ProofType.SMS },
        },
      },
    },
  } = useContext(LoginContext);

  const [isRequestPending, setIsRequestPending] = useState<boolean>(false);

  const {
    documentTitle,
    lightboxTitleId,
    lightboxTitle,
    lightboxDescriptionId,
    lightboxDescription,
    inputAriaLabel,
    inputAriaDescribedBy,
    maxLength,
    placeholder,
    type: inputType,
    renderCredentialLinks,
  } = useProofConfirmationViewProperties(otcCredential, {
    commonLoginStrings: commonLoginStringsFabric,
  });

  const {
    focus,
    showError,
    externalError,
    onBlur,
    onFocus,
    value,
    changeHandler,
    setValidationError: onValidationError,
    onFormSubmission,
  } = useProofConfirmationInputForm();
  const inputValidationFunction = useInputValidationFunction(otcCredential);
  const onSubmit = onFormSubmission(
    useSendOneTimeCode(otcCredential, isRequestPending, setIsRequestPending),
    ViewId.ProofConfirmation,
  );

  const switchToOtcLinkClickHandler = useSwitchToOtcLinkClickHandler(changeHandler);

  const switchToEvictedCredentialPickerLinkClickHandler =
    useSwitchToEvictedCredentialPickerLinkClickHandler();

  const showBackButton = useShowBackArrowButton();

  useActivateView(ViewId.ProofConfirmation, FlowId.Login, {
    showBackButtonOnActiveView: showBackButton,
    showIdentityBanner: true,
  });
  useDocumentTitle(documentTitle);
  useStaticFabricStyles();

  const fabricStyles = useFabricStyles();

  return (
    <div>
      <form
        name="f1"
        id="i0281"
        data-testid="proofConfirmationForm"
        noValidate
        spellCheck="false"
        method="post"
        autoComplete="off"
        onSubmit={onSubmit}
      >
        <LoginTitleFabric titleId={lightboxTitleId} title={lightboxTitle} />

        <div className={mergeClasses(fabricStyles.row, fabricStyles.formGroup)}>
          <div id={lightboxDescriptionId} className={fabricStyles.formGroup}>
            {lightboxDescription}
          </div>

          <TextInput
            id="proofConfirmationText"
            name="ProofConfirmation"
            placeholder={placeholder}
            type={inputType || "text"}
            showErrorInline={showError}
            externalError={externalError}
            value={value}
            changeHandler={changeHandler}
            validationErrorHandler={onValidationError}
            inputValidationFunc={inputValidationFunction}
            ariaLabel={inputAriaLabel}
            ariaDescribedBy={inputAriaDescribedBy}
            hasFocus={focus}
            hasInitialFocus
            focusHandler={onFocus}
            blurHandler={onBlur}
            autocomplete="off"
            maxLength={maxLength}
          />
        </div>

        <div className={fabricStyles.buttonMargin}>
          <div className={fabricStyles.row}>
            <SecondaryContentContainerFabric>
              {renderCredentialLinks && (
                <>
                  <div className={fabricStyles.formGroup}>
                    <CredentialSwitchLinksComponent
                      sourceViewId={ViewId.ProofConfirmation}
                      availableCredentials={availableCredentials}
                      currentCredential={otcCredential}
                      setRequestPendingFlag={setIsRequestPending}
                      shouldUpdateOtcCredential
                    />
                  </div>

                  {evictedCredentials.length > 0 && (
                    <div className={fabricStyles.formGroup}>
                      <LinkButton
                        linkId="evictedAccount"
                        text={getLocalString("Login_SwitchToCredPicker_Link_EvictedAcct")}
                        onClick={switchToEvictedCredentialPickerLinkClickHandler}
                      />
                    </div>
                  )}
                </>
              )}

              <div className={fabricStyles.formGroup}>
                <LinkButton
                  linkId="proofConfirmationToggle"
                  text={getLocalString("AlreadyHaveCode")}
                  onClick={switchToOtcLinkClickHandler}
                />
              </div>
            </SecondaryContentContainerFabric>
          </div>
        </div>

        <div className={fabricStyles.row}>
          <TextButtonContainer>
            {showButtons && (
              <TextButtonFabric
                isButtonType={false}
                isPrimary
                label={getLocalString("TFA_SendCodeButtonText")}
              />
            )}
          </TextButtonContainer>
        </div>
      </form>
    </div>
  );
};
