import type React from "react";
import { useContext } from "react";
import { AuthenticationContext } from "../../../../authentication-context";
import { AuthenticationActionType } from "../../../../authentication-reducer";
import { ViewId } from "../../../../constants/routing-constants";
import { GlobalContext } from "../../../../global-context";
import { useNavigateDirection } from "../../../../hooks/use-navigate-direction";
import { type OneTimeCodeCredential, ProofType } from "../../../../types/credential-types";
import {
  type OtcFailureParams,
  type OtcSuccessParams,
  OtcChannel,
  OtcPurposes,
  OtcStatus,
} from "../../../../utilities/api-helpers/one-time-code/one-time-code-types";
import { isCredentialEmailProofType } from "../../../../utilities/credential-helper";
import { getRouteFromViewId } from "../../../../utilities/routing-helper";
import { getInvalidProofError } from "../proof-confirmation-view-util";

/**
 * @param otcCredential the current one time code credential
 * @param setIsRequestPending dispatch function to set the `isRequestPending` state variable
 * @returns a function that returns the input params required to make a one time code request
 */
export const useGetOneTimeCodeParams = (
  otcCredential: OneTimeCodeCredential,
  setIsRequestPending: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const {
    globalState: {
      user: { username },
    },
  } = useContext(GlobalContext);

  const {
    authState: { flowTokenValue: initialFlowToken },
    dispatchStateChange: dispatchAuthStateChange,
  } = useContext(AuthenticationContext);

  const {
    proof: { clearDigits = "", data: proofData, isNopa, type: proofType },
  } = otcCredential;
  const isEmailProof = isCredentialEmailProofType(otcCredential);

  const navigate = useNavigateDirection();

  return (input: string, errorHandler: (error: string | JSX.Element) => void) => {
    const onSuccess = (successValues: OtcSuccessParams) => {
      const finalFlowToken =
        successValues.flowToken || successValues.response.FlowToken || initialFlowToken;
      dispatchAuthStateChange({
        type: AuthenticationActionType.SetFlowTokenValue,
        payload: finalFlowToken,
      });

      setIsRequestPending(false);

      navigate(ViewId.ProofConfirmation, getRouteFromViewId(ViewId.OneTimeCode));
    };

    const onFailure = (error: OtcFailureParams) => {
      let externalError: string;

      setIsRequestPending(false);

      const finalFlowToken = error.flowToken || initialFlowToken;
      dispatchAuthStateChange({
        type: AuthenticationActionType.SetFlowTokenValue,
        payload: finalFlowToken,
      });

      const errorNumericId = error.otcStatus;
      switch (errorNumericId) {
        case OtcStatus.ftError:
          externalError = getLocalString("OneTimeCode_SessionExpiredError");
          break;
        case OtcStatus.inputError:
          externalError = getInvalidProofError(isEmailProof, clearDigits);
          break;
        default:
          externalError = getLocalString("OneTimeCode_SendFailedError");
          break;
      }

      errorHandler(externalError);
    };

    const otcParams = {
      onSuccess,
      onFailure,
      channel: proofType === ProofType.Email ? OtcChannel.emailAddress : OtcChannel.mobileSms,
      flowToken: initialFlowToken,
      ...(input ? { proofConfirmation: input } : {}),
      proofData,
      proofType,
      purpose: isNopa ? OtcPurposes.noPassword : OtcPurposes.otcLogin,
      username,
    };

    return otcParams;
  };
};
