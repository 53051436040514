import { htmlEscape, htmlUnescape } from "./strings-helper";

/* UntrustedExternalInputText
 * This type should be used with strings set by an end-user at any point in the chain
 * (i.e. usernames, display names, app names, company and tenant names, machine names, etc.).
 * These strings should always be considered unsafe to set as html to protect from malicious actors.
 *
 * User entered strings that are sent from the server as html-escaped can be considered safe for use
 * in html such as formatted-text-with-bindings or dangerouslySetHtml, for example. They should be used
 * un-encoded in non-html strings.
 *
 * If an unsafe string is assigned to a variable of type string, that variable name must contain the
 * prefix "unsafe"
 * - i.e. const unsafeAppName = appName.unsafeString; // TODO: enforce variable naming with custom ESLint rule
 */
export type UntrustedExternalInputText = {
  safeHtmlEscapedString: string;
  unsafeUnescapedString: string;
};

/**
 * UntrustedExternalInputText type check
 * Intended to be used where a string value may be either a standard string or UntrustedExternalInputText.
 * @param text string or UntrustedExternalInputText
 * @returns  true if text is not a string
 */
export function isTextUntrustedExternalInputText(
  text: string | UntrustedExternalInputText,
): text is UntrustedExternalInputText {
  return typeof text !== "string";
}

/**
 * set UntrustedExternalInputText from a string
 * @param text String to set
 * @param isHtmlEscaped (defaults to true) If text is not htmlEscaped (i.e. it comes from a textbox,
 * an unescaped variable, etc. and not directly from data sent from the server), set this to false.
 * @returns UntrustedExternalInputText object
 */
export function setUntrustedExternalInputText(
  text: string,
  isHtmlEscaped = true,
): UntrustedExternalInputText {
  const returnText = {} as UntrustedExternalInputText;

  returnText.safeHtmlEscapedString = isHtmlEscaped ? text : htmlEscape(text);
  returnText.unsafeUnescapedString = isHtmlEscaped ? htmlUnescape(text) : text;

  return returnText;
}
