import React, { useContext, useEffect, useRef } from "react";
import GlobalConfig from "../global-config";
import { GlobalContext } from "../global-context";
import { PostRedirectContext } from "../post-redirect-context";
import { ServiceDiagEventNames } from "../telemetry-helpers/service-diag-event-names";
import { useTelemetry } from "../telemetry-helpers/use-telemetry";
import { trimQsParams } from "../utilities/strings-helper";

/**
 * A component that redirects to a URL using a POST method. It auto submits the form when the submit prop is set to true.
 * Note: Can't use the fetch api since we sometimes need to update/change the target value on the form which is an attribute not supported by fetch api
 * @returns a form with applied input post params which is auto-submitted when the submit prop is set to true.
 */
export const PostRedirectForm: React.FC = function PostRedirectForm() {
  const { telemetry } = GlobalConfig.instance;
  const {
    globalState: { activeView, activeFlow, activeFlavor },
  } = useContext(GlobalContext);
  const {
    postRedirectState: { url, postParams, target, submitForm = false },
  } = useContext(PostRedirectContext);

  const { logServiceDiagEvent } = useTelemetry(telemetry, {
    activeView,
    activeFlow,
    activeFlavor,
  });

  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (submitForm) {
      logServiceDiagEvent({
        metricName: ServiceDiagEventNames.Redirect,
        metricValue: trimQsParams(url),
      });
      formRef.current!.submit();
    }
  }, [logServiceDiagEvent, url, submitForm]);

  return (
    <form
      data-testid="post-redirect-form"
      ref={formRef}
      method="POST"
      aria-hidden="true"
      target={target || "_top"}
      action={url}
    >
      {postParams &&
        Object.entries(postParams).map(([key, value]) => (
          <input type="hidden" name={key} value={value || ""} />
        ))}
    </form>
  );
};
