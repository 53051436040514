import { useContext, useEffect, useState } from "react";
import { type Location, useLocation } from "react-router-dom";
import { mergeClasses } from "@griffel/react";
import { GlobalContext } from "../global-context";
import useLayoutAnimateStyles from "../styles/fabric/layout-animate-fabric.styles";

interface INavigationStageMap {
  slideIn: string;
  slideOut: string;
}

const navigationStages: Record<string, INavigationStageMap> = {
  forward: {
    slideIn: "slideInNext",
    slideOut: "slideOutNext",
  },
  back: {
    slideIn: "slideInBack",
    slideOut: "slideOutBack",
  },
};

/**
 * React hook used for applying view transition animations based on location change.
 * @returns An array with the current location, view transition animation, animation end event callback, and banner transition animation.
 */
export const useRouteAnimation = (): [
  displayLocation: Location,
  transitionView: string,
  onAnimationEnd: () => void,
  transitionBanner: string,
] => {
  const {
    globalState: { navigationDirection },
  } = useContext(GlobalContext);
  const animateStyles = useLayoutAnimateStyles();
  const location = useLocation();

  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransitionStage] = useState(
    navigationStages[navigationDirection].slideIn,
  );
  const [transitionBanner, setTransitionBanner] = useState(animateStyles.fadeIn);
  const [transitionView, setTransitionView] = useState(
    mergeClasses(animateStyles.animate, animateStyles.slideInNext),
  );

  useEffect(() => {
    // set the transition stage and animation style for the outgoing view based on the navigation direction
    if (location.pathname !== displayLocation.pathname) {
      setTransitionStage(navigationStages[navigationDirection].slideOut);
      const atomicStyle =
        navigationDirection === "forward" ? animateStyles.slideOutNext : animateStyles.slideOutBack;
      setTransitionView(mergeClasses(animateStyles.animate, atomicStyle));
      setTransitionBanner(animateStyles.fadeOut);
    }
  }, [location, displayLocation, navigationDirection, animateStyles]);

  const onAnimationEnd = () => {
    // set the transition stage and animation style for the incoming view based on the navigation direction
    if (transitionStage === navigationStages[navigationDirection].slideOut) {
      setTransitionStage(navigationStages[navigationDirection].slideIn);
      const atomicStyle =
        navigationDirection === "forward" ? animateStyles.slideInNext : animateStyles.slideInBack;
      setTransitionView(mergeClasses(animateStyles.animate, atomicStyle));
      setTransitionBanner(animateStyles.fadeIn);
      setDisplayLocation(location);
    }
  };

  return [displayLocation, transitionView, onAnimationEnd, transitionBanner];
};
