import React from "react";
import { Route, Routes } from "react-router-dom";
import RouteAnimation from "../../../components/route-animation";
import { FlowId, ViewId } from "../../../constants/routing-constants";
import { getRouteFromViewId } from "../../../utilities/routing-helper";
import AgreementView from "../../../views/fabric/agreement-view-fabric";
import { AccountPickerViewFabric } from "../account-picker/fabric/account-picker-view-fabric";
import { ConfirmSendViewFabric } from "../confirm-send/fabric/confirm-send-view-fabric";
import { CredentialPickerViewFabric } from "../credential-picker/fabric/credential-picker-view-fabric";
import { ErrorViewFabric } from "../error/fabric/error-view-fabric";
import { FetchSessionsProgressViewFabric } from "../fetch-sessions-progress/fabric/fetch-sessions-progress-view-fabric";
import { FidoViewFabric } from "../fido/fabric/fido-view-fabric";
import { IdpDisambiguationViewFabric } from "../idp-disambiguation/fabric/idp-disambiguation-view-fabric";
import { IdpRedirectViewFabric } from "../idp-redirect/fabric/idp-redirect-view-fabric";
import { IdpRedirectSpeedbumpViewFabric } from "../idp-redirect-speedbump/fabric/idp-redirect-speedbump-view-fabric";
import { LoginInitialViewPickerFabric } from "../initial-view-picker/fabric/initial-view-picker-fabric";
import { type LoginState, LoginProvider } from "../login-context";
import { OneTimeCodeViewFabric } from "../one-time-code/fabric/one-time-code-view-fabric";
import { PhoneDisambiguationViewFabric } from "../phone-disambiguation/fabric/phone-disambiguation-view-fabric";
import { ProofConfirmationViewFabric } from "../proof-confirmation/fabric/proof-confirmation-view-fabric";
import { RemoteConnectCanaryValidationViewFabric } from "../remote-connect-canary-validation/fabric/remote-connect-canary-validation-view-fabric";
import { RemoteNgcViewFabric } from "../remote-ngc/fabric/remote-ngc-view-fabric";
import { SignInOptionsViewFabric } from "../signin-options/fabric/signin-options-view-fabric";
import { PasswordViewFabric } from "../views/password/fabric/password-view-fabric";
import { UsernameViewFabric } from "../views/username";

/**
 * The Login flow component
 * @param props Input properties for the Login flow
 * @param props.initialState The initial state that should be passed to the LoginProvider
 * @returns An instance of this component
 */
export const LoginFlowFabric: React.FC<{ initialState: LoginState }> = function LoginFlowFabric({
  initialState,
}) {
  return (
    <LoginProvider initialState={initialState}>
      <Routes>
        <Route path="*" element={<LoginInitialViewPickerFabric />} />
        <Route path={getRouteFromViewId(ViewId.Username)} element={<UsernameViewFabric />} />
        <Route path={getRouteFromViewId(ViewId.Password)} element={<PasswordViewFabric />} />
        <Route
          path={getRouteFromViewId(ViewId.PhoneDisambiguation)}
          element={<PhoneDisambiguationViewFabric />}
        />
        <Route
          path={getRouteFromViewId(ViewId.SignInOptions)}
          element={<SignInOptionsViewFabric />}
        />
        <Route path={getRouteFromViewId(ViewId.Error)} element={<ErrorViewFabric />} />
        <Route
          path={getRouteFromViewId(ViewId.IdpDisambiguation)}
          element={<IdpDisambiguationViewFabric />}
        />
        <Route path={getRouteFromViewId(ViewId.IdpRedirect)} element={<IdpRedirectViewFabric />} />
        <Route path={getRouteFromViewId(ViewId.RemoteNgc)} element={<RemoteNgcViewFabric />} />
        <Route
          path={getRouteFromViewId(ViewId.AccountPicker)}
          element={<AccountPickerViewFabric />}
        />
        <Route
          path={getRouteFromViewId(ViewId.RemoteConnectCanaryValidation)}
          element={<RemoteConnectCanaryValidationViewFabric nextView={ViewId.Username} />}
        />
        <Route
          path={getRouteFromViewId(ViewId.CredentialPicker)}
          element={<CredentialPickerViewFabric />}
        />
        <Route
          path={getRouteFromViewId(ViewId.IdpRedirectSpeedbump)}
          element={<IdpRedirectSpeedbumpViewFabric />}
        />
        <Route
          path={getRouteFromViewId(ViewId.FetchSessionsProgress)}
          element={<FetchSessionsProgressViewFabric />}
        />
        <Route path={getRouteFromViewId(ViewId.Fido)} element={<FidoViewFabric />} />
        <Route path={getRouteFromViewId(ViewId.OneTimeCode)} element={<OneTimeCodeViewFabric />} />
        <Route path={getRouteFromViewId(ViewId.RemoteNgc)} element={<RemoteNgcViewFabric />} />
        <Route path={getRouteFromViewId(ViewId.ConfirmSend)} element={<ConfirmSendViewFabric />} />
        <Route
          path={getRouteFromViewId(ViewId.ProofConfirmation)}
          element={<ProofConfirmationViewFabric />}
        />
      </Routes>
    </LoginProvider>
  );
};

/**
 * A wrapper component for the routes defined for LoginApp. Includes Login Flow.
 * @param props The component properties
 * @param props.loginState initial state of the component
 * @returns An instance of this component
 */
export const LoginRoutesFabric: React.FC<{
  loginState: LoginState;
}> = function LoginRoutesFabric({ loginState }) {
  return (
    <RouteAnimation>
      <Route path="*" element={<LoginFlowFabric initialState={loginState} />} />
      <Route
        path={getRouteFromViewId(ViewId.ViewAgreement)}
        element={<AgreementView hostingFlow={FlowId.Login} />}
      />
    </RouteAnimation>
  );
};
