import React from "react";
import { type LoginPostProps } from "./login-types";

/**
 * Component that contains all shared hidden inputs for Login flow
 * @param props for all the hidden inputs
 * @returns a rendered instance of this component
 */
export const LoginFlowPostHiddenInputs: React.FC<LoginPostProps> =
  function LoginFlowPostHiddenInputs(props: LoginPostProps) {
    const {
      canary,
      cleansedUsername,
      context,
      flowTokenName,
      flowTokenValue,
      foundMsas,
      isFidoSupported,
      isKmsiChecked,
      loginOption,
      paginatedState,
      postType,
      postedForceSignIn,
      randomBlob,
      rngcDefaultType,
      rngcEntropy,
      rngcSessionIdentifier,
      showCookieBanner,
      displayUsername,
    } = props;

    return (
      <div data-testid="hiddenInputs">
        <input type="hidden" name="ps" data-testid="ps" id="ps" value={paginatedState} />
        <input
          type="hidden"
          name="psRNGCDefaultType"
          data-testid="psRNGCDefaultType"
          id="psRNGCDefaultType"
          value={rngcDefaultType ?? ""}
        />
        <input
          type="hidden"
          name="psRNGCEntropy"
          data-testid="psRNGCEntropy"
          id="psRNGCEntropy"
          value={rngcEntropy}
        />
        <input
          type="hidden"
          name="psRNGCSLK"
          data-testid="psRNGCSLK"
          id="psRNGCSLK"
          value={rngcSessionIdentifier}
        />
        <input type="hidden" name="canary" data-testid="canary" id="canary" value={canary} />
        <input type="hidden" name="ctx" data-testid="ctx" id="ctx" value={context} />
        <input
          type="hidden"
          name="hpgrequestid"
          data-testid="hpgrequestid"
          id="hpgrequestid"
          value=""
        />
        <input
          type="hidden"
          name={flowTokenName}
          data-testid="i0327"
          id="i0327"
          value={flowTokenValue}
        />
        <input type="hidden" name="PPSX" data-testid="PPSX" id="PPSX" value={randomBlob} />
        <input type="hidden" name="NewUser" data-testid="NewUser" id="NewUser" value={1} />
        <input
          type="hidden"
          name="FoundMSAs"
          data-testid="FoundMSAs"
          id="FoundMSAs"
          value={foundMsas}
        />
        <input
          type="hidden"
          name="fspost"
          data-testid="fspost"
          id="fspost"
          value={postedForceSignIn ? 1 : 0}
        />
        <input type="hidden" name="i21" data-testid="i21" id="i21" value={0} />
        <input
          type="hidden"
          name="CookieDisclosure"
          data-testid="CookieDisclosure"
          id="CookieDisclosure"
          value={showCookieBanner ? 1 : 0}
        />
        <input
          type="hidden"
          name="IsFidoSupported"
          data-testid="IsFidoSupported"
          id="IsFidoSupported"
          value={isFidoSupported ? 1 : 0}
        />
        <input
          type="hidden"
          name="isSignupPost"
          data-testid="isSignupPost"
          id="isSignupPost"
          value={0}
        />
        <input
          type="hidden"
          name="isRecoveryAttemptPost"
          data-testid="isRecoveryAttemptPost"
          id="isRecoveryAttemptPost"
          value={0}
        />
        <input type="hidden" name="i13" data-testid="i13" id="i13" value={isKmsiChecked ? 1 : 0} />
        <input type="hidden" name="login" data-testid="login" id="login" value={cleansedUsername} />
        <input
          type="hidden"
          name="loginfmt"
          data-testid="loginfmt"
          id="loginfmt"
          autoComplete="displayUsername"
          value={displayUsername}
        />
        <input type="hidden" name="type" data-testid="type" id="type" value={postType} />
        <input
          type="hidden"
          name="LoginOptions"
          data-testid="LoginOptions"
          id="LoginOptions"
          value={loginOption}
        />
        <input type="hidden" name="lrt" data-testid="lrt" id="lrt" value="" />
        <input
          type="hidden"
          name="lrtPartition"
          data-testid="lrtPartition"
          id="lrtPartition"
          value=""
        />
        <input type="hidden" name="hisRegion" data-testid="hisRegion" id="hisRegion" value="" />
        <input
          type="hidden"
          name="hisScaleUnit"
          data-testid="hisScaleUnit"
          id="hisScaleUnit"
          value=""
        />
      </div>
    );
  };
