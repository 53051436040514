import { NoValidationRegEx } from "../components/inputs/input-regex-constants";

/**
 * @param inputValue String value from input field
 * @param validationRegex Regex to match against
 * @returns false if regex is not required or matches
 *          true if the input is empty or does not match regex
 */
export function shouldShowValidationError(inputValue: string, validationRegex: string) {
  if (validationRegex === NoValidationRegEx) {
    return false;
  }

  if (!inputValue || inputValue.trim() === "") {
    return true;
  }

  return !inputValue.match(validationRegex);
}

/**
 * Provides a function for inline validation of an input value. Validation rule is based on regex match and empty field.
 * @param validationError Error string if value does not match validation regex
 * @param validationRegex Regex for inline validation
 * @param emptyInputError Error string if user attempts to submit a blank field
 * @returns A function for inline validation for input value
 */
export function simpleInputValidation(
  validationError: string = "",
  validationRegex: string = NoValidationRegEx,
  emptyInputError: string = getLocalString("Error_1018"),
): (value: string) => string {
  return (value: string): string => {
    if (!value || value.trim() === "") {
      return emptyInputError;
    }

    const errorMessage = !value.match(validationRegex) ? validationError : "";
    return errorMessage;
  };
}
