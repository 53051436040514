import { type ResponseBody } from "../../constants/constants";
import { appendOrReplaceQueryStringParams } from "../strings-helper";
import { type IPollingProps } from "./use-polling";

export const AuthorizationState = {
  Pending: 0,
  Declined: 1,
  Approved: 2,
  Invalid: 3,
  USIDApproved: 4,
  Consumed: 5,
  Visited: 6,
  LPTConsumed: 7,
};

export enum SessionLookupKeyType {
  TransferToken = "TSFT",
  RemoteNgc = "NGC",
}

export interface ISessionPollingProps extends IPollingProps {
  /* (required) URL to poll */
  pollingUrl: string;

  /* (required) Callback function - actions that should be taken if the session is approved */
  onSessionApproved: () => void;

  /* (required) Callback function - actions that should be taken if the session is denied */
  onSessionDenied: () => void;

  /* (required) Callback function - actions that should be taken if the session times out */
  onSessionTimeout: () => void;

  /* (optional) Callback function - actions that should be taken if an unexpected error is encountered.
   * defaults to onSessionTimeout if not supplied */
  onSessionError?: () => void;

  /* (optional) Callback function - actions that should be taken if a "session visited" response is encountered.
   * throws error by default. Not a commonly used response type. */
  onSessionVisited?: (otc: number) => void;

  /* (optional) slk or device code to add to qs and request body */
  sessionLookupKey?: string;

  /* (optional) slkt to add to qs */
  sessionLookupKeyType?: string;
}

export type SessionResponse = ResponseBody & {
  AuthorizationState?: number;
  Code?: number;
};

/**
 * Update polling URL and prepare postData if sessionIdentifier is present
 * @param pollingUrl URL to add qs params to
 * @param sessionLookupKey slk/code to append to qs
 * @param sessionLookupKeyType if needed, add the type of session identifier to the qs
 * @returns url updated with qs params and postData with DeviceCode
 */
export function updateSessionPollingUrl(
  pollingUrl: string,
  sessionLookupKey?: string,
  sessionLookupKeyType?: string,
) {
  return sessionLookupKey
    ? appendOrReplaceQueryStringParams(
        pollingUrl,
        sessionLookupKeyType
          ? {
              slk: sessionLookupKey,
              slkt: sessionLookupKeyType,
            }
          : {
              code: sessionLookupKey,
            },
      )
    : pollingUrl;
}
