import React, { useContext } from "react";
import { makeStyles, mergeClasses, shorthands } from "@griffel/react";
import { LayoutTemplateType } from "../constants/constants";
import { GlobalContext } from "../global-context";
import { MOBILE_LAYOUT_SIZE } from "../styles/fabric/constants-fabric.styles";

const useBackgroundLogoStyles = makeStyles({
  backgroundLogo: {
    maxWidth: "256px",
    maxHeight: "36px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "24px",
    animationDuration: "1s",
    animationName: {
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
    },

    [MOBILE_LAYOUT_SIZE]: {
      marginTop: "24px",
    },
  },

  verticalSplit: {
    marginTop: "44px",
    ...shorthands.padding(0, "44px"),

    [MOBILE_LAYOUT_SIZE]: {
      ...shorthands.padding(0),
    },
  },
});

/**
 * BackgroundLogo component
 * @returns PLACEHOLDER - NEEDS TO BE UPDATED
 */
const BackgroundLogo: React.FC = function BackgroundLogo() {
  const {
    globalState: {
      styles: { backgroundLogoUrl, layoutTemplate },
    },
  } = useContext(GlobalContext);
  const logoStyles = useBackgroundLogoStyles();
  const classNames = mergeClasses(
    logoStyles.backgroundLogo,
    layoutTemplate === LayoutTemplateType.VerticalSplit ? logoStyles.verticalSplit : "",
  );

  if (!backgroundLogoUrl) {
    return null;
  }

  return (
    <img
      className={classNames}
      data-testid="backgroundLogo"
      alt="presentation"
      src={backgroundLogoUrl}
    />
  );
};

export default BackgroundLogo;
