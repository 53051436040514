import React, { useEffect, useState } from "react";
import { mergeClasses } from "@griffel/react";
import useFabricStyles from "../styles/fabric/fabric.styles";
import { usePhoneNumberStyles } from "../styles/fabric/phone-number-fabric.styles";
import { type ICountryInfo } from "../utilities/country-helper";
import { type ITextInputProps, TextInput } from "./inputs/text-input";
import ErrorContainer from "./error-container";
import { type IPhoneCountryDropdownProps, PhoneCountryDropdown } from "./phone-country-dropdown";

export interface IPhoneNumberProps {
  dropdownProps: IPhoneCountryDropdownProps;
  inputProps: ITextInputProps;
  errorMessage?: string | JSX.Element;
  phoneNumberChangeHandler?: (country: ICountryInfo, phoneNumber: string) => void;
}

/**
 * PhoneNumber component
 * @param props The properties for this component.
 * @param props.dropdownProps The props to pass to the phone country dropdown component.
 * @param props.inputProps The props to pass to the text input component.
 * @param props.errorMessage The message to display when there is a validation or external error.
 * @param props.phoneNumberChangeHandler Callback for when the country dropdown or phone number input is updated.
 * @returns A shared component that includes the phone country dropdown and phone number input field
 */
export const PhoneNumber: React.FC<IPhoneNumberProps> = function PhoneNumber(props) {
  const {
    dropdownProps,
    inputProps,
    phoneNumberChangeHandler = () => {},
    errorMessage = "",
  } = props;

  const { id, value = "", showErrorInline = false } = inputProps;
  const { defaultCountryData, useInlinePhoneNumber } = dropdownProps;

  const styles = usePhoneNumberStyles();
  const fabricStyles = useFabricStyles();

  const [country, setCountry] = useState(defaultCountryData);
  const [phoneNumberInput, setPhoneNumberInput] = useState(value);

  useEffect(() => {
    phoneNumberChangeHandler(country, phoneNumberInput);
  }, [country, phoneNumberInput, phoneNumberChangeHandler]);

  return (
    <div className={fabricStyles.row}>
      {showErrorInline && errorMessage && (
        <ErrorContainer
          id={`${id}Error`}
          className={mergeClasses(styles.errorContainer, fabricStyles.alertError)}
        >
          {errorMessage}
        </ErrorContainer>
      )}
      <div className={styles.phoneNumberContainer}>
        <PhoneCountryDropdown
          {...dropdownProps}
          hasError={showErrorInline && !!errorMessage}
          onChangeHandler={(countryValue) => setCountry(countryValue)}
        />
        <div className={useInlinePhoneNumber ? styles.inlineInput : styles.nonInlineInput}>
          <TextInput
            {...inputProps}
            value={phoneNumberInput}
            externalError={errorMessage}
            changeHandler={(e) => setPhoneNumberInput(e.target.value)}
            useExternalErrorContainer
          />
        </div>
      </div>
    </div>
  );
};

export default PhoneNumber;
