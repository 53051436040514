import React from "react";
import { mergeClasses } from "@griffel/react";
import ErrorContainer from "../../../../components/error-container";
import { FlowId, ViewId } from "../../../../constants/routing-constants";
import { useActivateView } from "../../../../hooks/use-activate-view";
import { useDocumentTitle } from "../../../../hooks/use-document-title";
import useFabricStyles, { useStaticFabricStyles } from "../../../../styles/fabric/fabric.styles";
import {
  commonBindings,
  FormattedTextWithBindings,
} from "../../../../utilities/formatted-text-with-bindings";
import { LoginTitleFabric } from "../../components/fabric/login-title-fabric";
import { commonLoginStringsFabric } from "../../fabric/common-login-strings-fabric";
import { useErrorViewProperties, useRenderManageCredentialsLink } from "../hooks/error-view-hooks";

/**
 * ErrorView component
 * @returns A rendered instance of this component
 */
export const ErrorViewFabric: React.FC = function ErrorViewFabric() {
  const {
    documentTitle,
    lightboxTitle,
    lightboxTitleId,
    loginModeErrorDesc,
    loginModeErrorDescId,
    errorCodeDescription,
    usernameDescription,
    isBindFailedMode,
    isGenericErrorMode,
    isSwitchUserMode,
    isHipLockedMode,
    manageCredsLink,
    resetPasswordLink,
    staySignInLink,
    switchUserLink,
  } = useErrorViewProperties({ commonLoginStrings: commonLoginStringsFabric });
  const { boldText } = commonBindings;
  const fabricStyles = useFabricStyles();

  useActivateView(ViewId.Error, FlowId.Login);
  useDocumentTitle(documentTitle);
  useStaticFabricStyles();

  const renderManageCredentialsLink = useRenderManageCredentialsLink(manageCredsLink);

  return (
    <>
      <LoginTitleFabric
        titleId={lightboxTitleId}
        title={lightboxTitle}
        useSubtitle={!isBindFailedMode}
      />
      <div className={fabricStyles.textBlockBody}>
        {loginModeErrorDesc && (
          <ErrorContainer
            id={loginModeErrorDescId}
            // TODO: add external error class when we tackle ESTS
            className={mergeClasses(fabricStyles.textBody, fabricStyles.error)}
          >
            {loginModeErrorDesc}
          </ErrorContainer>
        )}
        {errorCodeDescription && (
          <ErrorContainer
            id="error_Info"
            // TODO: add external error class when we tackle ESTS
            className={mergeClasses(fabricStyles.textBody, fabricStyles.error)}
          >
            <FormattedTextWithBindings
              textWithBindings={errorCodeDescription}
              embeddedBindings={{
                manageCredsUrl: renderManageCredentialsLink,
              }}
            />
          </ErrorContainer>
        )}
        {usernameDescription && (
          <div id="username" className={mergeClasses(fabricStyles.row, fabricStyles.textBody)}>
            <FormattedTextWithBindings
              textWithBindings={usernameDescription}
              embeddedBindings={{ boldText }}
            />
          </div>
        )}
        {isHipLockedMode && (
          <div className={mergeClasses(fabricStyles.row, fabricStyles.textBody)}>
            <div className={fabricStyles.formGroup}>
              {getLocalString("Lockout_Reason_IncorrectPasswordTooManyTimes")}
            </div>
            <div className={fabricStyles.formGroup}>
              {getLocalString("Lockout_Reason_OrgAccountNotReady")}
            </div>
          </div>
        )}
      </div>
      {!isGenericErrorMode && (
        <div className={fabricStyles.row}>
          {isHipLockedMode && (
            <div className={fabricStyles.formGroup}>
              <a
                ref={resetPasswordLink.ref}
                id={resetPasswordLink.id}
                aria-describedby={resetPasswordLink.ariaDescribedBy}
                href={resetPasswordLink.href}
              >
                {resetPasswordLink.text}
              </a>
            </div>
          )}
          {isSwitchUserMode && (
            <div className={fabricStyles.formGroup}>
              <a
                ref={staySignInLink.ref}
                id={staySignInLink.id}
                aria-describedby={staySignInLink.ariaDescribedBy}
                href={staySignInLink.href}
              >
                {staySignInLink.text}
              </a>
            </div>
          )}
          <div className={fabricStyles.formGroup}>
            <a
              ref={switchUserLink.ref}
              id={switchUserLink.id}
              aria-describedby={switchUserLink.ariaDescribedBy}
              href={switchUserLink.href}
            >
              {switchUserLink.text}
            </a>
          </div>
        </div>
      )}
    </>
  );
};
