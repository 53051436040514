import React from "react";
import { CredentialSwitchLinksComponent } from "../../../../components/credential-switch-links/credential-switch-links";
import { InputContainer } from "../../../../components/inputs/input-container";
import { TextInput } from "../../../../components/inputs/text-input";
import { LinkButton } from "../../../../components/link-button";
import {
  TextButtonContainer,
  TextButtonFabric,
} from "../../../../components/text-button/fabric/text-button-fabric";
import { TitleFabric } from "../../../../components/title/fabric/title-fabric";
import { CredentialType } from "../../../../constants/constants";
import { FlowId, ViewId } from "../../../../constants/routing-constants";
import { useActivateView } from "../../../../hooks/use-activate-view";
import { useDocumentTitle } from "../../../../hooks/use-document-title";
import useTextInputForm from "../../../../hooks/use-text-input-form";
import useFabricStyles, { useStaticFabricStyles } from "../../../../styles/fabric/fabric.styles";
import { isProofEncrypted } from "../../../../utilities/api-helpers/one-time-code/get-one-time-code-helper";
import { SecondaryContentContainerFabric } from "../../components/fabric/secondary-content-container-fabric";
import { commonLoginStringsFabric } from "../../fabric/common-login-strings-fabric";
import { useSetServerDataErrorOnInput } from "../../hooks/login-hooks";
import { LoginFlowPostHiddenInputs } from "../../login-flow-post-hidden-inputs";
import {
  useEvictedCredPickerLinkProps,
  useGetLoginPostProps,
  useOneTimeCodeViewProperties,
  useOtcSubmitHandler,
  usePhoneDisambiguationLinkProps,
} from "../hooks/one-time-code-view-hooks";
import { otcInputValidation } from "../one-time-code-view-util";
import { oneTimeCodeViewStringsFabric } from "./one-time-code-view-strings-fabric";

/**
 * OneTimeCode view component
 * @returns A rendered instance of this component
 */
export const OneTimeCodeViewFabric: React.FC = function OneTimeCodeViewFabric() {
  const {
    credentialSwitchLinksProps,
    description,
    documentTitle,
    enterCodeAriaDescribedBy,
    enterCodeAriaLabel,
    otcCredential,
    otcInputName,
    otcMaxLength,
    placeholder,
    primaryButtonLabel,
    proofConfirmation,
    proofData,
    postUrl,
    title,
    useEvictedCredentials,
  } = useOneTimeCodeViewProperties({
    commonLoginStrings: commonLoginStringsFabric,
    oneTimeCodeViewStrings: oneTimeCodeViewStringsFabric,
  });

  useActivateView(ViewId.OneTimeCode, FlowId.Login, { showBackButtonOnActiveView: true });
  useDocumentTitle(documentTitle);

  useStaticFabricStyles();
  const fabricStyles = useFabricStyles();

  const loginPostProps = useGetLoginPostProps(otcCredential);

  const { switchToEvictedCredPickerOnClick, showEvictedCredPickerLink } =
    useEvictedCredPickerLinkProps();

  const { switchToPhoneDisambiguationOnClick, showPhoneDisambiguationLink } =
    usePhoneDisambiguationLinkProps();

  const {
    focus,
    value,
    showError,
    externalError,
    validationError,
    setFocus,
    setShowError,
    onBlur,
    onFocus,
    onTextChange,
    setValidationError,
    setExternalError,
  } = useTextInputForm();

  const onSubmit = useOtcSubmitHandler(
    postUrl,
    validationError,
    externalError,
    setFocus,
    setShowError,
  );

  const otcInputValidationFunc = otcInputValidation(
    otcCredential.credentialType === CredentialType.OneTimeCode,
    oneTimeCodeViewStringsFabric,
  );

  // If there is an error from server in server data, show it on initialize
  useSetServerDataErrorOnInput(setExternalError);

  return (
    <form
      name="oneTimeCodeForm"
      data-testid="oneTimeCodeForm"
      noValidate
      spellCheck="false"
      method="post"
      autoComplete="false"
      onSubmit={onSubmit}
      action={postUrl}
    >
      <TitleFabric
        titleId="oneTimeCodeTitle"
        title={title}
        descriptionId="oneTimeCodeDescription"
        description={description}
      />

      {/* AAD-TODO: add branding description + PublicIdentifierAuth hidden input */}

      <input
        type="hidden"
        name={isProofEncrypted(otcCredential) ? "SentProofIDE" : "SentProofID"}
        value={proofData}
        data-testid="sentProofInput"
      />
      {proofConfirmation && (
        <input
          type="hidden"
          name="ProofConfirmation"
          value={proofConfirmation}
          data-testid="proofConfirmationInput"
        />
      )}
      {otcCredential?.proof && otcCredential.proof.type && (
        <input
          type="hidden"
          name="ProofType"
          value={otcCredential.proof.type}
          data-testid="proofTypeInput"
        />
      )}

      <LoginFlowPostHiddenInputs {...loginPostProps} />

      <TextInput
        id="idTxtBx_OTC_Password"
        name={otcInputName}
        type="tel"
        value={value}
        placeholder={placeholder}
        changeHandler={onTextChange}
        showErrorInline={showError}
        externalError={externalError}
        validationErrorHandler={setValidationError}
        inputValidationFunc={otcInputValidationFunc}
        ariaLabel={enterCodeAriaLabel}
        ariaDescribedBy={enterCodeAriaDescribedBy}
        hasFocus={focus}
        hasInitialFocus
        focusHandler={onFocus}
        blurHandler={onBlur}
        maxLength={otcMaxLength}
      />

      {/* AAD-TODO: add resend code button */}

      <SecondaryContentContainerFabric>
        {!useEvictedCredentials && (
          <div className={fabricStyles.row}>
            <div className={fabricStyles.formGroup}>
              <CredentialSwitchLinksComponent {...credentialSwitchLinksProps} />
            </div>
          </div>
        )}

        {!useEvictedCredentials && showEvictedCredPickerLink && (
          <div className={fabricStyles.row}>
            <div className={fabricStyles.formGroup}>
              <LinkButton
                text={commonLoginStringsFabric.evictedCredPickerLinkText}
                linkId="evictedAccount"
                onClick={switchToEvictedCredPickerOnClick}
              />
            </div>
          </div>
        )}

        {/* AAD-TODO: add showChangeUserLink */}

        {showPhoneDisambiguationLink && (
          <div className={fabricStyles.row}>
            <div className={fabricStyles.formGroup}>
              <LinkButton
                text={commonLoginStringsFabric.phoneDisambiguationLinkText}
                onClick={switchToPhoneDisambiguationOnClick}
              />
            </div>
          </div>
        )}
      </SecondaryContentContainerFabric>

      <InputContainer>
        <div className={fabricStyles.positionButtons}>
          <div className={fabricStyles.row}>
            <TextButtonContainer>
              <TextButtonFabric
                buttonId="primaryButton"
                label={primaryButtonLabel}
                isPrimary
                isButtonType={false}
                ariaLabel={primaryButtonLabel}
              />
            </TextButtonContainer>
          </div>
        </div>
      </InputContainer>
    </form>
  );
};
