import React from "react";
import { HelpButton } from "../../../../../components/help-button";
import { InputContainer } from "../../../../../components/inputs/input-container";
import { TextInput } from "../../../../../components/inputs/text-input";
import { LinkButton } from "../../../../../components/link-button";
import {
  TextButtonContainer,
  TextButtonFabric,
} from "../../../../../components/text-button/fabric/text-button-fabric";
import { FlowId, ViewId } from "../../../../../constants";
import { useActivateView } from "../../../../../hooks/use-activate-view";
import { useDocumentTitle } from "../../../../../hooks/use-document-title";
import { useNavigateBack } from "../../../../../hooks/use-navigate-back";
import { useNavigateDirection } from "../../../../../hooks/use-navigate-direction";
import useTextInputForm from "../../../../../hooks/use-text-input-form";
import useFabricStyles, { useStaticFabricStyles } from "../../../../../styles/fabric/fabric.styles";
import { useHelpButtonStyles } from "../../../../../styles/fabric/help-button-fabric.styles";
import { FormattedTextWithBindings } from "../../../../../utilities/formatted-text-with-bindings";
import {
  documentationIcon,
  signInOptionsIcon,
} from "../../../../../utilities/image-helpers/accessible-images";
import { FormattedSignupMessageFabric as FormattedSignupMessage } from "../../../components/fabric/formatted-signup-message-fabric";
import { LoginTitleFabric as LoginTitle } from "../../../components/fabric/login-title-fabric";
import { SecondaryContentContainerFabric as SecondaryContentContainer } from "../../../components/fabric/secondary-content-container-fabric";
import { commonLoginStringsFabric } from "../../../fabric/common-login-strings-fabric";
import { useFidoPostRedirect, useSetServerDataErrorOnInput } from "../../../hooks/login-hooks";
import { useGct, useUsernameViewProperties } from "../hooks";
import { useUsernameErrorBindings } from "../hooks/use-username-error-bindings";
import { getInputValidation } from "../username-view-util";
import { usernameViewStringsFabric } from "./username-view-strings-fabric";

/**
 * UsernameView component
 * @flavor Fabric
 * @returns A rendered instance of this component
 */
export const UsernameViewFabric: React.FC = function UsernameViewFabric() {
  const {
    canGoBack,
    title,
    header,
    loginDescription,
    placeholder,
    signInOptionsText,
    signupLinkAriaLabel,
    signupLinkText,
    fidoLinkText,
    fidoDialogHeader,
    fidoDialogDescription,
    username,
  } = useUsernameViewProperties({
    usernameViewStrings: usernameViewStringsFabric,
    commonLoginStrings: commonLoginStringsFabric,
  });

  const navigate = useNavigateDirection();
  const navigateBack = useNavigateBack();

  useActivateView(ViewId.Username, FlowId.Login, {
    showIdentityBanner: false,
    showMoreOptions: {
      accessibleImage: signInOptionsIcon,
      text: signInOptionsText,
      onClick: () => navigate(ViewId.Username, ViewId.SignInOptions),
    },
  });

  useDocumentTitle(title);
  useStaticFabricStyles();

  const fabricStyles = useFabricStyles();
  const helpButtonStyles = useHelpButtonStyles();

  const getEmbeddedLinkBindings = useUsernameErrorBindings();
  const getErrorMessage = (unsafeUsername: string, gctError: string) => {
    const { ...bindings } = getEmbeddedLinkBindings(unsafeUsername);
    return (
      <FormattedTextWithBindings textWithBindings={gctError} embeddedBindings={{ ...bindings }} />
    );
  };

  const {
    focus,
    showError,
    externalError,
    onBlur,
    onFocus,
    value,
    onTextChange,
    setValidationError: onValidationError,
    onFormSubmission,
    setExternalError,
  } = useTextInputForm(username);

  const onSubmit = onFormSubmission(useGct(getErrorMessage), ViewId.Username);
  const redirectCallback = useFidoPostRedirect(value);
  const inputValidationFunction = getInputValidation(usernameViewStringsFabric);

  // Set server data error on the input on initialize and update its state
  useSetServerDataErrorOnInput(setExternalError);

  return (
    <div>
      <form
        name="f1"
        id="i0281"
        data-testid="usernameForm"
        noValidate
        spellCheck="false"
        method="post"
        autoComplete="false"
        onSubmit={onSubmit}
      >
        <LoginTitle
          titleId="usernameTitle"
          title={header}
          descriptionId="loginDescription"
          description={loginDescription}
        />
        <TextInput
          // Have to keep id the same as it was in KO for downstream scripts
          id="i0116"
          name="loginfmt"
          placeholder={placeholder}
          type="email"
          showErrorInline={showError}
          externalError={externalError}
          value={value}
          changeHandler={onTextChange}
          validationErrorHandler={onValidationError}
          inputValidationFunc={inputValidationFunction}
          ariaLabelledBy="usernameTitle"
          ariaDescribedBy="loginDescription"
          hasFocus={focus}
          hasInitialFocus
          focusHandler={onFocus}
          blurHandler={onBlur}
          autocomplete="username"
          maxLength={113}
        />

        <SecondaryContentContainer>
          {signupLinkText && (
            <div className={fabricStyles.row}>
              <div className={fabricStyles.formGroup}>
                <FormattedSignupMessage
                  id="signup"
                  username={value}
                  arialLabel={signupLinkAriaLabel}
                  text={signupLinkText}
                />
              </div>
            </div>
          )}

          {fidoLinkText && (
            <div className={fabricStyles.row}>
              <div className={fabricStyles.formGroup}>
                <div>
                  <LinkButton text={fidoLinkText} linkId="fidoUrl" onClick={redirectCallback} />
                  <HelpButton
                    helpDialogIcon={documentationIcon}
                    helpDialogHeader={fidoDialogHeader}
                    helpDialogDescription={fidoDialogDescription}
                    helpDialogIconClassName={helpButtonStyles.helpButton}
                  />
                </div>
              </div>
            </div>
          )}
        </SecondaryContentContainer>

        <InputContainer>
          <div className={fabricStyles.row}>
            <TextButtonContainer>
              {/* AAD-TODO: Implement ServerData.fShowButtons */}
              {canGoBack && (
                <TextButtonFabric
                  buttonId="idBtn_Back"
                  label={getLocalString("General_Back")}
                  isPrimary={false}
                  isButtonType
                  ariaLabelledBy={getLocalString("General_Back")}
                  ariaDescribedBy={getLocalString("General_Back")}
                  onClick={navigateBack}
                />
              )}
              <TextButtonFabric
                buttonId="idSIButton9"
                label={getLocalString("General_Buttons_Next")}
                isPrimary
                isButtonType={false}
                ariaLabelledBy="usernameTitle"
                ariaDescribedBy="loginDescription"
              />
            </TextButtonContainer>
          </div>
        </InputContainer>
      </form>
    </div>
  );
};
