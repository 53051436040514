import {
  AltEmailRegEx,
  PhoneNumberRegex,
  SkypeRegex,
} from "../../../../components/inputs/input-regex-constants";
import { isPhoneNumber } from "../../../../utilities/strings-helper";
import LoginConfig from "../../login-config";
import { type IUsernameViewStrings } from "./username-view-interface";

/**
 * @returns method for validating username input
 * @param usernameViewStrings Flavored strings that are used by this hook
 */
export const getInputValidation = (usernameViewStrings: IUsernameViewStrings) => {
  const { allowSkype, allowPhone } = LoginConfig.instance;
  const { errorInvalidMemberNameEmailPhoneSkype: errorMessage, badPhoneNumberErrorMessage } =
    usernameViewStrings;

  return (value: string): string => {
    if (!value || value.trim() === "") {
      return errorMessage;
    }

    if (
      !!value.match(AltEmailRegEx) ||
      (allowSkype && !!value.match(SkypeRegex)) ||
      (allowPhone && isPhoneNumber(value) && !!value.match(PhoneNumberRegex))
    ) {
      return "";
    }

    if (
      !value.match(AltEmailRegEx) &&
      (!allowSkype || !value.match(SkypeRegex)) &&
      allowPhone &&
      isPhoneNumber(value) &&
      !value.match(PhoneNumberRegex)
    ) {
      return badPhoneNumberErrorMessage;
    }

    return errorMessage;
  };
};
