import { useEffect } from "react";
import { type TelemetryState } from "../global-context";
import { ExceptionHelper } from "./exception-helper";

/**
 * Hook to listen for unhandled rejections in promises and log them to telemetry.
 * This adds an event listener to the window object.
 * Inspired by https://javascript.info/promise-error-handling#unhandled-rejections
 * @param telemetryState the telemetry state to use
 */
export const useUnhandledRejection = (telemetryState: TelemetryState): void => {
  useEffect(() => {
    window.addEventListener("unhandledrejection", (event) => {
      ExceptionHelper.logException(
        `UNHANDLED PROMISE REJECTION: ${event?.reason} in ${event?.promise}`,
        telemetryState,
        "UNHANDLED_PROMISE_REJECTION",
      );
    });
  }, [telemetryState]);
};
