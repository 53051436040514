import React from "react";
import { mergeClasses } from "@griffel/react";
import { CredentialSwitchLinksComponent } from "../../../../components/credential-switch-links/credential-switch-links";
import { InputContainer } from "../../../../components/inputs/input-container";
import { LinkButton } from "../../../../components/link-button";
import ProgressIndicatorFabric from "../../../../components/loading-progress/fabric/progress-indicator-fabric";
import {
  TextButtonContainer,
  TextButtonFabric,
} from "../../../../components/text-button/fabric/text-button-fabric";
import { CredentialType } from "../../../../constants/constants";
import { FlowId, ViewId } from "../../../../constants/routing-constants";
import { useActivateView } from "../../../../hooks/use-activate-view";
import { useDocumentTitle } from "../../../../hooks/use-document-title";
import useFabricStyles, { useStaticFabricStyles } from "../../../../styles/fabric/fabric.styles";
import { FormattedTextWithBindings } from "../../../../utilities/formatted-text-with-bindings";
import { LoginTitleFabric } from "../../components/fabric/login-title-fabric";
import { SecondaryContentContainerFabric } from "../../components/fabric/secondary-content-container-fabric";
import { commonLoginStringsFabric } from "../../fabric/common-login-strings-fabric";
import { useConfirmSendViewProperties } from "../confirm-send-view-hooks";
import { getConfirmSendStrings } from "../confirm-send-view-util";

/**
 * This view is shown to users to confirm they want to send a code to a certain source for authentication.
 * It presents options for using evicted credentials and switching to different credentials as well.
 * It can be an initial view for the Login flow or navigated into from another view.
 * @returns ConfirmSendView component
 */
export const ConfirmSendViewFabric: React.FC = function ConfirmSendViewFabric() {
  const fabricStyles = useFabricStyles();

  const {
    availableCredentials,
    currentCredential,
    description,
    buttonText,
    navigateToPhoneDisambig,
    onClickPrimaryButton,
    switchToEvictedCredPicker,
    showSwitchToEvictedCredPicker,
    useEvictedCredentials,
    selectAccount,
    requestIsPending,
    setRequestIsPending,
    preferredCredential,
    documentTitle,
    showChangeUserLink,
    showButton,
  } = useConfirmSendViewProperties(commonLoginStringsFabric);
  const { switchToEvictCredText, changeUserLinkText, headerText } = getConfirmSendStrings();

  useActivateView(ViewId.ConfirmSend, FlowId.Login);
  useDocumentTitle(documentTitle);
  useStaticFabricStyles();

  const phoneDisambigLink = (chunks: string[]) => (
    <LinkButton text={chunks[0]} onClick={navigateToPhoneDisambig} />
  );

  const descriptionContent = (
    <div className={fabricStyles.formGroup} data-testid="confirm-send-description">
      <div
        className={mergeClasses(
          fabricStyles.row,
          fabricStyles.textBody,
          fabricStyles.textBlockBody,
        )}
      >
        <FormattedTextWithBindings
          textWithBindings={description}
          embeddedBindings={{
            phoneDisambigLink,
          }}
        />
      </div>
    </div>
  );

  const evictedCredContent = (
    <>
      <div className={fabricStyles.formGroup}>
        <CredentialSwitchLinksComponent
          availableCredentials={availableCredentials}
          currentCredential={currentCredential}
          setRequestPendingFlag={setRequestIsPending}
          sourceViewId={ViewId.ConfirmSend}
          shouldUpdateOtcCredential
        />
      </div>
      {showSwitchToEvictedCredPicker && (
        <div className={fabricStyles.formGroup}>
          <LinkButton text={switchToEvictCredText} onClick={switchToEvictedCredPicker} />
        </div>
      )}
    </>
  );

  const showChangeUserLinkContent = (
    <div className={fabricStyles.formGroup}>
      <LinkButton text={changeUserLinkText} onClick={selectAccount} />
    </div>
  );

  const usingRemoteNgc = preferredCredential === CredentialType.RemoteNGC;
  const buttonContainerClass =
    !usingRemoteNgc && !showChangeUserLink ? fabricStyles.noMarginBottom : undefined;
  const titleId = "confirmSendTitle";

  return (
    <form noValidate spellCheck="false" autoComplete="false" onSubmit={onClickPrimaryButton}>
      <LoginTitleFabric titleId={titleId} title={headerText} />
      {requestIsPending ? <ProgressIndicatorFabric /> : descriptionContent}
      <InputContainer>
        <div className={fabricStyles.row}>
          <SecondaryContentContainerFabric>
            {!useEvictedCredentials && evictedCredContent}
            {showChangeUserLink && showChangeUserLinkContent}
          </SecondaryContentContainerFabric>
        </div>
      </InputContainer>
      {showButton && (
        <div className={fabricStyles.row}>
          <TextButtonContainer customCss={buttonContainerClass}>
            <TextButtonFabric
              label={buttonText}
              isPrimary
              hasFocus
              isButtonType={false}
              ariaDescribedBy={titleId}
            />
          </TextButtonContainer>
        </div>
      )}
    </form>
  );
};
