import { makeStyles } from "@griffel/react";
import * as dropdownConstants from "./dropdown-fabric.styles";

export const usePhoneCountryDropdownStyles = makeStyles({
  phoneCountryBoxNotInline: {
    width: "100%",
    float: "left",
    position: "relative",
    minHeight: "1px",
    paddingLeft: "2px",
    paddingRight: "2px",
    "& select.has-error": {
      ...dropdownConstants.hasError,
    },
    "& select.has-error:focus": {
      ...dropdownConstants.hasErrorFocusBox,
    },
  },
  phoneCountryBoxInline: {
    display: "inline-block",
    width: "20.83333%",
    float: "left",
    position: "relative",
    minHeight: "1px",
    paddingLeft: "2px",
    paddingRight: "2px",
    "& select": {
      left: 0,
      opacity: 0,
      cursor: "pointer",
    },
    ":global([dir='rtl'])": {
      float: "right",
    },
  },
  phoneCountryCodeLabel: {
    ...dropdownConstants.dropdownBaseStyle,
    position: "absolute",
    width: "100%",
    left: 0,
    paddingLeft: "4px",
    paddingRight: "4px",
    marginTop: 0,
    marginBottom: 0,
  },
  labelHasError: { ...dropdownConstants.hasError },
  labelHasFocus: { ...dropdownConstants.hasFocus },
  labelHasErrorFocusBox: { ...dropdownConstants.hasErrorFocusBox },
  downArrow: {
    position: "absolute",
    right: "-6px",
    paddingTop: "6px",
    paddingBottom: "6px",
    paddingRight: "0px",
    paddingLeft: "0px",
    height: "36px",
    ":global([dir='rtl'])": {
      left: "-6px",
      right: "auto",
    },
  },
});
