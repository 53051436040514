import { useContext } from "react";
import { ViewId } from "../../../../constants/routing-constants";
import { useNavigateDirection } from "../../../../hooks/use-navigate-direction";
import { getRouteFromViewId } from "../../../../utilities/routing-helper";
import { LoginContext } from "../../login-context";
import { LoginActionType } from "../../login-reducer";

/**
 * Hook that returns the click handler for the evicted account link button
 * @returns Click handler for the evicted account link button
 */
export const useIsEvictedAccountLinkClickHandler = (): (() => void) => {
  // Login context data
  const { dispatchStateChange: dispatchLoginStateChange } = useContext(LoginContext);

  const navigate = useNavigateDirection();

  return () => {
    dispatchLoginStateChange({
      type: LoginActionType.UpdateCredentials,
      payload: { useEvictedCredentials: true },
    });

    navigate(ViewId.IdpRedirectSpeedbump, getRouteFromViewId(ViewId.CredentialPicker));
  };
};
