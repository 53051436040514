import { type CredentialType } from "../constants/constants";

export enum ProofType {
  Email = 1,
  AltEmail = 2,
  SMS = 3,
  DeviceId = 4,
  CSS = 5,
  SQSA = 6,
  Certificate = 7,
  HIP = 8,
  Birthday = 9,
  TOTPAuthenticator = 10,
  RecoveryCode = 11,
  StrongTicket = 13,
  TOTPAuthenticatorV2 = 14,
  TwoWayVoice = 15,
  TwoWaySMS = 16,
  FidoKey = 17,
  AccessPass = 18,
  TransferToken = 19,
  VerifiableCredentialsHackathon = 20,
  CompanionApp = 21,
  Voice = -3,
}

export interface OneTimeCodeProofWithDisplay extends OneTimeCodeProof {
  display: string;
  clearDigits?: string;
}

export interface OneTimeCodeCredential extends UserCredential {
  proof: OneTimeCodeProofWithDisplay;
  credentialType: CredentialType.OneTimeCode;
}

export type OneTimeCodeProof = {
  clearDigits?: string;
  data?: string;
  display?: string;
  isDefault?: boolean;
  isEncrypted?: boolean;
  isLost?: boolean;
  isNopa?: boolean;
  isSADef?: boolean;
  isSleeping?: boolean;
  isVoiceOnly?: boolean;
  otcEnabled?: boolean;
  otcSent?: boolean;
  pushEnabled?: boolean;
  type: ProofType;
  voiceEnabled?: boolean;
};

/**
 * This type is used to represent the user credentials provided by the Server.
 * Each credential has an associated type that correspond to ways the user can authenticate.
 * A user may have multiple credential options to choose from.
 * This type is used in places such as LoginContext to list the available and evicted credentials.
 */
export interface UserCredential {
  credentialType: CredentialType;
  redirectUrl?: string;
  shownOnlyOnPicker?: boolean;
  proof?: OneTimeCodeProof;
  isDefault?: boolean;
  redirectPostParams?: object;
}

export interface UserCredentials {
  availableCredentials: Array<UserCredential>;
  evictedCredentials: Array<UserCredential>;
  useEvictedCredentials: boolean;
  otcCredential?: OneTimeCodeCredential;
  preferredCredential: CredentialType;
  proofConfirmation: string;
}
