export const CanaryValidationPageId = "ConvergedRemoteConnect";
export const CanaryValidationActionId = "OAuth2DeviceAuth";

export const UsernameMaxLength = 113;
export const PhoneNumberConfirmationLength = 4;

export enum LoginStringsVariant {
  Default = 0,
  SkypeMoveAlias = 1,
  CombinedSigninSignup = 2,
  CombinedSigninSignupDefaultTitle = 3,
  RemoteConnectLogin = 4,
  CombinedSigninSignupV2 = 5,
  CombinedSigninSignupV2WelcomeTitle = 6,
}

// This is the same as PaginatedState in KO code.
// These values can be returned from the server on post (example: sPOST_PaginatedLoginState); we cannot merge these with ViewId in React just yet due to the server dependency.
// function getViewIdFromLoginState converts these values to ViewId.
export const LoginState = {
  // Previous is not an actual view. It is used when a view wants to switch to the
  // view directly before the current one in the history.
  Previous: -1,
  Unknown: 0,
  Username: 1,
  Password: 2,
  OneTimeCode: 3,
  RemoteNGC: 4,
  PhoneDisambiguation: 5,
  LwaConsent: 6,
  IdpDisambiguation: 7,
  IdpRedirect: 8,
  ViewAgreement: 10,
  LearnMore: 11,
  Tiles: 12,
  ConfirmSend: 13,
  RemoteConnectCode: 14,
  RemoteLoginPolling: 15,
  BindRedirect: 16,
  TermsOfUse: 17,
  DesktopSsoProgress: 18,
  ResetPasswordSplitter: 19,
  Kmsi: 20,
  CheckPasswordType: 21,
  ChangePassword: 22,
  Fido: 23,
  CredentialPicker: 24,
  Consent: 25,
  Error: 26,
  ConfirmSignup: 27,
  ConfirmRecoverUsername: 28,
  ConfirmConsentSelection: 29,
  FedConflict: 30,
  ProofUpRedirect: 32,
  ProofUpRedirectLanding: 33,
  ConditionalAccessInstallBroker: 34,
  ConditionalAccessWorkplaceJoin: 35,
  ConditionalAccessError: 36,
  CreateFido: 37,
  FedLink: 38,
  FedLinkComplete: 40,
  IdpRedirectSpeedbump: 41,
  TransferLogin: 42,
  Cmsi: 43,
  ProofConfirmation: 44,
  MessagePrompt: 45,
  FinishError: 46,
  Hip: 48,
  LearnMoreOfflineAccount: 49,
  TenantDisambiguation: 50,
  AadFedConflict: 51,
  RemoteConnectCanaryValidation: 52,
  PartnerCanaryValidation: 53,
  ProofFedConflict: 54,
  FetchSessionsProgress: 55,
  AccessPass: 56,
  SignupUsername: 57,
  ReportSuspiciousApp: 58,
  MoreInfo: 59,
  AuthenticatorAddAccountView: 60,
  SignupCredentialPicker: 61,
  LoginError: 62,
  SearchOrganization: 63,
  Ptca: 64,
  GuestConsent: 65,
  RemoteConnectLocation: 66,
  AttributeCollection: 67,
  RdpDevicePrompt: 68,
  GuestConsentConnect: 69,
  SeeHowDataIsManaged: 70,
  SecurityDefaultsUpsell: 71,
  SecurityDefaultsUpsellOptOut: 72,
  SecurityDefaultsUpsellAutoEnabled: 73,
  WebNativeBridge: 74,
  TransferLoginChallengePin: 75,
  RecoveryCredentialPicker: 76,
  OneTimeCodeRecovery: 77,
  PhoneLinkLearnMore: 78,
  PhoneLinkSupport: 79,
  PostRedirect: 1000, // when we want to perform a post redirect. Note: its not an actual view
};

export const LoginMode = {
  None: 0,
  Login: 1,
  ForceCredType: 3,
  LWAConsent: 4,
  GenericError: 5,
  ForceSignin: 6,
  OTS: 7,
  HIP_Login: 8,
  HIP_Lockout: 9,
  InviteBlocked: 10,
  SwitchUser: 11,
  LWADelegation: 12,
  ServiceBlocked: 13,
  IDPFailed: 14,
  StrongAuthOTC: 16,
  StrongAuthMobileOTC: 25,
  Finish: 27,
  LoginWizard_Login: 28,
  StrongAuthWABOTC: 30,
  LoginWizard_HIP_Login: 32,
  LoginWizard_Finish: 34,
  LoginMobile: 36,
  ForceSigninMobile: 37,
  GenericErrorMobile: 38,
  LoginHost: 39,
  ForceSigninHost: 40,
  GenericErrorHost: 42,
  StrongAuthHostOTC: 43,
  HIP_LoginHost: 45,
  HIP_LoginMobile: 46,
  HIP_LockoutHost: 47,
  HIP_LockoutMobile: 48,
  SwitchUserHost: 49,
  LoginXbox_Login: 50,
  HIP_LoginXbox: 51,
  FinishXbox: 52,
  IfExistsXbox: 53,
  StartIfExistsXbox: 54,
  StrongAuthXboxOTC: 55,
  LoginWPWiz_Login: 56,
  LoginWPWiz_HIP_Login: 57,
  LoginWPWiz_Finish: 58,
  StrongAuthWizOTC: 59,
  StrongAuthWPWizOTC: 60,
  FinishWPWiz: 61,
  SwitchUserMobile: 62,
  LoginWPWiz_PhoneSignIn: 63,
  LoginWPWiz_HIP_PhoneSignIn: 64,
  Login_PhoneSignIn: 65,
  Login_HIP_PhoneSignIn: 66,
  LoginHost_PhoneSignIn: 67,
  LoginHost_HIP_PhoneSignIn: 68,
  LoginMobile_PhoneSignIn: 69,
  LoginMobile_HIP_PhoneSignIn: 70,
  LoginWizard_PhoneSignIn: 71,
  LoginWizard_HIP_PhoneSignIn: 72,
  LoginXbox_PhoneSignIn: 73,
  LoginXbox_HIP_PhoneSignIn: 74,
  LoginWin10: 75,
  HIP_LoginWin10: 76,
  FinishWin10: 77,
  FinishBlockedWin10: 78,
  LoginWin10_PhoneSignIn: 79,
  HIP_LoginWin10_PhoneSignIn: 80,
  FinishWin10_TokenBroker: 81,
  SwitchUserWin10: 82,
  ForceSignInXbox: 88,
  LoginClientSDK_Login: 92,
  LoginClientSDK_HIP_Login: 93,
  LoginClientSDK_Finish: 94,
  StrongAuthClientSDKOTC: 95,
  FinishClientSDK: 96,
  LoginClientSDK_PhoneSignIn: 97,
  LoginClientSDK_HIP_PhoneSignIn: 98,
  Win10InclusiveOOBE_Finish: 99,
  Win10InclusiveOOBE_FinishBlocked: 100,
  // OnPremPassword: 101,
  Tiles: 102,
  RemoteConnect: 103,
  // DesktopSsoProgress: 104,
  FedConflict: 105,
  Win10Host_Login: 106,
  Win10Host_Login_PhoneSignin: 107,
  Win10Host_Finish: 108,
  Win10Host_StrongAuth: 109,
  Win10Host_HIP_Login: 110,
  Fido: 111,
  Win10Host_HIP_Login_PhoneSignIn: 112,
  FedLink: 113,
  UserCredentialPolicyBlocked: 114,
  BindFailed: 115,
  Win10HostOOBE_HIP_Login: 116,
  Win10HostOOBE_HIP_Login_PhoneSignIn: 117,
  AadFedConflict: 118,
  ProofFedConflict: 119,
  FedBoundLink: 120,
  FetchSessionsProgress: 121,
  Win10Host_TransferLogin: 122,
  TransferLogin: 123,
  Signup: 124,
  CredentialPicker: 129,
};

export const Error = {
  // Success
  S_OK: "0",

  // Client-side errors
  InvalidRealmDiscLogin: 10,
  UsernameInvalid: 1000,
  PasswordEmpty: 1001,
  HIPEmpty: 1002,
  AltEmailInvalid: 1005,
  PhoneInvalid: 1006,
  SAContainsName: 1007,
  OTCEmpty: 1009,
  OTCInvalid: 1010,
  NotEnoughProofs: 1013,
  PhoneEmpty: 1015,
  FedUser: 1016,
  FedUserConflict: 1017,
  FedUserInviteBlocked: 1018,
  EmptyFields: 1020,
  PhoneHasSpecialChars: 1021,
  AutoVerifyNoCodeSent: 1022,
  ProofConfirmationEmpty: 1023,
  ProofConfirmationInvalid: 1024,
  TOTPInvalid: 1025,
  SessionNotApproved: 1026,
  PhoneNumberInvalid: 1027,
  PhoneFormattingInvalid: 1028,
  PollingTimedOut: 1029,
  SendNotificationFailed: 1030,

  // Error Message Only (Server Error, but no HR)
  Server_MessageOnly: 9999,

  // MSA Server-side errors
  PP_E_DB_MEMBERDOESNOTEXIST: "CFFFFC15",
  PP_E_EXCLUDED: "80041010",
  PP_E_MEMBER_LOCKED: "80041011",
  PP_E_BAD_PASSWORD: "80041012",
  PP_E_MISSING_MEMBERNAME: "80041031",
  PP_E_MISSING_PASSWORD: "80041032",
  PP_E_FEDERATION_INLINELOGIN_DISALLOWED: "800478AC",
  PP_E_PE_RULEFALSE: "8004490C",
  PP_E_MOBILECREDS_PHONENUMBER_BLANK: "80045801",
  PP_E_MOBILECREDS_PHONENUMBER_TOOSHORT: "80045806",
  PP_E_MOBILECREDS_PHONENUMBER_TOOLONG: "80045807",
  PP_E_MOBILECREDS_PHONENUMBER_INVALID: "80045800",
  PP_E_NAME_BLANK: "80041100",
  PP_E_EMAIL_INCOMPLETE: "8004110D",
  PP_E_EMAIL_INVALID: "8004110B",
  PP_E_NAME_TOO_SHORT: "80041101",
  PP_E_NAME_INVALID: "80041103",
  PP_E_INVALIDARG: "80048388",
  PP_E_SA_TOOSHORT: "80041120",
  PP_E_SA_TOOLONG: "80041121",
  PP_E_INVALID_PHONENUMBER: "8004113F",
  PP_E_SECRETQ_CONTAINS_SECRETA: "80041165",
  PP_E_SECRETA_CONTAINS_SECRETQ: "8004117D",
  PP_E_SA_CONTAINS_MEMBERNAME: "8004116A",
  PP_E_STRONGPROCESS_ALTEMAILSAMEASMAILBOX: "80049C2D",
  PP_E_EMAIL_RIGHT_TOO_LONG: "8004110C",
  PP_E_NAME_TOO_LONG: "80041102",
  PP_E_ALIAS_AUTH_NOTPERMITTED: "8004788B",
  PP_E_TOTP_INVALID: "80049C34",
  PP_E_OLD_SKYPE_PASSWORD: "80043557",
  PP_E_OTT_DATA_INVALID: "8004348F",
  PP_E_OTT_ALREADY_CONSUMED: "80043490",
  PP_E_OTT_INVALID_PURPOSE: "80043496",
  PP_E_PPSA_RPT_NOTOADDRESS: "80048120",
  PP_E_STRONGPROCESS_BADDEVICENAME: "80049C22",
  PP_E_INLINELOGIN_INVALID_SMS: "800434E1",
  PP_E_INLINELOGIN_INVALID_ALT: "800434E2",
  PP_E_PREVIOUS_PASSWORD: "80041013",
  PP_E_HIP_VALIDATION_WRONG: "80045505",
  PP_E_HIP_VALIDATION_ERROR_FATAL: "80045537",
  PP_E_HIP_VALIDATION_ERROR_UNAUTHENTICATED: "80045538",
  PP_E_HIP_VALIDATION_ERROR_OTHER: "80045539",
  PP_E_SQ_CONTAINS_PASSWORD: "8004341E",
  PP_E_SA_CONTAINS_PASSWORD: "8004341C",
  PP_E_SA_CONTAINED_IN_PASSWORD: "8004341D",
  PP_E_LIBPHONENUMBERINTEROP_NUMBERPARSE_EXCEPTION: "80043510",
  PP_E_STRONGPROCESS_EMAIL_HAS_MOBILE_DOMAIN: "80049C33",
  PP_E_STRONGPROCESS_MXALIAS_NOTALLOWED: "80049C23",
  PP_E_INVALID_MEMBERNAME: "80041034",
  PP_E_SA_TOO_MANY_CACHE_SESSIONS: "8004A00C",
  PP_E_INTERFACE_DISABLED: "80043448",
  PP_E_ASSOCIATE_DUPLICATE_ACCOUNT: "80043534",
  PP_E_OAUTH_REMOTE_CONNECT_USER_CODE_MISSING_OR_INVALID: "800478C7",
  PP_E_LOGIN_NOPA_USER_PASSWORD_REQUIRED: "800478CE",
  PP_E_IDP_LINKEDIN_BINDING_NOT_ALLOWED: "800478D5",
  PP_E_IDP_GOOGLE_BINDING_NOT_ALLOWED: "800478D6",
  PP_E_IDP_GITHUB_BINDING_NOT_ALLOWED: "800478D7",
  PP_E_IDP_BINDING_EXISTS_SAMSUNG: "8004453E",
};

export const transformCredentialFlags = {
  disableDesktopSsoPreferredCred: true,
  disableAutoSend: true,
  isPostRequest: true,
};
