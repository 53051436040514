import React, { useContext } from "react";
import {
  TextButtonContainer,
  TextButtonFabric,
} from "../../../../components/text-button/fabric/text-button-fabric";
import { TileList } from "../../../../components/tile-list";
import { TitleFabric } from "../../../../components/title/fabric/title-fabric";
import { FlowId, ViewId } from "../../../../constants/routing-constants";
import { GlobalContext } from "../../../../global-context";
import { useActivateView } from "../../../../hooks/use-activate-view";
import { useNavigateDirection } from "../../../../hooks/use-navigate-direction";
import useFabricStyles, { useStaticFabricStyles } from "../../../../styles/fabric/fabric.styles";
import { getFidoSupport } from "../../../../utilities/browser-helper";
import { commonLoginStringsFabric } from "../../fabric/common-login-strings-fabric";
import LoginConfig from "../../login-config";
import { getAvailableCredsWithoutUsername } from "../../login-util";
import { useSignInOptionsBuilder } from "../hooks/signin-options-view-hooks";
import { signInOptionsStringsFabric } from "./signin-options-strings-fabric";

/**
 * SignInOptionsView component
 * @returns A rendered instance of this component
 */
export const SignInOptionsViewFabric: React.FC = function SignInOptionsViewFabric() {
  const { backLabel, signInOptionsTitle } = signInOptionsStringsFabric;

  const { isFidoSupportedHint } = LoginConfig.instance;
  const isFidoSupported = getFidoSupport(isFidoSupportedHint);
  const credentials = getAvailableCredsWithoutUsername(isFidoSupported);

  const navigate = useNavigateDirection();

  useStaticFabricStyles();
  const fabricStyles = useFabricStyles();

  useActivateView(ViewId.SignInOptions, FlowId.Login, { showIdentityBanner: false });
  useContext(GlobalContext);

  const tileList = useSignInOptionsBuilder(credentials, {
    commonLoginStrings: commonLoginStringsFabric,
    signInOptionsStrings: signInOptionsStringsFabric,
  });

  return (
    <div>
      <TitleFabric titleId="signInOptionsTitle" title={signInOptionsTitle} />
      <div className={fabricStyles.formGroup}>
        <TileList
          ariaLabelledBy={signInOptionsTitle}
          ariaDescribedBy={signInOptionsTitle}
          tileList={tileList}
        />
      </div>
      <div className={fabricStyles.buttonMargin}>
        <TextButtonContainer>
          <TextButtonFabric
            buttonId="backButton"
            label={backLabel}
            isPrimary={false}
            isButtonType
            ariaLabel={backLabel}
            onClick={() => navigate(ViewId.Username, -1)}
          />
        </TextButtonContainer>
      </div>
    </div>
  );
};
