import { useContext } from "react";
import { AuthenticationContext } from "../../../../authentication-context";
import { ViewId } from "../../../../constants/routing-constants";
import { GlobalContext } from "../../../../global-context";
import { GlobalActionType } from "../../../../global-reducer";
import { useNavigateDirection } from "../../../../hooks/use-navigate-direction";
import { type IPhoneNumberSubmitProps } from "../../../../hooks/use-phone-number";
import {
  GctResultAction,
  sendAsync,
} from "../../../../utilities/api-helpers/get-credential-type-helper";
import { getFidoSupport } from "../../../../utilities/browser-helper";
import { type ICountryInfo, getDefaultCountry } from "../../../../utilities/country-helper";
import { getRouteFromViewId } from "../../../../utilities/routing-helper";
import { appendOrReplaceQueryStringParams } from "../../../../utilities/strings-helper";
import { useLoginFlowRedirect } from "../../hooks/login-hooks";
import LoginConfig from "../../login-config";
import { LoginContext } from "../../login-context";
import { type ICommonLoginStrings } from "../../login-interface";
import { getCommonTitle } from "../../login-util";

export interface IPhoneDisambiguationStrings {
  documentTitle: string;
  errorMessage: string;
  title: string;
  description: string;
  placeholder: string;
  ariaLabel: string;
  signInWithDifferentUser: string;
  createNewAccountLabel: string;
  backLabel: string;
  nextLabel: string;
}

/**
 * @returns Strings for the phone disambiguation view
 * @param strings Flavored strings used by this hook
 * @param strings.commonLoginStrings Common login strings
 * TODO @mjg-flavors: separate static localized strings (which should live in a static object) from dynamic strings like title (which need to be computed at runtime)
 */
export const usePhoneDisambiguationStrings = (strings: {
  commonLoginStrings: ICommonLoginStrings;
}): IPhoneDisambiguationStrings => {
  const { commonLoginStrings } = strings;
  const { improvePhoneDisambiguation, loginMode } = LoginConfig.instance;
  const {
    globalState: {
      styles: { friendlyAppName },
    },
  } = useContext(GlobalContext);

  return {
    documentTitle: getCommonTitle(loginMode, friendlyAppName, commonLoginStrings),
    errorMessage: getLocalString("Login_PhoneNumber_ForbiddenCharacters_Error"),
    title: getLocalString("Login_PhoneDisambiguation_PageTitle"),
    description: improvePhoneDisambiguation
      ? getLocalString("Login_Error_PhoneUser_NeedHelpToLookUpAccount")
      : getLocalString("Login_Description_VerifyCountryCodeAndPhone"),
    placeholder: getLocalString("General_PhoneNumber"),
    ariaLabel: getLocalString("Enter_PhoneNumber"),
    signInWithDifferentUser: getLocalString("PhoneUser_SigninWithDifferentUsername"),
    createNewAccountLabel: getLocalString("PhoneUser_CreateNewAccount"),
    backLabel: getLocalString("General_Back"),
    nextLabel: getLocalString("General_Buttons_Next"),
  };
};

/**
 * @returns Click handler for the "sign in as a different user" link
 */
export const useSignInDifferentUserHandler = () => {
  const { dispatchStateChange } = useContext(GlobalContext);
  const navigate = useNavigateDirection();

  return (unsafeUsername: string) => {
    dispatchStateChange({
      type: GlobalActionType.SetUser,
      payload: { username: unsafeUsername },
    });

    dispatchStateChange({
      type: GlobalActionType.BeginNavigate,
      source: ViewId.PhoneDisambiguation,
      destination: ViewId.Username,
      displayOptions: { navigationDirection: "forward" },
    });

    navigate(ViewId.PhoneDisambiguation, ViewId.Username);
  };
};

/**
 * @returns Default country
 */
export const useGetDefaultCountry = (): ICountryInfo => {
  const { countryList, defaultCountry: country } = LoginConfig.instance;

  const {
    viewState: { location },
  } = useContext(LoginContext);
  // Check if location context is available, else get default country from login config
  return getDefaultCountry(countryList, location || country);
};

/**
 * @returns function for the GCT request
 */
export const useGctRequest = () => {
  const navigator = useNavigateDirection();
  const { dispatchStateChange } = useContext(GlobalContext);

  const {
    authState: { flowTokenValue: flowToken },
  } = useContext(AuthenticationContext);

  const {
    viewState: { otherIdpRedirectUrl },
  } = useContext(LoginContext);
  const {
    isFidoSupportedHint,
    gctFederationFlags,
    isFederationDisabled,
    isExternalFederationDisallowed,
    isRemoteNGCSupported,
    isOtcLoginDisabled: otclogindisallowed,
    getCredentialTypeUrl,
    showSignup,
    signupUrl,
    improvePhoneDisambiguation,
    fedQs,
  } = LoginConfig.instance;

  const isFidoSupported = getFidoSupport(isFidoSupportedHint);

  const loginFlowRedirect = useLoginFlowRedirect();

  return async (params: IPhoneNumberSubmitProps) => {
    const { value: unsafeUsername, errorHandler, inputValue } = params;

    const updatedIdpRedirectUrl = appendOrReplaceQueryStringParams(otherIdpRedirectUrl, {
      username: unsafeUsername,
      login_hint: unsafeUsername,
    });

    const gctResult = await sendAsync(
      {
        unsafeUsername,
        flowToken,
        isFidoSupported,
        country: "", // for now, country is not needed in MSA
        gctFederationFlags: gctFederationFlags || 0,
        isExternalFederationDisallowed,
        isFederationDisabled,
        isRemoteNGCSupported,
        otclogindisallowed,
        getCredentialTypeUrl,
        otherIdpRedirectUrl: updatedIdpRedirectUrl,
        fedQs,
      },
      {
        showSignup,
        signupUrl,
        improvePhoneDisambiguation,
      },
      { isPhoneNumberFullyQualified: true, checkCurrentIdpOnly: true },
    );

    switch (gctResult.action) {
      case GctResultAction.ShowError:
        if (gctResult.error) {
          errorHandler(gctResult.error);
        }

        break;
      case GctResultAction.SwitchView:
        dispatchStateChange({
          type: GlobalActionType.SetUser,
          payload: { username: inputValue, displayUsername: gctResult.sharedData?.displayName },
        });
        navigator(ViewId.PhoneDisambiguation, getRouteFromViewId(gctResult.viewId!));
        break;
      case GctResultAction.Redirect:
        loginFlowRedirect(
          gctResult.redirectUrl || "",
          gctResult.redirectPostParams,
          gctResult.isIdpRedirect,
        );
        break;
      default:
      // do nothing for now
    }
  };
};
