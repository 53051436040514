import React, { useContext } from "react";
import { mergeClasses } from "@griffel/react";
import { CredentialSwitchLinksComponent } from "../../../../components/credential-switch-links/credential-switch-links";
import { LinkButton } from "../../../../components/link-button";
import {
  TextButtonContainer,
  TextButtonFabric,
} from "../../../../components/text-button/fabric/text-button-fabric";
import { TitleFabric } from "../../../../components/title/fabric/title-fabric";
import { FlowId, ViewId } from "../../../../constants/routing-constants";
import GlobalConfig from "../../../../global-config";
import { useActivateView } from "../../../../hooks/use-activate-view";
import { useDocumentTitle } from "../../../../hooks/use-document-title";
import useFabricStyles, { useStaticFabricStyles } from "../../../../styles/fabric/fabric.styles";
import { SecondaryContentContainerFabric } from "../../components/fabric/secondary-content-container-fabric";
import { commonLoginStringsFabric } from "../../fabric/common-login-strings-fabric";
import {
  useBrandingDescriptionProperties,
  useResetRemoteNgcRequestSent,
  useShowBackButton,
} from "../../hooks/login-hooks";
import { LoginContext } from "../../login-context";
import { useIdpRedirectSpeedbumpViewProperties } from "../hooks/use-idp-redirect-speedbump-view-properties";
import { useIsEvictedAccountLinkClickHandler } from "../hooks/use-is-evicted-account-link-click-handler";
import { usePrimaryButtonProperties } from "../hooks/use-primary-button-properties";

/**
 * IdpRedirectSpeedbumpView component
 *
 * If the user has more than 1 available credential or at least 1 evicted credential,
 * this view is shown instead of the IDP redirect view.
 *
 * This view is very similar to the IDP redirect view in that it is shown when
 * 1. the user is switching IDPs such as changing from AAD to MSA or vice versa
 * 2. redirecting the user to a federated login page
 *
 * The differences between this view and the IDP redirect view are that this view
 * 1. offers a Next button that initiates the redirect request when clicked instead of
 * starting the redirect after a 2 second timeout.
 * 2. shows a few links that allow the user to switch their preferred credential.
 *
 * @returns A rendered instance of this component
 */
export const IdpRedirectSpeedbumpViewFabric: React.FC = function IdpRedirectSpeedbumpViewFabric() {
  // Global config data
  const { showButtons } = GlobalConfig.instance;

  // Login context data
  const {
    viewState: {
      credentials: { evictedCredentials },
    },
  } = useContext(LoginContext);

  useResetRemoteNgcRequestSent();

  const {
    documentTitle,
    lightboxTitleId,
    lightboxTitle,
    lightboxDescriptionId,
    lightboxDescription,
    redirectUrl,
    redirectPostParams,
    renderSecondaryContent,
    credentialSwitchLinksProps,
  } = useIdpRedirectSpeedbumpViewProperties({ commonLoginStrings: commonLoginStringsFabric });

  const { renderBrandingDescription, brandingDescriptionId, brandingDescription } =
    useBrandingDescriptionProperties();

  const isEvictedAccountLinkClickHandler = useIsEvictedAccountLinkClickHandler();

  const { primaryButtonAriaDescribedBy, primaryButtonClickHandler } = usePrimaryButtonProperties(
    lightboxTitleId,
    lightboxDescriptionId,
    renderBrandingDescription,
    brandingDescriptionId,
    redirectUrl,
    redirectPostParams,
  );

  const showBackButton = useShowBackButton();

  useActivateView(ViewId.IdpRedirectSpeedbump, FlowId.Login, {
    showBackButtonOnActiveView: showBackButton,
    showIdentityBanner: true,
  });
  useDocumentTitle(documentTitle);
  useStaticFabricStyles();

  const fabricStyles = useFabricStyles();

  return (
    <>
      {/* @AAD-TODO: add external title class when we tackle ESTS */}
      <TitleFabric titleId={lightboxTitleId} title={lightboxTitle} />

      {renderBrandingDescription && (
        <div className={mergeClasses(fabricStyles.row, fabricStyles.textBody)}>
          <div id={brandingDescriptionId} className={fabricStyles.wrapContent}>
            {brandingDescription}
          </div>
        </div>
      )}

      {lightboxDescription && (
        <div className={fabricStyles.formGroup}>
          <div
            id={lightboxDescriptionId}
            className={mergeClasses(
              fabricStyles.row,
              fabricStyles.textBody,
              fabricStyles.textBlockBody,
            )}
          >
            {lightboxDescription}
          </div>
        </div>
      )}

      <div className={fabricStyles.buttonMargin}>
        <div className={fabricStyles.row}>
          {renderSecondaryContent && (
            <SecondaryContentContainerFabric>
              <div className={fabricStyles.formGroup}>
                <CredentialSwitchLinksComponent {...credentialSwitchLinksProps} />
              </div>

              {evictedCredentials.length > 0 && (
                <div className={fabricStyles.formGroup}>
                  <LinkButton
                    linkId="evictedAccount"
                    text={getLocalString("Login_SwitchToCredPicker_Link_EvictedAcct")}
                    onClick={isEvictedAccountLinkClickHandler}
                  />
                </div>
              )}
            </SecondaryContentContainerFabric>
          )}
        </div>
      </div>

      <div className={fabricStyles.row}>
        <TextButtonContainer>
          {showButtons && (
            <TextButtonFabric
              isButtonType
              isPrimary
              hasFocus
              ariaDescribedBy={primaryButtonAriaDescribedBy}
              label={getLocalString("General_Buttons_Next")}
              onClick={primaryButtonClickHandler}
            />
          )}
        </TextButtonContainer>
      </div>
    </>
  );
};
