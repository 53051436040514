import { useContext, useEffect, useMemo } from "react";
import { type FlowId, ViewId } from "../constants/routing-constants";
import GlobalConfig from "../global-config";
import { GlobalContext } from "../global-context";
import { type ViewOptions, defaultViewOptions, GlobalActionType } from "../global-reducer";
import { getStandardDimensions, TelemetryEventType } from "../telemetry-helpers/telemetry-helper";

/**
 * React hook used to indicate that a view has activated. Should be called from within the view's render function.
 * @param view The view to activate
 * @param flow The flow that this view belongs to
 * @param options The optional view properties
 */
export function useActivateView(view: ViewId, flow: FlowId, options?: Partial<ViewOptions>) {
  const {
    dispatchStateChange,
    globalState: { activeFlavor },
  } = useContext(GlobalContext);

  const viewOptions: ViewOptions = useMemo(() => ({ ...defaultViewOptions }), []);

  if (options?.isWideView !== undefined) {
    viewOptions.isWideView = options.isWideView;
  }

  if (options?.showIdentityBanner !== undefined) {
    viewOptions.showIdentityBanner = options.showIdentityBanner;
  }

  if (options?.showBackButtonOnActiveView !== undefined) {
    viewOptions.showBackButtonOnActiveView = options.showBackButtonOnActiveView;
  }

  if (options?.hideBannerLogo !== undefined) {
    viewOptions.hideBannerLogo = options.hideBannerLogo;
  }

  if (options?.loadingData !== undefined) {
    viewOptions.loadingData = options.loadingData;
  }

  if (options?.showMoreOptions !== undefined) {
    viewOptions.showMoreOptions = options.showMoreOptions;
  }

  useEffect(() => {
    dispatchStateChange({
      type: GlobalActionType.ActivateView,
      view,
      flow,
      flavor: activeFlavor,
      viewOptions,
    });

    if (view !== ViewId.None) {
      GlobalConfig.instance?.telemetry?.addEvent({
        _table: TelemetryEventType.ServiceDiag,
        metricName: "ViewActivation",
        metricValue: "1",
        dimensions: getStandardDimensions({ activeView: view, activeFlow: flow, activeFlavor }),
      });
    }

    return () => dispatchStateChange({ type: GlobalActionType.DeactivateView, view });
  }, [dispatchStateChange, view, flow, activeFlavor, viewOptions]);
}
