import { type IOneTimeCodeViewStrings } from "../one-time-code-view-interface";

export const oneTimeCodeViewStringsFabric: IOneTimeCodeViewStrings = {
  codeSentDescriptionEmail: getLocalString("OTC_Description_Email"),
  codeSentDescriptionSms: getLocalString("OTC_Description_SMS"),
  codeSentDescriptionVoice: getLocalString("OTC_Description_Voice"),
  codeSentIfMatchDescriptionEmail: getLocalString("VerifyCode_Desc_Email"),
  codeSentIfMatchDescriptionSms: getLocalString("VerifyCode_Desc_SMS"),
  codeSentIfMatchDescriptionVoice: getLocalString("VerifyCode_Desc_Voice"),
  emptyCodeError: getLocalString("EmptyOTC_Error"),
  enterCodeAriaLabel: getLocalString("OTC_Title"),
  enterCodeDescription: getLocalString("General_EnterCode_HelpText"),
  invalidCodeError: getLocalString("IncorrectOTC_Error"),
  placeholder: getLocalString("Generic_EnterCode"),
  primaryButtonLabel: getLocalString("SignIn_Title"),
  title: getLocalString("Generic_EnterCode"),
};
