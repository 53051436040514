import { type FlowId } from "../constants/routing-constants";

export type AgreementViewProps = {
  hostingFlow: FlowId;
};

export enum AgreementType {
  TermsOfUse = "tou",
  Privacy = "privacy",
  Impressum = "impressum",
  A11yConforme = "a11yConforme",
}

export interface LinkAgreementState {
  prevPath: string;
  agreementType: AgreementType;
}

export const agreementRoute = "/agreement";
