import { isEmailAddress, isPhoneNumber, isSkypeName } from "../../../utilities/strings-helper";
import { type UntrustedExternalInputText } from "../../../utilities/untrusted-external-input-text";

/**
 * Returns the appropriate page description to display at the top of the lightbox.
 * @param displayUsername the display username
 * @param hasInitialError whether the GCT call by the FetchSessionsProgress view resulted in an error
 * @returns The appropriate page description to display at the top of the lightbox.
 */
export const getPageDescription = (
  displayUsername: UntrustedExternalInputText,
  hasInitialError: boolean,
) => {
  const { unsafeUnescapedString: unsafeDisplayUsername } = displayUsername;

  const isOnlyPhoneNumber =
    !isEmailAddress(unsafeDisplayUsername) &&
    !isSkypeName(unsafeDisplayUsername) &&
    isPhoneNumber(unsafeDisplayUsername);

  const loginDescription = isOnlyPhoneNumber
    ? getLocalString("Disambiguation_Description_Phone")
    : getLocalString("ChooseAccount_Heading");

  return hasInitialError ? getLocalString("ChooseAccount_ErrorDescription") : loginDescription;
};
