import React, { type RefObject, useContext, useEffect, useRef } from "react";
import GlobalConfig from "../../../../global-config";
import { GlobalContext } from "../../../../global-context";
import { FOCUS_TIMEOUT } from "../../../../styles/fabric/layout-animate-fabric.styles";
import LoginConfig from "../../login-config";
import { Error, LoginMode } from "../../login-constants";
import { type ICommonLoginStrings } from "../../login-interface";
import { type IErrorViewLinkProperties, type IErrorViewProperties } from "../../login-types";
import { getCommonTitle } from "../../login-util";
import {
  getErrorCodeDescription,
  getLightboxTitle,
  getLoginModeErrorDesc,
  getSwitchUrlText,
  getUsernameDescription,
} from "../error-view-util";

interface IManageCredentialsLinkProps {
  /** The unique id for the manage credentials link */
  id?: string;
  /** The ids of the labels or elements that describe this link component */
  ariaDescribedBy?: string;
  /** The url that this link takes the user to */
  href?: string;
}

/**
 * ManageCredentialsLink component that is only used in the login error view
 * @returns A rendered instance of this component
 */
const ManageCredentialsLink = React.forwardRef<
  HTMLAnchorElement,
  React.PropsWithChildren<IManageCredentialsLinkProps>
>((props, forwardRef) => {
  const { children, id, ariaDescribedBy, href } = props;
  return (
    <a ref={forwardRef} id={id} aria-describedby={ariaDescribedBy} href={href}>
      {children}
    </a>
  );
});

/**
 * @param props properties to use for the embedded ManageCredentialsLink component
 * @returns a function that can be used to render the ManageCredentialsLink component embedded within a formatted string
 */
export const useRenderManageCredentialsLink = (props: IErrorViewLinkProperties) => {
  const { ref, id, ariaDescribedBy, href } = props;
  return function renderLink(linkText: string) {
    return (
      <ManageCredentialsLink ref={ref} id={id} ariaDescribedBy={ariaDescribedBy} href={href}>
        {linkText}
      </ManageCredentialsLink>
    );
  };
};

/**
 * Creates the reference object for a link in the error view and uses the input hasFocus boolean and the
 * useEffect hook to ensure the link gains focus after it has been rendered.
 * @param hasFocus - whether the link should have focus after being rendered.
 * @returns The created link reference object.
 */
const useLinkRef = (hasFocus: boolean): RefObject<HTMLAnchorElement> => {
  const linkRef = useRef<HTMLAnchorElement>(null);
  useEffect(() => {
    if (hasFocus) {
      setTimeout(() => {
        linkRef?.current?.focus();
      }, FOCUS_TIMEOUT);
    }
  }, [hasFocus]);
  return linkRef;
};

/**
 * @returns Login error view properties
 * @param strings Flavored strings used by this hook
 * @param strings.commonLoginStrings Common login strings
 */
export const useErrorViewProperties = (strings: {
  commonLoginStrings: ICommonLoginStrings;
}): IErrorViewProperties => {
  const { commonLoginStrings } = strings;

  // Global context data
  const {
    globalState: {
      styles: { friendlyAppName },
      debugInfo: { errorCode },
    },
  } = useContext(GlobalContext);

  // Global config data
  const {
    errorText,
    manageCredsUrl,
    postUsername,
    resetPasswordUrl,
    signInUsername,
    switchUserUrl,
  } = GlobalConfig.instance;

  // Login config data
  const {
    fedPartnerName,
    loginMode,
    loginStringsVariant,
    defaultSignInHeader,
    appBrandedSignInHeader,
    staySignInUrl,
  } = LoginConfig.instance;

  const lightboxTitleId = "loginHeader";
  const loginModeErrorDescId = "idTD_Error";
  const documentTitle = getCommonTitle(loginMode, friendlyAppName, commonLoginStrings);

  const isBindFailedMode = loginMode === LoginMode.BindFailed;
  const lightboxTitle = getLightboxTitle(
    isBindFailedMode,
    loginStringsVariant,
    defaultSignInHeader,
    appBrandedSignInHeader,
  );

  const isGenericErrorMode =
    loginMode === LoginMode.GenericError ||
    loginMode === LoginMode.GenericErrorMobile ||
    loginMode === LoginMode.GenericErrorHost;
  const isSwitchUserMode =
    loginMode === LoginMode.SwitchUser ||
    loginMode === LoginMode.SwitchUserMobile ||
    loginMode === LoginMode.SwitchUserHost;

  let username = "";
  if (!isGenericErrorMode) {
    username = isSwitchUserMode
      ? signInUsername
      : postUsername.unsafeUnescapedString || signInUsername;
  }

  const loginModeErrorDesc = getLoginModeErrorDesc(loginMode, username, fedPartnerName);
  const errorCodeDescription = getErrorCodeDescription(errorText, errorCode);

  const isHipLockedMode =
    loginMode === LoginMode.HIP_Lockout ||
    loginMode === LoginMode.HIP_LockoutMobile ||
    loginMode === LoginMode.HIP_LockoutHost;
  const usernameDescription = getUsernameDescription(username, isHipLockedMode);
  const switchUserUrlText = getSwitchUrlText(isHipLockedMode, isSwitchUserMode);

  const focusErrorTextLink = errorCode === Error.PP_E_IDP_BINDING_EXISTS_SAMSUNG;
  const linkAriaDescribedBy = `${lightboxTitleId} ${loginModeErrorDescId}`;
  const manageCredsLink: IErrorViewLinkProperties = {
    ref: useLinkRef(true),
    id: "manageCredsUrl",
    ariaDescribedBy: linkAriaDescribedBy,
    href: manageCredsUrl,
    // The text for this link is contained in the errorDescription and should be rendered using the
    // FormattedTextWithBindings component in the login error view.
    text: "",
  };

  const resetPasswordLink: IErrorViewLinkProperties = {
    ref: useLinkRef(!focusErrorTextLink && isHipLockedMode),
    id: "i1011",
    ariaDescribedBy: !focusErrorTextLink && isHipLockedMode ? linkAriaDescribedBy : undefined,
    href: resetPasswordUrl,
    text: getLocalString("Lockout_ResetPassword_LinkText"),
  };

  const staySignInLink: IErrorViewLinkProperties = {
    ref: useLinkRef(!focusErrorTextLink && !isHipLockedMode && isSwitchUserMode),
    id: "i1052",
    ariaDescribedBy:
      !focusErrorTextLink && !isHipLockedMode && isSwitchUserMode ? linkAriaDescribedBy : undefined,
    href: staySignInUrl,
    text: getLocalString("SwitchUser_RemainSignedIn"),
  };

  const switchUserLink: IErrorViewLinkProperties = {
    ref: useLinkRef(!focusErrorTextLink && !isHipLockedMode && !isSwitchUserMode),
    id: "i1051",
    ariaDescribedBy:
      !focusErrorTextLink && !isHipLockedMode && !isSwitchUserMode
        ? linkAriaDescribedBy
        : undefined,
    href: switchUserUrl,
    text: switchUserUrlText,
  };

  return {
    documentTitle,
    lightboxTitle,
    lightboxTitleId,
    loginModeErrorDesc,
    loginModeErrorDescId,
    errorCodeDescription,
    usernameDescription,
    isBindFailedMode,
    isGenericErrorMode,
    isSwitchUserMode,
    isHipLockedMode,
    manageCredsLink,
    resetPasswordLink,
    staySignInLink,
    switchUserLink,
  };
};
