import { useRedirect } from "../../../../../hooks/use-redirect";
import { appendOrReplaceQueryStringParams } from "../../../../../utilities/strings-helper";
import LoginConfig from "../../../login-config";

/**
 * Hook that provides the click handler for the recover username link button
 * @returns Click handler for the recover username link button
 */
export const useRecoverUsernameClickHandler = () => {
  const { forgotUsernameUrl } = LoginConfig.instance;
  const redirect = useRedirect();
  const onRecoverUsernameClicked = (username: string) => {
    const recoverUsernameUrl = appendOrReplaceQueryStringParams(forgotUsernameUrl, {
      mn: encodeURIComponent(username),
    });
    redirect(recoverUsernameUrl);
  };

  return onRecoverUsernameClicked;
};
