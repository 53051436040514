import { ViewId } from "../constants/routing-constants";

/**
 * Checks if a given string is a valid ViewID
 * @param viewId The viewId
 * @returns True if the ViewId is valid
 */
export function isViewId(viewId: unknown): viewId is ViewId {
  return typeof viewId === "string" && Object.values<string>(ViewId).includes(viewId);
}

/**
 * Gets a route for a given a viewId
 * @param viewId The viewId
 * @returns The matching route for the viewId; throws error if view not found
 */
export const getRouteFromViewId = (viewId: ViewId) => {
  if (Object.values<string>(ViewId).includes(viewId) && viewId !== ViewId.None) {
    return `/${viewId}`;
  }

  throw new Error(`Invalid view: ${viewId} - cannot create route.`);
};
