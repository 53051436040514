import { type Dispatch, type SetStateAction, useEffect, useState } from "react";

export const useDocumentTitle = (title: string): [string, Dispatch<SetStateAction<string>>] => {
  const [documentTitle, setDocumentTitle] = useState<string>(title);

  useEffect(() => {
    document.title = documentTitle;
  }, [documentTitle]);

  return [documentTitle, setDocumentTitle];
};
