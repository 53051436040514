import { useContext } from "react";
import { GlobalContext } from "../../../../global-context";
import LoginConfig from "../../login-config";
import { LoginContext } from "../../login-context";
import { type ICommonLoginStrings } from "../../login-interface";
import { getCommonTitle } from "../../login-util";
import { getBindProviderProperties } from "../idp-redirect-view-util";

export interface IIdpRedirectViewProperties {
  documentTitle: string;
  lightboxTitle: string;
}

/**
 * Hook that returns important properties for the IDP redirect view
 * @returns Login IDP redirect view properties
 * @param strings - Common login strings
 * @param strings.commonLoginStrings - Common login strings
 */
export const useIdpRedirectViewProperties = (strings: {
  commonLoginStrings: ICommonLoginStrings;
}): IIdpRedirectViewProperties => {
  const { commonLoginStrings } = strings;

  // Global context data
  const {
    globalState: {
      styles: { friendlyAppName },
    },
  } = useContext(GlobalContext);

  // Login context data
  const {
    viewState: { idpRedirectPostParams, idpRedirectProvider, idpRedirectUrl },
  } = useContext(LoginContext);

  // Login config data
  const { facebookFedUrl, githubFedUrl, googleFedUrl, linkedInFedUrl, loginMode } =
    LoginConfig.instance;

  const documentTitle = getCommonTitle(loginMode, friendlyAppName, commonLoginStrings);

  const { title: lightboxTitle } = getBindProviderProperties(
    idpRedirectUrl,
    idpRedirectPostParams,
    idpRedirectProvider,
    {
      linkedInFedUrl,
      githubFedUrl,
      googleFedUrl,
      facebookFedUrl,
    },
  );

  return { documentTitle, lightboxTitle };
};
