import React from "react";
import { CredentialSwitchLinksComponent } from "../../../../../components/credential-switch-links/credential-switch-links";
import { InputContainer } from "../../../../../components/inputs/input-container";
import { AtLeastOneCharacterRegex } from "../../../../../components/inputs/input-regex-constants";
import { TextInput } from "../../../../../components/inputs/text-input";
import { LinkButton } from "../../../../../components/link-button";
import {
  TextButtonContainer,
  TextButtonFabric,
} from "../../../../../components/text-button/fabric/text-button-fabric";
import { TitleFabric } from "../../../../../components/title/fabric/title-fabric";
import { FlowId, ViewId } from "../../../../../constants/routing-constants";
import { useActivateView } from "../../../../../hooks/use-activate-view";
import { useDocumentTitle } from "../../../../../hooks/use-document-title";
import { useRedirect } from "../../../../../hooks/use-redirect";
import useTextInputForm from "../../../../../hooks/use-text-input-form";
import useFabricStyles from "../../../../../styles/fabric/fabric.styles";
import { simpleInputValidation } from "../../../../../utilities/input-helper";
import { SecondaryContentContainerFabric } from "../../../components/fabric/secondary-content-container-fabric";
import { commonLoginStringsFabric } from "../../../fabric/common-login-strings-fabric";
import { useSetServerDataErrorOnInput } from "../../../hooks/login-hooks";
import { LoginFlowPostHiddenInputs } from "../../../login-flow-post-hidden-inputs";
import { useEvictAccountLink } from "../hooks/use-evict-account-link";
import { usePasswordHandler } from "../hooks/use-password-handler";
import { usePasswordViewProperties } from "../hooks/use-password-view-properties";
import { passwordViewStringsFabric } from "./password-view-strings-fabric";

/**
 * PasswordView component
 * @flavor Fabric
 * @returns A rendered instance of this component
 */
export const PasswordViewFabric: React.FC = function PasswordViewFabric() {
  const {
    title,
    header,
    placeholder,
    brandingDescription,
    loginPostProps,
    noPasswordErrorMessage,
    primaryButtonLabel,
    postUrl,
    canGoBack,
    resetPasswordUrl,
    forgotPasswordLinkText,
    credentialSwitchLinksProps,
    useEvictedCredentials,
    allowPhoneDisambiguation,
    navigateToPhoneDisambig,
    showChangeUserLink,
    selectAccount,
  } = usePasswordViewProperties({
    commonLoginStrings: commonLoginStringsFabric,
    passwordViewStrings: passwordViewStringsFabric,
  });
  const fabricStyles = useFabricStyles();
  const { evictAccountLinkText, evictAccountLinkOnClick } =
    useEvictAccountLink(passwordViewStringsFabric);

  useActivateView(ViewId.Password, FlowId.Login, { showBackButtonOnActiveView: canGoBack });
  useDocumentTitle(title);

  const {
    focus,
    value,
    showError,
    externalError,
    onBlur,
    onFocus,
    onTextChange,
    validationError,
    setValidationError: onValidationError,
    setShowError,
    setExternalError,
  } = useTextInputForm();

  const onSubmit = usePasswordHandler(
    postUrl,
    validationError,
    externalError,
    onFocus,
    setShowError,
  );

  const onResetPasswordClick = useRedirect();

  // Set server data error on the input on initialize and update its state
  useSetServerDataErrorOnInput(setExternalError, resetPasswordUrl);

  return (
    <form
      name="f1"
      id="i0281"
      data-testid="passwordForm"
      noValidate
      spellCheck="false"
      method="post"
      autoComplete="false"
      onSubmit={onSubmit}
      action={postUrl}
    >
      <TitleFabric
        titleId="loginHeader"
        title={header}
        descriptionId="passwordDescription"
        description={brandingDescription}
      />

      <LoginFlowPostHiddenInputs
        canary={loginPostProps.canary}
        cleansedUsername={loginPostProps.cleansedUsername}
        context={loginPostProps.context}
        flowTokenName={loginPostProps.flowTokenName}
        flowTokenValue={loginPostProps.flowTokenValue}
        foundMsas={loginPostProps.foundMsas}
        isFidoSupported={loginPostProps.isFidoSupported}
        isKmsiChecked={loginPostProps.isKmsiChecked}
        loginOption={loginPostProps.loginOption}
        paginatedState={loginPostProps.paginatedState}
        postType={loginPostProps.postType}
        postedForceSignIn={loginPostProps.postedForceSignIn}
        randomBlob={loginPostProps.randomBlob}
        showCookieBanner={loginPostProps.showCookieBanner}
        displayUsername={loginPostProps.displayUsername}
        rngcDefaultType={loginPostProps.rngcDefaultType}
        rngcEntropy={loginPostProps.rngcEntropy}
        rngcSessionIdentifier={loginPostProps.rngcSessionIdentifier}
      />

      <TextInput
        id="i0118"
        name="passwd"
        placeholder={placeholder}
        type="password"
        showErrorInline={showError}
        externalError={externalError}
        value={value}
        changeHandler={onTextChange}
        validationErrorHandler={onValidationError}
        inputValidationFunc={simpleInputValidation(
          noPasswordErrorMessage,
          AtLeastOneCharacterRegex,
          noPasswordErrorMessage,
        )}
        ariaLabelledBy="passwordTitle"
        ariaDescribedBy="passwordDescription"
        hasFocus={focus}
        hasInitialFocus
        focusHandler={onFocus}
        blurHandler={onBlur}
        autocomplete="current-password"
      />

      <div className={fabricStyles.row}>
        <SecondaryContentContainerFabric>
          {/* MSA-Windows-TODO / AAD-TODO: switchToOfflineAccount link */}

          {forgotPasswordLinkText && (
            <div className={fabricStyles.formGroup}>
              <LinkButton
                text={forgotPasswordLinkText}
                linkId="idA_PWD_ForgotPassword"
                onClick={() => onResetPasswordClick(resetPasswordUrl, true)}
              />
            </div>
          )}

          {allowPhoneDisambiguation && (
            <div className={fabricStyles.formGroup}>
              <LinkButton
                text={commonLoginStringsFabric.phoneDisambiguationLinkText}
                onClick={navigateToPhoneDisambig}
              />
            </div>
          )}

          {!useEvictedCredentials && (
            <div className={fabricStyles.formGroup}>
              <CredentialSwitchLinksComponent {...credentialSwitchLinksProps} />
            </div>
          )}

          {/* Evict account link is for when the user has not used the phone number for an extended period of time 
            and is considered eligible for eviction since the number could have been lost or changed */}
          {evictAccountLinkText && (
            <div className={fabricStyles.formGroup} data-testid="evictedAccount">
              <LinkButton
                text={evictAccountLinkText}
                linkId="evictedAccount"
                onClick={evictAccountLinkOnClick}
              />
            </div>
          )}

          {showChangeUserLink && (
            <div className={fabricStyles.formGroup}>
              <LinkButton
                text={commonLoginStringsFabric.signInDifferentAccount}
                onClick={selectAccount}
              />
            </div>
          )}
        </SecondaryContentContainerFabric>
      </div>

      <InputContainer>
        <div className={fabricStyles.row}>
          <TextButtonContainer>
            <TextButtonFabric
              buttonId="idSIButton9"
              label={primaryButtonLabel}
              isPrimary
              isButtonType={false}
              ariaLabelledBy="passwordTitle"
              ariaDescribedBy="passwordDescription"
            />
          </TextButtonContainer>
        </div>
      </InputContainer>
    </form>
  );
};
