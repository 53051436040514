import React, { useCallback, useContext, useMemo, useState } from "react";
import { makeStyles } from "@griffel/react";
import { ExternalClassName } from "../constants/constants";
import GlobalConfig from "../global-config";
import { GlobalContext } from "../global-context";
import { GlobalActionType } from "../global-reducer";
import useFabricStyles from "../styles/fabric/fabric.styles";
import { useTelemetryState } from "../telemetry-helpers/use-telemetry-state";
import { postApiRequest } from "../utilities/request-helper";
import ProgressIndicatorFabric from "./loading-progress/fabric/progress-indicator-fabric";

export const useDebugTraceStyles = makeStyles({
  traceSection: {
    marginTop: "10px",
  },
  traceLink: {
    "text-decoration": "none",
    fontSize: "13px",
  },
});

/**
 * @returns DebugTrace component used to toggle debug mode
 */
export const DebugTrace: React.FC = function DebugTrace() {
  const { postUsername, debugModeUrl } = GlobalConfig.instance;
  const { globalState, dispatchStateChange } = useContext(GlobalContext);
  const {
    debugInfo: { debugModeActive },
    user,
  } = globalState;
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const debugTraceStyles = useDebugTraceStyles();
  const fabricStyles = useFabricStyles();
  const telemetryState = useTelemetryState();

  const toggleDebugMode = useCallback(() => {
    dispatchStateChange({ type: GlobalActionType.ToggleDebugMode });
  }, [dispatchStateChange]);

  const requestBody = useMemo(
    () =>
      JSON.stringify({
        mode: debugModeActive ? 0 : 1,
        user:
          postUsername.safeHtmlEscapedString || user.displayUsername.safeHtmlEscapedString || "",
      }),
    [
      debugModeActive,
      postUsername.safeHtmlEscapedString,
      user.displayUsername.safeHtmlEscapedString,
    ],
  );

  const toggleTrace = useCallback(() => {
    if (isLoading) {
      return;
    }

    const processingStartTime = new Date();
    setIsLoading(true);
    setError(false);

    postApiRequest(debugModeUrl, { processingStartTime, body: requestBody, telemetryState })
      .then(
        // Success
        toggleDebugMode,
        // Failure
        () => {
          setError(true);
        },
      )
      .then(() => setIsLoading(false));
  }, [isLoading, debugModeUrl, requestBody, telemetryState, toggleDebugMode]);

  if (!debugModeUrl) {
    return null;
  }

  const toggleTraceText = debugModeActive
    ? getLocalString("Error_Details_Debug_Mode_Disable")
    : getLocalString("Error_Details_Debug_Mode_Enable");

  return (
    <div className={debugTraceStyles.traceSection} data-testid="debugTrace">
      <div>
        <span className={fabricStyles.bold}>
          {getLocalString("Error_Details_Debug_Mode")}&nbsp;
        </span>
        <a
          href={debugModeUrl}
          role="button"
          onClick={(e) => {
            e.preventDefault();
            toggleTrace();
          }}
          aria-label={toggleTraceText}
          className={debugTraceStyles.traceLink}
        >
          {toggleTraceText}
        </a>
      </div>
      {isLoading && (
        <div>
          <ProgressIndicatorFabric />
        </div>
      )}
      {error && (
        <div role="alert" className={ExternalClassName.error}>
          {getLocalString("Error_SomethingWentWrong")}
        </div>
      )}
      <div data-testid="debugTraceDesc">{getLocalString("Error_Details_Debug_Mode_Desc")}</div>
    </div>
  );
};
