import { makeStyles } from "@griffel/react";

export const useProofImageStyles = makeStyles({
  icon: {
    position: "relative",
    width: "48px",
    height: "48px",
  },

  small: {
    width: "24px",
    height: "24px",
    float: "left",
    marginRight: "8px"
  },

  animate: {
    animationDuration: "1s", 
    animationTimingFunction: "ease",
    animationDelay: "0s",
    animationIterationCount: "infinite",
    animationDirection: "alternate",
    animationName: {
     "from": {
        opacity: .4
      }
    }
  },
});