import { CredentialType } from "../../../constants/constants";
import { AadErrorCode } from "../../../constants/errors";
import { type IUser, type TelemetryState } from "../../../global-context";
import { type LoggedError, ExceptionHelper } from "../../../telemetry-helpers/exception-helper";
import {
  type OneTimeCodeCredential,
  type UserCredential,
  ProofType,
} from "../../../types/credential-types";
import { OtcChannel } from "../../../utilities/api-helpers/one-time-code/one-time-code-types";
import { cleanseUsername, isEmailAddress } from "../../../utilities/strings-helper";
import LoginConfig from "../login-config";
import { type ILoginCredentials } from "../login-context";
import { isForceSignIn } from "../login-util";
import {
  type ConfirmSendCredential,
  type ConfirmSendCredentialTypes,
  type ConfirmSendStrings,
  type ConfirmSendUserCredentials,
  ExpectedConfirmSendCredentialTypes,
} from "./confirm-send-view-types";

/**
 * @param currentCredential The current credential being used
 * @param user The user reference
 * @returns The correct ProofType that correlates to the user's current credential
 */
export const getProofType = (currentCredential: ConfirmSendCredential, user: IUser) => {
  if (currentCredential.credentialType === CredentialType.OneTimeCode) {
    return currentCredential.proof.type;
  }

  return isEmailAddress(user.username.unsafeUnescapedString) ? ProofType.Email : ProofType.SMS;
};

/**
 * @param currentCredential The current credential being used
 * @param user The user reference
 * @returns The proof data if the current credential is OneTimeCode.
 * Otherwise returns the displayUsername, cleansed
 */
export const getProofData = (currentCredential: ConfirmSendCredential, user: IUser) =>
  currentCredential.credentialType === CredentialType.OneTimeCode
    ? currentCredential.proof.data
    : cleanseUsername(user.displayUsername.unsafeUnescapedString);

/**
 * @param proofType The ProofType for the user's current credential
 * @returns The EmailAddress OtcChannel if the proof type is Email.
 * Otherwise returns the MobileSMS OtcChannel.
 */
export const getChannel = (proofType: ProofType) =>
  proofType === ProofType.Email ? OtcChannel.emailAddress : OtcChannel.mobileSms;

/**
 * Note: This method should NOT be used outside the `useDescription` hook, which implements the entirety of the description logic
 * @param preferredCredential The preferred credential for the user
 * @param isEmailDestination Whether the destination for the code is email
 * @param errorCode A potential error code from the Server
 * @returns A description string based on the preferred credential, whether it's an email destination, or has an error from the Server.
 */
export const getDescriptionString = (
  preferredCredential: ConfirmSendCredentialTypes,
  isEmailDestination: boolean,
  errorCode?: string,
) => {
  const forceSignIn = isForceSignIn();

  if (preferredCredential === CredentialType.RemoteNGC) {
    if (errorCode === AadErrorCode.SsoArtifactExpiredDueToConditionalAccess) {
      return getLocalString("Confirm_Send_Description_RemoteNgc_ASLP");
    }

    if (forceSignIn) {
      return getLocalString("Confirm_Send_Description_RemoteNgc_ForceSignin");
    }

    return getLocalString("Confirm_Send_Description_RemoteNgc");
  }

  // OneTimeCode or PublicIdentifierCode
  if (errorCode === AadErrorCode.SsoArtifactExpiredDueToConditionalAccess) {
    return getLocalString("Confirm_Send_Description_Otc_ASLP");
  }

  if (forceSignIn) {
    return getLocalString("Confirm_Send_Description_Otc_ForceSignin");
  }

  if (isEmailDestination) {
    return getLocalString("Confirm_Send_Description_Otc_Email");
  }

  return getLocalString("Confirm_Send_Description_PhoneDisambig");
};

/**
 * @param preferredCredential The preferred credential for the user
 * @returns The correct text to use in the ConfirmSendView's primary (only) button
 */
export const getButtonText = function getButtonText(
  preferredCredential: ConfirmSendCredentialTypes,
) {
  const { riskySignIn } = LoginConfig.instance;

  if (preferredCredential === CredentialType.RemoteNGC) {
    return riskySignIn
      ? getLocalString("Confirm_Send_Description_RemoteNgc_ContinueSignin")
      : getLocalString("Confirm_Send_Description_RemoteNgc_SendNotification");
  }

  // OneTimeCode or PublicIdentifierCode
  return getLocalString("TFA_SendCodeButtonText");
};

/**
 * @param preferredCredential the preferredCredential from LoginContext
 * @returns indicator the preferredCredential is a supported type for the ConfirmSend view
 */
export const isValidPreferredCredential = (
  preferredCredential: CredentialType,
): preferredCredential is ConfirmSendCredentialTypes =>
  ExpectedConfirmSendCredentialTypes.includes(preferredCredential);

/**
 * @param otcCredential the otcCredential from LoginContext
 * @returns indicator the otcCredential is a properly defined OneTimeCodeCredential type
 */
export const isOtcCredential = (
  otcCredential: UserCredential | undefined,
): otcCredential is OneTimeCodeCredential =>
  otcCredential !== undefined &&
  "proof" in otcCredential &&
  otcCredential.proof !== undefined &&
  "display" in otcCredential.proof;

/**
 * This method validates the Login credentials and logs/throws an error if they are not properly defined.
 * @param credentials The credentials from LoginContext
 * @param telemetryState The telemetry state, used for logging
 * @returns The Login credentials, validated as properly typed ConfirmSendUserCredentials
 */
export const validateCredentials = (
  credentials: ILoginCredentials,
  telemetryState: TelemetryState,
) => {
  const { preferredCredential, otcCredential } = credentials;
  let validationError: LoggedError | null = null;

  if (!isValidPreferredCredential(preferredCredential)) {
    validationError = new Error(
      `preferredCredential (${preferredCredential}) is not a supported type`,
    );
  }

  if (preferredCredential === CredentialType.OneTimeCode && !isOtcCredential(otcCredential)) {
    validationError = new Error(
      "otcCredential is not defined correctly for the preferredCredential, OneTimeCode",
    );
  }

  if (validationError) {
    ExceptionHelper.logException(validationError, telemetryState, "InvalidOtcCredentialError");
    validationError.isLogged = true;
    throw validationError;
  }

  return credentials as ConfirmSendUserCredentials;
};

/**
 * @param credentials the validated ConfirmSendUserCredentials
 * @returns The current credential to be used
 */
export const getCurrentCredential = (credentials: ConfirmSendUserCredentials) => {
  const { preferredCredential, otcCredential } = credentials;

  if (preferredCredential === CredentialType.OneTimeCode) {
    return otcCredential;
  }

  // RemoteNGC or PublicIdentifierCode
  return { credentialType: preferredCredential };
};

/**
 * @returns Some localized strings that are used by the ConfirmSendView
 */
export const getConfirmSendStrings = (): ConfirmSendStrings => ({
  switchToEvictCredText: getLocalString("Login_SwitchToCredPicker_Link_EvictedAcct"),
  changeUserLinkText: getLocalString("Login_SignInWithAnotherAccount"),
  headerText: getLocalString("SignIn_Title"), // TODO: WF_STR_HeaderDefault_Title flexes more than this
});
