import React, { type ReactNode, useMemo, useReducer } from "react";

export interface IViewContext<TViewState, TViewActions> {
  viewState: TViewState;
  dispatchStateChange: React.Dispatch<TViewActions>;
}

/**
 * Create a view context provider for a given flow
 * @param viewContext The context (created using React.createContext) for which to create a provider
 * @param reducer The reducer that processes actions for this flow
 * @returns The context provider component
 */
export function createViewProvider<TViewState, TViewActions>(
  viewContext: React.Context<IViewContext<TViewState, TViewActions>>,
  reducer: (state: TViewState, action: TViewActions) => TViewState,
) {
  return function Provider({
    initialState,
    children,
  }: {
    initialState: TViewState;
    children?: ReactNode;
  }) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const value = useMemo(
      () => ({
        viewState: state,
        dispatchStateChange: dispatch,
      }),
      [state],
    );
    return <viewContext.Provider value={value}>{children}</viewContext.Provider>;
  };
}
