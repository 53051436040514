import { doubleJoin } from "./object-helper";
import { replaceTokens } from "./strings-helper";

const expireDate = "Thu, 30-Oct-1980 16:00:00 GMT";
const persistTTLDays = 390; // 13 months in days
const useSameSite = !!window.ServerData?.fUseSameSite;

/**
 * @returns the expiration date for persistent cookies starting from the current date
 */
const getPersistDate = (): string => {
  const date = new Date();
  date.setDate(date.getDate() + persistTTLDays);
  return date.toUTCString();
};

/**
 * @param secure Whether to make the cookie secure
 * @returns The default same site attribute
 */
const getDefaultSameSiteAttribute = (secure: boolean): string => {
  if (secure && useSameSite) {
    return ";SameSite=None";
  }

  return "";
};

/**
 * Removes the cookie specified by the name, domain and path parameters.
 * @param name The name of the cookie
 * @param domain Domain attribute to use for the cookie
 * @param path The path of the cookie. If not passed, the default "/" is used
 */
export const removeCookie = (name: string, domain?: string, path?: string) => {
  const domainName = domain || document.location.hostname;
  const hostParts = domainName.split(".");
  const partCount = hostParts.length;

  const cookieDomain = `${hostParts[partCount - 2]}.${hostParts[partCount - 1]}`;
  const cookiePath = path || "/";
  const secure = document.location.protocol === "https:";
  const secureContent = secure ? ";secure" : "";
  const sameSiteContent = getDefaultSameSiteAttribute(secure);

  document.cookie = replaceTokens(
    "{0}= ;domain=.{1};path={2};expires={3}{4}{5}",
    name,
    cookieDomain,
    cookiePath,
    expireDate,
    secureContent,
    sameSiteContent,
  );
  document.cookie = replaceTokens(
    "{0}= ;domain=.{1};path={2};expires={3}{4}{5}",
    name,
    domainName,
    cookiePath,
    expireDate,
    secureContent,
    sameSiteContent,
  );
};

/**
 * Writes a cookie to the domain passed as a parameter using the provided expiration date.
 * Blank cookies will be assumed to be cookie deletes, and will expire in the past.</summary>
 * @param name The name of the cookie
 * @param value The value of the cookie
 * @param secure Whether to make the cookie secure
 * @param expiresOn A string object representing the date when the cookie expires (in local time).
 * @param domain domain attribute to use for the cookie.
 * @param path The path of the cookie. If not passed, the default "/" is used
 * @param sameSite The SameSite attribute of the cookie. If not passed, None is used for https requests and no value is used for http requests
 */
export const writeCookieWithExpiration = (
  name: string,
  value: string | Record<string, unknown>,
  secure?: boolean,
  expiresOn?: string,
  domain?: string,
  path?: string,
  sameSite?: string,
) => {
  if (value === "") {
    removeCookie(name, domain);
  } else {
    const valueStr = typeof value === "object" ? doubleJoin(value, "&", "=") : value;

    const expiration = expiresOn ? `;expires=${expiresOn}` : "";
    const cookieDomain = domain ? `;domain=${domain}` : "";
    const cookiePath = path || "/";
    const secureContent = secure ? ";secure" : "";

    // SameSite=None should trigger the getDefaultSameSiteAttribute path to handle iOS, where SameSite=None is an error and so SameSite should be omitted
    let sameSiteContent;
    if (!sameSite || sameSite.toLowerCase() === "none") {
      sameSiteContent = getDefaultSameSiteAttribute(!!secure);
    } else {
      sameSiteContent = `;SameSite=${sameSite}`;
    }

    const cookieToWrite = replaceTokens(
      "{0}={1}{2};path={3}{4}{5}{6}",
      name,
      valueStr,
      cookieDomain,
      cookiePath,
      expiration,
      secureContent,
      sameSiteContent,
    );

    document.cookie = cookieToWrite;
  }
};

/**
 * Writes a cookie to the domain passed as a parameter.
 * Persistent cookies will have an expiration in 2037.
 * Blank cookies will be assumed to be cookie deletes, and will expire in the past.</summary>
 * @param name The name of the cookie
 * @param value The value of the cookie
 * @param secure Whether to make the cookie secure
 * @param persist Whether to make the cookie persistent
 * @param topLevel Whether the cookie should be written to the top level domain or the current domain
 * @param addDomainPrefix Whether to add a "." as a prefix to the cookie domain
 * @param path The path of the cookie. If not passed, the default "/" is used
 * @param sameSite The SameSite attribute of the cookie. If not passed, None is used for https requests and no value is used for http requests
 */
export const writeCookie = (
  name: string,
  value: string | Record<string, unknown>,
  secure?: boolean,
  persist?: boolean,
  topLevel?: boolean,
  addDomainPrefix?: boolean,
  path?: string,
  sameSite?: string,
) => {
  const prefix = addDomainPrefix ? "." : "";
  const parts = document.domain.split(".");

  if (topLevel) {
    parts.splice(0, Math.max(0, parts.length - 2));
  }

  const cookieDomain = prefix + parts.join(".");

  writeCookieWithExpiration(
    name,
    value,
    secure,
    persist ? getPersistDate() : undefined,
    cookieDomain,
    path,
    sameSite,
  );
};
