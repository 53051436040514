import { extractDomain, isEmailAddress, replaceTokens } from "../../../utilities/strings-helper";
import { Error, LoginMode, LoginStringsVariant } from "../login-constants";

/**
 * Extracts the domain name from the input username and replaces the placeholder portion of the input string with the extracted domain name.
 * @param str - The string to format.
 * @param username - The username to grab the domain name from.
 * @returns The input string with the placeholder text replaced with the domain name from the username.
 */
const replaceFedDomain = (str: string, username: string): string =>
  username && isEmailAddress(username)
    ? replaceTokens(str, extractDomain(username, false, true))
    : str;

/**
 * Replaces the placeholder portion of the input string with the input federated partner name.
 * @param str - The string to format.
 * @param fedPartnerName - The federated partner name.
 * @returns The input string with the placeholder text replaced with the federated partner name.
 */
const replaceFedPartner = (str: string, fedPartnerName: string): string =>
  fedPartnerName ? replaceTokens(str, fedPartnerName) : str;

/**
 * Returns the appropriate lightbox title to display based on the login mode supplied by the server and the string variant ID.
 * @param isBindFailedMode - whether the login mode is LoginMode.BindFailed.
 * @param stringVariantId identifier for the type of login is in progress.
 * @param defaultSignInHeader - the default title to use as supplied by the server.
 * @param appBrandedSignInHeader - the app-branded title to use as supplied by the server.
 * @returns The corresponding lightbox title for the current login mode and string variant ID combination.
 * TODO @mjg-flavors: This function should be "unflavored", which means extracting static strings. The strings should be passed as a
 * parameter which implements a common IRemoteNgcStrings interface. That way, this function can be used for all flavors (each of which will
 * pass their own version of the strings that implements the interface)
 */
export const getLightboxTitle = (
  isBindFailedMode: boolean,
  stringVariantId: LoginStringsVariant,
  defaultSignInHeader: string,
  appBrandedSignInHeader: string,
): string => {
  if (isBindFailedMode) {
    return getLocalString("AccountUnavailable_Title");
  }

  let lightboxTitle = "";
  switch (stringVariantId) {
    case LoginStringsVariant.CombinedSigninSignup:
      lightboxTitle = getLocalString("Login_DefaultHeading_SISU");
      break;

    case LoginStringsVariant.CombinedSigninSignupV2WelcomeTitle:
      lightboxTitle = getLocalString("Confirm_Signup_Title");
      break;

    default:
      lightboxTitle = defaultSignInHeader;
      break;
  }

  if (
    appBrandedSignInHeader &&
    ![LoginStringsVariant.SkypeMoveAlias, LoginStringsVariant.RemoteConnectLogin].includes(
      stringVariantId,
    )
  ) {
    lightboxTitle = appBrandedSignInHeader;
  }

  return lightboxTitle;
};

/**
 * Returns the appropriate error description based on the input login mode.
 * @param loginMode - The current login mode supplied by the server.
 * @param username - The username used to grab the federated domain name.
 * @param fedPartnerName - The federated partner name.
 * @returns The corresponding error description for the current login mode.
 */
export const getLoginModeErrorDesc = (
  loginMode: number,
  username: string,
  fedPartnerName: string,
): string => {
  const showGenericIdpFailed = !username || !fedPartnerName;

  switch (loginMode) {
    case LoginMode.BindFailed:
      return "";
    case LoginMode.SwitchUser:
    case LoginMode.SwitchUserMobile:
    case LoginMode.SwitchUserHost:
      return getLocalString("AlreadySignedIn_Title");
    case LoginMode.InviteBlocked:
      return replaceFedDomain(getLocalString("InviteBlocked_Error"), username);
    case LoginMode.ServiceBlocked:
      return replaceFedDomain(getLocalString("ServiceBlocked_Error"), username);
    case LoginMode.IDPFailed:
      return showGenericIdpFailed
        ? getLocalString("IDPFailed_GenericError")
        : replaceFedPartner(getLocalString("IDPFailed_Error"), fedPartnerName);
    case LoginMode.HIP_Lockout:
    case LoginMode.HIP_LockoutMobile:
    case LoginMode.HIP_LockoutHost:
      return getLocalString("Lockout_Title");
    default:
      return getLocalString("GenericError_Title");
  }
};

/**
 * Returns the appropriate error description to display based on the login mode and error code supplied by the server.
 * @param errorText - the error text to use as supplied by the server.
 * @param errorCode - the error code supplied by the server.
 * @returns The corresponding error description for the current login mode and error code.
 */
export const getErrorCodeDescription = (errorText: string, errorCode: string): string => {
  if (errorText && errorCode === Error.PP_E_IDP_BINDING_EXISTS_SAMSUNG) {
    // Return the new AXIS string instead of the server-supplied string so we can use the FormattedTextWithBindings component to
    // replace the embedded `manageCredsUrl` child with an anchor tag.
    return getLocalString("BindFailed_AlreadyBound_Desc_Samsung");
  }

  // @TODO: Use serverData.sErrorCode and other (possibly new) server data properties to determine which error text to show instead of
  //        relying on the string supplied by the server.
  return errorText;
};

/**
 * Returns the appropriate text to display for the username div based on the login mode supplied by the server.
 * @param username - the username supplied by the server.
 * @param isHipLockedMode - whether the login mode is one of the HIP locked modes.
 * @returns The corresponding username div text for the current login mode.
 */
export const getUsernameDescription = (username: string, isHipLockedMode: boolean): string =>
  isHipLockedMode ? replaceTokens(getLocalString("Lockout_Desc"), username) : username;

/**
 * Returns the appropriate text to display for the switch user URL based on the login mode supplied by the server.
 * @param isHipLockedMode - whether the login mode is one of the HIP locked modes.
 * @param isSwitchUserMode - whether the login mode is one of the switch user modes.
 * @returns The corresponding switch user URL text for the current login mode.
 */
export const getSwitchUrlText = (isHipLockedMode: boolean, isSwitchUserMode: boolean): string => {
  if (isHipLockedMode) {
    return getLocalString("Lockout_AnotherID_Text");
  }

  if (isSwitchUserMode) {
    return getLocalString("SwitchUser_SignOutThenSignIn");
  }

  return getLocalString("SwitchUser_DifferentID");
};
