import React, { useContext } from "react";
import { makeStyles, mergeClasses, shorthands } from "@griffel/react";
import { ExternalClassName, LayoutTemplateType } from "../constants/constants";
import { GlobalContext } from "../global-context";
import { useRouteAnimation } from "../hooks/use-route-animation";
import * as styleConstants from "../styles/fabric/constants-fabric.styles";
import * as buttonStyleConstants from "../styles/fabric/text-button-constants-fabric.styles";
import { isCssAnimationSupported } from "../utilities/browser-helper";

const NEGATIVE_LIGHTBOX_PADDING = `-${styleConstants.LIGHTBOX_PADDING}px`;

export const useBoilerPlateStyles = makeStyles({
  boilerPlateText: {
    wordWrap: "break-word",
    backgroundColor: "#f2f2f2",
    ...shorthands.padding(
      "24px",
      `${styleConstants.LIGHTBOX_PADDING}px`,
      "36px",
      `${styleConstants.LIGHTBOX_PADDING}px`,
    ),
    ...shorthands.margin(
      `${styleConstants.LIGHTBOX_PADDING + buttonStyleConstants.MINHEIGHT}px`,
      NEGATIVE_LIGHTBOX_PADDING,
      NEGATIVE_LIGHTBOX_PADDING,
      NEGATIVE_LIGHTBOX_PADDING,
    ),

    [styleConstants.MOBILE_LAYOUT_SIZE]: {
      ...shorthands.padding(`${styleConstants.SECTION_PADDING}px`),
      ...shorthands.margin(
        `${styleConstants.LIGHTBOX_MOBILE_PADDING + buttonStyleConstants.MINHEIGHT}px`,
        0,
        0,
        0,
      ),
    },

    "& > p:first-child": {
      marginTop: 0,
    },

    "& > p:last-child": {
      marginBottom: 0,
    },
  },
  transparent: {
    backgroundColor: "rgba(242, 242, 242, 0.2)",
  },
  verticalSplit: {
    marginBottom: 0,
    backgroundColor: "transparent",
    [styleConstants.MOBILE_LAYOUT_SIZE]: {
      backgroundColor: "transparent",
    },
  },
  transparentVerticalSplit: {
    [styleConstants.MOBILE_LAYOUT_SIZE]: {
      backgroundColor: "rgba(242, 242, 242, 0.2)",
    },
  },
});

/**
 * Renders the BoilerPlateText if it is defined
 * @returns BoilerPlateText component
 */
const BoilerPlateText: React.FC = function BoilerPlateText() {
  const {
    globalState: {
      styles: { useTransparentLightbox, layoutTemplate, boilerPlateText },
    },
  } = useContext(GlobalContext);

  const styles = useBoilerPlateStyles();
  const useAnimation = isCssAnimationSupported();
  const [, transitionView, onAnimationEnd] = useRouteAnimation();
  const useVerticalSplit = layoutTemplate === LayoutTemplateType.VerticalSplit;
  const classNames = mergeClasses(
    ExternalClassName.boilerPlateText,
    styles.boilerPlateText,
    useTransparentLightbox ? styles.transparent : "",
    useTransparentLightbox && useVerticalSplit ? styles.transparentVerticalSplit : "",
    useVerticalSplit ? styles.verticalSplit : "",
    useAnimation ? transitionView : "",
  );

  if (!boilerPlateText) return null;

  const html = { __html: boilerPlateText };

  return (
    <div
      data-testid="boilerPlateText"
      className={classNames}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={html}
      onAnimationEnd={onAnimationEnd}
    />
  );
};

export default BoilerPlateText;
